import { Button, Form, Grid, MainLayout } from "@ims/common";
import path from "node:path/win32";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useMatches, useNavigate, useParams } from "react-router-dom";
import Page from "../../../../core-ui/Page";
import ReuseForm from "../../../../core-ui/ReuseForm";
import { useUnitOptions } from "../../../../hooks/useUnitOptions";
import { IOptions } from "../../../../interfaces/options.interface";
import { getUnits } from "../../../../rtk/feature/unit/unitApi";
import { useAppDispatch, useAppSelector } from "../../../../rtk/hook";
import { getGeneralWorkById, postGeneralWork, updateGeneralWork } from "../../../../rtk/feature/general-work/generalWorkApi"
import { generalWorkCleanup, generalWorkFormCleanUp, IGeneralWorkData } from "../../../../rtk/feature/general-work/generalWorkSlice";
import { parseCommandLine } from "typescript";
import WorkForm, { IWorkForm } from "../components/WorkForm/WorkForm";

const GeneralWorkForm = () => {
    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        getValues,
        setValue,
    } = useForm<IWorkForm>();
    const { pathname } = useLocation();
    const params = useParams();


    const dispatch = useAppDispatch();
    const isEditMode = pathname.includes("/edit");
    const navigate = useNavigate();
    const id = params.id;
    const workId = params.workid

    const { unitOptions } = useUnitOptions();
    const { data: generalWorks, error, loading, formSubmitted, byId: { data: generalWork } } = useAppSelector((state) => state.generalWork)


    useEffect(() => {
        if (isEditMode && params.id && workId) {
            dispatch(getGeneralWorkById({ id: params.id, workId: workId }))
        }

    }, [])

    useEffect(() => {
        dispatch(getUnits());
        return () => { dispatch(generalWorkFormCleanUp()) }
    }, []);
    useEffect(() => {
        if (formSubmitted) {
            navigate('/plan-management/plan?tab=detailed-estimate')
        }
    }, [formSubmitted])
    const onSubmit = (data: IWorkForm) => {

        const finalData = { ...data, unit: data.unit.value, number: data.number }
        if (params.id && !isEditMode) {
            dispatch(postGeneralWork({ data: finalData, id: params.id }))
        };
        if (workId && isEditMode && params.id) {
            dispatch(updateGeneralWork({ data: finalData, id: params.id, workId: workId }))

        }
    };


    useEffect(() => {

        if (generalWork && isEditMode) {
            setValue("breadth", generalWork.breadth)
            setValue("height", generalWork.height)
            setValue("length", generalWork.length)
            setValue("name", generalWork.name)
            setValue("number", generalWork.number)
            setValue("remarks", generalWork.remarks)
            setValue("unit", { label: generalWork.unit.name, value: generalWork.unit.id })

        }

    }, [generalWork, isEditMode])


    const handleCancel = () => {
        navigate('/plan-management/plan')



    };


    return (
        <WorkForm title='General  work' handleFormSubmit={onSubmit} work={generalWork} />

    );
};

export default GeneralWorkForm;
