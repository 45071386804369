import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { FixedRateEquipmentFormTypeI } from "./fixedRateEquipmentSlice";
import { FixedEquipmentRateSuccess } from "../../../utils/res-mes/response-message-generator";

export const getFixedRateEquipments = createAsyncThunk(
  "getFixedRateEquipments",
  async (data: { rateID: string }, { dispatch, rejectWithValue }) => {
    const { rateID } = data;

    try {
      const res = await imsClient.get(`/rate-analysis/${rateID}/equipment-quantity-rate/`);

      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addFixedRateEquipment = createAsyncThunk(
  "addFixedRateEquipment",
  async (data: { id: string; data: FixedRateEquipmentFormTypeI }, { dispatch, rejectWithValue }) => {
    const { id } = data;
    try {
      const res = await imsClient.post(`/rate-analysis/${id}/equipment-quantity-rate/`, data.data);
      if (res.data) toast.success(FixedEquipmentRateSuccess("added"));
      dispatch(getFixedRateEquipments({ rateID: data.id }));

      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateFixedRateEquipment = createAsyncThunk(
  "updateFixedRateEquipment",
  async (data: { id: string; updateID: string; data: FixedRateEquipmentFormTypeI }, { dispatch, rejectWithValue }) => {
    const { id, updateID } = data;
    try {
      const res = await imsClient.patch(`/rate-analysis/${id}/equipment-quantity-rate/${updateID}/`, data.data);
      if (res.data) toast.success(FixedEquipmentRateSuccess("updated"));
      dispatch(getFixedRateEquipments({ rateID: data.id }));

      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteFixedRateEquipment = createAsyncThunk(
  "deleteFixedRateEquipment",
  async (data: { deleteID: string; id: string }, { dispatch, rejectWithValue }) => {
    const { deleteID, id } = data;
    try {
      await imsClient.delete(`/rate-analysis/${id}/equipment-quantity-rate/${deleteID}/`);
      toast.success(FixedEquipmentRateSuccess("deleted"));
      dispatch(getFixedRateEquipments({ rateID: data.id }));

      return data.deleteID;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
