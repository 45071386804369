import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table, { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getConstructorMembers } from "../../rtk/feature/constructor-member/constructorMemberApi";
import { ConstructorMemberTypeI } from "../../rtk/feature/constructor-member/constructorMemberSlice";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiSearch } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import Page from "../../core-ui/Page";
import { Button, Grid } from "@ims/common";
import useTranslated from "../../hooks/useTranslated";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { useForm } from "react-hook-form";
import { realTimeFilter } from "../../utils/real-time-filter";
import ReuseForm from "../../core-ui/ReuseForm";
import getFormattedSort from "../../utils/getFormattedSort";

function ConstructorMemberManagePage() {
  const [constructorMembers, setConstructorMembers] = useState<ConstructorMemberTypeI[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const constructorMembersState = useAppSelector((state) => state.constructorMembers);

  /* Table pagination states */
  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    reset: filterReset,
    formState: { errors: filterErrors },
    watch: filterWatch,
  } = useForm();

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getConstructorMembers({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    dispatch(showLoading(constructorMembersState.loading));
  }, [dispatch, constructorMembersState.loading]);

  useEffect(() => {
    if (constructorMembersState?.data) {
      setConstructorMembers(constructorMembersState.data?.results);
      setTotalItems(constructorMembersState.data?.total);
    }
  }, [constructorMembersState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/budget/constructor/member/edit/${id}`);
  };
  const { keyword } = useTranslated();
  const onFilterSubmit = (data: any) => {
    dispatch(
      getConstructorMembers({
        per_page: perPage,
        search: data.search,
        ordering: getFormattedSort(sorts),
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getConstructorMembers({
        per_page: perPage,
        page: page,
        ordering: getFormattedSort(sorts),
      })
    );
    filterReset();
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>{keyword["Constructor Member Manage"]}</Page.Title>
        <Button variant="primary" onClick={() => navigate(`/budget/constructor/member/add`)}>
          {keyword["Add Constructor Member"]}
        </Button>
      </Page.Container>

      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>
              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Post"]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Name"]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Sex"]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Mobile Number"]} </Table.HeadCol>
              <Table.HeadCol>{keyword["Citizenship No."]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Father/Mother"]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Husband/Wife"]}</Table.HeadCol>

              <Table.HeadCol align="center">{keyword["Action"]}</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {constructorMembers &&
              constructorMembers.length > 0 &&
              constructorMembers.map((constructorMember, index) => {
                return (
                  <Table.Row key={constructorMember.id}>
                    <Table.Col>{englishNepaliNumberTranslation(getSN(index))}</Table.Col>
                    <Table.Col>{constructorMember.designation}</Table.Col>
                    <Table.Col>{constructorMember.name}</Table.Col>
                    <Table.Col>{constructorMember.sex}</Table.Col>
                    <Table.Col>{constructorMember.mobile_number || "N/A"}</Table.Col>
                    <Table.Col>{constructorMember.citizenship_number || "N/A"}</Table.Col>
                    <Table.Col>{constructorMember.father || "N/A"}</Table.Col>
                    <Table.Col>{constructorMember.husband || "N/A"}</Table.Col>

                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(constructorMember.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default ConstructorMemberManagePage;
