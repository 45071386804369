import { HTMLAttributes } from "react";
import styled from "styled-components";
export type justifyFlexTypes =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly"
  | "initial"
  | "inherit";
interface IFlexStyles extends HTMLAttributes<HTMLDivElement> {
  gap?: string;
  align?: justifyFlexTypes;
  dir?: "row" | "column";
  justify?: justifyFlexTypes;
  height?: string;
}
export const FlexStyles = styled.div<IFlexStyles>`
width:100%;
height:100%;
height:${(props) => props.height || "100%"};
display:flex;
gap:${(props) => props.gap};
flex-direction:${(props) => props.dir}
align-items:${(props) => props.align || "flex-start"}
justify-content:${(props) => props.justify || "flex-start"};





`;
