import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useTranslated from "../../../hooks/useTranslated";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { getConstructorDetails } from "../../../rtk/feature/assign-constructor/assignConstructorApi";
import { showLoading } from "../../../rtk/feature/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { Button, MainLayout, Table } from "@ims/common";
import Page from "../../../core-ui/Page";
import { FiEdit } from "react-icons/fi";
import MinuteDocumentDetails from "./MinuteDocumentDetails";
import { AiFillFileAdd } from "react-icons/ai";
import { styleSN } from "../../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../../utils/translateNumber";

interface PreviewAssignedConstructorPropsI {
  budgetID: string;
  isWard?: boolean;
}
export default function PreviewAssignedConstructor({ budgetID, isWard }: PreviewAssignedConstructorPropsI) {
  const [assignedConstructorList, setAssigntedConstructorList] = useState<any>(null);
  const [selectedConstructor, setSelectedConstructor] = useState<string>("");
  const constructorsState = useAppSelector((state) => state.assignConstructor);
  const { keyword } = useTranslated();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getConstructorDetails(budgetID));

    return () => {
      abortController.abort();
    };
  }, [dispatch, budgetID]);

  useEffect(() => {
    dispatch(showLoading(constructorsState.loading));
  }, [dispatch, constructorsState.loading]);

  useEffect(() => {
    if (constructorsState.data) {
      setAssigntedConstructorList(constructorsState.data);
    }
  }, [constructorsState, dispatch]);

  const handleEdit = (id: string) => {
    // plan-management/plan/constructor-detail/:id
    if (isWard) navigate(`/plan-management/plan/constructor-detail/${id}?assign-constructor=true&back=true`);
    else navigate(`/plan-management/plan/constructor-detail/${id}?assign-constructor=true`);
  };

  return (
    <>
      <MainLayout.Main>
        <Page.Container>
          <Page.Title>{keyword["Assigned Constructors"]}</Page.Title>
          {/* <Button variant="primary" onClick={() => {}}>
            {keyword["Add constructor"]}
          </Button> */}
        </Page.Container>

        <Table.Container>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
                <Table.HeadCol> {keyword["ID"]}</Table.HeadCol>
                <Table.HeadCol> {keyword.Type}</Table.HeadCol>
                <Table.HeadCol> {keyword["Constructor Name"]}</Table.HeadCol>
                <Table.HeadCol> {keyword["User"]}</Table.HeadCol>

                <Table.HeadCol> {keyword.Yojana}</Table.HeadCol>
                <Table.HeadCol align="center"> {keyword.Action}</Table.HeadCol>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {assignedConstructorList &&
                assignedConstructorList.length > 0 &&
                assignedConstructorList.map((co: any, index: number) => {
                  return (
                    <Table.Row key={co.id}>
                      <Table.Col>{englishNepaliNumberTranslation(styleSN(index + 1))}</Table.Col>
                      <Table.Col>{co.contractor.code}</Table.Col>
                      <Table.Col>{co.contractor.type}</Table.Col>
                      <Table.Col>{co.contractor.name}</Table.Col>
                      <Table.Col>{`${co.user.first_name} ${co.user.last_name}`}</Table.Col>
                      <Table.Col>{co.yojana.name || "N/A"}</Table.Col>
                      <Table.Col align="center">
                        <Table.ActionButton onClick={() => handleEdit(co.contractor.id)}>
                          <FiEdit />
                        </Table.ActionButton>{" "}
                        <Table.ActionButton
                          onClick={() => setSelectedConstructor(co.yojana.id)}
                          title={keyword["Add Minute"]}
                        >
                          <AiFillFileAdd />
                        </Table.ActionButton>
                      </Table.Col>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          {/* <Pagination /> */}
        </Table.Container>
      </MainLayout.Main>
      <hr />
      <br />
      <Page.Container>
        <Page.Title>{keyword["Minute Document"]}</Page.Title>
      </Page.Container>

      {!!selectedConstructor ? (
        <MinuteDocumentDetails id={selectedConstructor} />
      ) : (
        <p>{keyword["Please select Assigned Constructor to add document"]}</p>
      )}
    </>
  );
}
