import { createSlice } from "@reduxjs/toolkit";
import { INetworkRResponse, IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { IYojana } from "../plan-verification/planVerificationSlice";
import { getBillPaymentById, getBillPayments, updateBillPayment } from "./billPaymentAPI";

export interface Root {

}
interface IUser {
    id: string
    username: string
    email: string
    mobile_number: string
    role: string
    ward: string
    first_name: string
    last_name: string
}

export interface PlanBillVerification {
    id: string
    planning_bill_id: PlanningBillId
    status: string
    description: string
    comment: string
    is_verified: boolean
    from_to_user: IUser
}

export interface PlanningBillId {
    id: string
    yojana: IYojana
    bill_name: string
    bill_file: string
    bill_review: boolean
    is_verified: boolean
}






export interface IBillPayment {
    id: string
    plan_bill_verification: PlanBillVerification
    cheque_verify_by_lekha: boolean
    cheque_photo: string
    send_to_user: IUser

}


interface IInitialStateById extends INetworkRResponse {
    data: null | IBillPayment
}


interface IInitialState extends INetworkRResponse {
    data: null | IPaginatedResponse<IBillPayment>,
    byId: IInitialStateById,
    formSubmitted: boolean,


}

const initialState: IInitialState = {
    data: null,
    error: null,
    loading: false,
    formSubmitted: false,
    byId: {
        data: null,
        error: null,
        loading: false
    }
}

const billPaymentSlice = createSlice({
    name: "bill-payment-slice",
    initialState,
    reducers: {
        cleanupBillPayment: (state) => {
            return initialState
        }
    },
    extraReducers(builder) {

        builder.addCase(getBillPayments.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.data = null

        });
        builder.addCase(getBillPayments.rejected, (state) => {
            state.loading = true;
            state.error = null;
            state.data = null

        });
        builder.addCase(getBillPayments.fulfilled, (state, { payload }) => {
            state.loading = true;
            state.error = null;
            state.data = payload
        });
        builder.addCase(getBillPaymentById.pending, (state) => {
            state.byId.loading = true;
            state.byId.error = null;
            state.byId.data = null

        });
        builder.addCase(getBillPaymentById.rejected, (state) => {
            state.byId.loading = true;
            state.byId.error = null;
            state.byId.data = null

        });
        builder.addCase(getBillPaymentById.fulfilled, (state, { payload }) => {
            state.byId.loading = true;
            state.byId.error = null;
            state.byId.data = payload
        });
        builder.addCase(updateBillPayment.fulfilled, (state, { payload }) => {
            state.formSubmitted = true
        });
        builder.addCase(updateBillPayment.pending, (state, { payload }) => {
            state.formSubmitted = false
        });

    },
});
export const { cleanupBillPayment } = billPaymentSlice.actions;
export default billPaymentSlice.reducer