import { createGlobalStyle } from "styled-components";
import { theme } from "./Theme";

export default createGlobalStyle`
    *,
    *::before,
    *::after {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    input, select, button, a, textarea {
      &:focus-visible {
        box-shadow: rgb(92 147 237 / 50%) 0px 0px 0px 2px;
        outline: none;
      }
    }

    html {
        box-sizing: border-box;
        font-size: 62.5%;
    }

    body {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        line-height: 1.43;
        font-size: 1.6rem;
        background: ${theme.palette.background.paper};
    }

    p {
        margin-bottom: 1rem;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0.5rem;
        font-weight: 500;
    }

    em, strong {
      font-size: inherit;
    }


    /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color:  #d8dde0 #f0f0f0;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 12px;
    
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #d8dde0;
    border-radius: 4px;
    border: 2px solid #ffffff;

    :hover {
        background: #bcc4c9;
        cursor: pointer
    }
  }
`;
