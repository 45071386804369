import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosRequestConfig } from "axios";
import { BiSearch } from "react-icons/bi";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { UserFormTypeI, UserUpdatePasswordTypeI } from "./usersSlice";
import useTranslated from "../../../hooks/useTranslated";
import { PasswordSuccess, UserSuccess } from "../../../utils/res-mes/response-message-generator";

// userParams:
//   | { per_page: number; page: number; signal?: AbortSignal; search?: string; query?: string }
//   | undefined,

export interface optionTypeI {
  params?: any;
  signal?: any;
}

export const getUsers = createAsyncThunk("getUsers", async (option: optionTypeI, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.get(`/user/`, {
      params: option.params,
      signal: option.signal,
    });
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});
export const getUpabhoktaSamitiUsers = createAsyncThunk(
  "getUpabhoktaSamitiUsers",
  async (option: optionTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`user/?role=Upabhokta+Samiti`, {
        params: option.params,
        signal: option.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getUser = createAsyncThunk("getUser", async (id: string) => {
  try {
    const res = await imsClient.get(`/user/${id}/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
  }
});

export const getProfile = createAsyncThunk("getProfile", async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.get(`/user/me/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
  }
});

export const addUser = createAsyncThunk("addUser", async (data: UserFormTypeI, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.post("/user/", data);
    if (res.data) toast.success(UserSuccess("added"));
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async (credentials: UserUpdatePasswordTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/user/${credentials.id}/change-password/`, { ...credentials.data });
      if (res.data) toast.success(PasswordSuccess("updated"));
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async (data: { data: UserFormTypeI | any; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/user/${data.id}/`, data.data);
      if (res.data) toast.success(UserSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
