import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Auth from "../../components/auth/Auth";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import useTranslated from "../../hooks/useTranslated";
import i18n from "../../utils/i18n";
import { FiscalYearCalculator } from "../../utils/fiscal-year";

interface PropsI {
  children?: React.ReactNode;
}

const AuthLayout = ({ children }: PropsI) => {
  const [language, setLanguage] = useState<"English" | "नेपाली">("English");

  const dispatch = useAppDispatch();
  const authSelector = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { keyword } = useTranslated();

  const { loading, authenticated, nagarpalika } = authSelector;

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    if (authenticated) {
      if (location.state?.prev) navigate(location.state.prev);
      else navigate("/");
    }
    dispatch(showLoading(loading));
  }, [authenticated, loading, dispatch, navigate, location]);

  const handleOnLoad = () => {
    const lng = localStorage.getItem("lng");

    if (lng === "np") {
      setLanguage("English");
      i18n.changeLanguage("np");
    } else if (lng === "en") {
      setLanguage("नेपाली");
      i18n.changeLanguage("en");
    }
  };

  const handleChange = () => {
    // const lng = event.target.value;

    if (language === "नेपाली") {
      i18n.changeLanguage("np");

      setLanguage("English");
      localStorage.setItem("lng", "np");
    } else if (language === "English") {
      i18n.changeLanguage("en");
      setLanguage("नेपाली");
      localStorage.setItem("lng", "en");
    }
  };

  return (
    <Auth>
      <Auth.AuthHeader>
        <img src="/logo.png" alt="Nepal Government" />
        <Auth.AuthHeaderTitle>
          {process.env.REACT_APP_ORGANIZATION_NAME} <br /> एकीकृत सेवा र सूचना व्यवस्थापन प्रणाली
        </Auth.AuthHeaderTitle>
      </Auth.AuthHeader>
      <Auth.AuthBody>
        <div style={{ paddingLeft: "1rem" }}>
          <img src="/animated-nepal-flag.gif" alt="animated-flag" style={{ marginBottom: "1rem" }} />
          <h1>
            Welcome to <br /> {nagarpalika?.name}
          </h1>
          <h2> Integrated Service and Information Management System</h2>
          <h3>स्वागत छ {nagarpalika?.nepali_name} एकीकृत सेवा र सूचना व्यवस्थापन प्रणाली</h3>
          <p>({FiscalYearCalculator(nagarpalika?.fiscal_year!)})</p>
        </div>
        <Auth.FormContainer>
          <img src="/logo.png" alt="nagarpalika-logo" width={80} />
          {children}
        </Auth.FormContainer>
      </Auth.AuthBody>
      <Auth.AuthFooter>
        <small>&copy; 2024 Uptechsys Pvt. Ltd. All Rights Reserved.</small>
        <p>
          {nagarpalika?.office_address ||
            process.env.REACT_APP_ORGANIZATION_ADDRESS ||
            "आँधीखोला, स्याङ्जा, गण्डकी प्रदेश नेपाल"}
        </p>
      </Auth.AuthFooter>
      {/* <Auth.AuthBanner>
        <div></div>
        <div style={{ marginTop: "-12rem" }}>
          <h2>{nagarpalika?.name}</h2>
          <h3>{nagarpalika?.nepali_name}</h3>
          <p>Integrated Service and Information Management System</p>
          <p style={{ fontSize: "16px" }}>({FiscalYearCalculator(nagarpalika?.fiscal_year!)})</p>
        </div>
      </Auth.AuthBanner>
      <Auth.FormContainer>
        {children}{" "}
        <p style={changeLngTextStyle}>
          {keyword["Change Language"]} :{" "}
          <span style={languageTextStyle} onClick={handleChange}>
            {language}
          </span>
        </p>
      </Auth.FormContainer> */}
    </Auth>
  );
};

export default AuthLayout;

const changeLngTextStyle = {
  fontSize: "13px",
  color: "#555",
  marginTop: "20px",
};

const languageTextStyle = {
  cursor: "pointer",
  fontStyle: "italic",
  textDecoration: "underline",
};
