import { Button, Flex, Table } from "@ims/common";
import Page from "../../../../core-ui/Page";
import useTranslated, { keywordTypes } from "../../../../hooks/useTranslated";
import { YojanaBookTypeI } from "../../../../rtk/feature/yojana-books/yojanaBooksSlice";
import { FaEdit, FaTrash, FaDownload } from "react-icons/fa";
import { useState } from "react";
import PopupModal from "../../../../components/PopupModal/PopupModal";
import YojanaBookTemplateChoice from "./YojanaBookTemplateChoice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { styleSN } from "../../../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../../../utils/translateNumber";

interface PreviewYojanaBooksDetailsI {
  budgetId: string;
  yojanaBooks: YojanaBookTypeI[];
  handleRemoveYojanaBook: (yojanaBookId: string) => void;
  isVerified: boolean;
}

export default function PreviewYojanaBooksDetails({
  budgetId,
  yojanaBooks,
  handleRemoveYojanaBook,
  isVerified,
}: PreviewYojanaBooksDetailsI) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  type columnTypes = keyof keywordTypes;

  const columns: columnTypes[] = ["S.N.", "Title", "File", "Action"];
  const { keyword } = useTranslated();
  const navigate = useNavigate();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleEditYojanaBook = (yojanaBookId: string) => {
    navigate(`/editor/${budgetId}/${yojanaBookId}/?edit=true`);
  };

  // const downloadPDF = async (pdfUrl: string, yojanaBookTitle: string) => {
  //   try {
  //     const response = await axios.get(pdfUrl, {
  //       responseType: "blob", // Important for downloading binary data like PDF
  //     });

  //     const blob = new Blob([response.data], { type: "application/pdf" });
  //     const url = URL.createObjectURL(blob);

  //     // Create a link element and trigger a click to download the PDF
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = yojanaBookTitle; // Set desired filename
  //     a.click();

  //     // Clean up resources
  //     URL.revokeObjectURL(url);
  //   } catch (error) {
  //     toast.error("Error downloading PDF");
  //   }
  // };

  const downloadPDF = async (pdfUrl: string, yojanaBookTitle: string) => {
    if (pdfUrl) {
      const response = await fetch(pdfUrl);

      const blob = await response.blob();

      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.style.display = "none";

      a.href = url;

      a.download = yojanaBookTitle;

      document.body.appendChild(a);

      a.click();

      URL.revokeObjectURL(url);
    }
  };

  return (
    <>
      <Page.Container>
        <Flex align="center" justify="space-between">
          <Page.Title>{keyword["Yojana Book Details"]}</Page.Title>
          {isVerified && (
            <Page.ButtonGrp>
              <Button variant="primary" style={{ display: "flex", alignItems: "center" }} onClick={openModal}>
                {keyword["Add"]} +
              </Button>
            </Page.ButtonGrp>
          )}
        </Flex>
      </Page.Container>
      <Table.Container>
        <Table id="yojana-books-details">
          <Table.Head>
            <Table.Row>
              {columns.map((column, i) => (
                <Table.HeadCol key={i}>{keyword[column]} </Table.HeadCol>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {yojanaBooks?.map((book, idx) => {
              return (
                <Table.Row key={book.id}>
                  <Table.Col> {englishNepaliNumberTranslation(styleSN(idx + 1))} </Table.Col>
                  <Table.Col> {book.title} </Table.Col>
                  <Table.Col>
                    {book.pdf_file || book.pdf_file_url ? (
                      <a href={book.pdf_file || book.pdf_file_url} target="_blank" style={{ textDecoration: "none" }}>
                        view
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </Table.Col>
                  <Table.Col align="center">
                    <Flex style={{ flexWrap: "nowrap", width: "11rem", justifyContent: "space-between" }}>
                      <Table.ActionButton
                        onClick={() => {
                          downloadPDF(book.pdf_file || book.pdf_file_url || "", book.title);
                        }}
                      >
                        <FaDownload />{" "}
                      </Table.ActionButton>
                      {isVerified && (
                        <Table.ActionButton
                          onClick={() => {
                            handleEditYojanaBook(book.id);
                          }}
                        >
                          <FaEdit />
                        </Table.ActionButton>
                      )}
                      {isVerified && (
                        <Table.ActionButton
                          color="red"
                          onClick={() => {
                            handleRemoveYojanaBook(book.id);
                          }}
                        >
                          {" "}
                          <FaTrash />
                        </Table.ActionButton>
                      )}
                    </Flex>
                  </Table.Col>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Table.Container>
      <PopupModal isOpen={isModalOpen} onClose={closeModal}>
        <YojanaBookTemplateChoice budgetId={budgetId} onClose={closeModal} />
      </PopupModal>
    </>
  );
}
