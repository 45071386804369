import * as XLSX from "xlsx";

export default class TableUtility {
  protected name = "export-table";
  protected customStyle = "";

  constructor(name?: string, customStyle?: string) {
    if (name) this.name = name;
    if (customStyle) this.customStyle = customStyle;
  }

  print = () => {
    const tableToPrint = document.getElementById(this.name)!.outerHTML;
    let printHtml = htmlToPrint + tableToPrint;
    if (this.customStyle) printHtml += this.customStyle;

    const a = window.open("", "", "height=1024, width=1024")!;
    a.document.write(printHtml);
    a.document.title = 'Demo Rural municipality';
    a.print();
    a.close();
  };

  export = () => {
    const table = document.getElementById(this.name);
    if (!table) return;
    let workbook = XLSX.utils.table_to_book(table);
    XLSX.writeFile(workbook, `${table.title ? table.title : "Excel Sheet"}.xlsx`);
  };
}

export let htmlToPrint = `
<style>
  table * {
    color: black;
    background: transparent!important;
  }

  table {
    width: 100%;
    text-align: left;
    border: 1px solid #000;
    border-width: 1px 1px 1px 1px !important;
    border-collapse: collapse;
    font-size: 14px;
  }

  th, td {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
  }

  table tr td:last-child,
  table tr th:last-child {
    display: none;
  }

  td *{
    display: inline-block;
    margin-right: 5px;
  }
  
  
  td img {
    width: 40px;
  }

  input, textarea,
  button, svg{
    display: none!important;
  }
  
  .hidden-school-name {
    display: block !important;
  }

  @media print{    
    .no-print, .no-print *{
      display: none !important;
    }
  }

</style>
`;
