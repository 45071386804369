import { createAsyncThunk } from "@reduxjs/toolkit";
import async from "react-select/dist/declarations/src/Async";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { BillSuccess } from "../../../utils/res-mes/response-message-generator";

export const getBillplanning = createAsyncThunk(
  "getBillplanning",
  async (
    userParams: { per_page: number; page?: number; search?: any; ordering?: any; signal?: AbortSignal } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get(`/planning-bill/`, {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getBillPlanningById = createAsyncThunk("getBillplanningById", async (id: string, { rejectWithValue }) => {
  try {
    const res = await imsClient.get(`/planning-bill/${id}/`);
    return res.data;
  } catch (error) {
    const appError = new AppError(error);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

interface IUpdatePlanningBill {
  data: FormData;
  id: string;
}

export const updateBillPlanning = createAsyncThunk(
  "updateBillPlanning",
  async ({ data, id }: IUpdatePlanningBill, { rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/planning-bill/${id}/`, data, {
        headers: { "Content-Type": "multipart/form" },
      });
      if (res.data) toast.success(BillSuccess("updated"));
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

// interface IPostBillVerification {
//     data: any
// }
// export const postBillVerification = createAsyncThunk("getBillplanningById", async (data: IPostBillVerification, { rejectWithValue }) => {
//     try {
//         const res = await imsClient.post(`/bill-planning/`, data);
//         toast.success('data posted successfully')
//         return res.data
//     } catch (error) {
//         const appError = new AppError(error);
//         appError.show();
//         return rejectWithValue(appError.getError())
//     }
