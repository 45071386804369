import { Table } from "@ims/common";
import { AdminMinuteI } from "../../rtk/feature/admin-minute/adminMinuteSlice";
import { UpabhoktaSamitiMinuteI } from "../../rtk/feature/upabhokta-samiti-minute/upabhoktaSamitiMinuteSlice";
import GalleryCard from "../constructor/GalleryCard";
import MinutCard from "./MinuteCard";
import useTranslated from "../../hooks/useTranslated";
import { Link } from "react-router-dom";
import { StyledPrevewFile } from "../plan/RelativeDocs/components/PreviewRelativeDocs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { styleSN } from "../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { getSN } from "../../utils/usePagination";

interface PreviewMinutDocPropsI {
  Udocs?: UpabhoktaSamitiMinuteI[] | null;
  Adocs?: AdminMinuteI[] | null;
  clickHandler?: (id: string) => void;
  isEdit?: boolean;
}

export default function PreviewMinutDoc({ Udocs, Adocs, isEdit, clickHandler }: PreviewMinutDocPropsI) {
  const { keyword } = useTranslated();

  const columns: ["S.N.", "Title", "File", "Comments", "Action"] = ["S.N.", "Title", "File", "Comments", "Action"];

  return (
    <>
      <br />
      <Table.Container>
        <Table id="preview-minut-docs">
          <Table.Head>
            <Table.Row>
              {columns.map((column, i) => {
                if ((column === "Title" && Adocs?.length) || (!isEdit && column === "Action")) return;
                return <Table.HeadCol key={i}>{keyword[column]} </Table.HeadCol>;
              })}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {Udocs?.length &&
              Udocs.map((doc, idx) => {
                return (
                  <Table.Row key={doc.id}>
                    <Table.Col> {englishNepaliNumberTranslation(styleSN(idx + 1))} </Table.Col>
                    <Table.Col> {doc.title.title} </Table.Col>
                    <Table.Col>
                      <Link to={doc.file_url} target="_blank">
                        <StyledPrevewFile alt="Minut Docs" src={doc.file_url} />
                      </Link>
                    </Table.Col>
                    <Table.Col> {doc.comments} </Table.Col>
                    {!!clickHandler && (
                      <Table.Col align="center">
                        <Table.ActionButton onClick={() => clickHandler(doc.id || "")}>
                          <FiEdit />
                        </Table.ActionButton>
                      </Table.Col>
                    )}
                  </Table.Row>
                );
              })}
            {Adocs &&
              Adocs.map((doc, idx) => {
                return (
                  <Table.Row key={doc.id}>
                    <Table.Col> {englishNepaliNumberTranslation(styleSN(idx + 1))} </Table.Col>
                    <Table.Col>
                      <Link to={doc.file_url} target="_blank">
                        <StyledPrevewFile alt="Minut Docs" src={doc.file_url} />
                      </Link>
                    </Table.Col>
                    <Table.Col> {doc.comments} </Table.Col>

                    {!!clickHandler && (
                      <Table.Col align="center">
                        <Table.ActionButton onClick={() => clickHandler(doc.id || "")}>
                          <FiEdit />
                        </Table.ActionButton>
                      </Table.Col>
                    )}
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Table.Container>
    </>
  );
}
