import { Button, Grid, MainLayout, Table } from "@ims/common";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Page from "../../core-ui/Page";
import useTranslated from "../../hooks/useTranslated";
import { getDistrictRate } from "../../rtk/feature/district-rate/districtRateApi";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import usePagination from "../../utils/usePagination";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import getFormattedSort from "../../utils/getFormattedSort";
import { FiFilter, FiSearch } from "react-icons/fi";
import ReuseForm from "../../core-ui/ReuseForm";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { realTimeFilter } from "../../utils/real-time-filter";

const DisctrictRate = () => {
  const dispatch = useAppDispatch();
  const [districtRates, setDistrictRates] = useState<any[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});

  const [totalPages, setTotalPages] = useState(10);
  const [totalItems, setTotalItems] = useState(1);
  const { Pagination, page, perPage, getSN } = usePagination({ total: totalItems });

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    formState: { errors: filterErrors },
    reset: filterReset,
    watch: filterWatch,
  } = useForm();

  useEffect(() => {
    const abortController = new AbortController();

    dispatch(
      getDistrictRate({
        params: {
          page,
          per_page: perPage,
        },
        signal: abortController.signal,
      })
    );
    return () => {
      abortController.abort();
    };
  }, [page, perPage]);
  const districtRatesSelector = useAppSelector((state) => state.districtRate.data);
  const { keyword } = useTranslated();

  const navigate = useNavigate();
  const columns = [
    keyword["S.N."],
    keyword["Particular"],
    keyword["Analysis Rate"],
    keyword["Unit"],
    keyword["Remarks"],
    keyword["Actions"],
  ];

  useEffect(() => {
    if (districtRatesSelector) {
      setTotalItems(districtRatesSelector.total);
      setDistrictRates(districtRatesSelector?.results);
    }
  }, [districtRatesSelector]);

  const handleEdit = (id: string) => {
    navigate(`/district-rate/edit/${id}`);
  };

  const onFilterSubmit = (data: any) => {
    dispatch(
      getDistrictRate({
        params: { per_page: perPage, search: data.search, ordering: getFormattedSort(sorts) },
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getDistrictRate({
        params: { per_page: perPage, page: page, ordering: getFormattedSort(sorts) },
      })
    );
    filterReset();
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {keyword["District Rate"]} </Page.Title>
        <Page.ButtonGrp>
          <Button startIcon={<AiOutlinePlus />} variant="primary" onClick={() => navigate(`/district-rate/add`)}>
            {keyword["Add District Rate"]}
          </Button>
        </Page.ButtonGrp>
      </Page.Container>
      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>
              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
      <Table.Container id="budget-table">
        <Table width="100%">
          <Table.Row width="100%">
            {columns.map((col, i) => {
              return <Table.HeadCol key={col}> {col} </Table.HeadCol>;
            })}
          </Table.Row>
          <Table.Body>
            {districtRates.map((rate, idx) => {
              return (
                <Table.Row>
                  <Table.Col> {englishNepaliNumberTranslation(getSN(idx))} </Table.Col>
                  <Table.Col> {rate.particular} </Table.Col>
                  <Table.Col> {englishNepaliNumberTranslation(rate.analysis_rate)} </Table.Col>
                  <Table.Col> {rate.unit.name} </Table.Col>
                  <Table.Col> {rate.remarks} </Table.Col>

                  <Table.Col align="center">
                    {" "}
                    <Table.ActionButton onClick={() => handleEdit(rate.id)}>
                      {" "}
                      <BiEdit />{" "}
                    </Table.ActionButton>{" "}
                  </Table.Col>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
};

export default DisctrictRate;
