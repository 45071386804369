import { createSlice } from "@reduxjs/toolkit";
import { addWard, deleteWard, getWard, getWards, updateWard } from "./wardApi";
import { keywordTypes } from "../../../hooks/useTranslated";

// const trType = typeof keywordTypes;

export interface WardFormTypeI {
  nagarpalika: string;
  ward_name: string;
  ward_address: string;
  ward_number: number;
}

export interface Nagarpalika {
  id: string;
  url: string;
  name: string;
}

export interface WardTypeI {
  id: string;
  nagarpalika: Nagarpalika;
  // ward_name: string;
  ward_name: keyof keywordTypes;
  ward_number: number;
  ward_address: string;
}

export interface WardsSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: WardTypeI[];
  } | null;
  item: WardTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: WardsSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const wardsSlice = createSlice({
  name: "wards",
  initialState,
  reducers: {
    wardsCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addWard.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addWard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addWard.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getWards.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getWards.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getWards.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getWard.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getWard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getWard.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateWard.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateWard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateWard.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteWard.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteWard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteWard.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { wardsCleanUp } = wardsSlice.actions;
export default wardsSlice.reducer;
