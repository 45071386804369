import React, { useContext, useEffect, useState } from "react";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Form } from "@ims/common";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { updatePassword, updateUser } from "../../rtk/feature/users/usersApi";
import { TabContext } from "../user-profile/UserProfile";
import { usersCleanUp } from "../../rtk/feature/users/usersSlice";
import styled from "styled-components";
import { getActivityLog } from "../../rtk/feature/auth/authApi";
import usePagination from "../../utils/usePagination";
import moment from "moment";
import { trimText } from "@ims/common/src/utils/trimText";

export type CredentialInputs = {
  old_password: string;
  password: string;
  confirm_password: string;
};

export interface CredentialInformationProps {
  curUser: any;
}

function ActivityLogPane(props: CredentialInformationProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<CredentialInputs>();

  const { user, activityLog } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [totalPages, setTotalPages] = useState(0);
  const { Pagination, perPage, page } = usePagination({ total: totalPages });

  useEffect(() => {
    
    if (props.curUser && props.curUser.id) {
      dispatch(getActivityLog({ userID: props.curUser.id, per_page: perPage, page: page }));
    }
  }, [props.curUser, dispatch, perPage, page]);

  useEffect(() => {
    if (activityLog) {
      setTotalPages(activityLog.total);
    }
  }, [activityLog, props.curUser]);

  return (
    <React.Fragment>
      <StyledWrapper>
        {activityLog &&
          activityLog.results &&
          activityLog?.results.map((item, index) => (
            <StyledContainer key={index}>
              <StyledAvatar alt="profile" src="http://localhost:3000/logo.png" />
              <div className="avatar-content">
                <div className="avatar-heading">
                  <StyledTitle>{trimText(item.user.full_name_english, 35)}</StyledTitle>
                  <StyledSmall>{moment(item.timestamp).format("MMMM Do YYYY, h:mm:ss a")}</StyledSmall>
                </div>
                <StyledSubTitle>{item.message}</StyledSubTitle>
              </div>
            </StyledContainer>
          ))}

        {!activityLog ||
          (!activityLog?.results && <StyledAlert style={{ textAlign: "center" }}>No Activities ...</StyledAlert>)}
      </StyledWrapper>
      <Pagination />
    </React.Fragment>
  );
}

export default ActivityLogPane;

export const StyledAlert = styled.p`
  padding: 1.5rem;
  background: #efefef;
  text-align: center;
  opacity: 0.4;
  margin-bottom: 0px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  grid-gap: 1rem;
  flex-direction: column;
`;

export const StyledContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  background: aliceblue;
  padding: 10px 10px 10px 5px;
  border-radius: 1rem;

  .avatar-content {
    width: 100%;
    .avatar-heading {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (max-width: 780px) {
    .avatar-content .avatar-heading {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
export const StyledAvatar = styled.img`
  width: 45px;
  height: 45px;
  grid-area: 1 / 1 / 3 / 1;
  border: 1px solid #ddd;
  border-radius: 50%;
  object-fit: cover;
  padding: 2px;
`;
export const StyledTitle = styled.h4`
  grid-area: 1 / 2 / 1 / 2;
  margin-bottom: 0px;
  line-height: 1;
  font-size: 1.4rem;
`;
export const StyledSubTitle = styled.p`
  grid-area: 2 / 2 / 2 / 4;
  margin-bottom: 0px;
  line-height: 1.2;
  font-size: 1.4rem;
`;
export const StyledSmall = styled.small`
  text-align: right;
`;
