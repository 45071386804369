import React, { useContext, useEffect, useState } from "react";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Form } from "@ims/common";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { updatePassword, updateUser } from "../../rtk/feature/users/usersApi";
import { TabContext } from "../user-profile/UserProfile";
import { usersCleanUp } from "../../rtk/feature/users/usersSlice";
import { current } from "@reduxjs/toolkit";
import useTranslated from "../../hooks/useTranslated";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export type CredentialInputs = {
  old_password: string;
  password: string;
  confirm_password: string;
};

export interface CredentialInformationProps {
  curUser: any;
}

function CredentialInformationPane(props: CredentialInformationProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<CredentialInputs>();

  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const { hasPasswordChanged } = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();

  const { setCurrentTab } = useContext(TabContext);

  useEffect(() => {
    if (hasPasswordChanged) {
      setCurrentTab("basic");
      dispatch(usersCleanUp());
      reset();
    }
  }, [hasPasswordChanged, reset, setCurrentTab, dispatch]);

  const onSubmit: SubmitHandler<CredentialInputs> = (data) => {
    if (props.curUser.id) {
      dispatch(updatePassword({ data, id: props.curUser.id }));
    }
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            {props.curUser.is_superuser && (
              <Grid.Col spanMd={6} spanXs={12}>
                <Form.FormGroup error={Boolean(errors && errors["old_password"])}>
                  <Form.Label>{keyword["Old Password"]}</Form.Label>
                  <Form.Input
                    type="password"
                    placeholder={keyword["Enter your old password"]}
                    register={register("old_password", {
                      required: "Your old password is required.",
                    })}
                  />
                  {errors && errors["old_password"] && errors["old_password"].message && (
                    <Form.HelperText>{errors["old_password"].message}</Form.HelperText>
                  )}
                </Form.FormGroup>
              </Grid.Col>
            )}
          </Grid.Row>

          <br />
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["password"])}>
                <div style={{ position: "relative" }}>
                  <Form.Label>{keyword["New Password"]}</Form.Label>
                  <Form.Input
                    type={showNewPassword ? "text" : "password"}
                    placeholder={keyword["Enter your new password"]}
                    register={register("password", {
                      required: "Your new password is required.",
                    })}
                  />
                  {errors && errors["password"] && errors["password"].message && (
                    <Form.HelperText>{errors["password"].message}</Form.HelperText>
                  )}
                  <span style={{ position: "absolute", right: "22px", top: "34px", cursor: "pointer" }}>
                    {showNewPassword ? (
                      <AiFillEyeInvisible size={18} onClick={() => setShowNewPassword((prevState) => !prevState)} />
                    ) : (
                      <AiFillEye size={18} onClick={() => setShowNewPassword((prevState) => !prevState)} />
                    )}
                  </span>
                </div>
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["confirm_password"])}>
                <div style={{ position: "relative" }}>
                  <Form.Label>{keyword["Confirm New Password"]}</Form.Label>
                  <Form.Input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder={keyword["Confirm your new password"]}
                    register={register("confirm_password", {
                      required: "Your new confirm password is required.",
                    })}
                  />
                  {errors && errors["confirm_password"] && errors["confirm_password"].message && (
                    <Form.HelperText>{errors["confirm_password"].message}</Form.HelperText>
                  )}
                  <span style={{ position: "absolute", right: "22px", top: "34px", cursor: "pointer" }}>
                    {showConfirmPassword ? (
                      <AiFillEyeInvisible size={18} onClick={() => setShowConfirmPassword((prevState) => !prevState)} />
                    ) : (
                      <AiFillEye size={18} onClick={() => setShowConfirmPassword((prevState) => !prevState)} />
                    )}
                  </span>
                </div>
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanXs={12}>
              <Button variant="primary">{keyword["Change Password"]}</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Form>
    </React.Fragment>
  );
}

export default CredentialInformationPane;
