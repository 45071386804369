import { createAsyncThunk } from "@reduxjs/toolkit";
import { imsClient } from "../../../utils/imsClient";
import { AppError } from "../../../utils/AppError";
import { toast } from "react-toastify";
import { CreateYojanaBookTypeI, YojanaBookTypeI } from "./yojanaBooksSlice";
import { YojanaBookSuccess } from "../../../utils/res-mes/response-message-generator";

const yojanaBooksUrl = (budgetID: string, id = ""): string => {
  return id ? `/yojana/${budgetID}/book-details/${id}/` : `/yojana/${budgetID}/book-details/`;
};

export const getYojanaBooks = createAsyncThunk(
  "getYojanaBooks",
  async (
    planbriefParams: { per_page: number; page: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/yojana/", {
        params: planbriefParams,
        signal: planbriefParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getYojanaBook = createAsyncThunk("getYojanaBook", async (budgetID: string) => {
  try {
    const res = await imsClient.get(yojanaBooksUrl(budgetID));
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
  }
});

export const addYojanaBook = createAsyncThunk(
  "addYojanaBook",
  async (data: { data: CreateYojanaBookTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(yojanaBooksUrl(data.id), data.data, {
        headers: { "Content-Type": "application/json" },
      });
      if (res.data) toast.success(YojanaBookSuccess("added"));

      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateYojanaBook = createAsyncThunk(
  "updateYojanaBook",
  async (
    { data, id, budgetID }: { data: YojanaBookTypeI; id: string; budgetID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.patch(yojanaBooksUrl(budgetID, id), data, {
        headers: { "Content-Type": "application/json" },
      });
      if (res.data) toast.success(YojanaBookSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const removeYojanaBook = createAsyncThunk(
  "removeYojanaBook",
  async ({ id, budgetID }: { id: string; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.delete(yojanaBooksUrl(budgetID, id));
      if (res.data) toast.success(YojanaBookSuccess("deleted"));
      // return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
