import { MainLayout, Table } from "@ims/common";
import Page from "../../core-ui/Page";
import useTranslated from "../../hooks/useTranslated";
import styled from "styled-components";
import CreateMinutDoc from "./CreateMinuteDoc";
import { useNavigate, useParams } from "react-router-dom";
import PreviewMinutDoc from "./PreviewMinuteDoc";
import { useContext, useEffect, useState } from "react";
import { AdminMinuteI } from "../../rtk/feature/admin-minute/adminMinuteSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getUpahboktaSamitiMinute } from "../../rtk/feature/upabhokta-samiti-minute/upabhoktaSamitiMinuteApi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { getAdminMinutes } from "../../rtk/feature/admin-minute/adminMinuteApi";
import { ConstructorTypeI } from "../../rtk/feature/constructor/constructorSlice";
import { FiEdit } from "react-icons/fi";
import { getFilteredConstructorDetails } from "../../rtk/feature/assign-constructor/assignConstructorApi";
import { UpabhoktaSamitiMinuteI } from "../../rtk/feature/upabhokta-samiti-minute/upabhoktaSamitiMinuteSlice";
import { AiFillFileAdd } from "react-icons/ai";
import { LayoutContext } from "@ims/common/src/core-ui/layout/MainLayout";
import { styleSN } from "../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";

export default function MinutDocument() {
  const [minutDocuments, setMinutDocuments] = useState<AdminMinuteI[] | null>([]);
  const [myMinutDocuments, setMyMinutDocuments] = useState<UpabhoktaSamitiMinuteI[] | null>([]);
  const [selectedMinut, setSelectedMinut] = useState<UpabhoktaSamitiMinuteI | null>(null);

  const [assignedConstructorList, setAssigntedConstructorList] = useState<any>(null);
  const [yojanaId, setYojanaId] = useState<string | null>(null);
  const [samitiId, setSamitiId] = useState<string>("");

  const { id } = useParams();
  const { keyword } = useTranslated();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setExpanded } = useContext(LayoutContext);

  const adminMinutSelector = useAppSelector((state) => state.adminMinute);
  const constructorsState = useAppSelector((state) => state.assignConstructor);
  const upabhoktaSamitiMinutSelector = useAppSelector((state) => state.upabhoktaSamitiMinute);

  useEffect(() => {
    dispatch(showLoading(adminMinutSelector.loading || upabhoktaSamitiMinutSelector.loading));
  }, [dispatch, adminMinutSelector.loading, upabhoktaSamitiMinutSelector.loading]);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getFilteredConstructorDetails());

    return () => {
      abortController.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (constructorsState.data.length) {
      setAssigntedConstructorList(constructorsState.data);
    }
  }, [constructorsState, dispatch]);

  useEffect(() => {
    if (yojanaId) {
      dispatch(getAdminMinutes({ id: yojanaId }));
      dispatch(getUpahboktaSamitiMinute({ id: yojanaId }));
      setExpanded(false);
    }
  }, [yojanaId]);

  useEffect(() => {
    setMinutDocuments(adminMinutSelector.data);
  }, [adminMinutSelector.data]);

  useEffect(() => {
    setMyMinutDocuments(upabhoktaSamitiMinutSelector.data);
  }, [upabhoktaSamitiMinutSelector.data]);

  const handleEdit = (id: string) => {
    navigate(`/plan-management/plan/constructor-detail/${id}?assign-constructor=true&user=upabhokta`);
  };

  const setMinutHandler = (id: string) => {
    const matchedMinutDoc = myMinutDocuments?.find((doc) => doc.id === id);
    if (matchedMinutDoc) setSelectedMinut(matchedMinutDoc);
  };

  return (
    <>
      <MainLayout.Main>
        <Page.Container>
          <Page.Title>{keyword["Assigned Constructors"]}</Page.Title>
        </Page.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol> {keyword["ID"]}</Table.HeadCol>
              <Table.HeadCol> {keyword.Type}</Table.HeadCol>
              <Table.HeadCol> {keyword["Constructor Name"]}</Table.HeadCol>
              <Table.HeadCol> {keyword["User"]}</Table.HeadCol>

              <Table.HeadCol> {keyword.Yojana}</Table.HeadCol>
              <Table.HeadCol align="center"> {keyword.Action}</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {assignedConstructorList &&
              assignedConstructorList.length > 0 &&
              assignedConstructorList.map((co: any, index: number) => {
                return (
                  <Table.Row key={co.id}>
                    <Table.Col>{englishNepaliNumberTranslation(styleSN(index + 1))}</Table.Col>
                    <Table.Col>{co.contractor.code}</Table.Col>
                    <Table.Col>{co.contractor.type}</Table.Col>
                    <Table.Col>{co.contractor.name}</Table.Col>
                    <Table.Col>{`${co.user.first_name} ${co.user.last_name}`}</Table.Col>
                    <Table.Col>{co.yojana.name || "N/A"}</Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(co.contractor.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                      <Table.ActionButton
                        onClick={() => {
                          setYojanaId(co.yojana.id);
                          setSamitiId(co.id);
                        }}
                        title={keyword["Add Minute"]}
                      >
                        <AiFillFileAdd />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </MainLayout.Main>
      <MainLayout.Main>
        <Page.Container>
          <Page.Title>{keyword["Minute Document"]}</Page.Title>
        </Page.Container>
        {yojanaId ? (
          <ColumnContainer>
            <FirstColumn>
              <CreateMinutDoc yojanaId={yojanaId} id={samitiId} isUpabhokta={true} Udoc={selectedMinut} />
            </FirstColumn>
            <SecondColumn>
              <h4>{keyword["Sent Document"]}</h4>

              {myMinutDocuments?.length ? (
                <PreviewMinutDoc Udocs={myMinutDocuments} clickHandler={setMinutHandler} isEdit={true} />
              ) : (
                <p style={{ fontSize: "1.3rem" }}>{keyword["No minute document found"]}</p>
              )}
            </SecondColumn>
            <ThirdColumn>
              <h4>{keyword["Received  Document"]}</h4>
              {minutDocuments?.length ? (
                <PreviewMinutDoc Adocs={minutDocuments} />
              ) : (
                <p style={{ fontSize: "1.3rem" }}>{keyword["No minute document found"]}</p>
              )}
            </ThirdColumn>
          </ColumnContainer>
        ) : (
          <p>{keyword["Please select Assigned Constructor to add document"]}</p>
        )}
      </MainLayout.Main>
    </>
  );
}

const FirstColumn = styled.div`
  flex: 2;
  width: 32%;
  border-right: 1px solid rgb(204, 204, 204);
  margin-right: 10px;
  border-radius: 10px;
  padding: 16px;
`;

const SecondColumn = styled.div`
  flex: 3;
  width: 34%;
  border-left: 1px solid rgb(204, 204, 204);
  padding: 16px;
  border-radius: 10px;
`;
const ThirdColumn = styled.div`
  flex: 3;
  width: 34%;
  border-left: 1px solid rgb(204, 204, 204);
  padding: 16px;
  border-radius: 10px;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
`;
