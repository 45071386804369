import { Button, Grid, MainLayout, Table } from "@ims/common";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Page from "../../core-ui/Page";
import useTranslated from "../../hooks/useTranslated";
import {
  getAllDorEquipmentHireRate,
  getDorEquipmentHireRateById,
} from "../../rtk/feature/dor-equipment-hire-rate/dorEquipmentHireRateApi";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import usePagination from "../../utils/usePagination";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { useForm } from "react-hook-form";
import { realTimeFilter } from "../../utils/real-time-filter";
import ReuseForm from "../../core-ui/ReuseForm";
import { FiSearch } from "react-icons/fi";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import getFormattedSort from "../../utils/getFormattedSort";

const DOREquipmentHireRate = () => {
  const [totalItems, setTotalItems] = useState(10);
  const [dorEquipmentRates, setDorEquipmentRates] = useState<any>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});

  const dorEquipmentRatesSelector = useAppSelector((state) => state.dorEquipmentHireRate.data);

  const { Pagination, page, perPage, getSN } = usePagination({ total: totalItems });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    reset: filterReset,
    formState: { errors: filterErrors },
    watch: filterWatch,
  } = useForm();

  useEffect(() => {
    if (!!dorEquipmentRatesSelector) {
      setTotalItems(dorEquipmentRatesSelector?.total);
      setDorEquipmentRates(dorEquipmentRatesSelector?.results);
    }
  }, [dorEquipmentRatesSelector]);
  const { keyword } = useTranslated();

  useEffect(() => {
    const abortController = new AbortController();

    dispatch(getAllDorEquipmentHireRate({ params: { page, per_page: perPage }, signal: abortController.signal }));
    return () => {
      abortController.abort();
    };
  }, [page, perPage]);

  const columns = [
    keyword["S.N."],
    keyword["Description"],
    keyword["Unit"],
    keyword["Operator Allowance"],
    keyword["Dor Hire Rate Incl. Fuel"],
    keyword["Total Rate"],
    keyword["Fuel Consumption (Ltr)"],
    keyword["Fuel Charges"],
    keyword["Dor Hire Rate"],
    keyword["Actions"],
  ];

  const handleEdit = (id: string) => {
    navigate(`/dor-equipment-hire-rate/edit/${id}`);
  };

  const onFilterSubmit = (data: any) => {
    dispatch(
      getAllDorEquipmentHireRate({
        params: { per_page: perPage, search: data.search, ordering: getFormattedSort(sorts) },
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getAllDorEquipmentHireRate({
        params: { per_page: perPage, page: page, ordering: getFormattedSort(sorts) },
      })
    );
    filterReset();
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {keyword["DOR Equipment Hire Rate"]} </Page.Title>
        <Page.ButtonGrp>
          <Button
            startIcon={<AiOutlinePlus />}
            variant="primary"
            onClick={() => navigate(`/dor-equipment-hire-rate/add`)}
          >
            {keyword["DOR Equipment Hire Rate"]}
          </Button>
        </Page.ButtonGrp>
      </Page.Container>
      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>

              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
      <Table.Container id="budget-table">
        <Table width="100%">
          <Table.Row width="100%">
            {columns.map((col, i) => {
              return <Table.HeadCol key={col}> {col} </Table.HeadCol>;
            })}
          </Table.Row>
          <Table.Body>
            {dorEquipmentRates.map((rate: any, idx: number) => {
              return (
                <Table.Row>
                  <Table.Col> {englishNepaliNumberTranslation(getSN(idx))} </Table.Col>
                  <Table.Col> {rate.description} </Table.Col>
                  <Table.Col> {rate.unit.name} </Table.Col>
                  <Table.Col> {englishNepaliNumberTranslation(rate.operator_allowance)} </Table.Col>
                  <Table.Col> {englishNepaliNumberTranslation(rate.dor_hire_including_fuel)} </Table.Col>
                  <Table.Col> {englishNepaliNumberTranslation(rate.total_rate)} </Table.Col>
                  <Table.Col> {englishNepaliNumberTranslation(rate.fuel_consumption)} </Table.Col>
                  <Table.Col> {englishNepaliNumberTranslation(rate.fuel_charges)} </Table.Col>
                  <Table.Col> {englishNepaliNumberTranslation(rate.dor_hire_rate)} </Table.Col>

                  <Table.Col align="center">
                    {" "}
                    <Table.ActionButton onClick={() => handleEdit(rate.id)}>
                      {" "}
                      <BiEdit />{" "}
                    </Table.ActionButton>{" "}
                  </Table.Col>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
};

export default DOREquipmentHireRate;
