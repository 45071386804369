import React, {
  createContext,
  HTMLAttributes,
  HtmlHTMLAttributes,
  useContext,
  useState,
} from "react";
import { StyledContainer } from "../profile/Profile.style";
import {
  StyledAvatar,
  StyledButton,
  StyledCard,
  StyledMenuContainer,
  StyledPostTitle,
  StyledTab,
  StyledTabBody,
  StyledTabHeadButton,
  StyledTabHeader,
  StyledTabPane,
  StyledTitle,
  StyledUserProfile,
  StyledWrapper,
} from "./UserProfile.style";

export interface UserProfilePropsI extends HtmlHTMLAttributes<HTMLDivElement> {}
export default function UserProfile(props: UserProfilePropsI) {
  return <StyledUserProfile {...props} />;
}

export interface UserProfileWrapperPropsI
  extends HtmlHTMLAttributes<HTMLDivElement> {}

UserProfile.Wrapper = function UserProfileWrapperFunction(
  props: UserProfileWrapperPropsI
) {
  return <StyledWrapper {...props} />;
};

export interface UserProfileContainerPropsI
  extends HtmlHTMLAttributes<HTMLDivElement> {}

UserProfile.Container = function UserProfileContainerFunction(
  props: UserProfileContainerPropsI
) {
  return <StyledContainer {...props} />;
};

export interface UserProfileCardPropsI
  extends HtmlHTMLAttributes<HTMLDivElement> {}

UserProfile.Card = function UserProfileCardFunction(
  props: UserProfileCardPropsI
) {
  return <StyledCard {...props} />;
};

export interface UserProfileAvatarPropsI
  extends HTMLAttributes<HTMLImageElement> {
  src: string;
}

UserProfile.Avatar = function UserProfileAvatarFunction(
  props: UserProfileAvatarPropsI
) {
  return <StyledAvatar {...props} />;
};

export interface UserProfileMenuContainerPropsI
  extends HtmlHTMLAttributes<HTMLDivElement> {}

UserProfile.MenuContainer = function UserProfileMenuContainerFunction(
  props: UserProfileMenuContainerPropsI
) {
  return <StyledMenuContainer {...props} />;
};

export interface UserProfileButtonPropsI
  extends HtmlHTMLAttributes<HTMLButtonElement> {}

UserProfile.Button = function UserProfileButtonFunction(
  props: UserProfileButtonPropsI
) {
  return <StyledButton {...props} />;
};

export interface UserProfileTitlePropsI
  extends HtmlHTMLAttributes<HTMLHeadingElement> {}

UserProfile.Title = function UserProfileTitleFunction(
  props: UserProfileTitlePropsI
) {
  return <StyledTitle {...props} />;
};

export interface UserProfilePostTitlePropsI
  extends HtmlHTMLAttributes<HTMLHeadingElement> {}

UserProfile.PostTitle = function UserProfilePostTitleFunction(
  props: UserProfilePostTitlePropsI
) {
  return <StyledPostTitle {...props} />;
};

export const TabContext = createContext<{
  currentTab: string;
  setCurrentTab: Function;
}>({
  currentTab: "basic",
  setCurrentTab: () => {},
});

export interface UserProfileTabPropsI
  extends HtmlHTMLAttributes<HTMLDivElement> {}

UserProfile.Tab = function UserProfileTabFunction(props: UserProfileTabPropsI) {
  const [currentTab, setCurrentTab] = useState("basic");

  return (
    <TabContext.Provider value={{ currentTab, setCurrentTab }}>
      <StyledTab {...props} />
    </TabContext.Provider>
  );
};

export interface UserProfileTabHeaderPropsI
  extends HtmlHTMLAttributes<HTMLDivElement> {}

UserProfile.TabHeader = function UserProfileTabHeaderFunction(
  props: UserProfileTabHeaderPropsI
) {
  return <StyledTabHeader {...props} />;
};

export interface UserProfileTabHeadButtonPropsI
  extends HtmlHTMLAttributes<HTMLButtonElement> {
  id: string;
  disabled?: boolean;
}

UserProfile.TabHeadButton = function UserProfileTabHeadButtonFunction(
  props: UserProfileTabHeadButtonPropsI
) {
  const { currentTab, setCurrentTab } = useContext(TabContext);
  return (
    <StyledTabHeadButton
      className={currentTab === props.id ? "active" : ""}
      onClick={() => {
        if (!props.disabled) {
          setCurrentTab(props.id);
        }
      }}
      {...props}
    />
  );
};

export interface UserProfileTabBodyPropsI
  extends HtmlHTMLAttributes<HTMLDivElement> {}

UserProfile.TabBody = function UserProfileTabBodyFunction(
  props: UserProfileTabBodyPropsI
) {
  return <StyledTabBody {...props} />;
};

export interface UserProfileTabPanePropsI
  extends HtmlHTMLAttributes<HTMLDivElement> {
  targetId: string;
}

UserProfile.TabPane = function UserProfileTabPaneFunction(
  props: UserProfileTabPanePropsI
) {
  const { currentTab } = useContext(TabContext);
  return currentTab === props.targetId ? (
    <StyledTabPane {...props} />
  ) : (
    <React.Fragment />
  );
};
