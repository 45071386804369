import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { addUnit, getUnit, updateUnit } from "../../rtk/feature/unit/unitApi";
import { useNavigate, useParams } from "react-router-dom";
import { UnitFormTypeI, unitsCleanUp } from "../../rtk/feature/unit/unitSlice";
import ReuseForm from "../../core-ui/ReuseForm";
import useTranslated from "../../hooks/useTranslated";
import { toast } from "react-toastify";
import { LangI } from "../../utils/res-mes/response-message";

export interface UnitFormTypeII extends UnitFormTypeI {}

function UnitFormPage() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<UnitFormTypeII>({
    defaultValues: {
      name: "",
      code: "",
    },
  });

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const unitsSelector = useAppSelector((state) => state.units);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(unitsCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getUnit(params.id));
      setEditMode(true);
    } else {
      dispatch(unitsCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { item } = unitsSelector;

  useEffect(() => {
    if (item !== null) {
      setValue("name", item.name);
      setValue("code", item.code);
    } else {
      reset();
    }
  }, [item, setValue, reset]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = unitsSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/equipment/unit/list");
    }
  }, [unitsSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<UnitFormTypeII> = (data) => {
    if (editMode && params.id) {
      const newData = { ...data };
      dispatch(updateUnit({ data: newData, id: params.id }));
    } else {
      const newData = { ...data };
      dispatch(addUnit(newData));
    }
  };

  const handleCancel = () => {
    navigate("/equipment/unit/list");
  };
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <h4>{editMode ? keyword["Edit Unit"] : keyword["Create Unit"]} </h4>
      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Unit Name"]}
                name="name"
                placeholder={keyword["Enter unit name"]}
                rules={{ required: keyword["This is required field."] }}
                error={errors["name"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Unit Code"]}
                name="code"
                placeholder={keyword["Enter unit code"]}
                rules={{ required: keyword["This is required field."] }}
                error={errors["code"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword.Update : keyword["Create Unit"]}
              </Button>
              <Button onClick={handleCancel}>{keyword.Cancel} </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
}

export default UnitFormPage;
