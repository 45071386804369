import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { DataSuccess } from "../../../utils/res-mes/response-message-generator";

export const getPlanningInspection = createAsyncThunk(
  "getplanninginspection",
  async ({ params }: { params: any }, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/planning-inspection/`, { params });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getPlanningInspectionById = createAsyncThunk(
  "getplanninginspectionById",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/planning-inspection/${id}/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const postPlanningInspection = createAsyncThunk(
  "postPlanningInspection",
  async (data: FormData, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/planning-inspection/`, data, {
        headers: { "Content-Type": "multipart/form" },
      });
      if (res.data) toast.success(DataSuccess("posted"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);

export const updatePlanningInspection = createAsyncThunk(
  "updatePlanningInspection",
  async ({ data, id }: { id: string; data: FormData }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/planning-inspection/${id}/`, data, {
        headers: { "Content-Type": "multipart/form" },
      });
      if (res.data) toast.success(DataSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);
