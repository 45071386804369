import { Button, Form, Grid, MainLayout, Table } from "@ims/common";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BsPrinter, BsUpload } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import { FiEdit, FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Dropzone from "../../components/Dropzone";
import Page from "../../core-ui/Page";
import ReuseForm from "../../core-ui/ReuseForm";
import useTranslated from "../../hooks/useTranslated";
import {
  getMunicipalityBudgetData,
  postMunicipalityBudgetData,
} from "../../rtk/feature/municipality-budget/municipalityBudgetAPI";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import usePagination from "../../utils/usePagination";
import TableUtility from "../user/TableUtility";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { realTimeFilter } from "../../utils/real-time-filter";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import getFormattedSort from "../../utils/getFormattedSort";

interface PropsI {
  isEditable?: boolean;
}

const MunicipalityBudget = ({ isEditable = true }: PropsI) => {
  const [municipalityBudgetData, setMunicipalityBudgetData] = useState<any[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { keyword } = useTranslated();
  const municipalityBudgetDataSelector = useAppSelector((state) => state.municipalityBudget.data);

  const columns = [
    keyword["S.N."],
    keyword["Hint"],
    keyword["Name"],
    keyword["Expenditure Heading"],
    keyword["Source"],
    keyword["Attention"],
    keyword["Unit"],
    keyword["Allocation"],
    keyword["Expenditure"],
    keyword["Expenditure Rate"],
    keyword["Balance"],
    keyword["Actions"],
  ];

  const [totalItems, setTotalItems] = useState(10);
  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    if (!!municipalityBudgetDataSelector) {
      setTotalItems(municipalityBudgetDataSelector?.total);
      setMunicipalityBudgetData(municipalityBudgetDataSelector?.results);
    }
  }, [municipalityBudgetDataSelector]);
  interface IForm {
    document: FileList | null;
  }
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    register,
    control,
    watch,
  } = useForm<IForm>({
    defaultValues: {
      document: null,
    },
  });
  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    reset: filterReset,
    formState: { errors: filterErrors },
    watch: filterWatch,
  } = useForm();
  const { t, i18n } = useTranslation();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const formData = new FormData();
    const { files } = e.target;
    if (files) {
      formData.append("file", files[0]);
      dispatch(postMunicipalityBudgetData(formData));
    }
  };
  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getMunicipalityBudgetData({
        params: {
          per_page: perPage,
          page,
        },
        signal: abortController.signal,
      })
    );
    return () => {
      abortController.abort();
    };
  }, [perPage, page]);

  const tableUtility = new TableUtility("budget-table");
  const handleEdit = (id: string) => {
    navigate(`/budget/municipality-budget/edit/${id}`);
  };

  const onFilterSubmit = (data: any) => {
    dispatch(
      getMunicipalityBudgetData({
        params: { per_page: perPage, search: data.search, ordering: getFormattedSort(sorts) },
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getMunicipalityBudgetData({
        params: { per_page: perPage, page: page, ordering: getFormattedSort(sorts) },
      })
    );
    filterReset();
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {t("Municipality Budget")}</Page.Title>
        <Page.ButtonGrp>
          <Button startIcon={<CiExport />} onClick={tableUtility.export}>
            {keyword.Export}
          </Button>

          <Button startIcon={<BsPrinter />} onClick={tableUtility.print}>
            {keyword.Print}
          </Button>
          {isEditable && (
            <Form.File
              fileType="xlsx"
              id="budget"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleChange}
            ></Form.File>
          )}
        </Page.ButtonGrp>
      </Page.Container>

      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>

              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container id="budget-table">
        <Table width="100%">
          <Table.Row width="100%">
            {columns.map((col, i) => {
              if (col === "Actions" && !isEditable) return;
              return <Table.HeadCol key={col}> {col} </Table.HeadCol>;
            })}
          </Table.Row>
          <Table.Body>
            {municipalityBudgetData.map((data, index) => {
              if (data.expenditure === "None")
                return (
                  <Table.Row width="100%">
                    <Table.Col colSpan={9} slot="7" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      {data?.name}{" "}
                    </Table.Col>
                  </Table.Row>
                );
              return (
                <Table.Row key={data.id}>
                  <Table.Col>{englishNepaliNumberTranslation(data?.serial_no)}</Table.Col>
                  <Table.Col>{data?.hint}</Table.Col>
                  <Table.Col>{data?.name}</Table.Col>
                  <Table.Col>{data?.expenditure_heading}</Table.Col>
                  <Table.Col>{data?.source}</Table.Col>
                  <Table.Col>{data?.attention}</Table.Col>
                  <Table.Col>{data?.unit}</Table.Col>
                  <Table.Col>{data?.allocation}</Table.Col>
                  <Table.Col>{data?.expenditure}</Table.Col>
                  <Table.Col> {data?.expenditure_rate} </Table.Col>
                  <Table.Col>{data?.balance}</Table.Col>

                  {isEditable && (
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(data.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
};

export default MunicipalityBudget;
