import { Button, Grid, MainLayout, Table } from "@ims/common";
import React, { useEffect, useState } from "react";
import { FiEdit, FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Page from "../../core-ui/Page";
import useTranslated from "../../hooks/useTranslated";
import { getPlanningContractors } from "../../rtk/feature/planning-contractor/planningContractorAPI";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import usePagination from "../../utils/usePagination";
import { MdDoneAll, MdRemoveDone } from "react-icons/md";
import { BiTask, BiTaskX } from "react-icons/bi";
import { styleSN } from "../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { useForm } from "react-hook-form";
import { realTimeFilter } from "../../utils/real-time-filter";
import ReuseForm from "../../core-ui/ReuseForm";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import getFormattedSort from "../../utils/getFormattedSort";

const PlanningContractor = () => {
  const [planContractors, setPlanContractors] = useState<any[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});

  const navigate = useNavigate();

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    reset: filterReset,
    formState: { errors: filterErrors },
    watch: filterWatch,
  } = useForm();

  const dispatch = useAppDispatch();
  const planContractorsDataSelector = useAppSelector((state) => state.planningContractor.data);

  useEffect(() => {
    if (planContractorsDataSelector) setPlanContractors(planContractorsDataSelector?.results);
  }, [planContractorsDataSelector]);

  const handleEdit = (id: string) => {
    navigate(`/planning-contractor/edit/${id}`);
  };

  const [totalItems, setTotalItems] = useState(1);
  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });
  useEffect(() => {
    dispatch(getPlanningContractors());
  }, []);
  const { keyword } = useTranslated();

  const onFilterSubmit = (data: any) => {
    dispatch(
      getPlanningContractors({
        per_page: perPage,
        search: data.search,
        ordering: getFormattedSort(sorts),
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getPlanningContractors({
        per_page: perPage,
        page: page,
        ordering: getFormattedSort(sorts),
      })
    );
    filterReset();
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>{keyword["Plan Contractor"]}</Page.Title>
        <Button variant="primary" onClick={() => navigate(`/planning-contractor/add`)}>
          {keyword["Add Contractor"]}
        </Button>
      </Page.Container>

      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>

              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>{keyword.Name}</Table.HeadCol>
              <Table.HeadCol>{keyword.Yojana}</Table.HeadCol>
              <Table.HeadCol> {keyword.Description} </Table.HeadCol>
              <Table.HeadCol> {keyword["Work Update"]} </Table.HeadCol>
              <Table.HeadCol>{keyword.Actions}</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {planContractors?.length > 0 &&
              planContractors?.map((contractor, index) => {
                return (
                  <Table.Row key={contractor.id}>
                    <Table.Col>{englishNepaliNumberTranslation(styleSN(index + 1))}</Table.Col>
                    <Table.Col>{contractor.name}</Table.Col>
                    <Table.Col>{contractor.yojana_id.name}</Table.Col>
                    <Table.Col>{contractor.description}</Table.Col>
                    <Table.Col>
                      <span style={{ marginLeft: "30%" }}>
                        {contractor.work_update === "done" ? (
                          <BiTask size={20} color="green" title={keyword["Done"]} />
                        ) : (
                          <BiTaskX size={20} color="#222" title={keyword["Not Done"]} />
                        )}
                      </span>
                    </Table.Col>

                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(contractor.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
};

export default PlanningContractor;
