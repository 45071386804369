import { createSlice } from "@reduxjs/toolkit";
import { INetworkRResponse } from "../../../interfaces/networkResponse.interface";
import { IGeneralWorkData } from "../general-work/generalWorkSlice";
import { deleteElectrificationWorks, getElectrificationWork, getElectrificationWorks, postElectrificationWorks, updateElectrificationWorks } from "./electrificationWorkAPI";
interface IElectrificationWorksById extends INetworkRResponse {
    data: IGeneralWorkData | null
}
interface IElectrificationWork extends INetworkRResponse {
    data: null | IGeneralWorkData[],
    byId: IElectrificationWorksById,
    deleteSuccess: boolean,
    formSubmitted: boolean,
}

const initialState: IElectrificationWork = {
    data: null,
    error: null,
    loading: false,
    deleteSuccess: false,
    formSubmitted: false,

    byId: {
        data: null,
        error: null,
        loading: false
    }
}

const electrificationWorkSlice = createSlice({
    name: "electiificationwork",
    initialState,
    reducers: {
        cleanupElectrificationWork: (state) => {
            return initialState
        }
    },
    extraReducers(builder) {

        builder.addCase(getElectrificationWorks.pending, (state) => {
            state.loading = true
            state.data = null
            state.error = null
        });
        builder.addCase(getElectrificationWorks.rejected, (state) => {
            state.loading = false
            state.data = null
            state.error = null
        });
        builder.addCase(getElectrificationWorks.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
            state.error = null
        })

        // by id
        builder.addCase(getElectrificationWork.pending, (state) => {
            state.byId.loading = true
            state.byId.data = null
            state.byId.error = null
        });
        builder.addCase(getElectrificationWork.rejected, (state) => {
            state.byId.loading = false
            state.byId.data = null
            state.byId.error = null
        });
        builder.addCase(getElectrificationWork.fulfilled, (state, { payload }) => {
            state.byId.loading = false
            state.byId.data = payload
            state.byId.error = null
        })


        //delete work

        builder.addCase(deleteElectrificationWorks.pending, (state) => {
            state.deleteSuccess = false
        });
        builder.addCase(deleteElectrificationWorks.fulfilled, (state, { payload }) => {
            state.deleteSuccess = true;
            const newData = state.data?.filter((item) => item.id != payload);

            state.data = newData || []

        });


        //post electrification work

        builder.addCase(postElectrificationWorks.pending, (state) => {
            state.formSubmitted = false
        });
        builder.addCase(postElectrificationWorks.fulfilled, (state) => {
            state.formSubmitted = true
        });

        //post electrification work

        builder.addCase(updateElectrificationWorks.pending, (state) => {
            state.formSubmitted = false
        });
        builder.addCase(updateElectrificationWorks.fulfilled, (state) => {
            state.formSubmitted = true
        })


    }
});
export const { cleanupElectrificationWork } = electrificationWorkSlice.actions;
export default electrificationWorkSlice.reducer