import { HtmlHTMLAttributes, useEffect } from "react";
import { StyledDialogContainer, StyledDialogWrapper, StyledTitleContainer } from "./Dialog.style";

interface DialogPropsI extends HtmlHTMLAttributes<HTMLDivElement> {
  active: boolean;
  onClose: () => void;
}

export default function Dialog(props: DialogPropsI) {
  return <StyledDialogWrapper {...props} onClick={props.onClose} />;
}

interface DialogContainerPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

Dialog.Container = function FunctionDialogContainer(props: DialogContainerPropsI) {
  return (
    <StyledDialogContainer
      {...props}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};

interface DialogTitlePropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

Dialog.Title = function FunctionDialogTitle(props: DialogTitlePropsI) {
  return <StyledTitleContainer {...props} />;
};
