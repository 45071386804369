import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { addWard, getWard, updateWard } from "../../rtk/feature/ward/wardApi";
import { useNavigate, useParams } from "react-router-dom";
import { WardFormTypeI, wardsCleanUp } from "../../rtk/feature/ward/wardSlice";
import ReuseForm from "../../core-ui/ReuseForm";
import Page from "../../core-ui/Page";
import { useTranslation } from "react-i18next";
import useTranslated from "../../hooks/useTranslated";

export interface WardFormTypeII extends WardFormTypeI {}

function WardFormPage() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<WardFormTypeII>({
    defaultValues: {
      ward_name: "",
    },
  });

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const wardsSelector = useAppSelector((state) => state.wards);
  const [editMode, setEditMode] = useState(false);
  const { t, i18n } = useTranslation();
  const { keyword } = useTranslated();

  useEffect(() => {
    return () => {
      dispatch(wardsCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getWard(params.id));
      setEditMode(true);
    } else {
      dispatch(wardsCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { item } = wardsSelector;

  useEffect(() => {
    if (item !== null) {
      setValue("ward_name", item.ward_name);
      setValue("ward_number", item.ward_number);
      setValue("ward_address", item.ward_address);
    } else {
      reset();
    }
  }, [item, setValue, reset]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = wardsSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/ward/list");
    }
  }, [wardsSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<WardFormTypeII> = (data) => {
    if (editMode && params.id) {
      const newData = { ...data };
      dispatch(updateWard({ data: newData, id: params.id }));
    } else {
      const newData = { ...data };
      dispatch(addWard(newData));
    }
  };

  const handleCancel = () => {
    navigate("/ward/list");
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>{editMode ? "Update Ward" : "Create Ward"}</Page.Title>
      </Page.Container>
      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={t("Ward Name")}
                name="ward_name"
                placeholder="Enter ward name"
                rules={{ required: keyword["This is required field."] }}
                error={errors["ward_name"]}
                readOnly={editMode}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={t("Ward Number")}
                name="ward_number"
                placeholder="Enter ward number"
                rules={{ required: keyword["This is required field."] }}
                error={errors["ward_number"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={t("Ward Address")}
                name="ward_address"
                placeholder="Enter ward address"
                rules={{ required: keyword["This is required field."] }}
                error={errors["ward_address"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword.Update : keyword["Create Ward"]}
              </Button>
              <Button onClick={handleCancel}> {keyword.Cancel} </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
}

export default WardFormPage;
