import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { IPlanningContractorForm } from "../../../pages/planning-contractor/PlanningContractorForm";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { DataSuccess } from "../../../utils/res-mes/response-message-generator";

export const getPlanningContractors = createAsyncThunk(
  "getPlanningContractor",
  async (
    userParams: { per_page: number; page?: number; search?: any; ordering?: any; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get(`/planning-contractor/`, {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      return isRejectedWithValue(appError.getError());
    }
  }
);

interface IPostPlanContractor extends Omit<IPlanningContractorForm, "yojana" | "user"> {
  yojana_id: string;
  user_id: string;
}

export const postPlanningContractor = createAsyncThunk(
  "postPlanningContractor",
  async (data: IPostPlanContractor, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/planning-contractor/`, data);
      if (res.data) toast.success(DataSuccess("posted"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);

export const getplanningContractorById = createAsyncThunk(
  "getplanningContractorById",
  async (options: { id: string; signal: AbortSignal }) => {
    try {
      const res = await imsClient.get(`/planning-contractor/${options.id}/`, { signal: options.signal });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return isRejectedWithValue(appError.getError());
    }
  }
);

export const updatePlanningContractor = createAsyncThunk(
  "updatePlanningContractor",
  async ({ id, data }: { id: string; data: IPostPlanContractor }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/planning-contractor/${id}/`, data);
      if (res.data) toast.success(DataSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);
