import { createSlice } from "@reduxjs/toolkit";
import { FiscalYearTypeI } from "../fiscal-year/fiscalYearSlice";
import { UserTypeI } from "../users/usersSlice";
import {
  changePasswordWithOTP,
  getActivityLog,
  getNagarpalika,
  getUserRoles,
  getWardList,
  login,
  logout,
  sendOTP,
  userVerified,
  verifyToken,
} from "./authApi";
import { keywordTypes } from "../../../hooks/useTranslated";
export type roleTypes =
  | "Normal User"
  | "IT Officer"
  | "Palika User"
  | "Ward Sachib"
  | "Prasasakiya Adhikrit"
  | "Purwadhar Sakha"
  | "Yojana Sakha"
  | "Gaupalika Stariya Anugaman Samiti"
  | "Lekha Sakha"
  | "Admin"
  | "Citizen"
  | "Sub Engineer"
  | "Engineer"
  | "Upabhokta Samiti";
export interface NagarpalikaTypeI {
  id: string;
  name: string;
  nepali_name: string;
  fiscal_year: FiscalYearTypeI;
  office_address: string | null;
}

export interface LoginFormTypeI {
  username_or_phone: string;
  password: string;
  remember_me: boolean;
}

export interface ForgotPasswordFormTypeI {
  email: string;
}

export interface ChangePasswordFormTypeI {
  otp: string;
  password: string;
  confirm_password: string;
}

export interface UserState {
  id: string;
  username: string;
  email: string;
  full_name_english: string;
  full_name_nepali: string;
  mobile_number: string;
  role: roleTypes;
  first_name: string;
}

export interface UserRoleTypeI {
  id: string;
  url: string;
  name: string;
}

export interface WardListTypeI {
  id: string;
  url: string;
  negarpalika: {
    id: string;
    url: string;
    name: string;
  };
  ward_name: keyof keywordTypes;
  ward_number: number;
  created_by: UserTypeI;
}

export interface ActivityLogTypeI {
  user: {
    id: string;
    username: string;
    profile_image: null;
    role: string;
    ward: string;
    first_name: string;
    full_name_english: string;
    full_name_nepali: string;
  };
  action: string;
  changes: string;
  timestamp: string;
  content_type: {
    id: number;
    app_label: string;
    model: string;
  };
  message: string;
}
export interface AuthState {
  loading: boolean;
  user: null | UserState;
  authenticated: boolean;
  hasSubmitted: boolean;
  roles: roleTypes[];
  wardList: WardListTypeI[];
  error: any;
  showLoading: boolean;
  nagarpalika: NagarpalikaTypeI | null;
  activityLog: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: ActivityLogTypeI[];
  } | null;
}

const initialState: AuthState = {
  loading: false,
  user: null,
  authenticated: false,
  hasSubmitted: false,
  roles: [],
  error: null,
  wardList: [],
  showLoading: false,
  nagarpalika: null,
  activityLog: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    showLoading: (state, action) => {
      state.showLoading = action.payload;
    },
    logoutAction: (state) => {
      state.authenticated = false;
      state.user = null;
      state.error = null;
      state.loading = false;
      localStorage.clear();
    },

    authCleanUp: (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.authenticated = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.user = action.payload.user;
      state.authenticated = true;

      localStorage.setItem("access", action.payload.data.access);
      action.payload.remember_me && localStorage.setItem("refresh", action.payload.data.refresh);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
    });
    builder.addCase(login.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.authenticated = false;
      state.error = payload;
    });

    builder.addCase(userVerified.pending, (state) => {
      state.loading = true;
      state.authenticated = false;
    });
    builder.addCase(userVerified.fulfilled, (state, action) => {
      let user: any = JSON.parse(localStorage.getItem("user")!);
      if (user) {
        state.user = user;
        state.loading = false;
        state.authenticated = true;
      }
    });

    builder.addCase(userVerified.rejected, (state, { payload }: any) => {
      state.authenticated = false;
      state.loading = false;
    });

    builder.addCase(sendOTP.pending, (state) => {
      state.loading = true;
      state.hasSubmitted = false;
    });

    builder.addCase(sendOTP.fulfilled, (state) => {
      state.loading = false;
      state.hasSubmitted = true;
    });

    builder.addCase(sendOTP.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
    });

    builder.addCase(changePasswordWithOTP.pending, (state) => {
      state.loading = true;
      state.hasSubmitted = false;
    });

    builder.addCase(changePasswordWithOTP.fulfilled, (state) => {
      state.loading = false;
      state.hasSubmitted = true;
    });

    builder.addCase(changePasswordWithOTP.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
    });

    builder.addCase(getNagarpalika.pending, (state) => {});
    builder.addCase(getNagarpalika.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.nagarpalika = action.payload;
      localStorage.setItem("nagarpalika", JSON.stringify(action.payload));
    });
    builder.addCase(getNagarpalika.rejected, (state) => {});

    builder.addCase(getUserRoles.pending, (state) => {
      state.roles = [];
    });
    builder.addCase(getUserRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    });
    builder.addCase(getUserRoles.rejected, (state) => {
      state.roles = [];
    });

    builder.addCase(getWardList.pending, (state) => {
      state.wardList = [];
    });
    builder.addCase(getWardList.fulfilled, (state, action) => {
      state.wardList = action.payload;
    });
    builder.addCase(getWardList.rejected, (state) => {
      state.wardList = [];
    });

    builder.addCase(verifyToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyToken.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.user = action.payload.user;
      state.authenticated = true;

      localStorage.setItem("user", JSON.stringify(action.payload.user));
    });
    builder.addCase(verifyToken.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.authenticated = false;
      state.user = null;
      state.error = null;
      state.loading = false;
      localStorage.clear();
    });
    builder.addCase(logout.rejected, (state) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getActivityLog.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getActivityLog.fulfilled, (state, action) => {
      state.loading = false;
      state.activityLog = action.payload;
    });

    builder.addCase(getActivityLog.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { showLoading, logoutAction, authCleanUp } = authSlice.actions;

export default authSlice.reducer;
