import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { YojanaTypeFormTypeI } from "./yojanaTypeSlice";
import { YojanaSuccess } from "../../../utils/res-mes/response-message-generator";

export const getYojanaTypes = createAsyncThunk(
  "getYojanaTypes",
  async (
    userParams: { per_page: number; page: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/yojana-types/", {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getYojanaType = createAsyncThunk("getYojanaType", async (id: string, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.get(`/yojana-types/${id}/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const addYojanaType = createAsyncThunk(
  "addYojanaType",
  async (data: YojanaTypeFormTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post("/yojana-types/", data);
      if (res.data) toast.success(YojanaSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteYojanaType = createAsyncThunk(
  "deleteYojanaType",
  async (deleteId: string, { dispatch, rejectWithValue }) => {
    try {
      await imsClient.delete(`/yojana-types/${deleteId}/`);
      dispatch(getYojanaTypes());
      toast.success(YojanaSuccess("deleted"));
      return deleteId;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateYojanaType = createAsyncThunk(
  "updateYojanaType",
  async (data: { data: YojanaTypeFormTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/yojana-types/${data.id}/`, data.data);
      if (res.data) toast.success(YojanaSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
