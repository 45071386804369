import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { idText } from "typescript";
import useTranslated from "../../../../hooks/useTranslated";
import { setActiveTab, tabEnum } from "../../../../rtk/feature/plan-management/planManagementSlice";
import {
  getSanitoryWork,
  postSanitoryWork,
  updateSanitoryWorks,
} from "../../../../rtk/feature/sanitory-work/sanitoryWorkAPI";
import { cleanupSanitoryWork } from "../../../../rtk/feature/sanitory-work/sanitoryWorkSlice";
import { useAppDispatch, useAppSelector } from "../../../../rtk/hook";
import WorkForm, { IWorkForm } from "../components/WorkForm/WorkForm";

const SanitoryWorkForm = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isEditMode = pathname.includes("/edit");
  const { byId, formSubmitted } = useAppSelector((state) => state.sanitoryWork);
  const params = useParams();
  const workId = params.workid;
  const handleSubmit = (data: IWorkForm) => {
    const finalData = { ...data, unit: data.unit.value };
    if (params.id) {
      dispatch(postSanitoryWork({ data: finalData, id: params.id }));
      return;
    }
    if (isEditMode && !!workId && params.id) {
      dispatch(updateSanitoryWorks({ data: finalData, id: params.id, workId: workId }));
    }
  };

  useEffect(() => {
    if (params.id && workId) dispatch(getSanitoryWork({ id: params.id, workId }));
  }, [params.id, workId]);

  useEffect(() => {
    if (formSubmitted) {
      navigate(`/plan-management/plan?tab=detailed-estimate`);
      dispatch(setActiveTab(tabEnum.sanitoryWork));
    }
    return () => {
      dispatch(cleanupSanitoryWork());
    };
  }, [formSubmitted]);
  const { keyword } = useTranslated();

  return (
    <>
      <WorkForm title={keyword["Sanitary Work Form"]} handleFormSubmit={handleSubmit} work={byId.data} />
    </>
  );
};

export default SanitoryWorkForm;
