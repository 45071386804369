import { Button, Form, Grid } from "@ims/common";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Page from "../../core-ui/Page";
import { getBudget } from "../../rtk/feature/budget/budgetApi";
import { planbriefCleanUp } from "../../rtk/feature/plan-brief/planBriefSlice";
import { cleanUpPlanReport } from "../../rtk/feature/plan-report/planreportSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { planVerificationCleanUp } from "../../rtk/feature/plan-verification/planVerificationSlice";
import { groupPlanEstimateCleanUp } from "../../rtk/feature/plan-group-estimate/planGroupEstimateSlice";
import { setActiveBudgetId } from "../../rtk/feature/plan-management/planManagementSlice";
import useTranslated from "../../hooks/useTranslated";
import PlanInfo from "./PlanInfo";
import { budgetCleanUp } from "../../rtk/feature/budget/budgetSlice";
import { AiOutlineCloseCircle } from "react-icons/ai";

export interface ReactSelectOptionI {
  label: string;
  value: string;
}
interface FormTypeI {
  name: string;
  work_name: string;
}

export interface PlanSearchTypeI {
  setBudgetID: Function;
}

function PlanSearch(props: PlanSearchTypeI) {
  const navigate = useNavigate();
  const { keyword } = useTranslated();
  const {
    register,
    formState: { errors },
  } = useForm<FormTypeI>();

  const dispatch = useAppDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  const budgetSelector = useAppSelector((state) => state.budgets);
  const activeBudgetId = useAppSelector((state) => state.planManagement.activeBudgetId);
  const { data: budgetList, item: budget } = budgetSelector;
  const [plan, setPlan] = useState<ReactSelectOptionI | null>(null);

  const budgetParam = searchParams.get("budget");

  useEffect(() => {
    dispatch(budgetCleanUp());
  }, []);

  useEffect(() => {
    if (!activeBudgetId) {
      setPlan(null);
    }
  }, [activeBudgetId]);

  useEffect(() => {
    if (budgetParam) {
      dispatch(getBudget(budgetParam));
      dispatch(setActiveBudgetId(budgetParam));
      props.setBudgetID(budgetParam);
    }
  }, [budgetParam]);

  useEffect(() => {
    if (budget) setPlan({ label: budget?.name, value: budget?.id });
    else setPlan(null);
  }, [budgetParam, budget]);

  const handleSearch = (plan: ReactSelectOptionI) => {
    dispatch(cleanUpPlanReport());
    dispatch(planbriefCleanUp());
    dispatch(planVerificationCleanUp());
    dispatch(groupPlanEstimateCleanUp());

    if (plan) {
      dispatch(getBudget(plan.value));
      props.setBudgetID(plan.value);
      dispatch(setActiveBudgetId(plan.value));

      setSearchParams((prevParams: any) => {
        return new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          ...{ budget: plan.value },
        });
      });
      // navigate("/plan-management/plan");
    } else toast.warning("Please select a plan.");
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>{keyword["Select Yojana"]}</Page.Title>
      </Page.Container>

      <Form>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6}>
              <Form.FormGroup>
                <Form.Label>{keyword["Budget / Plan"]}</Form.Label>
                <Form.ReactSelect
                  name="name"
                  placeholder={keyword["Select"] + "..."}
                  options={budgetList?.map((plan) => ({
                    value: plan.id,
                    label: plan.name,
                  }))}
                  value={plan}
                  onChange={(selectedOption: ReactSelectOptionI) => {
                    setPlan(selectedOption);
                    handleSearch(selectedOption);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      if (plan) {
                        handleSearch(plan);
                      }
                    }
                  }}
                />
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col>
              {activeBudgetId && (
                <section style={{ marginBottom: "-26px" }}>
                  <AiOutlineCloseCircle
                    size={30}
                    onClick={() => {
                      dispatch(setActiveBudgetId(null));
                      props.setBudgetID(null);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </section>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Form>
      <PlanInfo budget={budget} />
    </MainLayout.Main>
  );
}

export default PlanSearch;

export const BasicTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 1rem;
  font-size: 1.4rem;

  & td,
  & th {
    padding: 5px;
    border: 1px solid rgb(183 183 183);
    background: #f9f9f9;

    & strong {
      font-weight: 500;
    }
  }

  & tr th {
    font-weight: 500;
  }
`;

export const SimpleTable = styled(BasicTable)`
  & tr td,
  & tr th {
    border: 1px solid #f1f1f1;
  }
`;
