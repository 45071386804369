import { createSlice } from "@reduxjs/toolkit";
import { getYojanaBookTemplate, updateYojanaBookTemplate } from "./yojanaBookTemplateApi";

export interface YojanaBookTemplateTypeI {
  id: string;
  title: string;
  content: string;
  order: number;
  pdf_file?: string;
  pdf_file_url?: string;
}

export interface YojanaBookTemplateSliceStateI {
  loading: boolean;
  data: YojanaBookTemplateTypeI[] | [];

  hasSubmitted: boolean;
  error: any;
}

const initialState: YojanaBookTemplateSliceStateI = {
  loading: false,
  data: [],
  hasSubmitted: false,
  error: null,
};

const YojanaBookTemplateSlice = createSlice({
  name: "YojanaBookTemplate",
  initialState,
  reducers: {
    YojanaBookTemplateCleanUp: (state) => {
      state.data = [];
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getYojanaBookTemplate.pending, (state) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(getYojanaBookTemplate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getYojanaBookTemplate.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
    builder.addCase(updateYojanaBookTemplate.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateYojanaBookTemplate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(updateYojanaBookTemplate.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
  },
});

export const { YojanaBookTemplateCleanUp } = YojanaBookTemplateSlice.actions;
export default YojanaBookTemplateSlice.reducer;
