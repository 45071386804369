import React from 'react'
import en from '../utils/locale/en.json'
import { resources } from '../utils/i18n'
import { useTranslation } from 'react-i18next'
export type keywordTypes = typeof resources.en.translation;
export type TranslateKeyTypes = keyof keywordTypes
const useTranslated = () => {
    const { t } = useTranslation()
    const keyword: { [key: string]: string } = {} as keywordTypes;

    for (const key in resources.en.translation as keywordTypes) {
        keyword[key] = `${t(key as keyof keywordTypes)}` || key
    }
    return {
        keyword: keyword as keywordTypes
    }

}

export default useTranslated;