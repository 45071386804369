import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";

export const getWorkReports = createAsyncThunk("getWorkReports", async (id: string, { rejectWithValue }) => {


    try {

        const res = await imsClient.get(`yojana/${id}/work-report/`);
        return res.data

    } catch (error) {
        const appError = new AppError(error);
        appError.show();
        rejectWithValue(error)

    }

})