import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getGeneralDocuments } from "../../rtk/feature/general-document/generalDocumentApi";
import { GeneralDocumentTypeI } from "../../rtk/feature/general-document/generalDocumentSlice";
import { useNavigate } from "react-router-dom";
import { FiDownload, FiEdit } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import Page from "../../core-ui/Page";
import { Button } from "@ims/common";
import useTranslated from "../../hooks/useTranslated";

function GeneralDocumentsManagePage() {
  const [generalDocuments, setGeneralDocuments] = useState<GeneralDocumentTypeI[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const generalDocumentsState = useAppSelector((state) => state.generalDocuments);

  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getGeneralDocuments({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    dispatch(showLoading(generalDocumentsState.loading));
  }, [dispatch, generalDocumentsState.loading]);

  useEffect(() => {
    if (generalDocumentsState.data) {
      setGeneralDocuments(generalDocumentsState.data.results);
      setTotalItems(generalDocumentsState.data.total);
    }
  }, [generalDocumentsState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/settings/general-document/edit/${id}`);
  };
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {keyword["General Document Manage"]}</Page.Title>
        <Button variant="primary" onClick={() => navigate(`/settings/general-document/add`)}>
          {keyword.Add} {keyword["General Documents"]}
        </Button>
      </Page.Container>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol> {keyword.Title} </Table.HeadCol>
              <Table.HeadCol> {keyword["Document Type"]} </Table.HeadCol>
              <Table.HeadCol align="center"> {keyword.Action} </Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {generalDocuments &&
              generalDocuments.length > 0 &&
              generalDocuments.map((generalDocument, index) => {
                return (
                  <Table.Row key={generalDocument.id}>
                    <Table.Col>{getSN(index)}</Table.Col>
                    <Table.Col>{generalDocument.title}</Table.Col>
                    <Table.Col>{generalDocument.document_type}</Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(generalDocument.id)}>
                        <FiEdit />
                      </Table.ActionButton>

                      <a
                        href={generalDocument.document}
                        download
                        id={"download" + generalDocument.id}
                        target="_blank"
                        hidden
                        rel="noreferrer"
                      >
                        Something
                      </a>

                      <Table.ActionButton
                        onClick={() => {
                          document.getElementById("download" + generalDocument.id)!?.click();
                        }}
                        color="green"
                      >
                        <FiDownload />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default GeneralDocumentsManagePage;
