import { createAsyncThunk } from "@reduxjs/toolkit";
import { imsClient } from "../../../utils/imsClient";
import { AppError } from "../../../utils/AppError";
import { toast } from "react-toastify";
import { WardSuccess } from "../../../utils/res-mes/response-message-generator";

export const getAssignedWard = createAsyncThunk(
  "getAssignedWard",
  async (budgetID: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/yojana/${budgetID}/assign-to-ward/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const assignWard = createAsyncThunk(
  "assignWard",
  async (data: { budgetID: string; wardID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/yojana/${data.budgetID}/assign-to-ward/`, { ward: data.wardID });
      if (res.data) toast.success(WardSuccess("assigned"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
