import { createSlice } from "@reduxjs/toolkit";
import {
  addFixedAnalyzeRate,
  deleteFixedAnalyzeRate,
  getFixedAnalyzeRate,
  getFixedAnalyzeRates,
  updateFixedAnalyzeRate,
} from "./fixedAnalyzeRateApi";

export interface FixedAnalyzeRateFormTypeI {
  english_name: string;
  nepali_name: string;
  english_description: string;
  nepali_description: string;
  vat_applied: "true" | "false";
  unit: string;
  code: string;
  working_type: string;
}

export interface FixedAnalyzeRateTypeI {
  id: string;
  english_name: string;
  nepali_name: string;
  english_description: string;
  nepali_description: string;
  vat_applied: "true" | "false";
  unit: {
    id: string;
    name: string;
    code: string;
  };
  rate_id: string;
  equipment_rate_id: string;
  code: string;
  working_type: string;
}

export interface FixedAnalyzeRatesSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: FixedAnalyzeRateTypeI[];
  } | null;
  item: FixedAnalyzeRateTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: FixedAnalyzeRatesSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const fixedAnalyzeRatesSlice = createSlice({
  name: "fixedAnalyzeRates",
  initialState,
  reducers: {
    analyzeRatesCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addFixedAnalyzeRate.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addFixedAnalyzeRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addFixedAnalyzeRate.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getFixedAnalyzeRates.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getFixedAnalyzeRates.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getFixedAnalyzeRates.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getFixedAnalyzeRate.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getFixedAnalyzeRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getFixedAnalyzeRate.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateFixedAnalyzeRate.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateFixedAnalyzeRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateFixedAnalyzeRate.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteFixedAnalyzeRate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteFixedAnalyzeRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteFixedAnalyzeRate.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { analyzeRatesCleanUp } = fixedAnalyzeRatesSlice.actions;
export default fixedAnalyzeRatesSlice.reducer;
