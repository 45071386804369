import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { DataSuccess } from "../../../utils/res-mes/response-message-generator";

interface IGetBillPayments {
  params: {
    per_page?: number;
    page?: number;
    search?: any;
    ordering?: any;
  };
}

export const getBillPayments = createAsyncThunk("getBillPayment", async ({ params }: IGetBillPayments) => {
  try {
    const res = await imsClient.get(`/cheque-verify-by-lekha/`, { params });
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return isRejectedWithValue(appError.getError());
  }
});

export const getBillPaymentById = createAsyncThunk("getBillPaymentbyId", async ({ id }: { id: string }) => {
  try {
    const res = await imsClient.get(`/cheque-verify-by-lekha/${id}/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return isRejectedWithValue(appError.getError());
  }
});

export const updateBillPayment = createAsyncThunk(
  "updateBillPayment",
  async ({ id, data }: { id: string; data: FormData }) => {
    try {
      const res = await imsClient.patch(`/cheque-verify-by-lekha/${id}/`, data, {
        headers: { "Content-Type": "form/multipart" },
      });
      if (res.data) toast.success(DataSuccess("posted"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return isRejectedWithValue(appError.getError());
    }
  }
);

interface ISendToPrasasakiya {
  plan_cheque: string;
  user_id: string;
}

export const sendBillToPrasasakiya = createAsyncThunk("sendToPrasasakiya", async (data: ISendToPrasasakiya) => {
  try {
    const res = await imsClient.post(`cheque-verify-by-prasasakiya/`, data);
    if (res.data) toast.success(DataSuccess("posted"));
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return isRejectedWithValue(appError.getError());
  }
});
