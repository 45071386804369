import { Grid } from "@ims/common";
import useTranslated from "../../hooks/useTranslated";
import { BudgetTypeI } from "../../rtk/feature/budget/budgetSlice";

interface Props {
  budget: BudgetTypeI | null;
}

const PlanInfo = ({ budget }: Props) => {
  const { keyword } = useTranslated();
  return (
    <>
      <Grid>
        <Grid.Row gap={1}>
          <Grid.Col spanMd={6} spanSm={12}>
            <strong> {keyword["Budget Name"]} </strong>: {budget?.name}
          </Grid.Col>
          <Grid.Col spanMd={6} spanSm={12}>
            <strong> {keyword["Balance"]}</strong>: {budget?.balance}
          </Grid.Col>{" "}
          <Grid.Col spanMd={6} spanSm={12}>
            <strong> {keyword["Allocation"]} </strong>: {budget?.allocation}
          </Grid.Col>{" "}
          <Grid.Col spanMd={6} spanSm={12}>
            <strong> {keyword.Attention} </strong>: {budget?.attention}
          </Grid.Col>
          <Grid.Col spanMd={6} spanSm={12}>
            <strong> {keyword.Expenditure} </strong>: {budget?.expenditure}
          </Grid.Col>{" "}
          <Grid.Col spanMd={6} spanSm={12}>
            <strong> {keyword.Hint} </strong>: {budget?.hint}
          </Grid.Col>{" "}
          <Grid.Col spanMd={6} spanSm={12}>
            <strong> {keyword.Ward} </strong>: {budget?.ward}
          </Grid.Col>{" "}
          <Grid.Col spanMd={6} spanSm={12}>
            <strong> {keyword.Unit} </strong>: {budget?.unit}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default PlanInfo;
