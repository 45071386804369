import axios from "axios";
import i18n from "../i18n";

export type LangI = "en" | "np";
export type ActionI =
  | "login"
  | "logout"
  | "added"
  | "created"
  | "assigned"
  | "posted"
  | "updated"
  | "deleted"
  | "verified"
  | "verification"
  | "resentVerification"
  | "started"
  | "discarded"
  | "approved";

type stringObject = {
  [key: string]: string;
};

export type messageTypes = {
  en: stringObject;
  np: stringObject;
};

export const generateSuccessMessage = (acn: ActionI, lng: LangI, name?: string): string => {
  const SucessMessage: messageTypes = {
    en: {
      created: `${name} created Successfully`,
      added: `${name} added Successfully`,
      assigned: `${name} assigned Successfully`,
      posted: `${name} posted Successfully`,
      updated: `${name} updated successfully!`,
      deleted: `${name} deleted successfully!`,
      approved: `${name} approved successfully!`,
      discarded: `${name} discarded successfully!`,
      started: `${name} started successfully!`,
      login: "Successfully logged in",
      logout: "Successfully logged out",
      verified: "Verified",
      verification: "Sent for verifiecation",
      resentVerification: "Verification successfully resent",
    },
    np: {
      created: `${name} सफलतापूर्वक सिर्जना गरियो`,
      added: `${name} सफलतापूर्वक थपियो`,
      assigned: `${name} सफलतापूर्वक नियुक्त गरियो`,
      posted: `${name} सफलतापूर्वक पोस्ट गरियो`,
      updated: `${name} सफलतापूर्वक अपडेट गरियो!`,
      deleted: `${name} सफलतापूर्वक हटाइयो!`,
      approved: `${name} सफलतापूर्वक स्वीकृत भयो!`,
      discarded: `${name} सफलतापूर्वक खारेज गरियो!`,
      started: `${name} सफलतापूर्वक सुरु भयो!`,
      login: "सफलतापूर्वक लग इन गरियो",
      logout: "सफलतापूर्वक लग आउट भयो",
      verified: "प्रमाणित",
      verification: "प्रमाणीकरणका लागि पठाइयो",
      resentVerification: "प्रमाणीकरण सफलतापूर्वक पुन: पठाइयो",
    },
  };

  return SucessMessage[lng][acn];
};

export const ErrorMessage: messageTypes = {
  en: {
    Unauthorized: "You are not authorized to access this resource.",
    NotFound: "The requested resource was not found.",
    ServerError: "An internal server error occurred. Please try again later.",
    ValidationError: "Validation error. Please check your input",
    BadRequest: "Bad request. Your request is invalid.",
    SthWrong: "Something went Wrong. Please try again later.",
    NoPermission: "e",
    AlreadeyExists: "Sorry,this entry already exists",
  },
  np: {
    BadRequest: "खराब अनुरोध। तपाईंको अनुरोध अमान्य छ।",
    Unauthorized: "तपाईं यो स्रोत पहुँच गर्न अधिकृत हुनुहुन्न।",
    NotFound: "अनुरोध गरिएको स्रोत फेला परेन।",
    ServerError: "आन्तरिक सर्भर त्रुटि भयो। फेरी प्रयास गर्नु होला।",
    ValidationError: "प्रमाणीकरण त्रुटि। कृपया आफ्नो इनपुट जाँच गर्नुहोस्",
    SthWrong: "केहि गलत भयो। फेरी प्रयास गर्नु होला।",
    NoPermission: "तपाईसँग यो कार्य गर्ने अनुमति छैन।",
    AlreadeyExists: "माफ गर्नुहोस्, यो प्रविष्टि पहिले नै अवस्थित छ",
  },
};

export const generateErrorMessage = (error?: any): string => {
  const lng: LangI = getActiveLang();
  if (error?.response?.status === 400) return ErrorMessage[lng].BadRequest;
  else if (error?.response?.status === 401) return ErrorMessage[lng].Unauthorized;
  else if (error?.response?.status === 404) return ErrorMessage[lng].NotFound;
  else if (error?.response?.status === 422) return ErrorMessage[lng].ValidationError;
  else if (error?.response?.status >= 500) return ErrorMessage[lng].ServerError;
  else return ErrorMessage[lng].SthWrong;
};

export function getActiveLang(): LangI {
  const activeLang = i18n.language;
  return activeLang === "np" || activeLang === "en" ? activeLang : "en";
}
