import { Button, Flex, Form, Grid, MainLayout } from "@ims/common";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../core-ui/Page";
import ReuseForm from "../../core-ui/ReuseForm";
import {
  getChequeVerifyByPrasasakiya,
  getChequeVerifyByPrasasakiyaById,
  postChequeVerifyByPrasasakiya,
} from "../../rtk/feature/cheque-verify-by-prasasakiya/chequeVerifyByPrasasakiyaAPI";
import { cleanupChequeVerifyByPrasasakiya } from "../../rtk/feature/cheque-verify-by-prasasakiya/chequeVerifyByPrasasakiyaSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { toast } from "react-toastify";

const ChequeDetails = () => {
  interface IUpdateCheque {
    updated_cheque: FileList;
    isverified: boolean;
  }
  const dispatch = useAppDispatch();

  const params = useParams();
  const id = params.id as string;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateCheque>();

  useEffect(() => {
    dispatch(getChequeVerifyByPrasasakiyaById({ id: id }));
  }, [params]);

  const onSubmit = async (data: IUpdateCheque) => {
    const formData = new FormData();
    const updatedFile = data?.updated_cheque?.[0];
    if (!updatedFile) {
      toast.error("Please upload updated cheque photo");
      return;
    }
    formData.append("updated_cheque_photo", updatedFile);
    formData.append("cheque_verify_by_prasasakiya", String(data.isverified));
    await dispatch(postChequeVerifyByPrasasakiya({ data: formData, id: id }));
    await dispatch(getChequeVerifyByPrasasakiya({ params: {} }));
  };
  const { data, error, loading } = useAppSelector((state) => state.chequeVeirfyByPrasasakiya.byId);
  const formSubmitted = useAppSelector((state) => state.chequeVeirfyByPrasasakiya.formSubmitted);

  const navigate = useNavigate();

  useEffect(() => {
    if (formSubmitted) {
      navigate("/bill-payments");
    }

    return () => {
      dispatch(cleanupChequeVerifyByPrasasakiya());
    };
  }, [formSubmitted]);

  return (
    <MainLayout.Main>
      <Page.Title>Cheque details</Page.Title>
      <ReuseForm control={control} onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Col spanLg={12} spanMd={12} spanSm={12}>
            <Form.Label style={{ width: "100%" }}>Cheque photo</Form.Label>

            <img width={350} height={250} src={data?.plan_cheque.cheque_photo} alt="cheque" />
          </Grid.Col>

          <Grid.Row gap={3}>
            <Grid.Col spanLg={12} spanMd={12} spanSm={12}>
              <Form.Label style={{ width: "100%" }}>update Cheque photo</Form.Label>
              <Controller
                name="updated_cheque"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field: { onChange, value }, fieldState, formState }) => {
                  return (
                    <Form.File
                      id="updated_cheque"
                      name="updated_cheque"
                      fileType="image"
                      accept="image/*"
                      previewImage={data?.updated_cheque_photo || ""}
                      onChange={(e) => {
                        onChange(e.target.files);
                      }}
                      placeholder="add cheque"
                    />
                  );
                }}
              ></Controller>
            </Grid.Col>
            <Grid.Col>
              <Flex gap="2rem">
                <Form.Label style={{ whiteSpace: "nowrap" }}>is verified</Form.Label>

                <input type="checkbox" {...register("isverified")} />
              </Flex>
            </Grid.Col>
          </Grid.Row>
        </Grid>
        {!data?.cheque_verify_by_prasasakiya && (
          <Button variant="primary" style={{ marginTop: "1rem" }} type="submit">
            {" "}
            submit{" "}
          </Button>
        )}
        <Button type="button" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </ReuseForm>
    </MainLayout.Main>
  );
};

export default ChequeDetails;
