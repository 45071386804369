import { createSlice } from "@reduxjs/toolkit";
import { UserTypeI } from "../users/usersSlice";
import { addYojana, getYojana, getYojanas, updateYojana } from "./yojanaApi";

export interface YojanaFormTypeI {
  status: string;
  title: string;
  address: string;
  total_cost: string;
  agreement_date: string;
  start_date: string;
  end_date: string;
}

export interface YojanaTypeI {
  id: string;
  status: string;
  name: string;
  address: string;
  total_cost: string;
  agreement_date: string;
  start_date: string;
  end_date: string;
  user: UserTypeI;
}

export interface YojanasSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: YojanaTypeI[];
  } | null;
  item: YojanaTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: YojanasSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const yojanasSlice = createSlice({
  name: "yojanas",
  initialState,
  reducers: {
    yojanasCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addYojana.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addYojana.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addYojana.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getYojanas.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getYojanas.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getYojanas.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getYojana.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getYojana.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getYojana.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateYojana.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateYojana.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateYojana.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
  },
});

export const { yojanasCleanUp } = yojanasSlice.actions;
export default yojanasSlice.reducer;
