import styled from "styled-components/macro";

export const StyledPage = styled.div``;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 1rem 0px;

  & > button {
    margin-right: 0px;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  text-transform: capitalize;
  color: #333;
`;
export const StyledSubTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  color: #333;
`;
export const StyledMiniTitle = styled.h4`
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: capitalize;
  color: #333;
`;
export const StyledCorrectionText = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  color: red;
  background: #eeebeb;
  border: 1px solid #ccc;
  font-style: italic;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem;
`;

export const StyledButtonGrp = styled.div``;
