import { useEffect, useState } from "react";
import { getWards } from "../../../rtk/feature/ward/wardApi";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { getWardList } from "../../../rtk/feature/auth/authApi";
import { WardTypeI } from "../../../rtk/feature/ward/wardSlice";
import { showLoading } from "../../../rtk/feature/auth/authSlice";
import Page from "../../../core-ui/Page";
import { Button, Flex, Form, Grid, MainLayout } from "@ims/common";
import useTranslated, { TranslateKeyTypes } from "../../../hooks/useTranslated";
import { assignWard, getAssignedWard } from "../../../rtk/feature/assign-ward/assignWardApi";
import { AssignWardTypeI } from "../../../rtk/feature/assign-ward/assignWardSlice";
import { ReactSelectOptionI } from "../PlanSearch";
import { englishNepaliNumberTranslation } from "../../../utils/translateNumber";
import { getProfile } from "../../../rtk/feature/users/usersApi";
import { userFriendlyDate } from "../../../utils/date";

interface AssignWardPropsI {
  budgetID: string;
}

export default function AssignWard({ budgetID }: AssignWardPropsI) {
  const [wardList, setWardList] = useState<WardTypeI[] | []>([]);
  const [assignedWard, setAssignedWard] = useState<AssignWardTypeI[] | null>(null);
  const [selectedWard, setSelectedWard] = useState<ReactSelectOptionI | null>(null);
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();
  const { item: user, loading } = useAppSelector((state) => state.users);
  const userAssignedWard = user?.ward?.ward_name;

  const wardSelecotor = useAppSelector((state) => state.wards);
  const assignedWardSelecotor = useAppSelector((state) => state.assignWard);

  useEffect(() => {
    dispatch(showLoading(wardSelecotor.loading || assignedWardSelecotor.loading || loading));
  }, [dispatch, wardSelecotor.loading, assignedWardSelecotor.loading, loading]);

  useEffect(() => {
    dispatch(getWards({ per_page: 100000, page: 1 }));
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAssignedWard(budgetID));
  }, [dispatch, budgetID]);

  useEffect(() => {
    if (assignedWardSelecotor.data) {
      setAssignedWard(assignedWardSelecotor.data);
    }
  }, [assignedWardSelecotor.data]);

  useEffect(() => {
    if (wardSelecotor.data) setWardList(wardSelecotor.data.results);
  }, [wardSelecotor.data]);

  const handleAssignWard = async () => {
    try {
      if (selectedWard) {
        await dispatch(assignWard({ budgetID, wardID: selectedWard.value }));
        await dispatch(getAssignedWard(budgetID));
      }
    } catch (error) {}
  };

  const wardOptions = wardList?.filter((ward) => ward?.ward_name === userAssignedWard);

  console.log(wardOptions, "@wardoptoin");

  return (
    <>
      <Page.Container>
        <Page.Title>{!assignedWard?.length ? keyword["Assign Ward"] : keyword["Assigned Ward"]}</Page.Title>
      </Page.Container>

      {!assignedWard?.length && (
        <>
          <Form>
            <Grid>
              <Grid.Row gap={1.5}>
                <Grid.Col spanMd={6}>
                  <Form.FormGroup>
                    <Form.Label>{keyword["Ward"]}</Form.Label>
                    <Form.ReactSelect
                      name="name"
                      placeholder={keyword["Select"] + "..."}
                      options={wardOptions?.map((ward) => {
                        return {
                          value: ward.id,
                          label: keyword[ward.ward_name],
                        };
                      })}
                      value={selectedWard}
                      onChange={(selectedOption: ReactSelectOptionI) => {
                        setSelectedWard(selectedOption);
                      }}
                    />
                  </Form.FormGroup>
                </Grid.Col>
                <Grid.Col spanMd={3}>
                  <br />
                  <Button variant="primary" type="button" onClick={handleAssignWard}>
                    {keyword["Assign"]}
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form>
          <br />
          <p>{keyword["Ward not Assigned."]}</p>
        </>
      )}

      {!!assignedWard?.length && (
        <>
          <hr />
          <br />
          <Grid>
            {assignedWard?.map((aw, idx) => (
              <Grid.Row gap={1} key={idx}>
                <Grid.Col spanMd={6} spanSm={12}>
                  <strong> {keyword["Ward Number"]} </strong>: {englishNepaliNumberTranslation(aw.ward.ward_number)}
                </Grid.Col>
                <Grid.Col spanMd={6} spanSm={12}>
                  <strong> {keyword["Ward Name"]} </strong>: {keyword[aw.ward.ward_name]}
                </Grid.Col>{" "}
                <Grid.Col spanMd={6} spanSm={12}>
                  <strong> {keyword["Ward Address"]} </strong>: {aw.ward.ward_address}
                </Grid.Col>
                <Grid.Col spanMd={6} spanSm={12}>
                  <strong> {keyword["Ward Address"]} </strong>: {aw.ward.ward_address_en}
                </Grid.Col>{" "}
                <Grid.Col spanMd={6} spanSm={12}>
                  <strong> {keyword["Ward Chief"]}</strong>: {aw.ward.ward_chief}
                </Grid.Col>
                <Grid.Col spanMd={6} spanSm={12}>
                  <strong> {keyword["Ward Chief Number"]}</strong>: {aw.ward.ward_chief_no}
                </Grid.Col>{" "}
                <Grid.Col spanMd={6} spanSm={12}>
                  <strong> {keyword["Ward Email"]}</strong>: {aw.ward.ward_email}
                </Grid.Col>{" "}
                <Grid.Col spanMd={6} spanSm={12}>
                  <strong> {keyword["Ward Fax"]}</strong>: {aw.ward.ward_fax_no}
                </Grid.Col>
                <Grid.Col spanMd={6} spanSm={12}>
                  <strong> {keyword["Phone Number"]}</strong>: {aw.ward.ward_phone_no}
                </Grid.Col>
                <hr />
              </Grid.Row>
            ))}
          </Grid>
        </>
      )}
    </>
  );
}
