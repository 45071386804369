import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import Tab from "../../../components/tab/Tab";
import styled from "styled-components";
import ReuseForm, { FormContext } from "../../../core-ui/ReuseForm";
import { useForm } from "react-hook-form";
import { Form, Table } from "@ims/common";
import { FiDelete, FiSave, FiTrash } from "react-icons/fi";
import { EstimationDetailTypeI, PlanEstimateFormTypeI } from "../../../rtk/feature/plan-estimate/planEstimateSlice";
import {
  addPlanEstimate,
  deletePlanEstimate,
  updatePlanEstimate,
} from "../../../rtk/feature/plan-estimate/planEstimateApi";
import { toast } from "react-toastify";
import { FormulaChoice, getOptionFromValue, ShapeChoice } from "../../../utils/constants";
import {
  addEstimateItem,
  deleteEstimateItem,
  getGroupPlanEstimate,
  getGroupPlanEstimates,
  updateEstimateItem,
  updateGroupPlanEstimate,
} from "../../../rtk/feature/plan-group-estimate/planGroupEstimateApi";

export const geometry: any = {
  pyramid: ["area"],
  cylinder: ["area", "volume", "surface area", "curved surface area"],
  triangle: ["area"],
  circle: ["area"],
  rectangle: ["area"],
};

interface EstimateItemFormPropsI {
  value?: EstimationDetailTypeI;
  onChange: (data: PlanEstimateFormTypeI) => void;
  onAdd: () => void;
  onDelete: (id: string | undefined) => void;
  onAddRow: () => void;
  index: number;
  total: number;
  budgetID: string | null;
  groupID?: string;
  latest?: boolean;
}

function EstimateItemForm(props: EstimateItemFormPropsI) {
  console.error("props", props);
  const dispatch = useAppDispatch();
  const { onChange, value, budgetID } = props;

  const {
    formState: { errors, isDirty },
    control,
    getValues,
    watch,
    setValue,
    reset,
  } = useForm<PlanEstimateFormTypeI>({
    defaultValues: {},
  });

  const fields = watch();

  useEffect(() => {
    if (props.value?.shapes) setValue("shapes", props.value.shapes);
    if (props.value?.formula) setValue("formula", props.value.formula);
    if (props.value?.name) setValue("name", props.value.name);
    // if (props.value?.description) setValue("description", props.value.description);
    if (props.value?.length) setValue("length", props.value.length);
    if (props.value?.breadth) setValue("breadth", props.value.breadth);
    if (props.value?.height) setValue("height", props.value.height);
    if (props.value?.number) setValue("number", props.value.number);
    if (props.value?.remarks) setValue("remarks", props.value.remarks);
    if (props.value?._is_latest) setValue("_is_latest", props.value._is_latest);
    if (props.value?.final_measurement) setValue("final_measurement", props.value.final_measurement);
  }, [setValue]);

  useEffect(() => {
    watch((value, { name, type }) => {
      const finalData = { ...value, ...getValues(), id: props?.value?.id, quantity: props.value?.quantity };
      onChange(finalData);
    });
  }, [watch, getValues, onChange]);

  const handleSubmit = async (id: string | undefined) => {
    if (!budgetID || !props.groupID || !id) return toast.warning("Budget ID or Estimate Detail is not valid.");
    const formData = {
      ...fields,
      id,
      number: +fields.number,
      final_measurement: undefined,
      height: +fields.height,
      length: +fields.length,
      breadth: +fields.breadth,
      total: 90,
    };

    if (formData._is_latest) {
      await dispatch(addEstimateItem({ data: formData }))
        .then(async (addData) => {
          if (!props.groupID || !addData.payload?.id) {
            toast.warn("Unable to add such estimate in plan!");
            throw new Error("Unable to add such estimate in plan!");
          }

          if (addData.meta.requestStatus === "fulfilled" && addData.payload?.id) {
            await dispatch(getGroupPlanEstimate({ budgetID: budgetID, groupID: props.groupID })).then(async (data) => {
              if (data.meta.requestStatus === "fulfilled") {
                const equipment_measurement_analysis = data.payload.equipment_measurement_analysis;
                if (!equipment_measurement_analysis || !props.groupID) return;
                const tempIds = equipment_measurement_analysis.map((el: any) => el.id);

                if (addData.payload?.id) {
                  await dispatch(
                    updateGroupPlanEstimate({
                      budgetID: budgetID,
                      id: props.groupID,
                      data: {
                        equipment_measurement_analysis: [...tempIds, addData.payload.id],
                      },
                    })
                  );
                }
              }
            });
          }
        })
        .then(() => {
          props.onAddRow();
        });
    } else {
      await dispatch(updateEstimateItem({ data: formData, id: formData.id }));
    }
  };

  const handleDelete = async (id: string | undefined) => {
    if (!budgetID || !props.groupID || !id) return toast.warning("Budget ID or Estimate Detail is not valid.");
    // props.onDelete(id);
    await dispatch(deleteEstimateItem(id)).then(async (data) => {
      if (!props.groupID) return;

      await dispatch(getGroupPlanEstimates({ budgetID: budgetID }));
    });
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit(props?.value?.id);
      props.onAddRow();
    }
  };

  return (
    <ReuseForm.ReuseFormTr control={control}>
      <td>
        <ReuseForm.Input type="" name="name" placeholder="name" />
      </td>

      <td>
        <ReuseForm.Input type="number" name="number" placeholder="number" onKeyDown={handleEnter} />
      </td>

      <td>
        <ReuseForm.Input type="number" name="length" placeholder="m" />
      </td>
      <td>
        <ReuseForm.Input type="number" name="breadth" placeholder="m" />
      </td>
      <td>
        <ReuseForm.Input type="number" name="height" placeholder="m" />
      </td>

      <td>
        <div>{props?.value?.quantity}</div>
      </td>
      <td>
        <ReuseForm.Input name="remarks" placeholder="Remarks" onKeyDown={handleEnter} />
      </td>
      <td>
        <Table.ActionButton
          color="green"
          type="button"
          disabled={!isDirty}
          onClick={() => handleSubmit(props?.value?.id)}
        >
          <FiSave />
        </Table.ActionButton>

        <Table.ActionButton
          color="red"
          disabled={props?.value?._is_latest}
          onClick={() => {
            handleDelete(props?.value?.id);
          }}
        >
          <FiTrash />
        </Table.ActionButton>
      </td>
    </ReuseForm.ReuseFormTr>
  );
}

export default EstimateItemForm;

export const StyledTabHeader = styled(Tab.TabHeader)`
  display: flex;
  & > button {
    flex: 1;
  }
`;
