import { createAsyncThunk } from "@reduxjs/toolkit";
import { imsClient } from "../../../utils/imsClient";
import { toast } from "react-toastify";
import { YojanaBookTemplateTypeI } from "./yojanaBookTemplateSlice";
import { AppError } from "../../../utils/AppError";
import { YojanaTemplateSuccess } from "../../../utils/res-mes/response-message-generator";
import { generateErrorMessage } from "../../../utils/res-mes/response-message";

export const getYojanaBookTemplate = createAsyncThunk("getYojanaBookTemplate", async () => {
  try {
    const res = await imsClient.get("/book-templates/");
    return res.data.sort((a: YojanaBookTemplateTypeI, b: YojanaBookTemplateTypeI) => a.order - b.order);
  } catch (err) {
    generateErrorMessage();
  }
});

export const updateYojanaBookTemplate = createAsyncThunk(
  "updateYojanaBookTemplate",
  async ({ data, id }: { data: YojanaBookTemplateTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/book-templates/${id}/`, data, {
        headers: { "Content-Type": "application/json" },
      });
      if (res.data) toast.success(YojanaTemplateSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
