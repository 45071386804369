import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { EquipmentTypeI } from "../../rtk/feature/equipment/equipmentSlice";
import { useNavigate } from "react-router-dom";

import { FiEdit } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import { getEquipments } from "../../rtk/feature/equipment/equipmentApi";
import { Button } from "@ims/common";
import Page from "../../core-ui/Page";
import { CiExport } from "react-icons/ci";
import { BsPrinter } from "react-icons/bs";
import TableUtility from "../user/TableUtility";
import { AiOutlinePlus } from "react-icons/ai";
import useTranslated from "../../hooks/useTranslated";

function EquipmentManagePage() {
  const [equipments, setEquipments] = useState<EquipmentTypeI[]>([]);
  const { keyword } = useTranslated();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const equipmentsState = useAppSelector((state) => state.equipments);
  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getEquipments({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    if (equipmentsState.data) {
      setEquipments(equipmentsState.data.results);
      setTotalItems(equipmentsState.data.total);
    }

    dispatch(showLoading(equipmentsState.loading));
  }, [equipmentsState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/equipment/edit/${id}`);
  };

  const tableUtility = new TableUtility();

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {keyword["Equipment Manage"]} </Page.Title>
        <Page.ButtonGrp>
          <Button startIcon={<CiExport />} onClick={tableUtility.export}>
            {keyword.Export}
          </Button>

          <Button startIcon={<BsPrinter />} onClick={tableUtility.print}>
            {keyword.Print}
          </Button>

          <Button variant="primary" startIcon={<AiOutlinePlus />} onClick={() => navigate(`/equipment/add`)}>
            {keyword["Add Equipment"]}
          </Button>
        </Page.ButtonGrp>
      </Page.Container>

      <Table.Container>
        <Table id="export-table">
          <Table.Head>
            <Table.Row>
              <Table.HeadCol> {keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>Name (English)</Table.HeadCol>
              <Table.HeadCol>Name (Nepali)</Table.HeadCol>
              <Table.HeadCol>Type</Table.HeadCol>
              <Table.HeadCol>Equipment Type</Table.HeadCol>
              <Table.HeadCol>Unit</Table.HeadCol>
              <Table.HeadCol>Unit Rate</Table.HeadCol>
              <Table.HeadCol>Transport Type</Table.HeadCol>
              <Table.HeadCol>Unit Weight</Table.HeadCol>
              <Table.HeadCol>Load/Unload Rate</Table.HeadCol>
              <Table.HeadCol align="center">Action</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {equipments &&
              equipments.length > 0 &&
              equipments.map((equipment, index) => {
                return (
                  <Table.Row key={equipment.id}>
                    <Table.Col>{getSN(index)}</Table.Col>
                    <Table.Col>{equipment.english_name}</Table.Col>
                    <Table.Col>{equipment.nepali_name}</Table.Col>
                    <Table.Col>{equipment.type}</Table.Col>
                    <Table.Col>{equipment.equipment_type}</Table.Col>
                    <Table.Col>{equipment.unit.name}</Table.Col>
                    <Table.Col>{equipment.unit_rate}</Table.Col>
                    <Table.Col>{equipment.transportation_type.name}</Table.Col>
                    <Table.Col>{equipment.unit_weight}</Table.Col>
                    <Table.Col>
                      {equipment.load_unload_rate} per {equipment.load_unload_per}
                    </Table.Col>

                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(equipment.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default EquipmentManagePage;
