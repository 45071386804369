import React, { useState } from "react";
import styled from "styled-components";

export interface PopupModalPropsI {
  isOpen: boolean;

  onClose: () => void;
  children?: React.ReactNode;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  overflow: auto;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

// const Column = styled.div`
//   flex: 1;
//   padding: 10px;
// `;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 48px;
  color: #fff;
  cursor: pointer;
`;

const PopupModal: React.FC<PopupModalPropsI> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  children = children || <h1>No Content</h1>;
  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>×</CloseButton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};

export default PopupModal;
