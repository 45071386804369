import { Control, Controller, FieldErrors, UseFormHandleSubmit, useForm } from "react-hook-form";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Flex, Form, Grid } from "@ims/common";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import useTranslated from "../../hooks/useTranslated";
import ReuseForm from "../../core-ui/ReuseForm";
import { CommunicationTitleI } from "../../rtk/feature/communication-title/communicationTitleSlice";
import { getCommunicationTitles } from "../../rtk/feature/communication-title/communicationTitleApi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { ReactSelectOptionI } from "../plan/PlanSearch";
import {
  AdminMinuteUpdateI,
  addAdminMinutes,
  getAdminMinutes,
  updateAdminMinutes,
} from "../../rtk/feature/admin-minute/adminMinuteApi";
import {
  UpabhoktaSamitiMinuteUpdateI,
  addUpahboktaSamitiMinute,
  getUpahboktaSamitiMinute,
  updateUpahboktaSamitiMinute,
} from "../../rtk/feature/upabhokta-samiti-minute/upabhoktaSamitiMinuteApi";
import { UpabhoktaSamitiMinuteI } from "../../rtk/feature/upabhokta-samiti-minute/upabhoktaSamitiMinuteSlice";
import { AdminMinuteI } from "../../rtk/feature/admin-minute/adminMinuteSlice";

interface CreateMinutDocPropsI {
  id?: string;
  yojanaId: string;
  isUpabhokta?: boolean;
  Udoc?: UpabhoktaSamitiMinuteI | null;
  Adoc?: AdminMinuteI | null;
}

export default function CreateMinutDoc({ yojanaId, id, isUpabhokta, Udoc, Adoc }: CreateMinutDocPropsI) {
  const [imageFile, setImageFile] = useState<string | ArrayBuffer | null>(null);
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    register,
    control,
  } = useForm<any>({
    defaultValues: {},
  });
  const [communicationTitleList, setCommunicationTitleList] = useState<CommunicationTitleI[] | []>([]);
  const [selectedTitle, setSelectedTitle] = useState<ReactSelectOptionI | null>(null);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<boolean>(false);

  const communicationTitleSelector = useAppSelector((state) => state.communicationTitle);

  useEffect(() => {
    dispatch(showLoading(communicationTitleSelector.loading));
  }, [dispatch, communicationTitleSelector.loading]);

  useEffect(() => {
    dispatch(getCommunicationTitles());
  }, [dispatch]);

  useEffect(() => {
    if (communicationTitleSelector.data) setCommunicationTitleList(communicationTitleSelector.data);
  }, [communicationTitleSelector.data]);

  useEffect(() => {
    if (Udoc) {
      setValue("file", Udoc.file_url);
      setValue("comments", Udoc.comments);
      setValue("title", Udoc.title);
      setSelectedTitle({ label: Udoc.title.title, value: Udoc.title.id });
      setIsUpdate(true);
    } else if (Adoc) {
      setValue("file", Adoc.file_url);
      setValue("comments", Adoc.comments);
      setIsUpdate(true);
    }
    setIsPreview(true);
  }, [Udoc, Adoc]);

  const resetAll = () => {
    setSelectedTitle(null);
    setImageFile(null);
    reset({ file: null });
    setIsPreview(false);
  };

  const onSubmit = async (data: any) => {
    let file: string = "";
    if (imageFile) {
      file = imageFile.toString();
    }

    if (!yojanaId) return;

    if (isUpdate) {
      if (Udoc?.id) {
        const payload: UpabhoktaSamitiMinuteUpdateI = { comments: data.comments };
        if (id) payload.upabhokta_samiti = id;
        if (file) payload.file = file;
        if (selectedTitle) payload.title = selectedTitle.value;

        await dispatch(
          updateUpahboktaSamitiMinute({
            yojanaId,
            minuteId: Udoc.id,
            data: payload,
          })
        );
        await dispatch(getUpahboktaSamitiMinute({ id: yojanaId }));
      } else if (Adoc?.id) {
        const payload: AdminMinuteUpdateI = { id: Adoc.id, comments: data.comments };
        if (file) payload.file = file;

        await dispatch(updateAdminMinutes({ id: yojanaId, data: payload }));
        await dispatch(getAdminMinutes({ id: yojanaId }));
      }
    } else if (isUpabhokta) {
      if (selectedTitle && id) {
        const payload = { upabhokta_samiti: id, comments: data.comments, title: selectedTitle.value, file };
        await dispatch(
          addUpahboktaSamitiMinute({
            id: yojanaId,
            data: payload,
          })
        );
        await dispatch(getUpahboktaSamitiMinute({ id: yojanaId }));
      }
    } else {
      await dispatch(addAdminMinutes({ id: yojanaId, data: { file, comments: data.comments } }));
      await dispatch(getAdminMinutes({ id: yojanaId }));
      // !!addNewDocument && addNewDocument({{ file_url:file, comments: data.comments }});
    }

    resetAll();
  };

  const convertFileToBase64 = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setImageFile(reader.result);
      };
    }
  };

  const handleCancel = () => {
    resetAll();
    setIsUpdate(false);
  };
  return (
    <>
      <h4>{isUpdate ? keyword["Update Minute"] : keyword["Create Minute"]}</h4>
      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          {isUpabhokta && (
            <>
              <Grid.Row gap={1.5}>
                <Grid.Col spanMd={12} spanXs={12}>
                  <Form.FormGroup>
                    <Form.Label>{keyword["Title"]}</Form.Label>
                    <Form.ReactSelect
                      name="name"
                      placeholder={keyword["Select"] + "..."}
                      options={communicationTitleList?.map((communication) => ({
                        value: communication.id,
                        label: communication.title,
                      }))}
                      value={selectedTitle}
                      onChange={(selectedOption: ReactSelectOptionI) => {
                        setSelectedTitle(selectedOption);
                      }}
                    />
                  </Form.FormGroup>
                </Grid.Col>
              </Grid.Row>
              <br />
            </>
          )}
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={12} spanXs={12}>
              <Flex.Col>
                <Form.Label> {keyword["Document"]} </Form.Label>
                <Controller
                  name="file"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field: { onChange, value }, fieldState, formState }) => {
                    return (
                      <Form.File
                        id="file"
                        fileType="image"
                        clearData={isPreview}
                        onChange={(e) => {
                          setIsPreview(true);
                          onChange(convertFileToBase64(e));
                        }}
                        placeholder="Enter final measurement"
                        previewImage={(isPreview && Adoc?.file_url) || Udoc?.file_url}
                      />
                    );
                  }}
                ></Controller>
              </Flex.Col>
              <br />
              <ReuseForm.TextArea
                label={keyword.Comments}
                name="comments"
                placeholder="Write a comment"
                rules={{ required: keyword["This is required field."] }}
                error={errors["comments"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {isUpdate ? keyword.Update : keyword.Create}
              </Button>
              <Button type="button" onClick={handleCancel}>
                {keyword.Cancel}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </>
  );
}
