import { createSlice } from "@reduxjs/toolkit";
import {
  addAnalyzeRate,
  deleteAnalyzeRate,
  getAnalyzeRate,
  getAnalyzeRates,
  updateAnalyzeRate,
} from "./analyzeRateApi";

export interface AnalyzeRateFormTypeI {
  equipments: string;
  quantity: string;
  total_amount: string;
  vat_amount: string;
  vat_percent: string;
  grand_total: string;
  _is_latest: boolean;
}

export interface RateEquipmentTypeII {
  id: string;
  equipment: any | null;
  quantity: number;
  total_amount?: number;
  unit_price?: number;
  _is_latest?: boolean;
}

export interface AnalyzeRateTypeI {
  id: string;
  yojana: string;
  name: string;
  code: string;
  measurement: string;
  budget_upobhokta: string;
}

export interface AnalyzeRatesSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: AnalyzeRateTypeI[];
  } | null;
  item: AnalyzeRateTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: AnalyzeRatesSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const analyzeRatesSlice = createSlice({
  name: "analyzeRates",
  initialState,
  reducers: {
    analyzeRatesCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addAnalyzeRate.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addAnalyzeRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addAnalyzeRate.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getAnalyzeRates.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getAnalyzeRates.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getAnalyzeRates.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getAnalyzeRate.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getAnalyzeRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getAnalyzeRate.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateAnalyzeRate.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateAnalyzeRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateAnalyzeRate.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteAnalyzeRate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteAnalyzeRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteAnalyzeRate.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { analyzeRatesCleanUp } = analyzeRatesSlice.actions;
export default analyzeRatesSlice.reducer;
