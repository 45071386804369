import { Button, Grid, MainLayout, Table } from "@ims/common";
import React, { useEffect, useState } from "react";
import Page from "../../core-ui/Page";
import { getBillplanning } from "../../rtk/feature/plannning-bill/planningBillAPI";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { useNavigate } from "react-router-dom";
import { getSN } from "../../utils/usePagination";
import { FiEdit, FiEye, FiSearch } from "react-icons/fi";
import useTranslated from "../../hooks/useTranslated";
import { styleSN } from "../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { GrStatusGood } from "react-icons/gr";
import { BiLoaderCircle } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { realTimeFilter } from "../../utils/real-time-filter";
import ReuseForm from "../../core-ui/ReuseForm";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import getFormattedSort from "../../utils/getFormattedSort";
const PlanningBill = () => {
  const [planningBillData, setPlannigBillData] = useState<any[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});
  const dispatch = useAppDispatch();

  const perPage = 100;

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    reset: filterReset,
    formState: { errors: filterErrors },
    watch: filterWatch,
  } = useForm();

  useEffect(() => {
    dispatch(getBillplanning());
  }, []);
  const { data: planningBillDataSelector } = useAppSelector((state) => state.billPlanning);
  const navigate = useNavigate();

  useEffect(() => {
    if (planningBillDataSelector) setPlannigBillData(planningBillDataSelector?.results);
  }, [planningBillDataSelector]);

  const handleEdit = (id: string) => {
    navigate(`edit/${id}`);
  };

  const handleView = (id: string) => {
    navigate(`details/${id}`);
  };
  const { keyword } = useTranslated();

  const onFilterSubmit = (data: any) => {
    dispatch(
      getBillplanning({
        per_page: perPage,
        search: data.search,
        ordering: getFormattedSort(sorts),
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getBillplanning({
        per_page: perPage,
        ordering: getFormattedSort(sorts),
      })
    );
    filterReset();
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {keyword["Planning Bill"]}</Page.Title>
        <Button variant="primary" onClick={() => navigate(`/budget/constructor/member/add`)}>
          {keyword["Add Bill"]}
        </Button>
      </Page.Container>

      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>
              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Yojana Name"]}</Table.HeadCol>
              <Table.HeadCol> {keyword["Bill Name"]} </Table.HeadCol>
              <Table.HeadCol> {keyword["Bill Review"]} </Table.HeadCol>
              <Table.HeadCol> {keyword["Bill File"]}</Table.HeadCol>
              <Table.HeadCol> {keyword.Actions} </Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {planningBillData?.length > 0 &&
              planningBillData?.map((bill, index) => {
                return (
                  <Table.Row key={bill.id}>
                    <Table.Col>{englishNepaliNumberTranslation(styleSN(index + 1))}</Table.Col>
                    <Table.Col>{bill.yojana.name}</Table.Col>
                    <Table.Col>{bill.bill_name}</Table.Col>
                    <Table.Col>
                      <span style={{ marginLeft: "30%" }}>
                        {bill.bill_review ? (
                          <GrStatusGood size={18} title={keyword["Reviewed"]} />
                        ) : (
                          <BiLoaderCircle size={18} title={keyword["Reviewed"]} />
                        )}
                      </span>
                    </Table.Col>
                    <Table.Col>{bill.bill_file}</Table.Col>

                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(bill.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                      <Table.ActionButton onClick={() => handleView(bill.id)}>
                        <FiEye color="rgb(141, 24, 24)" />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        {/* <Pagination /> */}
      </Table.Container>
    </MainLayout.Main>
  );
};

export default PlanningBill;
