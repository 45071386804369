import styled, { css } from "styled-components";

export const StyledDialogWrapper = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  display: flex;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  /* transition: 0.3s all ease; */

  ${(props) =>
    props.active
      ? css`
          display: flex;
          .container-dialog {
            animation-name: fadeIn;
            animation-duration: 0.2s;
            animation-fill-mode: forwards;
          }
        `
      : css`
          display: none;
        `}

  @keyframes fadeIn {
    from {
      display: none;
      opacity: 0;
      transform: scale(0.6);
    }
    to {
      display: flex;
      opacity: 1;
      transform: scale(1);
    }
  }
`;

// overflow: hidden;
export const StyledDialogContainer = styled.div`
  margin: auto;
  background: #fefefe;
  border-radius: 4px;
  min-width: 400px;
  padding: 10px 16px;
  .container-body {
    padding: 1.5rem 1.5rem 1rem 1.5rem;

    h4 {
      font-weight: 400;
    }
    .sub-title {
      margin-bottom: 1.5rem;
    }

    .container-button {
      display: flex;
      justify-content: flex-end;

      button:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export const StyledTitleContainer = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  ${(props) => `background: ${props.theme.palette.error.main};`};
  color: white;
  align-items: center;

  h3 {
    font-size: 16px;
    margin-bottom: 0px;
  }
`;

// button {
//   display: flex;
//   border: 0px;
//   background: transparent;
//   font-size: 2rem;
//   cursor: pointer;
//   margin-left: auto;
//   padding: 0.75rem;
// }
