import { useEffect, useState } from "react";
import { SamitiDocsI } from "../../rtk/feature/contractor-docs/contractorDocsSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import useTranslated from "../../hooks/useTranslated";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { Grid, MainLayout } from "@ims/common";
import GalleryCard from "./GalleryCard";
import Card from "./Card";
import { getContractorDocs } from "../../rtk/feature/contractor-docs/contractorDocsApi";
import ContractorDocsCreateEdit from "./ContractorDocsCreateEdit";
import styled from "styled-components";

interface ContractorDocsI {
  type?: string;
  contractorId: string;
}

export default function ContractorDocs({ type, contractorId }: ContractorDocsI) {
  const [docs, setDocs] = useState<SamitiDocsI[] | null>(null);
  const [selectDoc, setSelectedDoc] = useState<SamitiDocsI | null>(null);

  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();

  const contractorDocsSelector = useAppSelector((state) => state.contractorDocs);

  useEffect(() => {
    dispatch(showLoading(contractorDocsSelector.loading));
  }, [dispatch, contractorDocsSelector.loading]);

  useEffect(() => {
    dispatch(getContractorDocs(contractorId));
  }, [dispatch]);

  useEffect(() => {
    if (contractorDocsSelector.data) setDocs(contractorDocsSelector.data.samiti_docs);
  }, [contractorDocsSelector]);

  return (
    <>
      <ColumnContainer>
        {type !== "read-only" && (
          <FirstColumn>
            <ContractorDocsCreateEdit doc={selectDoc} contractorId={contractorId} />
          </FirstColumn>
        )}

        <SecondColumn>
          <GalleryCard>
            {docs?.length ? (
              docs.map((doc, idx) => (
                <div
                  key={idx}
                  style={{ display: "inline-block" }}
                  onClick={() => {
                    setSelectedDoc(doc);
                  }}
                >
                  <Card imageUrl={doc.file} title={doc.title} />
                </div>
              ))
            ) : (
              <p>{keyword["No Documents Found"]}</p>
            )}
          </GalleryCard>
        </SecondColumn>
      </ColumnContainer>
    </>
  );
}

const FirstColumn = styled.div`
  flex: 1;
  width: 25%;
  border: 1px solid rgb(204, 204, 204);
  margin-right: 10px;
  border-radius: 10px;
  padding: 16px;
`;

const SecondColumn = styled.div`
  flex: 3;
  width: 75%;
  border: 1px solid rgb(204, 204, 204);
  padding: 16px;
  border-radius: 10px;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
`;
