import React from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { Link, useRouteError } from "react-router-dom";

function ErrorPage() {
  const error: any = useRouteError();

  return (
    <MainLayout>
      <MainLayout.Section>
        <MainLayout.Main>
          <h1>Page Not Found</h1>
          <p>{error && error.error && error.error.message}</p>

          <Link to="/">Back to Dashboard</Link>
        </MainLayout.Main>
      </MainLayout.Section>
    </MainLayout>
  );
}

export default ErrorPage;
