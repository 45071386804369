import { useEffect, useState } from "react";
import useTranslated from "../../../hooks/useTranslated";
import ConstructorManagePage from "../../constructor/ConstructorManagePage";
import { showLoading } from "../../../rtk/feature/auth/authSlice";
import { getConstructors } from "../../../rtk/feature/constructor/constructorApi";
import { useNavigate } from "react-router-dom";
import { ConstructorTypeI } from "../../../rtk/feature/constructor/constructorSlice";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import usePagination from "../../../utils/usePagination";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Page from "../../../core-ui/Page";
import { Button, Form, Grid } from "@ims/common";
import { ReactSelectOptionI } from "../PlanSearch";
import { addConstructorDetails } from "../../../rtk/feature/assign-constructor/assignConstructorApi";
import { toast } from "react-toastify";
import PreviewAssignedConstructor from "./PreviewAssignedConstructor";
import { imsClient } from "../../../utils/imsClient";
import { getAllVerifiedVerifications } from "../../../rtk/feature/plan-verification/planVerificationAPI";
import { getUpabhoktaSamitiUsers } from "../../../rtk/feature/users/usersApi";
import { UserTypeI } from "../../../rtk/feature/users/usersSlice";
import { roles } from "../../../constants/constants";

interface AssingnConstructorPropsI {
  budgetID: string;
  isWard?: boolean;
}

export default function AssingnConstructor({ budgetID, isWard }: AssingnConstructorPropsI) {
  const [constructorList, setConstructorList] = useState<ConstructorTypeI[]>([]);
  const [upabhoktaSamitiUser, setUpabhoktaSamitiUser] = useState<UserTypeI[] | null>(null);
  const [user, setUser] = useState<ReactSelectOptionI | null>(null);
  // const [constructor, setConstructor] = useState<ConstructorTypeI | null>(null);
  const [selectedConstructor, setSelectedConstructor] = useState<ReactSelectOptionI | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const constructorsState = useAppSelector((state) => state.constructors);
  const upabhoktaSamitiUserState = useAppSelector((state) => state.users);
  const { keyword } = useTranslated();
  const myRole = useAppSelector((state) => state.auth.user?.role);

  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getConstructors({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );
    dispatch(getUpabhoktaSamitiUsers({}));

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    dispatch(showLoading(constructorsState.loading));
  }, [dispatch, constructorsState.loading]);

  useEffect(() => {
    if (constructorsState.data) {
      setConstructorList(constructorsState.data.results);
      setTotalItems(constructorsState.data.total);
    }
  }, [constructorsState, dispatch]);

  useEffect(() => {
    if (upabhoktaSamitiUserState.data) setUpabhoktaSamitiUser(upabhoktaSamitiUserState.data.results);
  }, [upabhoktaSamitiUserState, dispatch]);

  const handleAddConstructor = () => {
    navigate(`/budget/constructor/add?assign-constructor=true&create=new&back=true`);
  };

  const handleSubmitAssignConstructor = () => {
    try {
      if (selectedConstructor && user)
        dispatch(
          addConstructorDetails({ data: { contractor: selectedConstructor?.value, user: user?.value }, budgetID })
        );
    } catch (error) {
      toast.error("Something went wrong ");
    }
  };

  return (
    <>
      {myRole === roles.Purwadhar_Sakha && (
        <MainLayout.Main>
          <Page.Container>
            <Page.Title>{keyword["Assign Constructor"]}</Page.Title>
            {/* <Button variant="primary" onClick={handleAddConstructor}>
            {keyword["Add constructor"]}
          </Button> */}
          </Page.Container>

          <Form>
            <Grid>
              <Grid.Row gap={1.5}>
                <Grid.Col spanMd={4}>
                  <Form.FormGroup>
                    <Form.Label>{keyword["Constructors"]}</Form.Label>
                    <Form.ReactSelect
                      name="name"
                      placeholder={keyword["Select"] + "..."}
                      options={constructorList?.map((constructor) => ({
                        value: constructor.id,
                        label: constructor.name,
                      }))}
                      value={selectedConstructor}
                      onChange={(selectedOption: ReactSelectOptionI) => {
                        // handleSubmitAssignConstructor(selectedOption.value);
                        setSelectedConstructor(selectedOption);
                        // setConstructor(
                        //   constructorList.find((constructor) => constructor.id === selectedOption.value) || null
                        // );
                      }}
                    />
                  </Form.FormGroup>
                </Grid.Col>
                <Grid.Col spanMd={4}>
                  <Form.FormGroup>
                    <Form.Label>{keyword["User"]}</Form.Label>
                    <Form.ReactSelect
                      name="name"
                      placeholder={keyword["Select"] + "..."}
                      options={upabhoktaSamitiUser?.map((user) => ({
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                      }))}
                      value={user}
                      onChange={(selectedOption: ReactSelectOptionI) => {
                        setUser(selectedOption);
                      }}
                    />
                  </Form.FormGroup>
                </Grid.Col>
                <Grid.Col spanMd={3}>
                  <br />
                  <Button variant="primary" type="button" onClick={handleSubmitAssignConstructor}>
                    {keyword.Assign}
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form>
          <br />
          {/* <Grid>
          <Grid.Row gap={1}>
            <Grid.Col spanMd={6} spanSm={12}>
              <strong> {keyword["ID"]} </strong>: {constructor?.id}
            </Grid.Col>{" "}
            <Grid.Col spanMd={6} spanSm={12}>
              <strong> {keyword["Constructor Name"]} </strong>: {constructor?.name}
            </Grid.Col>
            <Grid.Col spanMd={6} spanSm={12}>
              <strong> {keyword["Type"]}</strong>: {constructor?.type}
            </Grid.Col>{" "}
            <Grid.Col spanMd={6} spanSm={12}>
              <strong> {keyword["Yojana"]}</strong>: {constructor?.yojana.name || "N/A"}
            </Grid.Col>{" "}
          </Grid.Row>
        </Grid> */}
        </MainLayout.Main>
      )}
      <PreviewAssignedConstructor budgetID={budgetID} isWard={isWard} />
    </>
  );
}
