import { createAsyncThunk } from "@reduxjs/toolkit";
import { imsClient } from "../../../utils/imsClient";
import { AppError } from "../../../utils/AppError";
import { toast } from "react-toastify";
import { ConstructorSuccess } from "../../../utils/res-mes/response-message-generator";

export const getConstructorDetails = createAsyncThunk(
  "getConstructorDetails",
  async (budgetId: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`yojana/${budgetId}/assign-contractor/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
export const getFilteredConstructorDetails = createAsyncThunk(
  "getFilteredConstructorDetails",
  async (
    filteredConstructorParams: { per_page?: number; page?: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get(`/upabhokta-contractor/`, {
        params: filteredConstructorParams,
        signal: filteredConstructorParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addConstructorDetails = createAsyncThunk(
  "addConstructorDetails",
  async (data: { data: { contractor: string; user: string }; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`yojana/${data.budgetID}/assign-contractor/`, data.data, {
        headers: { "Content-Type": "application/json" },
      });
      if (res) toast.success(ConstructorSuccess("assigned"));
      dispatch(getConstructorDetails(data.budgetID));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
