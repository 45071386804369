import styled from "styled-components/macro";
import { ButtonPropsI } from "./Button";

export const StyledButton = styled.button<ButtonPropsI>`
  background-color: #e7e7e7;
  border: none;
  color: black;
  padding: 0.9rem 2rem;
  font-size: 1.4rem;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
  margin-right: 1rem;
  cursor: pointer;
  transition: 0.3s all ease;

  ${(props) =>
    props.variant === "primary" &&
    `background: ${props.theme.palette.primary.main}; 
     color: ${props.theme.palette.primary.contrastText};`};
  ${(props) =>
    props.variant === "auth" &&
    `background: ${props.theme.palette.primary.main}; 
     color: ${props.theme.palette.primary.contrastText};
     font-weight:700;
     font-size:20px;
     line-height:27px;
       padding: 1rem 4rem;
     border-radius:33px;`};

  ${(props) =>
    props.variant === "error" &&
    `background: ${props.theme.palette.error.main}; 
     color: ${props.theme.palette.error.contrastText};`};

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 1;
  }

  &:focus-visible {
    box-shadow: rgb(92 147 237 / 50%) 0px 0px 0px 3.2px;
    outline: none;
  }

  @media screen and (max-width: 1440px) {
    ${(props) =>
      props.variant === "auth" &&
      `
     font-size:16px;
     line-height:20px;
     border-radius:33px;`};
  }
`;
