import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../rtk/hook";
import { getWorkReports } from "../../../../../rtk/feature/work-report/workReportAPI";
import { showLoading } from "../../../../../rtk/feature/auth/authSlice";
import { IData } from "../../../../../rtk/feature/work-report/workReportSlice";
import { Button, Flex, Form, Grid, Table } from "@ims/common";
import AbstractCostTable from "../../../AbstractOfCost/components/AbstractCostTable";
import { BasicTable } from "../../../PlanSearch";
import Page from "../../../../../core-ui/Page";
import { LayoutContext } from "@ims/common/src/core-ui/layout/MainLayout";
import useTranslated from "../../../../../hooks/useTranslated";
import { BsPrinter } from "react-icons/bs";
import TableUtility from "../../../../user/TableUtility";
import { updateElectrificationWorks } from "../../../../../rtk/feature/electrification-work/electrificationWorkAPI";
import { updateSanitoryWorks } from "../../../../../rtk/feature/sanitory-work/sanitoryWorkAPI";
import { updateGeneralWork } from "../../../../../rtk/feature/general-work/generalWorkApi";
import { updateGroupPlanEstimate } from "../../../../../rtk/feature/plan-group-estimate/planGroupEstimateApi";
import EstimationBasicTable from "./EstimationBasicTable";
import FileCard from "./FileCard";
import PopupModal from "../../../../../components/PopupModal/PopupModal";
import MainLayout from "../../../../../../../common/src/core-ui/layout/MainLayout";
import ReuseForm from "../../../../../core-ui/ReuseForm";
import { useForm } from "react-hook-form";
import EstimationDocument from "./EstimationDocument";

interface FinalEstimationPropsI {
  budgetId: string;
}

const costTableStyle = `
    <style>
  table * {
    color: black;
    background: transparent!important;
  }

  table {
    width: 100%;
    text-align: left;
    border: 1px solid #000;
    border-width: 1px 1px 1px 1px !important;
    border-collapse: collapse;
    font-size: 14px;
  }

  th, td {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
    width:6rem;

  }
   


   th:nth-child(2), td:nth-child(2) {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
    width:17rem;
  }

    th:nth-child(1), td:nth-child(1) {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
    width:1rem;
  }



  td *{
    display: inline-block;
    margin-right: 5px;
  }
  
  
  td img {
    width: 40px;
  }

  input, textarea,
  button, svg{
    display: none!important;
  }
  table tr td:last-child,
  table tr th:last-child {
    display: inline-block;

  }
  .seperate{
    border:none;
    margin-top:1rem;
    float:right;
    width:40rem;
    word-break:no-break;
  }

  @media print{    
    .no-print, .no-print *{
      display: none !important;
    }
  }

</style>


  `;

export default function FinalEsitmation({ budgetId }: FinalEstimationPropsI) {
  const [data, setData] = useState<IData | null>(null);
  const dispatch = useAppDispatch();
  const { setExpanded } = useContext(LayoutContext);
  const { keyword } = useTranslated();

  const workReportSelector = useAppSelector((state) => state.workReport);

  useEffect(() => {
    dispatch(showLoading(workReportSelector.loading));
  }, [dispatch, workReportSelector.loading]);

  useEffect(() => {
    dispatch(getWorkReports(budgetId));
    setExpanded(false);
  }, [dispatch, budgetId]);

  useEffect(() => {
    if (workReportSelector.data) setData(workReportSelector.data);
  }, [workReportSelector.data]);

  const tableUtility = new TableUtility("estimation-table", costTableStyle);

  const handleAddElectrificationFinalQuantity = async (finalQuantity: number, workId: string) => {
    await dispatch(updateElectrificationWorks({ data: { final_quantity: finalQuantity }, id: budgetId, workId }));
    await dispatch(getWorkReports(budgetId));
  };

  const handleAddSanitoryFinalQuantity = (finalQuantity: number, workId: string) => {
    dispatch(updateSanitoryWorks({ data: { final_quantity: finalQuantity }, id: budgetId, workId }));
  };

  const handleAddWorkFinalQuantity = async (finalQuantity: number, workId: string) => {
    await dispatch(updateGeneralWork({ data: { final_quantity: finalQuantity }, id: budgetId, workId }));
    await dispatch(getWorkReports(budgetId));
  };
  const handleAddPlanEstimationFinalQuantity = async (finalQuantity: number, workId: string) => {
    await dispatch(
      updateGroupPlanEstimate({ data: { final_quantity: finalQuantity }, budgetID: budgetId, id: workId })
    );
    await dispatch(getWorkReports(budgetId));
  };

  // data: { data: GroupPlanEstimateFormTypeI | any; id: string; budgetID: string },

  return (
    <>
      <Page.Title>{keyword["Final Estimation"]}</Page.Title>
      {/* <Button startIcon={<BsPrinter />} onClick={tableUtility.print}>
        Print
      </Button> */}
      <br />
      <EstimationDocument budgetId={budgetId} />
      <br />
      <Flex.Col id="abstract" justify="flex-end">
        {/*
        <Flex.Col align="center">
          <h3 style={{ textAlign: "center" }}>Phedikhola Rural Municipality</h3>

          <h1 style={{ textAlign: "center" }}>OFFICE OF RURAL MUNICIPAL EXECUTIVE</h1>
          <h4 style={{ textAlign: "center" }}>PHEDIKHOLA, SYANGJA</h4>
          <h5 style={{ textAlign: "left" }}>Name of project : {data?.yojana_name}</h5>

          <Flex justify="space-between">
            {" "}
            <h3> Location:- Phedikhola-01-Ramkot,Syanja </h3> <h3> {data?.fiscal_year.name} </h3>{" "}
          </Flex> 
        </Flex.Col>
        */}
        <Table.Container>
          <Table id="estimation-table">
            {data?.general_work && (
              <AbstractCostTable
                showHead={true}
                onFinalQuantityAdd={handleAddWorkFinalQuantity}
                data={data?.general_work}
                tableName={keyword["General Work"]}
              />
            )}
            <br />
            {data?.construction_work && (
              <AbstractCostTable
                showHead={true}
                data={data.construction_work}
                onFinalQuantityAdd={handleAddPlanEstimationFinalQuantity}
                tableName={keyword["Construction Work"]}
              />
            )}
            <br />
            {data?.sanitary_work && (
              <AbstractCostTable
                showHead={true}
                data={data?.sanitary_work}
                onFinalQuantityAdd={handleAddSanitoryFinalQuantity}
                tableName={keyword["Sanitary Work"]}
              />
            )}
            <br />
            {data?.electrification_work && (
              <AbstractCostTable
                showHead={true}
                data={data.electrification_work}
                onFinalQuantityAdd={handleAddElectrificationFinalQuantity}
                tableName={keyword["Electrification Work"]}
              />
            )}
            <br />

            <Page.SubTitle>{keyword["Estimation"]}</Page.SubTitle>

            <hr />
            <br />
            <Flex justify="space-between">
              <Table.Col style={tableColor}>
                <Page.MiniTitle style={tableHeadingColor}> {keyword.Total}</Page.MiniTitle>
                {data && <EstimationBasicTable data={data?.total} />}
              </Table.Col>
              <Table.Col style={tableColor}>
                <Page.MiniTitle style={tableHeadingColor}>{keyword["Difference Amount"]}</Page.MiniTitle>
                {data && <EstimationBasicTable data={data?.difference_amount} />}
              </Table.Col>
              <Table.Col style={tableColor}>
                <Page.MiniTitle style={tableHeadingColor}>{keyword["Final Analysis"]}</Page.MiniTitle>
                {data && <EstimationBasicTable data={data?.final_analysis} />}
              </Table.Col>
            </Flex>
          </Table>
        </Table.Container>
      </Flex.Col>
    </>
  );
}

const tableColor = {
  color: "#A7A1AE",
  backgroundColor: "rgb(0 39 68)",
};

const tableHeadingColor = {
  color: "#fff",
};
