import axios from "axios";
import { toast } from "react-toastify";
import { ErrorMessage, LangI, getActiveLang } from "./res-mes/response-message";
import { KeywordBeautify } from "./Prettify";
import i18n from "./i18n";

export class AppError {
  error: any;

  constructor(error: any) {
    const lang: LangI = getActiveLang();
    if (error?.response?.status === 404) error.message = ErrorMessage[lang].NotFound;
    else if (error?.response?.status === 403) error.message = ErrorMessage[lang].NoPermission;

    if (!axios.isCancel(error)) {
      this.error = error;
    }
  }

  showServerError(errors: any) {
    errors.forEach((error: any) => {
      const lang: LangI = getActiveLang();

      const message = error?.message;
      // .toLowerCase().includes("already exists")
      //   ? ErrorMessage[lang].AlreadeyExists
      //   : ErrorMessage[lang].ValidationError;

      const responseMessage = error?.field ? `(In field : ${KeywordBeautify(error?.field)}) : ${message}` : message;
      toast.error(responseMessage);
    });
  }

  showClientError(error: any) {
    toast.error(error?.message);
  }

  isServerError() {
    const errors = this.error?.response?.data?.errors;
    return !!errors;
  }

  show() {
    const errors = this.error?.response?.data?.errors;
    if (this.isServerError()) {
      this.showServerError(errors);
    } else {
      this.showClientError(this.error);
    }
  }

  getFormattedError() {
    const errors = this.error?.response?.data?.errors;
    if (this.isServerError()) {
      return errors;
    } else {
      return this.convertToServerError();
    }
  }

  getError() {
    return {
      status: true,
      data: this.getFormattedError(),
      isServerError: this.isServerError(),
    };
  }

  convertToServerError() {
    return [
      {
        message: this.error.message,
      },
    ];
  }
}
