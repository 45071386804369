import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Form from "@ims/common/src/core-ui/forms/Form";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getUserRoles, getWardList } from "../../rtk/feature/auth/authApi";
import { addUser, getUser, updateUser } from "../../rtk/feature/users/usersApi";
import { useNavigate, useParams } from "react-router-dom";
import { UserFormTypeI, UserFormTypeII, UserTypeI, usersCleanUp } from "../../rtk/feature/users/usersSlice";
import Page from "../../core-ui/Page";
import { getOptionFromValue, getOptionsFromChoice, RoleChoice } from "../../utils/constants";
import { getUnits } from "../../rtk/feature/unit/unitApi";
import useTranslated from "../../hooks/useTranslated";
import EnglishDate from "nepali-date-converter";
import constructorSlice from "../../rtk/feature/constructor/constructorSlice";
import { addCodeToNumber } from "../../utils/addCountryCode";

function UserFormPage() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm<UserFormTypeII>();
  const { keyword } = useTranslated();
  const [user, setUser] = useState<UserTypeI | null>(null);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authSelector = useAppSelector((state) => state.auth);
  const usersSelector = useAppSelector((state) => state.users);
  const userData = useAppSelector((state) => state.users.item);
  const [editMode, setEditMode] = useState(false);
  const [dobNepali, setDobNepali] = useState(user?.dob_nepali || "");

  const [role, setRole] = useState<any | { label: string; value: string }>(user?.role);
  const [ward, setWard] = useState<any | { label: String; value: string }>(user?.ward);
  const [engDate, setEngDate] = useState<string>(user?.dob_english || "");

  useEffect(() => {
    dispatch(getWardList());

    return () => {
      dispatch(usersCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (userData) setUser(user);
  }, [userData]);

  useEffect(() => {
    if (params.id) {
      dispatch(getUser(params.id));
      setEditMode(true);
    } else {
      dispatch(usersCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { roles, wardList } = authSelector;
  const { item } = usersSelector;
  useEffect(() => {
    if (user) {
      setValue("user_name", user.username);
      setValue("email", user.email);
      setValue("last_name", user.last_name);
      setValue("first_name", user.first_name);
      setValue("mobile_number", user.mobile_number);
      setValue("last_name", user.last_name);
      setValue("dob_english", user.dob_english);
      setValue("role", user.role);
      setValue("ward", user.ward.ward_name);

      setEngDate(user.dob_english);

      // let role = getOptionFromValue(RoleChoice, item.role);
      // let ward = wardList.find((el) => el.id === item.ward?.id);

      // if (role) setRole(role);

      // if (ward) {
      //   const wardOj = { label: ward.ward_name, value: ward.id };
      //   setWard(wardOj);
      // }

      // if (item.dob_nepali) {
      //   setDobNepali(item.dob_nepali);
      // }
    } else {
      reset();
      // setRole(null);
    }
  }, [item, setValue, roles, reset, wardList]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = usersSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/user/list");
    }
  }, [usersSelector, dispatch, navigate]);

  function bsToAdConverter(bsDate: string) {
    const convertDate = new EnglishDate(bsDate);
    const adDate = convertDate.getAD();
    const adYear = adDate.year;
    const adMonth = adDate.month + 1 > 10 ? adDate.month : `0${adDate.month + 1}`;
    const adDay = adDate.date > 10 ? adDate.date : `0${adDate.date}`;
    const adDateFormat = `${adYear}-${adMonth}-${adDay}`;
    setEngDate(adDateFormat);
  }

  const onSubmit: SubmitHandler<UserFormTypeII> = (data) => {
    if (dobNepali) data["dob_nepali"] = dobNepali;
    if (data.mobile_number) data["mobile_number"] = addCodeToNumber(data.mobile_number);

    // if (role && ward) {
    if (editMode && params.id) {
      // data.role = role.value;
      // data.ward = ward.value;
      dispatch(updateUser({ data, id: params.id }));
    } else if (role && ward) {
      data.ward = ward.value;
      const payload: UserFormTypeI = {
        username: data.user_name,
        email: data.email,
        last_name: data.last_name,
        first_name: data.first_name,
        mobile_number: data.mobile_number,
        role: role.value,
        password: data.confirm_password,
        confirm_password: data.confirm_password,
      };

      dispatch(addUser(payload));
    }
    // }
  };

  const handleCancel = () => {
    navigate("/user/list");
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {editMode ? keyword["Update User Profile"] : keyword["Create User"]} </Page.Title>
      </Page.Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["user_name"])}>
                <Form.Label> {keyword["User Name"]} </Form.Label>
                <Form.Input
                  type="text"
                  placeholder={keyword["Enter your username"]}
                  register={register("user_name", {
                    required: keyword["Your username is required."],
                  })}
                />
                {errors && errors["user_name"] && errors["user_name"].message && (
                  <Form.HelperText>{errors["user_name"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["first_name"])}>
                <Form.Label> {keyword["First Name"]} </Form.Label>
                <Form.Input
                  type="text"
                  placeholder={keyword["Enter your first name"]}
                  register={register("first_name", {
                    required: keyword["Your last name is required."],
                  })}
                />
                {errors && errors["first_name"] && errors["first_name"].message && (
                  <Form.HelperText>{errors["first_name"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["last_name"])}>
                <Form.Label> {keyword["Last Name"]} </Form.Label>
                <Form.Input
                  type="text"
                  placeholder={keyword["Enter your last name"]}
                  register={register("last_name", {
                    required: keyword["Your name in english is required."],
                  })}
                />
                {errors && errors["last_name"] && errors["last_name"].message && (
                  <Form.HelperText>{errors["last_name"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["mobile_number"])}>
                <Form.Label> {keyword["Phone Number"]} </Form.Label>
                <Form.Input
                  type="text"
                  placeholder={keyword["Enter your mobile number"]}
                  register={register("mobile_number", {
                    required: keyword["Your mobile number is required."],
                  })}
                />
                {errors && errors["mobile_number"] && errors["mobile_number"].message && (
                  <Form.HelperText>{errors["mobile_number"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["dob_nepali"])}>
                <Form.Label> {keyword["Date of Birth (Nepali) "]} : </Form.Label>
                <Form.NepaliDatePicker
                  inputClassName="form-control"
                  value={dobNepali}
                  onChange={(value: string) => {
                    bsToAdConverter(value);
                    setDobNepali(value);
                  }}
                  options={{ calenderLocale: "en", valueLocale: "en" }}
                />

                {errors && errors["dob_nepali"] && errors["dob_nepali"].message && (
                  <Form.HelperText>{errors["dob_nepali"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["last_name"])}>
                <Form.Label> {keyword["Date of Birth (English) "]} </Form.Label>
                <Form.Input
                  type="text"
                  value={engDate}
                  placeholder={engDate || keyword["Edit Nepali date to automatically fill this field"]}
                  register={register("dob_english")}
                  style={{ cursor: "not-allowed" }}
                  readOnly
                />
                {errors && errors["last_name"] && errors["last_name"].message && (
                  <Form.HelperText>{errors["last_name"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["email"])}>
                <Form.Label> {keyword.Email} </Form.Label>
                <Form.Input
                  type="text"
                  placeholder={keyword["Enter your email"]}
                  register={register("email", {
                    required: keyword["Your email address is required."],
                  })}
                />
                {errors && errors["email"] && errors["email"].message && (
                  <Form.HelperText>{errors["email"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup>
                <Form.Label>{keyword.Role} </Form.Label>

                {user?.role ? (
                  <Form.Input type="text" register={register("role")} style={{ cursor: "not-allowed" }} readOnly />
                ) : (
                  <Form.ReactSelect
                    name="react-select"
                    options={getOptionsFromChoice(RoleChoice)}
                    value={role}
                    placeholder={keyword["Select"] + "..."}
                    onChange={(selectedOption: any) => {
                      setRole(selectedOption);
                    }}
                    defaultValue={user?.role}
                  />
                )}
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup>
                <Form.Label> {keyword.Ward} </Form.Label>

                {user?.ward ? (
                  <Form.Input type="text" register={register("ward")} style={{ cursor: "not-allowed" }} readOnly />
                ) : (
                  <Form.ReactSelect
                    name="ward"
                    options={wardList.map((ward) => ({
                      value: ward.id,
                      label: keyword[ward.ward_name],
                    }))}
                    placeholder={keyword["Select"] + "..."}
                    value={ward}
                    onChange={(selectedOption: any) => {
                      setWard(selectedOption);
                    }}
                  />
                )}
              </Form.FormGroup>
            </Grid.Col>

            {editMode === false && (
              <React.Fragment>
                <Grid.Col spanMd={6} spanXs={12}>
                  <Form.FormGroup error={Boolean(errors && errors["password"])}>
                    <Form.Label> {keyword.Password} </Form.Label>
                    <Form.Input
                      type="password"
                      placeholder={keyword["Enter your password"]}
                      register={register("password", {
                        required: keyword["Your new password is required."],
                      })}
                    />
                    {errors && errors["password"] && errors["password"].message && (
                      <Form.HelperText>{errors["password"].message}</Form.HelperText>
                    )}
                  </Form.FormGroup>
                </Grid.Col>

                <Grid.Col spanMd={6} spanXs={12}>
                  <Form.FormGroup error={Boolean(errors && errors["confirm_password"])}>
                    <Form.Label> {keyword["Confirm Password"]} </Form.Label>
                    <Form.Input
                      type="password"
                      placeholder={keyword["Confirm your password"]}
                      register={register("confirm_password", {
                        required: keyword["Your new confirm password is required."],
                      })}
                    />
                    {errors && errors["confirm_password"] && errors["confirm_password"].message && (
                      <Form.HelperText>{errors["confirm_password"].message}</Form.HelperText>
                    )}
                  </Form.FormGroup>
                </Grid.Col>
              </React.Fragment>
            )}

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword.Update : keyword["Create User"]}
              </Button>
              <Button onClick={handleCancel}> {keyword.Cancel} </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Form>
    </MainLayout.Main>
  );
}

export default UserFormPage;
