import { createSlice } from "@reduxjs/toolkit";
import CommunicationTitle from "../../../components/comunication-title/CommunicationTitle";
import { addCommunicationTitle, getCommunicationTitles, updateCommunicationTitle } from "./communicationTitleApi";

export interface CommunicationTitleFormI {
  id?: string;
  title: string;
}

export interface CommunicationTitleI {
  id: string;
  title: string;
}

export interface CommunicationTitleStateI {
  loading: boolean;
  data: CommunicationTitleI[] | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: CommunicationTitleStateI = {
  loading: false,
  data: null,
  hasSubmitted: false,
  error: null,
};

const communicationTitleSlice = createSlice({
  name: "communicationTitle",
  initialState,
  reducers: {
    communicationTitleCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCommunicationTitle.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addCommunicationTitle.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addCommunicationTitle.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getCommunicationTitles.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getCommunicationTitles.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getCommunicationTitles.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(updateCommunicationTitle.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(updateCommunicationTitle.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(updateCommunicationTitle.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
  },
});

export const { communicationTitleCleanUp } = communicationTitleSlice.actions;
export default communicationTitleSlice.reducer;
