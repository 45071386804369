import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { showLoading } from "../rtk/feature/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../rtk/hook";

interface ProtectedRouteI {
  redirectPath?: string;
  children?: JSX.Element;
}

/*
 * Protected Route helps us to prevent from disallowed users
 * If provided with children, it will not use outlet which
 * is use to group nested routes in single protected route.
 */

const ProtectedRoute = (props: ProtectedRouteI) => {
  const location = useLocation();
  const { loading, authenticated } = useAppSelector((state) => state.auth);

  const access = localStorage.getItem("access");
  const user = localStorage.getItem("user");
  const hasTokens = Boolean(access && user);
  const dispatch = useAppDispatch();

  const { redirectPath = "/login", children } = props;
  const myRole = useAppSelector((state) => state.auth.user?.role);

  useEffect(() => {
    dispatch(showLoading(loading));
  }, [dispatch, loading]);

  if (!authenticated && !myRole && !loading && !hasTokens) {
    return <Navigate to={redirectPath} state={{ prev: location.pathname }} replace />;
  }
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
