import { Button, Form, Grid, MainLayout } from "@ims/common";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../core-ui/Page";
import ReuseForm from "../../../core-ui/ReuseForm";
import { IOptions } from "../../../interfaces/options.interface";
import { getBillPlanningById } from "../../../rtk/feature/plannning-bill/planningBillAPI";
import { getUsers } from "../../../rtk/feature/users/usersApi";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { postBillVerification } from "../../../rtk/feature/bill-verification/billVerificationAPI";
import { roles } from "../../../constants/constants";
import { cleanupVerificationSlice } from "../../../rtk/feature/bill-verification/billVerificationSlice";
import useTranslated from "../../../hooks/useTranslated";
interface IPlanningDetailsForm {
  user_id: IOptions;
  description: string;
  comment: string;
}

const PlanningBillDetails = () => {
  const navigate = useNavigate();
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<IPlanningDetailsForm>();
  const { formSubmitted } = useAppSelector((state) => state.billVerification);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getBillPlanningById(id));
    }
  }, [id]);

  const onSubmit = (data: IPlanningDetailsForm) => {
    const finalData = { ...data, user_id: data.user_id.value };
    if (id) dispatch(postBillVerification({ data: finalData, id }));
  };
  const handleCancel = () => {
    navigate("/planning-bill");
  };
  useEffect(() => {
    dispatch(getUsers({ params: { per_page: 100000 } }));
    return () => {
      dispatch(cleanupVerificationSlice());
    };
  }, []);

  useEffect(() => {
    if (formSubmitted) {
      navigate("/planning-bill");
    }
  }, [formSubmitted]);

  const users = useAppSelector((state) => state.users);
  const userOptions = users.data?.results
    .filter((el) => el.role === roles.Prasasakiya_Adhikrit)
    .map((user) => {
      return {
        label: user.username,
        value: user.id,
      };
    });
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Title> {keyword["Plan Bill Details"]} </Page.Title>
      <ReuseForm control={control} onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["description"])}>
                <Form.Label> {keyword.Description} </Form.Label>

                <Form.Input
                  placeholder={keyword.Description}
                  register={register("description", { required: keyword["Description is necessary"] })}
                  name="description"
                />
                <Form.HelperText> {errors.description?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["comment"])}>
                <Form.Label> {keyword.Comment} </Form.Label>
                <Form.Input
                  placeholder={keyword.Comment}
                  register={register("comment", { required: keyword["Comment is required"] })}
                  name="comment"
                />
                <Form.HelperText> {errors.comment?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>{" "}
            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["user_id"])}>
                <ReuseForm.ReactSelect
                  label={keyword.User}
                  name="user_id"
                  options={userOptions}
                  placeholder={keyword["Select Type"]}
                  rules={{ required: keyword["User is required."] }}
                  error={errors["user_id"]}
                />
                {/* <Form.HelperText  > {errors.user_id?.message} </Form.HelperText> */}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {keyword["Send for verification"]}
              </Button>
              <Button onClick={handleCancel}>{keyword.Cancel}</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
};

export default PlanningBillDetails;
