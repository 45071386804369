import styled from "styled-components/macro";
import { GridColPropsI } from "./Grid";

export const StyledGrid = styled.div`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

export const StyledGridContainer = styled.div`
  padding: 1rem;
`;
export const StyledGridRow = styled.div<{ gap?: number }>`
  display: grid;
  gap: ${(props) => (props.gap ? props.gap + "rem" : "0px")};
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
`;
export const StyledGridCol = styled.div<GridColPropsI>`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.xs}) {
    grid-column: span ${(props) => props.spanXs};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-column: span ${(props) => props.spanSm};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    ${(props) => props.spanMd && `grid-column: span ${props.spanMd};`}
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    ${(props) => props.spanLg && `grid-column: span ${props.spanLg};`}
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    ${(props) => props.spanXl && `grid-column: span ${props.spanXl};`}
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    ${(props) => props.spanXl && `grid-column: span ${props.spanXl};`}
  }
`;
