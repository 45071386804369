import styled from "styled-components/macro";

export const StyledProfile = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const StyledContainer = styled.div`
  position: relative;
`;
export const StyledCard = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-left: auto;
  background: transparent;
  color: ${(props) => props.theme.palette.text.primary};
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: 2px;
  transition: 0.2s all ease;
  font-size: 1.5rem;
  background: rgba(0, 0, 0, 0.025);
  font-weight: bold;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const StyledButton = styled.button`
  border: none;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  text-align: left;
  background: transparent;
  min-width: 160px;
  cursor: pointer;
  font-family: inherit;

  &:hover {
    color: white;
    background: rgba(0, 114, 198, 1);
  }
`;
export const StyledMenuContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0.5rem 0rem;
  position: absolute;
  right: 0rem;
  top: 4rem;
  box-shadow: 4px 4px 1rem rgb(0 0 0 / 15%);
  border-radius: 4px;
`;
