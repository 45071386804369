import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getWards } from "../../rtk/feature/ward/wardApi";
import { WardTypeI } from "../../rtk/feature/ward/wardSlice";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import Page from "../../core-ui/Page";
import { useTranslation } from "react-i18next";
import useTranslated from "../../hooks/useTranslated";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";

function WardManagePage() {
  const [wards, setWards] = useState<WardTypeI[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const wardsState = useAppSelector((state) => state.wards);

  /* Table pagination states */
  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getWards({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    dispatch(showLoading(wardsState.loading));
  }, [dispatch, wardsState.loading]);

  useEffect(() => {
    if (wardsState.data) {
      setWards(wardsState.data.results);
      setTotalItems(wardsState.data.total);
    }
  }, [wardsState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/ward/edit/${id}`);
  };
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>{keyword["Ward Manage"]}</Page.Title>
        {/* <Button variant="primary" onClick={() => navigate(`/ward/add`)}>
          {keyword["Add Ward"]}
        </Button> */}
      </Page.Container>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>{t("Ward Name")}</Table.HeadCol>
              <Table.HeadCol>{t("Ward Number")}</Table.HeadCol>
              <Table.HeadCol>{t("Ward Address")}</Table.HeadCol>
              <Table.HeadCol align="center">{t("Action")}</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {wards &&
              wards.length > 0 &&
              wards.map((ward, index) => {
                return (
                  <Table.Row key={ward.id}>
                    <Table.Col>{englishNepaliNumberTranslation(getSN(index))}</Table.Col>
                    <Table.Col>{keyword[ward.ward_name]}</Table.Col>
                    <Table.Col>{englishNepaliNumberTranslation(ward.ward_number)}</Table.Col>
                    <Table.Col>{ward.ward_address}</Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(ward.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default WardManagePage;
