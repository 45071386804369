import { toast } from "react-toastify";
import { LangI, getActiveLang } from "./res-mes/response-message";

export const addCodeToNumber = (number: string): string => {
  if (!number) {
    const lang: LangI = getActiveLang();
    const message = lang === "en" ? "Invalid Number" : "अवैध नम्बर";
    toast.error(message);
  } else if (!number.includes("+977")) number = `+977${number}`;

  return number;
};
