import { Control, Controller, FieldErrors, UseFormHandleSubmit, useForm } from "react-hook-form";

import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Flex, Form, Grid } from "@ims/common";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../rtk/hook";
import useTranslated from "../../hooks/useTranslated";
import ReuseForm from "../../core-ui/ReuseForm";
import {
  SamitiDocsFormI,
  SamitiDocsI,
  contractorDocsCleanUp,
} from "../../rtk/feature/contractor-docs/contractorDocsSlice";
import {
  addContractorDoc,
  getContractorDocs,
  updateContractorDoc,
} from "../../rtk/feature/contractor-docs/contractorDocsApi";
import { getValue } from "@testing-library/user-event/dist/utils";

interface ContractorDocsCreateEditPropsI {
  contractorId: string;
  doc?: SamitiDocsI | null;
}

export default function ContractorDocsCreateEdit({ contractorId, doc }: ContractorDocsCreateEditPropsI) {
  const [editMode, setEditMode] = useState(false);
  const [imageFile, setImageFile] = useState<string | ArrayBuffer | null>(null);
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    register,
    control,
  } = useForm<SamitiDocsFormI>({
    defaultValues: {},
  });

  useEffect(() => {
    if (doc) {
      setValue("title", doc?.title);
    }
  }, [doc]);

  useEffect(() => {
    if (doc) {
      setEditMode(true);
    } else {
      dispatch(contractorDocsCleanUp());
      setEditMode(false);
    }
  }, [dispatch, doc]);

  const resetAll = () => {
    reset();
    setImageFile(null);
  };

  const onSubmit = async (data: any) => {
    if (!contractorId) toast.error("Cannot Procceed");
    if (!File) toast.error("Please upload file");

    const file = imageFile?.toString() || "";

    if (contractorId && doc) {
      let payload: { title?: string; file?: string } = {};
      if (data.title) payload.title = data.title;
      if (file) payload.file = file;
      await dispatch(updateContractorDoc({ contractorId: doc.id, data: payload }));
    } else {
      await dispatch(addContractorDoc({ contractor_details: contractorId, title: data.title, file }));
    }
    await dispatch(getContractorDocs(contractorId));
    resetAll();
    // navigate(-1);
  };

  const convertFileToBase64 = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setImageFile(reader.result);
      };
    }
  };
  return (
    <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
      <Grid>
        <Grid.Row gap={1.5}>
          <Grid.Col spanMd={12} spanXs={12}>
            <ReuseForm.Input
              label={keyword["Title"]}
              name="title"
              placeholder={keyword["Enter Document Title"]}
              rules={{ required: keyword["This is required field."] }}
              error={errors["title"]}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row gap={1.5}>
          <Grid.Col spanMd={12} spanXs={12}>
            <Flex.Col>
              <Form.Label> {keyword["Document"]} </Form.Label>
              <Controller
                name="file"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field: { onChange, value }, fieldState, formState }) => {
                  return (
                    <Form.File
                      id="file"
                      fileType="image"
                      clearData={!!imageFile}
                      onChange={(e) => {
                        onChange(convertFileToBase64(e));
                      }}
                      placeholder="Enter final measurement"
                      // previewImage={doc?.file}
                    />
                  );
                }}
              ></Controller>
              {errors && errors["file"] && errors["file"].message && (
                <Form.HelperText>{errors["file"].message}</Form.HelperText>
              )}
            </Flex.Col>
          </Grid.Col>

          <Grid.Col spanMd={12} spanXs={12}>
            <Button type="submit" variant="primary">
              {editMode ? keyword["Update Documents"] : keyword["Create Documents"]}
            </Button>
            {/* <Button onClick={handleCancel}>{keyword.Cancel}</Button> */}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </ReuseForm>
  );
}
