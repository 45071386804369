import { Button, Form, Grid, MainLayout } from "@ims/common";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Page from "../../../../../core-ui/Page";
import ReuseForm from "../../../../../core-ui/ReuseForm";
import useTranslated from "../../../../../hooks/useTranslated";
import { useUnitOptions } from "../../../../../hooks/useUnitOptions";
import { IOptions } from "../../../../../interfaces/options.interface";
import { IGeneralWorkData } from "../../../../../rtk/feature/general-work/generalWorkSlice";

export interface IWorkForm {
  name: string;
  length: number;
  breadth: number;
  height: number;
  unit: IOptions;
  number: number;
  remarks: string;
}

interface IProps {
  handleFormSubmit: (data: IWorkForm) => void;
  work?: IGeneralWorkData | null;
  title: string;
}

const WorkForm = (props: IProps) => {
  const { handleFormSubmit, work } = props;
  const onSubmit = (data: IWorkForm) => {
    handleFormSubmit(data);
  };

  const { unitOptions } = useUnitOptions();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IWorkForm>();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/plan-management/plan");
  };
  const params = useParams();
  const location = useLocation();

  const isEditMode = location.pathname.includes("/edit");

  useEffect(() => {
    if (work && isEditMode) {
      setValue("breadth", work.breadth);
      setValue("height", work.height);
      setValue("length", work.length);
      setValue("name", work.name);
      setValue("number", work.number);
      setValue("remarks", work.remarks);
      setValue("unit", { label: work.unit.name, value: work.unit.id });
    }
  }, [work, isEditMode]);
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Title> {props.title}</Page.Title>
      <ReuseForm control={control} onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["name"])}>
                <Form.Label> {keyword.Name} </Form.Label>

                <Form.Input
                  placeholder="particular"
                  register={register("name", { required: "particular is necessary" })}
                  name="particular"
                />
                <Form.HelperText> {errors.name?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["length"])}>
                <Form.Label> {keyword.Length} </Form.Label>
                <Form.Input
                  type="number"
                  step="any"
                  placeholder="analysis length"
                  register={register("length")}
                  name="length"
                />
                <Form.HelperText> {errors.length?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>{" "}
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["breadth"])}>
                <Form.Label>{keyword.Breadth} </Form.Label>

                <Form.Input type="number" placeholder="breadth " register={register("breadth")} name="breadth" />
                <Form.HelperText> {errors.breadth?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["height"])}>
                <Form.Label>{keyword.Height} </Form.Label>

                <Form.Input placeholder="height " register={register("height")} name="height" type="number" />
                <Form.HelperText> {errors.height?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["unit"])}>
                <ReuseForm.ReactSelect
                  label={keyword.Unit}
                  name="unit"
                  options={unitOptions}
                  placeholder="Select unit"
                  rules={{ required: "Type is required." }}
                  error={errors["unit"]}
                />
                {/* <Form.HelperText  > {errors.unit?.message} </Form.HelperText> */}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["number"])}>
                <Form.Label>{keyword.Number} </Form.Label>

                <Form.Input
                  placeholder="number "
                  register={register("number", { required: "number is required" })}
                  name="number"
                />
                <Form.HelperText> {errors.number?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>{" "}
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["remarks"])}>
                <Form.Label> {keyword.Remarks} </Form.Label>

                <Form.Input placeholder="remarks " register={register("remarks")} name="remarks" />
                <Form.HelperText> {errors.remarks?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
          </Grid.Row>
        </Grid>
        <Grid.Col style={{ marginTop: "2rem" }} spanMd={6} spanXs={12}>
          <Button type="submit" variant="primary">
            {isEditMode ? keyword.Update : keyword.Add}
          </Button>
          <Button type="button" onClick={handleCancel}>
            {keyword.Cancel}
          </Button>
        </Grid.Col>
      </ReuseForm>
    </MainLayout.Main>
  );
};

export default WorkForm;
