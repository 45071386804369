import { Flex, MainLayout, Table } from "@ims/common";
import { current } from "@reduxjs/toolkit";
import React, { MouseEvent, MouseEventHandler, useState } from "react";
import { FiEye } from "react-icons/fi";
import useTranslated, { TranslateKeyTypes } from "../../../hooks/useTranslated";
import { IEquipmentQuantityAnalysis } from "../../../rtk/feature/plan-verification/planVerificationSlice";
import { StyledContainerDrawer } from "../analyze-rate/RateAnalysisTable";
import EquipmentAnalysisDetails from "./EquipmentAnalysisDetails";
import { prettifyNumberValue, styleSN } from "../../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../../utils/translateNumber";
interface IProps {
  data: IEquipmentQuantityAnalysis[];
}
const EquipmentQuanityAnalysis = (props: IProps) => {
  const { keyword } = useTranslated();
  const { data } = props;
  const quantityAnalysisColumns: TranslateKeyTypes[] = [
    "S.N.",
    "Code",
    "English Description",
    "English Name",
    "Nepali Description",
    "Nepali Name",
    "Per Rate",
    "Unit",
    "View",
  ];
  const [activeId, setActiveId] = useState<string | null>(null);
  const handleView = (id: string) => {
    setActiveId(id);
  };
  const handleClose = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setActiveId(null);
  };

  return (
    <>
      <Table.Container>
        <Table.Head>{keyword["Equipment Quantity Analysis"]} </Table.Head>
        <Table id="verification-table">
          <Table.Row>
            {quantityAnalysisColumns.map((col, i) => {
              return <Table.HeadCol key={col}> {keyword[col]} </Table.HeadCol>;
            })}
          </Table.Row>

          <Table.Body>
            {!!data.length &&
              data?.map((curr, idx) => {
                const current = curr?.planning_rate_analysis;
                return (
                  <Table.Row>
                    <Table.Col> {englishNepaliNumberTranslation(styleSN(idx + 1))} </Table.Col>

                    <Table.Col> {current?.code || "___"} </Table.Col>
                    <Table.Col> {current?.english_description || "___"} </Table.Col>
                    <Table.Col> {current?.english_name || "___"} </Table.Col>
                    <Table.Col> {current?.nepali_description || "___"} </Table.Col>
                    <Table.Col> {current?.nepali_name || "___"} </Table.Col>
                    <Table.Col> {current?.per_rate || "___"} </Table.Col>
                    <Table.Col> {current?.unit?.name || "___"} </Table.Col>
                    <Table.Col align="center">
                      {" "}
                      <Table.ActionButton onClick={() => handleView(curr?.id)}>
                        {" "}
                        <FiEye />{" "}
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Table.Container>
      <StyledContainerDrawer onClick={handleClose} open={!!activeId} id="drawer">
        <div id="content-drawer">
          <MainLayout.Main>
            {data
              .filter((el) => el?.id === activeId)
              .map((curr, idx) => {
                const current = curr?.planning_rate_analysis;
                return (
                  <Flex.Col align="flex-end">
                    <h4> {keyword["General Info"]} </h4>
                    <Flex.Col gap="5rem" align="flex-end">
                      {" "}
                      <Table.Body width="100%">
                        <Table.Row>
                          <Table.HeadCol width="25%"> {keyword.Name} </Table.HeadCol>
                          <Table.HeadCol width="25%"> {keyword.Unit} </Table.HeadCol>
                          <Table.HeadCol width="25%"> {keyword.Code} </Table.HeadCol>
                          <Table.HeadCol width="25%"> {keyword.Description} </Table.HeadCol>
                        </Table.Row>
                        <Table.Row>
                          <Table.Col> {current?.nepali_description || "___"} </Table.Col>
                          <Table.Col> {current?.unit?.name || "___"} </Table.Col>
                          <Table.Col> {current?.code || "___"} </Table.Col>
                          <Table.Col> {current?.english_description || "___"} </Table.Col>
                        </Table.Row>
                      </Table.Body>
                      <EquipmentAnalysisDetails data={curr || {}} />
                      <Flex justify="flex-end">
                        {" "}
                        <Table.Body width="100%">
                          {" "}
                          <Table.Row>
                            <Table.Col width="70%"> {keyword.Total} </Table.Col>
                            <Table.Col> {prettifyNumberValue(curr?.total_amount?.sub_total)} </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col width="70%"> {keyword["Amount After VAT"]} </Table.Col>
                            <Table.Col> {prettifyNumberValue(curr?.total_amount?.grand_total)} </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col width="70%"> {keyword["VAT Amount"]} </Table.Col>
                            <Table.Col> {prettifyNumberValue(curr?.total_amount?.vat_amt)} </Table.Col>
                          </Table.Row>
                        </Table.Body>
                      </Flex>
                    </Flex.Col>
                  </Flex.Col>
                );
              })}
          </MainLayout.Main>
        </div>
      </StyledContainerDrawer>
    </>
  );
};

export default EquipmentQuanityAnalysis;
