import React, { useEffect, useState } from "react";
import Table from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { getAnalyzeRates } from "../../../rtk/feature/analyze-rate/analyzeRateApi";
import { AnalyzeRateTypeI } from "../../../rtk/feature/analyze-rate/analyzeRateSlice";
import { FiEdit } from "react-icons/fi";
import { showLoading } from "../../../rtk/feature/auth/authSlice";
import usePagination from "../../../utils/usePagination";
import styled, { css } from "styled-components";
import RateIntro from "../../fixed-analyze-rate/analyze-rate/RateIntro";
import RateSubTable, { formatter } from "./RateSubTable";
import { TypeChoice } from "../../../utils/constants";
import { toast } from "react-toastify";
import { getRateEquipments } from "../../../rtk/feature/rate-equipment/rateEquipmentApi";
import { rateEquipmentCleanUp, RateEquipmentFormTypeI } from "../../../rtk/feature/rate-equipment/rateEquipmentSlice";
import ReuseForm from "../../../core-ui/ReuseForm";
import { useForm } from "react-hook-form";
import { Form } from "@ims/common";
import TableUtility from "../../user/TableUtility";
import { getGroupPlanEstimates } from "../../../rtk/feature/plan-group-estimate/planGroupEstimateApi";
import { getFixedRateEquipments } from "../../../rtk/feature/fixed-rate-equipment/fixedRateEquipmentApi";
import useTranslated from "../../../hooks/useTranslated";

interface RateAnalysisTablePropsI {
  budgetID: string | null;
}

export const newItem = {
  id: Date.now().toString(),
  equipment: null,
  quantity: 0,
  total_amount: 0,
  unit_rate: 0,
  _is_latest: true,
};

export interface TotalAnalyzeRateTypeI {
  is_vat_percent: string;
  vat_amount: number;
}

function RateAnalysisTable(props: RateAnalysisTablePropsI) {
  const { budgetID } = props;
  const { control, getValues, setValue, watch } = useForm<TotalAnalyzeRateTypeI>();
  const [analyzeRates, setAnalyzeRates] = useState<AnalyzeRateTypeI[]>([]);

  const dispatch = useAppDispatch();
  const analyzeRatesState = useAppSelector((state) => state.analyzeRates);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rateID, setRateID] = useState<null | string>(null);
  const [rateEquipments, setRateEquipments] = useState<RateEquipmentFormTypeI[]>([]);
  const [rateManpowers, setRateManpowers] = useState<RateEquipmentFormTypeI[]>([]);
  const [rateMachines, setRateMachines] = useState<RateEquipmentFormTypeI[]>([]);
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotalAmt, setGrandTotalAmt] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);

  const planGroupEstimateSelector = useAppSelector((state) => state.planGroupEstimates);

  const equipmentQuantityRate = useAppSelector((state) => state.fixedRateEquipments.item?.total_amount);
  /* Table pagination states */
  const [totalItems, setTotalItems] = useState(1);
  const rateEquipmentSelector = useAppSelector((state) => state.rateEquipments.data);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    // if (budgetID) dispatch(getAnalyzeRates({ budgetID, perPage, page }));
    if (budgetID) dispatch(getGroupPlanEstimates({ budgetID }));
  }, [dispatch, perPage, page, budgetID]);

  useEffect(() => {
    const el = document.getElementById("drawer");
    if (!el) return;

    const handleClickAwayListener = (e: any) => {
      const parentNode = document.getElementById("content-drawer");
      if (!parentNode || !e.target) return;

      if (e.target !== parentNode && !parentNode.contains(e.target)) {
        setOpenDrawer(false);
        setRateID(null);
      }
    };

    el.addEventListener("click", handleClickAwayListener);
    return () => el.removeEventListener("click", handleClickAwayListener);
  }, []);

  useEffect(() => {
    setRateEquipments([newItem]);
    setRateManpowers([newItem]);
    setRateMachines([newItem]);
  }, []);

  const calculateTotal = (tableData: RateEquipmentFormTypeI[]) => {
    const total = tableData.reduce((prev, curr) => {
      if (curr.unit_rate) prev = prev + curr.quantity * curr.unit_rate;
      return prev;
    }, 0);
    return total;
  };

  const is_vat_percent = JSON.parse(watch("is_vat_percent") || "false");
  let vat_amount = watch("vat_amount") || 0;
  vat_amount = +vat_amount;

  useEffect(() => {
    const equipmentTotal = calculateTotal(rateEquipments);
    const manpowerTotal = calculateTotal(rateManpowers);
    const machineTotal = calculateTotal(rateMachines);
    const total = equipmentTotal + manpowerTotal + machineTotal;

    let tempVat: number;

    if (is_vat_percent) {
      tempVat = (+vat_amount * +total) / 100;
    } else {
      tempVat = +vat_amount;
    }

    setVatAmt(+tempVat);
    setSubTotal(total);
    setGrandTotalAmt(+total + vat_amount);
  }, [rateEquipments, rateManpowers, rateMachines, is_vat_percent, vat_amount]);

  useEffect(() => {
    dispatch(showLoading(analyzeRatesState.loading));
  }, [dispatch, analyzeRatesState.loading]);

  useEffect(() => {
    if (analyzeRatesState.data) {
      setAnalyzeRates(analyzeRatesState.data.results);
      setTotalItems(analyzeRatesState.data.total);
    }
  }, [analyzeRatesState, dispatch]);

  const fixedRateEquipmentSelector = useAppSelector((state) => state.fixedRateEquipments);

  useEffect(() => {
    const { data: fixedRateEquipmentList } = fixedRateEquipmentSelector;
    let results: any;

    if (!fixedRateEquipmentList) return;

    results = fixedRateEquipmentList.reduce((total: any, current) => {
      const type = current.equipment.type;
      if (!type) return total;

      if (total[type]) {
        total[type] = [...total[type], current];
      } else {
        total[type] = [current];
      }
      return total;
    }, {});

    const resultManpower = results[TypeChoice[0]];
    const resultEquipment = results[TypeChoice[1]];
    const resultMachine = results[TypeChoice[2]];

    if (resultEquipment) {
      resultEquipment.push(newItem);
      setRateEquipments(resultEquipment);
    } else {
      setRateEquipments([newItem]);
    }

    if (resultManpower) {
      resultManpower.push(newItem);
      setRateManpowers(resultManpower);
    } else {
      setRateManpowers([newItem]);
    }

    if (resultMachine) {
      resultMachine.push(newItem);
      setRateMachines(resultMachine);
    } else {
      setRateMachines([newItem]);
    }
  }, [fixedRateEquipmentSelector, rateID]);

  // useEffect(() => {
  //   const { data: rateEquipmentList } = rateEquipmentSelector;
  //   let results: any;

  //   results = rateEquipmentList.reduce((total: any, current: any) => {
  //     const type = current.equipment.type;
  //     if (!type) return total;

  //     if (total[type]) {
  //       total[type] = [...total[type], current];
  //     } else {
  //       total[type] = [current];
  //     }
  //     return total;
  //   }, {});

  //   const resultManpower = results[TypeChoice[0]];
  //   const resultEquipment = results[TypeChoice[1]];
  //   const resultMachine = results[TypeChoice[2]];

  //   if (resultEquipment) {
  //     resultEquipment.push(newItem);
  //     setRateEquipments(resultEquipment);
  //   } else {
  //     setRateEquipments([newItem]);
  //   }

  //   if (resultManpower) {
  //     resultManpower.push(newItem);
  //     setRateManpowers(resultManpower);
  //   } else {
  //     setRateManpowers([newItem]);
  //   }

  //   if (resultMachine) {
  //     resultMachine.push(newItem);
  //     setRateMachines(resultMachine);
  //   } else {
  //     setRateMachines([newItem]);
  //   }
  // }, [rateEquipmentSelector, rateID]);

  const handleAnalysisShow = async (id: string) => {
    if (!budgetID || !id) return toast.warning("No budget ID or rate ID");
    setRateID(id);
    await dispatch(getFixedRateEquipments({ rateID: id }));
    setOpenDrawer(true);
  };

  const vatAmount = 0.13 * subTotal; // 13% VAT on total
  const grandTotal = subTotal + vatAmount;

  const tableUtility = new TableUtility(
    "analysis-table",
    `<style>table tr td:last-child, table tr th:last-child {display: table-cell;} input, textarea{display: block!important;}</style> `
  );
  const { keyword } = useTranslated();
  return (
    <div>
      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol> {keyword["S.N."]} </Table.HeadCol>
              <Table.HeadCol>{keyword.Name} </Table.HeadCol>
              {/* <Table.HeadCol>Code</Table.HeadCol>
              <Table.HeadCol>Rate</Table.HeadCol>
              <Table.HeadCol>Comittee Rate</Table.HeadCol> */}
              <Table.HeadCol align="center"> {keyword.Action} </Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {planGroupEstimateSelector &&
              planGroupEstimateSelector?.data?.results?.length > 0 &&
              planGroupEstimateSelector?.data?.results?.map((analyzeRate, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Col>{getSN(index)}</Table.Col>
                    <Table.Col>{analyzeRate.name}</Table.Col>
                    {/* <Table.Col>{analyzeRate.code}</Table.Col>
                    <Table.Col>{analyzeRate.measurement}</Table.Col>
                    <Table.Col>{analyzeRate.budget_upobhokta}</Table.Col> */}
                    <Table.Col align="center">
                      <Table.ActionButton
                        onClick={() =>
                          handleAnalysisShow(analyzeRate.equipment_quantity_analysis?.planning_rate_analysis)
                        }
                      >
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>

      <StyledContainerDrawer open={openDrawer} id="drawer">
        <div id="content-drawer">
          <div className="container" id="analysis-table">
            <div>
              <button onClick={tableUtility.print}>Print this out!!!</button>
              <button
                onClick={() => {
                  setOpenDrawer(false);
                }}
              >
                {keyword.Close}
              </button>
            </div>

            <RateIntro rateID={rateID} />
            <br />
            <h4>Material</h4>
            <RateSubTable
              budgetID={budgetID}
              tableData={rateEquipments}
              setTableData={setRateEquipments}
              rateID={rateID}
              type={TypeChoice[1]}
            />
            <br />
            <h4>Manpower</h4>
            <RateSubTable
              budgetID={budgetID}
              tableData={rateManpowers}
              setTableData={setRateManpowers}
              rateID={rateID}
              type={TypeChoice[0]}
            />

            <br />
            <h4>Machines</h4>
            <RateSubTable
              budgetID={budgetID}
              tableData={rateMachines}
              setTableData={setRateMachines}
              rateID={rateID}
              type={TypeChoice[2]}
            />

            <br />
            <ReuseForm control={control}>
              <BasicTable>
                <tbody>
                  <tr>
                    <td width="80%" align="right">
                      <b>Sub Total</b>
                    </td>
                    <td width="110px">{formatter.format(fixedRateEquipmentSelector.total_amount?.sub_total || 0)}</td>
                  </tr>
                  <tr>
                    <td width="80%" align="right">
                      <b>Contractor Profit</b>
                    </td>
                    <td width="110px">
                      {formatter.format(fixedRateEquipmentSelector.total_amount?.contractor_profit || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td width="80%" align="right">
                      <b>Sub toatal after CP</b>
                    </td>
                    <td width="110px">
                      {formatter.format(fixedRateEquipmentSelector.total_amount?.sub_total_after_cp || 0)}
                    </td>
                  </tr>

                  <tr>
                    <td width="80%" align="right">
                      <b>VAT Amount</b>
                    </td>
                    <td width="110px">{formatter.format(fixedRateEquipmentSelector.total_amount?.vat_amt || 0)}</td>
                  </tr>

                  <tr>
                    <td width="80%" align="right">
                      <b> Grand Total</b>
                    </td>
                    <td width="110px">{formatter.format(fixedRateEquipmentSelector.total_amount?.grand_total || 0)}</td>
                  </tr>
                  {/* <tr>
                    <td width="80%" align="right">
                      Total
                    </td>
                    <td width="110px">{formatter.format(subTotal)}</td>
                  </tr>
                  <tr>
                    <td width="80%" align="right">
                      <ReuseForm.Select
                        name="is_vat_percent"
                        style={{ marginLeft: "auto", width: "150px" }}
                        defaultValue="false"
                      >
                        <Form.Option value="false">VAT Amount</Form.Option>
                        <Form.Option value="true">VAT Percent</Form.Option>
                      </ReuseForm.Select>
                    </td>
                    <td width="110px">
                      <ReuseForm.Input type="number" name="vat_amount" placeholder="Rs." />
                    </td>
                  </tr>

                  {is_vat_percent && (
                    <tr>
                      <td width="80%" align="right">
                        VAT Amount
                      </td>
                      <td width="110px">{formatter.format(vatAmt)}</td>
                    </tr>
                  )}
                  <tr>
                    <td width="80%" align="right">
                      Grand Total
                    </td>
                    <td width="120px">{formatter.format(grandTotalAmt)}</td>
                  </tr> */}
                </tbody>
              </BasicTable>
            </ReuseForm>
          </div>
        </div>
      </StyledContainerDrawer>
    </div>
  );
}

export default RateAnalysisTable;

export const StyledContainerDrawer = styled.div<{ open: boolean }>`
  ${(props) =>
    props.open
      ? css`
          display: block;
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.25);
        `
      : css`
          display: block;
        `}

  & #content-drawer {
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    transition: 0.3s all ease;
    transform: ${(props) => (props.open ? "translateX(0%)" : "translateX(150%)")};
    transform-origin: right;
    width: 50%;
    height: 100vh;
    background: white;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  .container {
    padding: 1.5rem;
  }
`;

const BasicTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;

  & tr td {
    padding: 5px;
    border: 1px solid #f1f1f1;
    background: #f9f9f9;

    & strong {
      font-weight: 500;
    }

    input {
      width: 100%;
    }
  }
`;
