import { Button, Form, Grid, MainLayout } from "@ims/common";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../core-ui/Page";
import useTranslated from "../../hooks/useTranslated";
import {
  getMunicipalityBudgetDataById,
  updateMunicipalityBudgetData,
} from "../../rtk/feature/municipality-budget/municipalityBudgetAPI";
import {
  ImunicipalityData,
  municipalityBudgetCleanUp,
} from "../../rtk/feature/municipality-budget/municipalityBudgetSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
const MunicipalityBudgetEditPage = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm<ImunicipalityData>();

  const params = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();

  const { item, formSubmitted } = useAppSelector((state) => state.municipalityBudget);

  useEffect(() => {
    const controller = new AbortController();

    if (params.id) dispatch(getMunicipalityBudgetDataById({ id: params.id, signal: controller.signal }));
  }, []);

  useEffect(() => {
    if (formSubmitted) {
      navigate(`/budget/municipality-budget`);
    }
    return () => {
      dispatch(municipalityBudgetCleanUp());
    };
  }, [formSubmitted]);

  useEffect(() => {
    if (item) {
      setValue("allocation", item.allocation);
      setValue("attention", item.attention);
      setValue("balance", item.balance);
      setValue("expenditure", item.expenditure);
      setValue("expenditure_heading", item.expenditure_heading);
      setValue("expenditure_rate", item.expenditure_rate);
      setValue("hint", item.hint);
      setValue("name", item.name);
      setValue("source", item.source);
      setValue("unit", item.unit);
    }
  }, [item]);

  const onSubmit: SubmitHandler<ImunicipalityData> = (data) => {
    if (params.id) dispatch(updateMunicipalityBudgetData({ data, id: params.id }));
  };

  const handleCancel = (): void => {
    navigate(-1);
  };

  interface IFormFields {
    name: keyof ImunicipalityData;
    label: string;
    type?: any;
  }

  const formFields: IFormFields[] = [
    {
      name: "allocation",
      label: keyword["Allocation"],
    },
    {
      name: "attention",
      label: keyword["Attention"],
    },
    {
      name: "balance",
      label: keyword["Balance"],
    },
    {
      name: "expenditure",
      label: keyword["Expenditure"],
    },
    {
      name: "expenditure_heading",
      label: keyword["Expenditure Heading"],
    },
    {
      name: "expenditure_rate",
      label: keyword["Expenditure Rate"],
    },
    {
      name: "hint",
      label: keyword["Hint"],
    },
    {
      name: "name",
      label: keyword["Name"],
    },

    {
      name: "source",
      label: keyword["Source"],
    },
    {
      name: "unit",
      label: keyword["Unit"],
    },
  ];

  return (
    <MainLayout.Main>
      <Page.Title> {keyword["Municipality Budget Edit"]} </Page.Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            {formFields.map((field, index) => {
              return (
                <Grid.Col spanMd={6} spanXs={12}>
                  <Form.FormGroup error={Boolean(errors && errors[field.name])}>
                    <Form.Label> {field.label} </Form.Label>
                    <Form.Input
                      type="text"
                      placeholder={`Enter ${field.label}`}
                      register={register(field.name, {
                        required: `${field.label}  is required`,
                      })}
                    />
                    {errors && errors[field.name] && errors[field.name]?.message && (
                      <Form.HelperText>{errors[field.name]?.message}</Form.HelperText>
                    )}
                  </Form.FormGroup>
                </Grid.Col>
              );
            })}

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {keyword["Update"]}
              </Button>
              <Button onClick={handleCancel}>{keyword.Cancel}</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Form>
    </MainLayout.Main>
  );
};

export default MunicipalityBudgetEditPage;
