import React, { HtmlHTMLAttributes, useContext, useEffect } from "react";
import { useWindowSize } from "../../utils/useWindowSize";
import { LayoutContext, NavbarState } from "../layout/MainLayout";
import {
  MainHeaderRoot,
  MainHeaderToggleButton,
  StyledProfileCard,
} from "./MainHeader.style";

interface MainHeaderPropsI {
  children: React.ReactNode;
}

export default function MainHeader(props: MainHeaderPropsI) {
  const { children } = props;
  return <MainHeaderRoot>{children}</MainHeaderRoot>;
}

interface MainHeaderProfileCardPropsI
  extends HtmlHTMLAttributes<HTMLButtonElement> {}

MainHeader.ProfileCard = function MainHeaderProfileCardFunction(
  props: MainHeaderProfileCardPropsI
) {
  return <StyledProfileCard {...props} />;
};

interface MainHeaderToggleButtonPropsI {
  children: React.ReactNode;
  closeButton?: boolean;
}

const navbarState = new NavbarState();
MainHeader.ToggleButton = function MainHeader(
  props: MainHeaderToggleButtonPropsI
) {
  const { closeButton } = props;
  const { setExpanded, setMobileNavOpen } = useContext(LayoutContext);

  const size = useWindowSize();

  useEffect(() => {
    if (size && size.width && size.width <= 920) {
      setExpanded(true);
      navbarState.saveState(true);
    }
  }, [size, setMobileNavOpen, setExpanded]);

  const handleClick = () => {
    if (closeButton === true) {
      setMobileNavOpen((active: boolean) => !active);
    } else if (size && size.width && size.width <= 920) {
      setMobileNavOpen((active: boolean) => !active);
    } else {
      setExpanded((active: boolean) => {
        navbarState.saveState(!active);
        return !active;
      });
    }
  };

  return (
    <MainHeaderToggleButton
      onClick={handleClick}
      {...props}
      className={closeButton ? "btn-close" : ""}
    />
  );
};
