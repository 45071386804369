import { MainLayout } from "@ims/common";
import useTranslated from "../../../hooks/useTranslated";
import Page from "../../../core-ui/Page";
import PreviewMinutDoc from "../../minute-document/PreviewMinuteDoc";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { getUpahboktaSamitiMinute } from "../../../rtk/feature/upabhokta-samiti-minute/upabhoktaSamitiMinuteApi";
import upabhoktaSamitiMinutSlice, {
  UpabhoktaSamitiMinuteI,
  UpabhoktaSamitiMinuteStateI,
} from "../../../rtk/feature/upabhokta-samiti-minute/upabhoktaSamitiMinuteSlice";
import { LayoutContext } from "@ims/common/src/core-ui/layout/MainLayout";

import { showLoading } from "../../../rtk/feature/auth/authSlice";
import CreateMinutDoc from "../../minute-document/CreateMinuteDoc";
import { AdminMinuteI } from "../../../rtk/feature/admin-minute/adminMinuteSlice";
import { getAdminMinutes } from "../../../rtk/feature/admin-minute/adminMinuteApi";

interface MinutDocumentDetailsPropsI {
  id: string;
}

export default function MinutDocumentDetails({ id }: MinutDocumentDetailsPropsI) {
  const [minutDocuments, setMinutDocuments] = useState<UpabhoktaSamitiMinuteI[] | null>([]);
  const [myMinutDocuments, setMyMinutDocuments] = useState<AdminMinuteI[] | null>([]);
  const [selectedMinut, setSelectedMinut] = useState<AdminMinuteI | null>(null);
  const { keyword } = useTranslated();
  const { setExpanded } = useContext(LayoutContext);
  const dispatch = useAppDispatch();

  const upabhoktaSamitiMinutSelector = useAppSelector((state) => state.upabhoktaSamitiMinute);
  const adminMinutSelector = useAppSelector((state) => state.adminMinute);

  useEffect(() => {
    dispatch(showLoading(upabhoktaSamitiMinutSelector.loading || adminMinutSelector.loading));
  }, [dispatch, upabhoktaSamitiMinutSelector.loading, adminMinutSelector.loading]);

  useEffect(() => {
    setExpanded(false);
  }, []);

  useEffect(() => {
    dispatch(getUpahboktaSamitiMinute({ id }));
    dispatch(getAdminMinutes({ id: id }));
  }, [id]);

  useEffect(() => {
    setMyMinutDocuments(adminMinutSelector.data);
  }, [adminMinutSelector.data]);

  useEffect(() => {
    setMinutDocuments(upabhoktaSamitiMinutSelector.data);
  }, [upabhoktaSamitiMinutSelector.data]);

  const updateMyMinuteDocument = (newDocument: AdminMinuteI) => {
    if (myMinutDocuments === null) {
      setMyMinutDocuments([newDocument]);
    } else {
      setMyMinutDocuments([...myMinutDocuments, newDocument]);
    }
  };

  const setMinutHandler = (id: string) => {
    const matchedDoc = myMinutDocuments?.find((doc) => doc.id === id);
    if (matchedDoc) setSelectedMinut(matchedDoc);
  };

  return (
    <>
      {/* <MainLayout.Main> */}

      <ColumnContainer>
        <FirstColumn>
          <CreateMinutDoc yojanaId={id} Adoc={selectedMinut} />
        </FirstColumn>
        <SecondColumn>
          <h4>{keyword["Sent Document"]}</h4>
          {myMinutDocuments?.length ? (
            <PreviewMinutDoc Adocs={myMinutDocuments} isEdit={true} clickHandler={setMinutHandler} />
          ) : (
            <p style={{ fontSize: "1.3rem" }}>{keyword["No minute document found"]}</p>
          )}
        </SecondColumn>{" "}
        <ThirdColumn>
          <h4>{keyword["Received  Document"]}</h4>
          {minutDocuments?.length ? (
            <PreviewMinutDoc Udocs={minutDocuments} />
          ) : (
            <p style={{ fontSize: "1.3rem" }}>{keyword["No minute document found"]}</p>
          )}
        </ThirdColumn>
      </ColumnContainer>
      {/* </MainLayout.Main> */}
    </>
  );
}

const FirstColumn = styled.div`
  flex: 2;
  width: 32%;
  border-right: 1px solid rgb(204, 204, 204);
  margin-right: 10px;
  border-radius: 10px;
  padding: 16px;
`;

const SecondColumn = styled.div`
  flex: 3;
  width: 34%;
  border-left: 1px solid rgb(204, 204, 204);
  padding: 16px;
  border-radius: 10px;
`;
const ThirdColumn = styled.div`
  flex: 3;
  width: 34%;
  border-left: 1px solid rgb(204, 204, 204);
  padding: 16px;
  border-radius: 10px;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
`;
