import React, { useEffect, useState } from "react";
import DocumentCard from "../../components/documents/DocumentCard";
import { StyledDocument, StyledDocumentName, StyledGrid } from "../../components/documents/Document.style";
import { useNavigate } from "react-router-dom";
import { CgMenuBoxed } from "react-icons/cg";
import { AiOutlineDownload } from "react-icons/ai";
import {
  YojanaBookTemplateCleanUp,
  YojanaBookTemplateTypeI,
} from "../../rtk/feature/yojana-book-template/yojanaBookTemplateSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { getYojanaBookTemplate } from "../../rtk/feature/yojana-book-template/yojanaBookTemplateApi";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import useTranslated from "../../hooks/useTranslated";
const DocumentPage = () => {
  const [yojanaTemplates, setYojanaTemplates] = useState<YojanaBookTemplateTypeI[]>([
    {
      id: "",
      title: "",
      content: "",
      order: 0,
    },
  ]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();

  const yojanaBookTemplateSelector = useAppSelector((state) => state.yojanaBookTemplate);
  const { data: yojanaBookTemplatesData } = yojanaBookTemplateSelector;

  useEffect(() => {
    dispatch(showLoading(yojanaBookTemplateSelector.loading));
  }, [dispatch, yojanaBookTemplateSelector.loading]);

  useEffect(() => {
    dispatch(YojanaBookTemplateCleanUp());
    dispatch(getYojanaBookTemplate());
  }, [dispatch]);

  useEffect(() => {
    setYojanaTemplates(yojanaBookTemplatesData);
  }, [yojanaBookTemplatesData, dispatch]);

  // to download pdf
  const downloadPDF = async (pdfUrl: string, yojanaBookTitle: string) => {
    if (pdfUrl) {
      const response = await fetch(pdfUrl);

      const blob = await response.blob();

      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.style.display = "none";

      a.href = url;

      a.download = yojanaBookTitle;

      document.body.appendChild(a);

      a.click();

      URL.revokeObjectURL(url);
    }
  };

  return (
    <StyledGrid>
      {yojanaTemplates.length ? (
        yojanaTemplates.map((document: any, index: number) => {
          return (
            <DocumentCard
              key={index}
              order={document.order}
              documentName={document.title}
              handleClick={() => navigate(`/editor/budgetId/${document.id}`)}
            />
          );
        })
      ) : (
        <h1>{keyword["No template available."]}</h1>
      )}
    </StyledGrid>
  );
};

export default DocumentPage;
