import { Button, Flex, Table } from "@ims/common";
import React, { useEffect } from "react";
import { BsPrinter } from "react-icons/bs";
import { getWorkReports } from "../../../rtk/feature/work-report/workReportAPI";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import TableUtility, { htmlToPrint } from "../../user/TableUtility";
import { BasicTable } from "../PlanSearch";
import AbstractCostTable from "./components/AbstractCostTable";

interface IProps {
  budgetId: string;
}

const AbstractOfCost = (props: IProps) => {
  const { budgetId } = props;
  const { data } = useAppSelector((state) => state.workReport);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getWorkReports(budgetId));
  }, []);

  const costTableStyle = `
    <style>
  table * {
    color: black;
    background: transparent!important;
  }

  table {
    width: 100%;
    text-align: left;
    border: 1px solid #000;
    border-width: 1px 1px 1px 1px !important;
    border-collapse: collapse;
    font-size: 14px;
  }

  th, td {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
    width:6rem;

  }
   


   th:nth-child(2), td:nth-child(2) {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
    width:17rem;
  }

    th:nth-child(1), td:nth-child(1) {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
    width:1rem;
  }



  td *{
    display: inline-block;
    margin-right: 5px;
  }
  
  
  td img {
    width: 40px;
  }

  input, textarea,
  button, svg{
    display: none!important;
  }
  table tr td:last-child,
  table tr th:last-child {
    display: inline-block;

  }
  .seperate{
    border:none;
    border-top:inherit;
    margin-top:1rem;
    float:right;
    width:40rem;
    word-break:no-break;
  }

  @media print{    
    .no-print, .no-print *{
      display: none !important;
    }
  }

</style>


  `;

  const tableUtility = new TableUtility("abstract", costTableStyle);

  return (
    <>
      {" "}
      <Button startIcon={<BsPrinter />} onClick={tableUtility.print}>
        Print
      </Button>
      <Flex.Col id="abstract" justify="flex-end">
        <Flex.Col align="center">
          <h3 style={{ textAlign: "center" }}>Phedikhola Rural Municipality</h3>

          <h1 style={{ textAlign: "center" }}>OFFICE OF RURAL MUNICIPAL EXECUTIVE</h1>
          <h4 style={{ textAlign: "center" }}>PHEDIKHOLA, SYANGJA</h4>
          <h5 style={{ textAlign: "left" }}>Name of project : {data?.yojana_name}</h5>

          <Flex justify="space-between">
            {" "}
            <h3> Location:- Phedikhola-01-Ramkot,Syanja </h3> <h3> {data?.fiscal_year.name} </h3>{" "}
          </Flex>
        </Flex.Col>
        <Table.Container>
          <Table id="cost-table">
            {data?.general_work && (
              <AbstractCostTable showHead={true} data={data?.general_work} tableName="general work" />
            )}
            {data?.construction_work && (
              <AbstractCostTable showHead={true} data={data.construction_work} tableName="Construction work" />
            )}

            {data?.sanitary_work && (
              <AbstractCostTable showHead={true} data={data?.sanitary_work} tableName="sanitary work" />
            )}
            {data?.electrification_work && (
              <AbstractCostTable showHead={true} data={data.electrification_work} tableName="Electrification work" />
            )}

            <BasicTable style={{ width: "40rem", float: "right" }} className="seperate">
              <tbody>
                <tr>
                  <td> sub total </td>
                  <td>Rs. {data?.total.sub_total}</td>
                </tr>{" "}
                <tr>
                  <td> With Contengencies </td>
                  <td>Rs. {data?.total.with_contengencies}</td>
                </tr>{" "}
                <tr>
                  <td> With VAT </td>
                  <td>Rs. {data?.total.with_vat}</td>
                </tr>
                <tr>
                  <td> Grand total </td>
                  <td>Rs. {data?.total.grand_total}</td>
                </tr>
              </tbody>
            </BasicTable>
          </Table>
        </Table.Container>
      </Flex.Col>
    </>
  );
};

export default AbstractOfCost;
