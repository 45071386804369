import React, { useState } from "react";
import { useAppDispatch } from "../../../rtk/hook";
import styled from "styled-components";
import RateItem from "./RateItem";
import { RateEquipmentFormTypeI } from "../../../rtk/feature/rate-equipment/rateEquipmentSlice";
import useTranslated from "../../../hooks/useTranslated";

interface RateSubTablePropsI {
  budgetID: string | null;
  tableData: RateEquipmentFormTypeI[];
  setTableData: React.Dispatch<React.SetStateAction<RateEquipmentFormTypeI[]>>;
  rateID: string | null;
  type: string;
  total: number | null;
}

export const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "NPR" });

const newItem = {
  id: Date.now().toString(),
  equipment: null,
  quantity: 0,
  total_amount: 0,
  unit_rate: 0,
  _is_latest: true,
};

function RateSubTable(props: RateSubTablePropsI) {
  const { budgetID, tableData, setTableData, rateID, type } = props;

  const [totalAmt, setTotalAmt] = useState(0);
  const dispatch = useAppDispatch();

  const handleAdd = () => {
    setTableData((item) => {
      return [...item, newItem];
    });
  };

  const handleChange = (data: any) => {
    setTableData((item) =>
      item.map((el) => {
        if (el.id === data.id) return data;
        return el;
      })
    );
    calculateTotal(tableData);
  };

  const handleDelete = (ID: any) => {
    setTableData((item) => item.filter((el) => el.id !== ID));
  };

  const calculateTotal = (tableData: RateEquipmentFormTypeI[]) => {
    const total = tableData.reduce((prev, curr) => {
      if (curr.total_amount) prev = prev + curr.total_amount;
      return prev;
    }, 0);
    setTotalAmt(total);
  };
  const { keyword } = useTranslated();

  return (
    <BasicTable>
      <tbody>
        <tr>
          <td style={{ background: "rgb(0 39 68)", color: "white" }} width="40px">
            {keyword["S.N."]}.
          </td>
          <td style={{ background: "rgb(0 39 68)", color: "white" }}>{keyword["Description"]}</td>
          <td style={{ background: "rgb(0 39 68)", color: "white" }} width="110px">
            {keyword["Quantity"]}
          </td>
          <td style={{ background: "rgb(0 39 68)", color: "white" }} width="110px">
            {keyword["Analysis rate"]}
          </td>
          <td style={{ background: "rgb(0 39 68)", color: "white" }} width="110px">
            {keyword["Total Price"]}
          </td>
          <td style={{ background: "rgb(0 39 68)", color: "white" }} width="80px" align="center" className="no-print">
            {keyword["Action"]}
          </td>
        </tr>

        {tableData.map((item, index) => {
          return (
            <RateItem
              key={item.id}
              index={index + 1}
              value={tableData.find((el) => el.id === item.id)}
              onAdd={handleAdd}
              onChange={handleChange}
              onDelete={handleDelete}
              total={tableData.length}
              budgetID={budgetID}
              rateID={rateID}
              type={type}
            />
          );
        })}

        <tr>
          <td colSpan={4}>{keyword["Total"]}</td>
          <td colSpan={2}>{formatter.format(props.total || 0)}</td>
        </tr>
      </tbody>
    </BasicTable>
  );
}

export default RateSubTable;

const BasicTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;

  & tr td {
    padding: 5px;
    border: 1px solid #f1f1f1;
    background: #f9f9f9;

    & strong {
      font-weight: 500;
    }

    input {
      width: 100%;
    }
  }
`;
