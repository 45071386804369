import React from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Form from "@ims/common/src/core-ui/forms/Form";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import Table from "@ims/common/src/core-ui/table/Table";
import useTranslated from "../hooks/useTranslated";

type Inputs = {
  example: string;
  exampleRequired: string;
};

function HomePage() {
  const { handleSubmit } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {};

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>First Name</Table.HeadCol>
              <Table.HeadCol>Last Name</Table.HeadCol>
              <Table.HeadCol>Age</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.Col>Aashish</Table.Col>
              <Table.Col>Maharjan</Table.Col>
              <Table.Col>22</Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.Col>John</Table.Col>
              <Table.Col>Doe</Table.Col>
              <Table.Col>25</Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.Col>Mohan</Table.Col>
              <Table.Col>Maharjan</Table.Col>
              <Table.Col>22</Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.Col>Harry</Table.Col>
              <Table.Col>Doe</Table.Col>
              <Table.Col>25</Table.Col>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table.Pagination>
          <Grid>
            <Grid.Row gap={1}>
              <Grid.Col spanMd={6} spanSm={12}>
                <Form.Label>Show per page: </Form.Label>
                <Form.Select name="page-items">
                  <Form.Option value="kalanki">5</Form.Option>
                  <Form.Option value="naikap">10</Form.Option>
                  <Form.Option value="naikap">20</Form.Option>
                  <Form.Option value="naikap">50</Form.Option>
                </Form.Select>
              </Grid.Col>

              <Grid.Col spanMd={6} spanSm={12}>
                <Table.PaginationContainer>
                  <span>1-2 of 2 items</span>
                  <Table.PaginationButton>&laquo;</Table.PaginationButton>

                  <Table.PaginationItem>1</Table.PaginationItem>
                  <Table.PaginationItem className="active">2</Table.PaginationItem>
                  <Table.PaginationItem>3</Table.PaginationItem>
                  <Table.PaginationItem>4</Table.PaginationItem>

                  <Table.PaginationButton>&raquo;</Table.PaginationButton>
                </Table.PaginationContainer>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Table.Pagination>
      </Table.Container>

      <br />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup>
                <Form.Label>Full Name</Form.Label>
                <Form.Input type="text" placeholder="Enter your full name" name="fullname" />
                <Form.HelperText>Your full name is required.</Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error>
                <Form.Label>Email</Form.Label>
                <Form.Input type="text" placeholder="Enter your email" name="email" />
                <Form.HelperText>Your email is required.</Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup>
                <Form.Label>Password</Form.Label>
                <Form.Input type="password" placeholder="Enter your password" name="password" />
                <Form.HelperText>Password is required.</Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup>
                <Form.Label>React Select</Form.Label>
                <Form.ReactSelect name="react-select" options={options} />
                <Form.HelperText>React Select option is required.</Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error>
                <Form.Label>{keyword.Description}</Form.Label>
                <Form.TextArea placeholder="Enter your password" name="password" />
                <Form.HelperText>Password is required.</Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup>
                <Form.Label>Departments</Form.Label>
                <Form.Select name="departments">
                  <Form.Option value="kalanki">Kalanki</Form.Option>
                  <Form.Option value="naikap">Naikap</Form.Option>
                </Form.Select>
                <Form.HelperText>Password is required.</Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup>
                <Form.Label>Please select profile image</Form.Label>
                <Form.Input type="file" name="password" />
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup hasCheck>
                <Form.Input type="radio" value="HTML" name="lang" id="HTML" />
                <Form.Label for="HTML">HTML</Form.Label>

                <Form.Input type="radio" value="CSS" name="lang" id="CSS" />
                <Form.Label for="CSS">CSS</Form.Label>
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup hasCheck>
                <Form.Input type="checkbox" value="HTML" name="agree" id="agree" />
                <Form.Label for="agree">Do you agree to our terms and conditions.</Form.Label>
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                Submit
              </Button>
              <Button type="reset">Reset</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Form>

      <br />
      <h1>Hello IMS - Government</h1>
      <h2>Hello Aashish</h2>
      <h3>Hello Lumus</h3>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi animi dolorum quae dicta ipsum voluptas illo,
        voluptatibus voluptate saepe blanditiis, explicabo voluptatem quaerat error officia iste doloremque architecto
        iusto accusamus, porro itaque. Magni quibusdam, repellendus enim laborum ipsum sapiente ipsa sunt quam aut a
        molestiae, labore error repellat sed laboriosam.
      </p>
    </MainLayout.Main>
  );
}

export default HomePage;
