import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { BudgetFormTypeI } from "./budgetSlice";
import { BudgetSuccess } from "../../../utils/res-mes/response-message-generator";

export const getBudgets = createAsyncThunk(
  "getBudgets",
  async (
    budgetParams: { per_page: number; page: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/budget-planning/?per_page=", {
        params: budgetParams,
        signal: budgetParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getBudget = createAsyncThunk("getBudget", async (id: string) => {
  try {
    const res = await imsClient.get(`/budget-planning/${id}/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
  }
});

export const addBudget = createAsyncThunk("addBudget", async (data: BudgetFormTypeI, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.post("/budget-planning/", data);
    if (res.data) toast.success(BudgetSuccess("added"));
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const updateBudget = createAsyncThunk(
  "updateBudget",
  async (data: { data: BudgetFormTypeI | any; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/budget-planning/${data.id}/`, data.data);
      if (res.data) toast.success(BudgetSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
