import { Button, Flex, Form, MainLayout } from "@ims/common";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { roles } from "../../../constants/constants";
import Page from "../../../core-ui/Page";
import ReuseForm from "../../../core-ui/ReuseForm";
import useTranslated, { keywordTypes } from "../../../hooks/useTranslated";
import { getVerificationById, updateReceivedPlan } from "../../../rtk/feature/plan-verification/planVerificationAPI";
import {
  planVerificationCleanUp,
  IplanVerificationData,
} from "../../../rtk/feature/plan-verification/planVerificationSlice";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import AbstractOfCost from "../AbstractOfCost/AbstractOfCost";
import DetailedEstimateReport from "../report/DetailedEstimateReport";
import ReviewPlanDetail from "./ReviewPlanDetail";
import PopupModal from "../../../components/PopupModal/PopupModal";
import UploadDocument from "./UploadDocument";
import { BsArrowLeft } from "react-icons/bs";

const ReviewPlanDetailsPage = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [actionFor, setActionFor] = useState({
    discard: false,
    approve: false,
    startYojana: false,
  });
  const {
    verificationReceive: { data: verificationData },
    formSubmitted,
  } = useAppSelector((state) => state.sendPlanVerification);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { keyword } = useTranslated();
  const navigate = useNavigate();
  const myRole = useAppSelector((state) => state.auth.user?.role);
  interface Iform {
    comment: string;
  }
  const { control, register, setValue, handleSubmit, getValues } = useForm<Iform>({
    defaultValues: {
      comment: "",
    },
  });
  useEffect(() => {
    if (id) {
      dispatch(getVerificationById({ id: id }));
    }
  }, []);

  const startYojana = () => {
    if (id)
      dispatch(
        updateReceivedPlan({
          id: id,
          data: {
            is_verified: true,
            is_started: true,
          },
        })
      );
  };
  const approve = () => {
    if (id)
      dispatch(
        updateReceivedPlan({
          id: id,
          data: {
            is_verified: true,
            comment: getValues("comment"),
          },
        })
      );
  };
  const discard = () => {
    if (id && getValues("comment"))
      dispatch(
        updateReceivedPlan({
          id: id,
          data: {
            is_verified: false,
            comment: getValues("comment"),
          },
        })
      );
  };
  useEffect(() => {
    if (verificationData?.comment) {
      setValue("comment", verificationData.comment);
    }
  }, [verificationData?.comment]);

  useEffect(() => {
    if (formSubmitted) {
      navigate("/plan-management/plan");
    }
    return () => {
      dispatch(planVerificationCleanUp());
    };
  }, [formSubmitted]);

  const openPopupModal = (acn: "discard" | "approve" | "startYojana") => {
    setOpenPopup(true);
    setActionFor((prevState) => {
      return {
        ...prevState,
        [acn]: true,
      };
    });
  };
  const closePopupModal = () => {
    setOpenPopup(false);
    setActionFor({
      discard: false,
      approve: false,
      startYojana: false,
    });
  };

  const handleProceed = () => {
    if (actionFor.approve) approve();
    else if (actionFor.discard) discard();
    else if (actionFor.startYojana) startYojana();
  };

  if (!verificationData || !id) return null;
  return (
    <MainLayout.Main>
      <Button
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginBottom: "1rem" }}
      >
        <BsArrowLeft /> Go Back
      </Button>
      <ReviewPlanDetail data={verificationData} />
      <div style={{ margin: "2rem 0" }}>
        {" "}
        <Page.Title style={{ textAlign: "center" }}> Work Report </Page.Title>{" "}
        <AbstractOfCost budgetId={verificationData.yojana.id} />
      </div>

      {myRole === roles.Prasasakiya_Adhikrit ? (
        <Flex.Col gap=".8rem">
          {(verificationData?.status === "pending" || verificationData?.status === "not_verified") && (
            <>
              <Form.Label> Comment </Form.Label>
              <div>
                <Form.TextArea name="comment" register={register("comment")} />
              </div>
            </>
          )}

          <Flex dir="row">
            {(verificationData?.status === "pending" || verificationData?.status === "not_verified") && (
              <>
                <Button variant="error" onClick={() => openPopupModal("discard")}>
                  Return Back
                </Button>

                <Button variant="primary" onClick={() => openPopupModal("approve")}>
                  Approve
                </Button>
              </>
            )}
          </Flex>
        </Flex.Col>
      ) : myRole === roles.Yojana_Sakha ? (
        !verificationData?.is_started ? (
          <>
            <Button variant="primary" onClick={() => openPopupModal("startYojana")}>
              {" "}
              Start {keyword.Yojana}
            </Button>
          </>
        ) : null
      ) : null}

      <PopupModal isOpen={openPopup} onClose={closePopupModal}>
        <h1>{keyword["Are you sure?"]}</h1>

        <Button variant="primary" onClick={handleProceed}>
          {keyword.Proceed}
        </Button>
        <Button onClick={closePopupModal}>{keyword.Cancel}</Button>
      </PopupModal>
    </MainLayout.Main>
  );
};

export default ReviewPlanDetailsPage;
