import React, { ReactNode } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { JsxElement } from "typescript";
import LoginPage from "../../pages/auth/LoginPageSample";
import { roleTypes } from "../../rtk/feature/auth/authSlice";
import { useAppSelector } from "../../rtk/hook";
import useTranslated from "../../hooks/useTranslated";
interface IProps {
  children?: JSX.Element;
  redirectPath: string;
  allowedRoles: roleTypes[];
}

const RoleProtectedRoute = (props: IProps) => {
  const { keyword } = useTranslated();
  const myRole = useAppSelector((state) => state.auth.user?.role);
  const authSelector = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  if (myRole && props.allowedRoles.includes(myRole)) {
    return props.children ? props.children : <Outlet />;
  } else if (authSelector.loading) return <h1>{keyword.Loading}...</h1>;
  else return <h1>{keyword["You are not authenticated"]}</h1>;
};

export default RoleProtectedRoute;
