import { createSlice } from "@reduxjs/toolkit";
import { Yojana } from "../constructor/constructorSlice";
import { addPlanBrief, getPlanBrief, getPlanBriefs, updatePlanBrief } from "./planBriefApi";

export interface PlanBriefFormTypeI {
  yojana: string;
  contingency: string;
  yojana_head: string;
  tole: string;
  beneficiaries: number;
  yojana_status: string;
  measurement: string;
  yojana_subtype: string;
  completion_date: string;
  contribution_donation: string;
  description: string;
  notice_published: boolean;
  yojana_completion: boolean;
  final_measurement: string;
  pre_project_photo: null;
  mid_project_photo: null;
  final_project_photo: null;
  completed_photo: null;
}

export interface PlanBriefTypeI {
  id: string;
  yojana: Yojana;
  contingency: string;
  yojana_head: string;
  tole: string;
  beneficiaries: number;
  yojana_status: string;
  measurement: string;
  yojana_subtype: string;
  completion_date: string;
  contribution_donation: string;
  description: string;
  notice_published: boolean;
  yojana_completion: boolean;
  final_measurement: string;
  pre_project_photo: null;
  mid_project_photo: null;
  final_project_photo: null;
  completed_photo: null;
}

export interface PlanBriefUpdatePasswordTypeI {
  old_password: string;
  password: string;
  confirm_password: string;
}
export interface PlanBriefsSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: PlanBriefTypeI[];
  } | null;
  item: PlanBriefTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: PlanBriefsSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const planbriefSlice = createSlice({
  name: "planbrief",
  initialState,
  reducers: {
    planbriefCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPlanBrief.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addPlanBrief.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addPlanBrief.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getPlanBriefs.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getPlanBriefs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getPlanBriefs.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getPlanBrief.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getPlanBrief.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getPlanBrief.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updatePlanBrief.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updatePlanBrief.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updatePlanBrief.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
  },
});

export const { planbriefCleanUp } = planbriefSlice.actions;
export default planbriefSlice.reducer;
