import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getYojanas } from "../../rtk/feature/yojana/yojanaApi";
import { YojanaTypeI } from "../../rtk/feature/yojana/yojanaSlice";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import useTranslated from "../../hooks/useTranslated";

function YojanaManagePage() {
  const [yojanas, setYojanas] = useState<YojanaTypeI[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const yojanasState = useAppSelector((state) => state.yojanas);

  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getYojanas({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    dispatch(showLoading(yojanasState.loading));
  }, [dispatch, yojanasState.loading]);

  useEffect(() => {
    if (yojanasState.data) {
      setYojanas(yojanasState.data.results);
      setTotalItems(yojanasState.data.total);
    }
  }, [yojanasState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/yojana/edit/${id}`);
  };
  const { keyword } = useTranslated();
  return (
    <MainLayout.Main>
      <h4>{keyword.Yojana}Manage</h4>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>Title</Table.HeadCol>
              <Table.HeadCol>Agreement Date</Table.HeadCol>
              <Table.HeadCol>Created By</Table.HeadCol>
              <Table.HeadCol>Ward</Table.HeadCol>
              <Table.HeadCol align="center">Action</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {yojanas &&
              yojanas.length > 0 &&
              yojanas.map((yojana, index) => {
                let serialNumber: string = "";

                let key = index + 1 + (page - 1) * perPage;
                if (key < 10) serialNumber = "0" + key;
                else serialNumber = key.toString();

                return (
                  <Table.Row key={serialNumber}>
                    <Table.Col>{getSN(index)}</Table.Col>
                    <Table.Col>{yojana.name}</Table.Col>
                    <Table.Col>{yojana.agreement_date}</Table.Col>
                    <Table.Col>{yojana.user.first_name}</Table.Col>
                    <Table.Col>{yojana.user.last_name}</Table.Col>

                    <Table.Col>{yojana.user?.ward?.ward_name || "Not Available"}</Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(yojana.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default YojanaManagePage;
