import styled from "styled-components/macro";

export const MainHeaderRoot = styled.div`
  display: flex;
  align-items: center;
`;

export const MainHeaderToggleButton = styled.button`
  color: ${({ theme }) => theme.palette.text.main};
  font-size: 2rem;
  background: transparent;
  border: none;
  padding: 4px;
  display: flex;
  cursor: pointer;

  &.btn-close {
    color: ${({ theme }) => theme.palette.text.primary};
    position: absolute;
    top: 10px;
    right: 15px;
  }

  @media (min-width: 920px) {
    &.btn-close {
      display: none;
    }
  }
`;

export const StyledProfileCard = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-left: auto;
  background: transparent;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  cursor: pointer;
  border-radius: 2px;
  transition: 0.2s all ease;
  font-size: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  font-weight: bold;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
