import { Button, Flex, Form, Grid, MainLayout, Table } from "@ims/common";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { FiEye, FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { roles } from "../../constants/constants";
import Page from "../../core-ui/Page";
import ReuseForm from "../../core-ui/ReuseForm";
import useDiscloure from "../../hooks/useDiscloure";
import useTranslated from "../../hooks/useTranslated";
import { IOptions } from "../../interfaces/options.interface";
import { getBillPayments, sendBillToPrasasakiya } from "../../rtk/feature/bill-payment/billPaymentAPI";
import { IBillPayment } from "../../rtk/feature/bill-payment/billPaymentSlice";
import { getChequeVerifyByPrasasakiya } from "../../rtk/feature/cheque-verify-by-prasasakiya/chequeVerifyByPrasasakiyaAPI";
import { getUsers } from "../../rtk/feature/users/usersApi";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import usePagination from "../../utils/usePagination";
import Dialog from "../plan/estimate/Dialog/Dialog";
import ChequeVerifyByPrasasakiya from "./ChequeVerifyByPrasasakiya/ChequeVerifyByPrasasakiya";
import { styleSN } from "../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { realTimeFilter } from "../../utils/real-time-filter";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import getFormattedSort from "../../utils/getFormattedSort";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { MdVerified } from "react-icons/md";
import { GoUnverified } from "react-icons/go";

interface IForm {
  user: IOptions;
}

const BillPayments = () => {
  const [billPayments, setBillPayments] = useState<any[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { close, isOpen, open } = useDiscloure();
  const users = useAppSelector((state) => state.users.data?.results);
  const { data: verifyByPrasasakiya } = useAppSelector((state) => state.chequeVeirfyByPrasasakiya);
  const { user } = useAppSelector((state) => state.auth);
  const isPrasasakiya = user?.role === roles.Prasasakiya_Adhikrit;
  const { data: dataSelector } = useAppSelector((state) => state.billPayment);
  const results = dataSelector?.results;
  const [total, setTotal] = useState(1);

  const { Pagination, perPage, page } = usePagination({ total });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    register,
  } = useForm<IForm>();

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    reset: filterReset,
    formState: { errors: filterErrors },
    watch: filterWatch,
  } = useForm();

  useEffect(() => {
    if (dataSelector) {
      setTotal(dataSelector?.total);
      setBillPayments(dataSelector?.results);
    }
  }, [dataSelector]);

  useEffect(() => {
    if (isPrasasakiya) {
      dispatch(getChequeVerifyByPrasasakiya({ params: {} }));
    } else {
      dispatch(getBillPayments({ params: { page, per_page: perPage } }));
    }
  }, [page, perPage, isPrasasakiya]);

  const userOptions = users
    ?.filter((user) => user.role === roles.Prasasakiya_Adhikrit && user.is_active)
    ?.map((user) => {
      return {
        label: user.first_name + user.last_name,
        value: user.id,
      };
    });

  useEffect(() => {
    dispatch(getUsers({ params: { per_page: 10000 } }));
  }, []);

  const [activeBill, setActiveBill] = useState<null | IBillPayment>(null);

  const viewDetails = (id: string) => {
    navigate(`/bill-payments/details/${id}`);
  };
  const { keyword } = useTranslated();

  const sendToPrasasakiya = (data: IBillPayment) => {
    setActiveBill(data);
    open();
  };

  // "plan_cheque": null,
  //     "user_id": null,
  //         "cheque_verify_by_prasasakiya": false,
  //             "updated_cheque_photo": null

  const confirmSend = () => {
    if (activeBill) {
      const user = getValues("user.value");
      const finalData = {
        plan_cheque: activeBill.id,
        user_id: user,
      };
      dispatch(sendBillToPrasasakiya(finalData));
    }
  };

  const onFilterSubmit = (data: any) => {
    if (isPrasasakiya)
      dispatch(
        getChequeVerifyByPrasasakiya({
          params: { per_page: perPage, search: data.search, ordering: getFormattedSort(sorts) },
        })
      );
    else
      dispatch(
        getBillPayments({
          params: { per_page: perPage, search: data.search, ordering: getFormattedSort(sorts) },
        })
      );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    if (isPrasasakiya)
      dispatch(
        getChequeVerifyByPrasasakiya({
          params: { per_page: perPage, page: page, ordering: getFormattedSort(sorts) },
        })
      );
    dispatch(
      getBillPayments({
        params: { per_page: perPage, page: page, ordering: getFormattedSort(sorts) },
      })
    );
    filterReset();
  };

  if (user?.role === roles.Prasasakiya_Adhikrit && verifyByPrasasakiya) {
    return (
      <ChequeVerifyByPrasasakiya
        onFilterSubmitFn={onFilterSubmit}
        handleFilterResetFn={handleFilterReset}
        data={verifyByPrasasakiya}
        sorts={sorts}
        setSorts={setSorts}
      />
    );
  }
  return (
    <MainLayout.Main>
      <Dialog active={isOpen} onClose={close}>
        <Dialog.Container className="container-dialog">
          <div className="container-body" style={{ height: "30rem" }}>
            <h2 className="sub-title">Are you sure, you want to send?</h2>

            <ReuseForm control={control} onSubmit={handleSubmit(confirmSend)}>
              <Form.FormGroup error={!!errors.user}>
                <h4 className="title"> send to prasasakiya! </h4>
                <ReuseForm.ReactSelect options={userOptions} name="user" register={register("user")} />
                <Form.HelperText> {errors.user?.message?.toString()} </Form.HelperText>
              </Form.FormGroup>
              <Flex style={{ marginTop: "2rem" }}>
                <Button variant="primary" onClick={() => confirmSend()}>
                  Send
                </Button>
                <Button type="button" onClick={close}>
                  {keyword.Cancel}
                </Button>
              </Flex>
            </ReuseForm>
            {/* <div className="container-button">
                            <Button onClick={close}>{keyword.Cancel}</Button>
                            <Flex gap='2rem'>
                                <Button variant="error" onClick={confirmSend}>
                                    send
                                </Button>

                            </Flex>
                        </div> */}
          </div>
        </Dialog.Container>
      </Dialog>

      <Page.Container>
        <Page.Title> {keyword["Bill Payments"]} </Page.Title>
      </Page.Container>

      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>

              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container>
        <Table id="export-table">
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol> {keyword.From} </Table.HeadCol>

              <Table.HeadCol> {keyword["Send To"]}</Table.HeadCol>
              <Table.HeadCol> {keyword["Cheque Verified By Lekha"]}</Table.HeadCol>
              <Table.HeadCol> {keyword.Description} </Table.HeadCol>
              <Table.HeadCol> {keyword["Verified by prasasakiya"]}</Table.HeadCol>
              <Table.HeadCol align="center"> {keyword.Actions}</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {billPayments?.length > 0 &&
              billPayments?.map((data, index) => {
                return (
                  <Table.Row key={data.id}>
                    <Table.Col>{englishNepaliNumberTranslation(styleSN(index + 1))}</Table.Col>
                    <Table.Col>{data?.plan_bill_verification?.from_to_user?.first_name}</Table.Col>

                    <Table.Col>{data?.send_to_user?.first_name}</Table.Col>
                    <Table.Col>
                      <span style={{ marginLeft: "40%" }}>
                        {data?.cheque_verify_by_lekha ? (
                          <MdVerified color="blue" size={20} title={keyword["Verified"]} />
                        ) : (
                          <GoUnverified color="#888" size={20} title={keyword["Not Verified"]} />
                        )}
                      </span>
                    </Table.Col>
                    <Table.Col>{data?.plan_bill_verification?.description}</Table.Col>

                    <Table.Col>
                      <span style={{ marginLeft: "40%" }}>
                        {data?.plan_bill_verification?.cheque_verify_by_lekha ? (
                          <MdVerified color="blue" size={20} title={keyword["Verified"]} />
                        ) : (
                          <GoUnverified color="#888" size={20} title={keyword["Not Verified"]} />
                        )}
                      </span>
                    </Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => viewDetails(data?.id)}>
                        <FiEye />
                      </Table.ActionButton>

                      <Table.ActionButton onClick={() => sendToPrasasakiya(data)}>
                        send to prasasakiya
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
};

export default BillPayments;
