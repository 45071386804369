import React, { useEffect } from "react";
import { getPlanReport } from "../../../rtk/feature/plan-report/planReportAPI";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
// import { BasicTable } from "../PlanSearch";
import { getYojana } from "../../../rtk/feature/yojana/yojanaApi";
import ReviewPlanDetail from "../ReviewPlanDetails/ReviewPlanDetail";

interface IProps {
  id: string;
}

function DetailedEstimateReport(props: IProps) {
  const { id } = props;
  const dispatch = useAppDispatch();
  const planReport = useAppSelector((state) => state.planReport.data);
  useEffect(() => {
    dispatch(getPlanReport({ id }));
  }, []);
  if (!planReport) return null;
  return (
    <React.Fragment>
      <ReviewPlanDetail data={planReport || {}} />

      {/* </BasicTable> */}
    </React.Fragment>
  );
}

export default DetailedEstimateReport;
