import { Button, Grid } from "@ims/common";
import useTranslated from "../../../../hooks/useTranslated";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DisabledButton from "../../../../components/DisabaledButton/DisabledButton";
import { StyledDocument, StyledDocumentName } from "../../../../components/documents/Document.style";
import { CgMenuBoxed } from "react-icons/cg";
import {
  YojanaBookTemplateCleanUp,
  YojanaBookTemplateTypeI,
} from "../../../../rtk/feature/yojana-book-template/yojanaBookTemplateSlice";
import { useAppDispatch, useAppSelector } from "../../../../rtk/hook";
import { showLoading } from "../../../../rtk/feature/auth/authSlice";
import { getYojanaBookTemplate } from "../../../../rtk/feature/yojana-book-template/yojanaBookTemplateApi";
import styled from "styled-components";

interface YojanaBookTemplateChoiceI {
  budgetId: string;
  onClose: () => void;
}

export default function YojanaBookTemplateChoice({ budgetId, onClose }: YojanaBookTemplateChoiceI) {
  const [yojanaTemplates, setYojanaTemplates] = useState<YojanaBookTemplateTypeI[]>([
    {
      id: "",
      title: "",
      content: "",
      order: 0,
    },
  ]);
  const [selectedYojana, setSelectedYojana] = useState<string | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();
  const yojanaBookTemplateSelector = useAppSelector((state) => state.yojanaBookTemplate);
  const { data: yojanaBookTemplatesData } = yojanaBookTemplateSelector;

  useEffect(() => {
    dispatch(showLoading(yojanaBookTemplateSelector.loading));
  }, [dispatch, yojanaBookTemplateSelector.loading]);

  useEffect(() => {
    dispatch(YojanaBookTemplateCleanUp());
    dispatch(getYojanaBookTemplate());
  }, [dispatch]);

  useEffect(() => {
    setYojanaTemplates(yojanaBookTemplatesData);
  }, [yojanaBookTemplatesData, dispatch]);

  const handleCancel = () => {
    setSelectedYojana(null);
    onClose();
  };

  const handleProceed = () => {
    navigate(`/editor/${budgetId}/${selectedYojana}/`);
  };
  return (
    <>
      <GridContainer>
        {yojanaTemplates.length ? (
          yojanaTemplates?.map((template, idx) => (
            <StyledDocument
              key={idx}
              onClick={() => {
                setSelectedYojana(template.id);
              }}
              selected={selectedYojana === template.id ? true : false}
            >
              <StyledDocumentName>
                <CgMenuBoxed size={40} />
                <p>{template.title}</p>
              </StyledDocumentName>
            </StyledDocument>
          ))
        ) : (
          <Grid.Col spanMd={12} spanXs={12}>
            <h1>{keyword["No template available."]}</h1>
          </Grid.Col>
        )}
      </GridContainer>
      <Grid>
        <Grid.Row gap={1.5}>
          <Grid.Col spanMd={12} spanXs={12}>
            {selectedYojana ? (
              <Button type="submit" variant="primary" onClick={handleProceed} disabled={false}>
                Preceed to Create
              </Button>
            ) : (
              <DisabledButton>Select Yojana</DisabledButton>
            )}

            <Button onClick={handleCancel}>{keyword.Cancel}</Button>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Adjust the gap as needed */
`;
