import { Button, Form, Grid, MainLayout } from "@ims/common";
import React, { ChangeEvent, ChangeEventHandler, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Page from "../../core-ui/Page";
import useTranslated from "../../hooks/useTranslated";
import {
  getMunicipalityInformation,
  updateMunicipalityInformation,
} from "../../rtk/feature/municipality-information/municipalityInformationAPI";
import { IMunicipalityInformation } from "../../rtk/feature/municipality-information/municipalityInformationSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
export interface IMunicipalityInformationForm extends IMunicipalityInformation {
  machine_rental_tax: number;
  fiscal_id: string;
  fiscal_name: string;
  start_date: string;
  end_date: string;
  status: boolean;
}

const MunicipalityInformationForm = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMunicipalityInformation());
  }, []);
  const info = useAppSelector((state) => state.municipalityInfo.data);
  const {
    control,
    register,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<IMunicipalityInformationForm>({});
  const { keyword } = useTranslated();
  useEffect(() => {
    if (info) {
      setValue("name", info.name);
      setValue("contact", info.contact);
      setValue("contengency_rate", info.contengency_rate);
      setValue("email", info.email);
      setValue("end_date", info.fiscal_year.end_date);
      setValue("fiscal_id", info.fiscal_year.id);
      setValue("start_date", info.fiscal_year.start_date);
      setValue("end_date", info.fiscal_year.end_date);
      setValue("status", info.fiscal_year.status);
      setValue("fiscal_name", info.fiscal_year.name);
      setValue("status", info.fiscal_year.status);
      setValue("website", info.website);
      setValue("vat_percent", info.vat_percent);
      setValue("nepali_name", info.nepali_name);
      setValue("estd_date", info.estd_date);
      setValue("reg_no", info.reg_no);
      setValue("no_of_wards", info.no_of_wards);
      setValue("office_address", info.office_address);
      setValue("phone_number", info.phone_number);
      setValue("contact", info.contact);
      setValue("email", info.email);
      // setValue("logo", info.logo);
      setValue("pan_bill_TDS_rate", info.pan_bill_TDS_rate);
      setValue("social_sec_ax_rate", info.social_sec_ax_rate);
      setValue("municipal_JCB_rental", info.municipal_JCB_rental);
      setValue("machine_rental_tax", info.machine_rental_tax);
      setValue("fiscal_year", info.fiscal_year);
    }
  }, [info]);

  const onSubmit = (data: any) => {
    const formData = new FormData();

    const finalData = {
      ...data,
    };
    delete finalData.fiscal_id;
    delete finalData.fiscal_name;
    delete finalData.end_date;
    delete finalData.start_date;
    delete finalData.status;
    delete finalData.logo;
    delete finalData.id;

    Object.keys(finalData).forEach((key) => {
      formData.append("logo", data.logo[0]);

      if (info) dispatch(updateMunicipalityInformation({ data: formData, id: info.id }));
    });
  };
  return (
    <MainLayout.Main>
      <Form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Grid style={{ width: "100%" }}>
          <Grid.Row gap={2}>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["name"])}>
                <Form.Label> {keyword["English Name"]} </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter your name"
                  register={register("name", {
                    required: "Your name is required.",
                  })}
                />
                {errors && errors["name"] && errors["name"].message && (
                  <Form.HelperText>{errors["name"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["nepali_name"])}>
                <Form.Label> {keyword["Nepali Name"]} </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter your nepali_name"
                  register={register("nepali_name", {
                    required: "Your nepali_name is required.",
                  })}
                />
                {errors && errors["nepali_name"] && errors["nepali_name"].message && (
                  <Form.HelperText>{errors["nepali_name"]?.message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["estd_date"])}>
                <Form.Label> {keyword["Established Date"]} </Form.Label>
                <Form.Input
                  type="date"
                  placeholder="Enter your estd_date"
                  register={register("estd_date", {
                    required: "Your estd_date is required.",
                  })}
                />
                {errors && errors["estd_date"] && errors["estd_date"].message && (
                  <Form.HelperText>{errors["estd_date"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["reg_no"])}>
                <Form.Label> {keyword["Reg No"]}</Form.Label>
                <Form.Input
                  type="number"
                  placeholder="Enter your reg_no"
                  register={register("reg_no", {
                    required: "Your reg_no is required.",
                  })}
                />
                {errors && errors["reg_no"] && errors["reg_no"].message && (
                  <Form.HelperText>{errors["reg_no"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["no_of_wards"])}>
                <Form.Label> {keyword["No Of Wards"]}</Form.Label>
                <Form.Input
                  type="number"
                  placeholder="Enter your no_of_wards"
                  register={register("no_of_wards", {
                    required: "Your no_of_wards is required.",
                  })}
                />
                {errors && errors["no_of_wards"] && errors["no_of_wards"].message && (
                  <Form.HelperText>{errors["no_of_wards"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["office_address"])}>
                <Form.Label> {keyword["Office Address"]}</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter your office_address"
                  register={register("office_address", {
                    required: "Your office_address is required.",
                  })}
                />
                {errors && errors["office_address"] && errors["office_address"].message && (
                  <Form.HelperText>{errors["office_address"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["website"])}>
                <Form.Label> {keyword.Website} </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter your website"
                  register={register("website", {
                    required: "Your website is required.",
                  })}
                />
                {errors && errors["website"] && errors["website"].message && (
                  <Form.HelperText>{errors["website"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["phone_number"])}>
                <Form.Label> {keyword["Phone Number"]} </Form.Label>
                <Form.Input
                  type="number"
                  placeholder="Enter your phone_number"
                  register={register("phone_number", {
                    required: "Your phone_number is required.",
                  })}
                />
                {errors && errors["phone_number"] && errors["phone_number"].message && (
                  <Form.HelperText>{errors["phone_number"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["contact"])}>
                <Form.Label> contact</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter your contact"
                  register={register("contact", {
                    required: "Your contact is required.",
                  })}
                />
                {errors && errors["contact"] && errors["contact"].message && (
                  <Form.HelperText>{errors["contact"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["email"])}>
                <Form.Label> {keyword.Email} </Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter your logo"
                  register={register("email", {
                    required: "Your logo is required.",
                  })}
                />
                {errors && errors["email"] && errors["email"].message && (
                  <Form.HelperText>{errors["email"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["logo"])}>
                <Form.Label> {keyword.Logo} </Form.Label>
                <Controller
                  name="logo"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field: { onChange, value }, fieldState, formState }) => {
                    return (
                      <Form.File
                        id="logo"
                        type="file"
                        placeholder="Enter your logo"
                        accept="images/*"
                        onChange={(e) => onChange(e.target.files)}
                      />
                    );
                  }}
                ></Controller>

                {errors && errors["logo"] && errors["logo"].message && (
                  <Form.HelperText>{errors["logo"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["vat_percent"])}>
                <Form.Label> {keyword["VAT Percent %"]}</Form.Label>
                <Form.Input
                  type="number"
                  placeholder="Enter your vat_percent"
                  register={register("vat_percent", {
                    required: "Your vat_percent is required.",
                  })}
                />
                {errors && errors["vat_percent"] && errors["vat_percent"].message && (
                  <Form.HelperText>{errors["vat_percent"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["contengency_rate"])}>
                <Form.Label> {keyword["Contingency Rate %"]}</Form.Label>
                <Form.Input
                  type="number"
                  placeholder="Enter your contengency_rate"
                  register={register("contengency_rate", {
                    required: "Your contengency_rate is required.",
                  })}
                />
                {errors && errors["contengency_rate"] && errors["contengency_rate"].message && (
                  <Form.HelperText>{errors["contengency_rate"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["pan_bill_TDS_rate"])}>
                <Form.Label> {keyword["Pan Bill TDS Rate %"]} </Form.Label>
                <Form.Input
                  type="number"
                  placeholder="Enter your pan_bill_TDS_rate"
                  register={register("pan_bill_TDS_rate", {
                    required: "Your pan_bill_TDS_rate is required.",
                  })}
                />
                {errors && errors["pan_bill_TDS_rate"] && errors["pan_bill_TDS_rate"].message && (
                  <Form.HelperText>{errors["pan_bill_TDS_rate"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["social_sec_ax_rate"])}>
                <Form.Label>{keyword["Social Sec Tax Rate %"]}</Form.Label>
                <Form.Input
                  type="number"
                  placeholder="Enter your social_sec_ax_rate"
                  register={register("social_sec_ax_rate", {
                    required: "Your social_sec_ax_rate is required.",
                  })}
                />
                {errors && errors["social_sec_ax_rate"] && errors["social_sec_ax_rate"].message && (
                  <Form.HelperText>{errors["social_sec_ax_rate"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["municipal_JCB_rental"])}>
                <Form.Label> {keyword["Municipal JCB Rental %"]}</Form.Label>
                <Form.Input
                  type="number"
                  placeholder="Enter your municipal_JCB_rental"
                  register={register("municipal_JCB_rental", {
                    required: "Your municipal_JCB_rental is required.",
                  })}
                />
                {errors && errors["municipal_JCB_rental"] && errors["municipal_JCB_rental"].message && (
                  <Form.HelperText>{errors["municipal_JCB_rental"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["machine_rental_tax"])}>
                <Form.Label> {keyword["Machine Rental Tax %"]}</Form.Label>
                <Form.Input
                  type="text"
                  placeholder="Enter your machine_rental_tax"
                  register={register("machine_rental_tax", {
                    required: "Your machine_rental_tax is required.",
                  })}
                />
                {errors && errors["machine_rental_tax"] && errors["machine_rental_tax"].message && (
                  <Form.HelperText>{errors["machine_rental_tax"].message}</Form.HelperText>
                )}
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col>
              {" "}
              <Button variant="primary" type="submit">
                {" "}
                {keyword.Update}{" "}
              </Button>{" "}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Form>
    </MainLayout.Main>
  );
};

export default MunicipalityInformationForm;
