import { createAsyncThunk } from "@reduxjs/toolkit";
import { AiTwotoneAppstore } from "react-icons/ai";
import { toast } from "react-toastify";
import { IPageOptions } from "../../../interfaces/page.interface";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { IDistrictRateForm } from "./districtRateSlice";
import { RateSuccess } from "../../../utils/res-mes/response-message-generator";

export const getDistrictRate = createAsyncThunk("getDistrictRate", async (data: IPageOptions, { rejectWithValue }) => {
  try {
    const res = await imsClient.get(`/district-rate/`, { params: data.params, signal: data.signal });
    return res.data;
  } catch (error) {
    const appError = new AppError(error);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const updateDistrictRate = createAsyncThunk(
  "updateDistrictRate",
  async ({ id, data }: { id: string; data: IDistrictRateForm }, { rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/district-rate/${id}/`, data);
      if (res.data) toast.success(RateSuccess("updated"));

      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const createDistrictRate = createAsyncThunk(
  "createDistrictRate",
  async ({ data }: { data: IDistrictRateForm }, { rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/district-rate/`, data);
      if (res.data) toast.success(RateSuccess("created"));
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();

      return rejectWithValue(appError.getError());
    }
  }
);
export const getDistrictRateById = createAsyncThunk(
  "getdistrictRateById",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/district-rate/${id}/`);
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();

      return rejectWithValue(appError.getError());
    }
  }
);
