import { MainLayout } from "@ims/common";
import React from "react";
import { useParams } from "react-router-dom";
import Tab from "../../../components/tab/Tab";
import useTranslated from "../../../hooks/useTranslated";
import GeneralWorkSlice from "../../../rtk/feature/general-work/generalWorkSlice";
import { setActiveTab, tabEnum } from "../../../rtk/feature/plan-management/planManagementSlice";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { StyledTabHeader } from "../PlanPage";
import ElectrificationWork from "./electrification-work/ElectrificationWork";
import EstimateTable from "./EstimateTable";
import GeneralWork from "./general-work/generalWork";
import SanitoryWork from "./sanitory-work/SanitoryWork";
import { isEditable } from "@testing-library/user-event/dist/utils";
import FinalEsitmation from "./components/final-estimation/FinalEstimation";
interface IProps {
  budgetId: string;
  isAddable: boolean;
}
const DetailesEstimateContainer = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { tab } = useParams();
  const handleTabSelect = (value: tabEnum) => {
    dispatch(setActiveTab(value));
  };

  const activeTab = useAppSelector((state) => state.planManagement.activeTab);
  const { keyword } = useTranslated();
  return (
    <>
      <Tab>
        <StyledTabHeader>
          <Tab.TabHeadButton
            id="general-work"
            active={activeTab === tabEnum.generalWork}
            onSelect={() => handleTabSelect(tabEnum.generalWork)}
          >
            {keyword["General Works"]}
          </Tab.TabHeadButton>
          <Tab.TabHeadButton
            id="construction-works"
            active={activeTab === tabEnum.constructionWork}
            onSelect={() => handleTabSelect(tabEnum.constructionWork)}
          >
            {keyword["Construction works"]}
          </Tab.TabHeadButton>

          <Tab.TabHeadButton
            id="sanitory-work"
            active={activeTab === tabEnum.sanitoryWork}
            onSelect={() => handleTabSelect(tabEnum.sanitoryWork)}
          >
            {keyword["Sanitary Work"]}
          </Tab.TabHeadButton>
          {/* <Tab.TabHeadButton id="cost-estimate" active={tab === "cost-estimate"} disabled={!budgetID}>
              Cost Estimation
            </Tab.TabHeadButton> */}
          <Tab.TabHeadButton
            id="electrification-work"
            active={activeTab === tabEnum.electrificationWork}
            onSelect={() => handleTabSelect(tabEnum.electrificationWork)}
          >
            {keyword["Electrification Work"]}
          </Tab.TabHeadButton>
          <Tab.TabHeadButton
            id="final-estimation"
            active={activeTab === tabEnum.finalEstimation}
            onSelect={() => handleTabSelect(tabEnum.finalEstimation)}
          >
            {keyword["Final Estimation"]}
          </Tab.TabHeadButton>
        </StyledTabHeader>
        <Tab.TabBody>
          <Tab.TabPane targetId="construction-works">
            <EstimateTable budgetID={props.budgetId} isAddable={props.isAddable} />
          </Tab.TabPane>
          <Tab.TabPane targetId="general-work">
            <GeneralWork budgetId={props.budgetId} isAddable={props.isAddable} />
          </Tab.TabPane>
          <Tab.TabPane targetId="sanitory-work">
            <SanitoryWork budgetId={props.budgetId} isAddable={props.isAddable} />
          </Tab.TabPane>
          <Tab.TabPane targetId="electrification-work">
            <ElectrificationWork budgetId={props.budgetId} isAddable={props.isAddable} />
          </Tab.TabPane>
          <Tab.TabPane targetId="final-estimation">
            <FinalEsitmation budgetId={props.budgetId} />
          </Tab.TabPane>
        </Tab.TabBody>
      </Tab>
    </>
  );
};

export default DetailesEstimateContainer;
