import { createSlice } from "@reduxjs/toolkit";
import {
  addYojanaType,
  deleteYojanaType,
  getYojanaType,
  getYojanaTypes,
  updateYojanaType,
} from "./yojanaTypeApi";

export interface YojanaTypeFormTypeI {
  name: string;
}

export interface YojanaTypeTypeI {
  id: string;
  name: string;
}

export interface YojanaTypesSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: YojanaTypeTypeI[];
  } | null;
  item: YojanaTypeTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: YojanaTypesSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const yojanaTypesSlice = createSlice({
  name: "yojanaTypes",
  initialState,
  reducers: {
    yojanaTypesCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addYojanaType.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addYojanaType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addYojanaType.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getYojanaTypes.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getYojanaTypes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getYojanaTypes.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getYojanaType.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getYojanaType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getYojanaType.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateYojanaType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateYojanaType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateYojanaType.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteYojanaType.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteYojanaType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteYojanaType.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { yojanaTypesCleanUp } = yojanaTypesSlice.actions;
export default yojanaTypesSlice.reducer;
