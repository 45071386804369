import { createSlice } from "@reduxjs/toolkit";
import municipalityBudgetSlice from "../municipality-budget/municipalityBudgetSlice";
import { getMunicipalityInformation } from "./municipalityInformationAPI";
export interface IFiscalYear {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
  status: boolean;
}
export interface IMunicipalityInformation {
  id: string;
  name: string;
  nepali_name: null | string;
  estd_date: null | string;
  reg_no: null | string;
  no_of_wards: null | string;
  office_address: null | string;
  website: string;
  phone_number: null | string;
  contact: null | string;
  email: null | string;
  logo: string | null;
  vat_percent: number;
  contengency_rate: number;
  pan_bill_TDS_rate: number;
  social_sec_ax_rate: number;
  municipal_JCB_rental: number;

  machine_rental_tax: number;
  fiscal_year: IFiscalYear;
}

interface IMunicipalityInformationSlice {
  loading: boolean;
  data: IMunicipalityInformation | null;
  error: any;
}
const initialData: IMunicipalityInformationSlice = {
  data: null,
  error: null,
  loading: false,
};

const municipalityInformationSlice = createSlice({
  name: "municipalityInformation",
  initialState: initialData,
  reducers: {
    cleanUpmunicipalityData: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getMunicipalityInformation.pending, (state, {}) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMunicipalityInformation.rejected, (state, {}) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getMunicipalityInformation.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.error = null;
    });
  },
});
export const { cleanUpmunicipalityData } = municipalityInformationSlice.actions;
export default municipalityInformationSlice.reducer;
