import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getYojanaTypes } from "../../rtk/feature/yojana-type/yojanaTypeApi";
import { YojanaTypeTypeI } from "../../rtk/feature/yojana-type/yojanaTypeSlice";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import Page from "../../core-ui/Page";
import { Button } from "@ims/common";
import useTranslated from "../../hooks/useTranslated";

function YojanaTypeManagePage() {
  const [yojanaTypes, setYojanaTypes] = useState<YojanaTypeTypeI[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const yojanaTypesState = useAppSelector((state) => state.yojanaTypes);

  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getYojanaTypes({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    dispatch(showLoading(yojanaTypesState.loading));
  }, [dispatch, yojanaTypesState.loading]);

  useEffect(() => {
    if (yojanaTypesState.data) {
      setYojanaTypes(yojanaTypesState.data.results);
      setTotalItems(yojanaTypesState.data.total);
    }
  }, [yojanaTypesState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/budget/yojana-type/edit/${id}`);
  };
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>{keyword.Yojana}Types Manage</Page.Title>
        <Button variant="primary" onClick={() => navigate(`/budget/yojana-type/add`)}>
          Add {keyword.Yojana}Type
        </Button>
      </Page.Container>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>{keyword.Yojana}Type</Table.HeadCol>
              <Table.HeadCol align="center">Action</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {yojanaTypes &&
              yojanaTypes.length > 0 &&
              yojanaTypes.map((yojanaType, index) => {
                return (
                  <Table.Row key={yojanaType.id}>
                    <Table.Col>{getSN(index)}</Table.Col>
                    <Table.Col>{yojanaType.name}</Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(yojanaType.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default YojanaTypeManagePage;
