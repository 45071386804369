import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import {
  addConstructorMember,
  getConstructorMember,
  updateConstructorMember,
} from "../../rtk/feature/constructor-member/constructorMemberApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ConstructorMemberFormTypeI,
  constructorMembersCleanUp,
} from "../../rtk/feature/constructor-member/constructorMemberSlice";
import ReuseForm from "../../core-ui/ReuseForm";
import { getBudgets } from "../../rtk/feature/budget/budgetApi";
import Page from "../../core-ui/Page";
import useTranslated from "../../hooks/useTranslated";

export interface ConstructorMemberFormTypeII extends ConstructorMemberFormTypeI {
  sex: any;
}

export const sexOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Others", value: "others" },
];

function ConstructorMemberFormPage() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<ConstructorMemberFormTypeII>({
    defaultValues: {
      name: "",
    },
  });

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const constructorMembersSelector = useAppSelector((state) => state.constructorMembers);
  const budgetsState = useAppSelector((state) => state.budgets);
  const [editMode, setEditMode] = useState(false);

  const { data: budgetList } = budgetsState;

  useEffect(() => {
    dispatch(getBudgets());
    return () => {
      dispatch(constructorMembersCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getConstructorMember(params.id));
      setEditMode(true);
    } else {
      dispatch(constructorMembersCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { item } = constructorMembersSelector;

  useEffect(() => {
    if (item !== null) {
      setValue("name", item.name);
      setValue("designation", item.designation);

      setValue("mobile_number", item.mobile_number);
      setValue("contact_number", item.contact_number);
      setValue("address", item.address);
      setValue("citizenship_number", item.citizenship_number);
      setValue("district", item.district);
      setValue("father", item.father);
      setValue("husband", item.husband);

      const sex = sexOptions.find((sex) => sex.value === item.sex);
      if (sex) setValue("sex", sex);
    } else {
      reset();
    }
  }, [item, setValue, reset, budgetList]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = constructorMembersSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/budget/constructor/member/list");
    }
  }, [constructorMembersSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<ConstructorMemberFormTypeII> = (data) => {
    data.sex = data.sex.value;

    if (editMode && params.id) {
      const newData = { ...data };
      dispatch(updateConstructorMember({ data: newData, id: params.id }));
    } else {
      const newData = { ...data };
      dispatch(addConstructorMember(newData));
    }
  };

  const handleCancel = () => {
    navigate("/budget/constructor/member/list");
  };

  const { keyword } = useTranslated();
  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>{editMode ? keyword["Update Contructor Member"] : keyword["Add Constructor Member"]} </Page.Title>
      </Page.Container>

      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword.Name}
                name="name"
                placeholder={keyword["Enter member name"]}
                rules={{ required: keyword["This is required field."] }}
                error={errors["name"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword.Designation}
                name="designation"
                placeholder={keyword["Enter designation"]}
                error={errors["designation"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.ReactSelect
                label={keyword.Sex}
                name="sex"
                options={sexOptions}
                placeholder={keyword["Select Gender"]}
                rules={{ required: "Gender is required." }}
                error={errors["sex"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Phone Number"]}
                name="mobile_number"
                placeholder={keyword["Enter mobile number"]}
                error={errors["mobile_number"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Contact Number"]}
                name="contact_number"
                placeholder={keyword["Enter contact number"]}
                error={errors["contact_number"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword.Address}
                name="address"
                placeholder={keyword["Enter address"]}
                error={errors["address"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Citizenship Number"]}
                name="citizenship_number"
                placeholder={keyword["Enter citizenship number"]}
                error={errors["citizenship_number"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword.District}
                name="district"
                placeholder={keyword["Enter district name"]}
                error={errors["district"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Father/Mother Name"]}
                name="father"
                placeholder={keyword["Enter father name"]}
                error={errors["father"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Husband/Wife"]}
                name="husband"
                placeholder={keyword["Enter husband/wife name"]}
                error={errors["husband"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword.Update : keyword["Create Constructor Member"]}
              </Button>
              <Button onClick={handleCancel}> {keyword.Cancel} </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
}

export default ConstructorMemberFormPage;
