import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { toast } from "react-toastify";
import { getBudget } from "../budget/budgetApi";
import { RelativeDocsSuccess } from "../../../utils/res-mes/response-message-generator";

const relativeDocsUrl = (budgetID: string, id = ""): string => {
  return id ? `/yojana/${budgetID}/related-docs/${id}/` : `/yojana/${budgetID}/related-docs/`;
};

export const getRelativeDocs = createAsyncThunk(
  "getRelativeDocs",
  async (
    planbriefParams: { per_page: number; page: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/yojana/", {
        params: planbriefParams,
        signal: planbriefParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getRelativeDoc = createAsyncThunk("getRelativeDoc", async (budgetID: string) => {
  try {
    const res = await imsClient.get(relativeDocsUrl(budgetID));
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    // appError.show();
  }
});

export const addRelativeDocs = createAsyncThunk(
  "addRelativeDoc",
  async (data: { data: FormData; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(relativeDocsUrl(data.budgetID), data.data, {
        headers: { "Content-Type": "application/json" },
      });
      if (res.data) toast.success(RelativeDocsSuccess("added"));
      dispatch(getBudget(data.budgetID));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateRelativeDoc = createAsyncThunk(
  "updateRelativeDoc",
  async ({ data, id, budgetID }: { data: FormData; id: string; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(relativeDocsUrl(budgetID, id), data, {
        headers: { "Content-Type": "application/json" },
      });
      if (res.data) toast.success(RelativeDocsSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const removeRelativeDoc = createAsyncThunk(
  "removeRelativeDoc",
  async ({ id, budgetID }: { id: string; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.delete(relativeDocsUrl(budgetID, id));
      if (res.data) toast.success(RelativeDocsSuccess("deleted"));
      // return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
