import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import constructorMemberSlice from "../constructor-member/constructorMemberSlice";
import useTranslated from "../../../hooks/useTranslated";
import { VerifySuccess, YojanaSuccess } from "../../../utils/res-mes/response-message-generator";
interface IVerifyPlanFormSubmission {
  id: string;
  data: { user: string; description: string };
}
interface IVerifyPlanFormUpdate {
  id: string;
  yojanaId: string;
  data: {
    description: string;
    is_verified: boolean;
  };
}

export const submitPlanVerification = createAsyncThunk(
  "postPlanVerification",
  async (data: IVerifyPlanFormSubmission, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/yojana/${data.id}/verification/`, data.data);
      toast.success(res.data.message);
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);

export const getPlanVerifications = createAsyncThunk(
  "getPlanVerification",
  async (data: { id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/yojana/${data.id}/verification/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updatePlanVerification = createAsyncThunk(
  "updatePlanVerification",
  async (data: IVerifyPlanFormUpdate, { rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/verification/${data.yojanaId}/`, data.data);
      if (res) toast.success(VerifySuccess("resentVerification"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);

export const getAllVerifications = createAsyncThunk(
  "getAllPendingVerifications",
  async (
    userParams: { per_page?: number; page?: number; search?: any; ordering?: any; signal?: AbortSignal } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("verification/", {
        params: userParams,
        signal: userParams?.signal,
      });

      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);
export const getAllVerifiedVerifications = createAsyncThunk(
  "getAllVerifiedVerifications",
  async (_, { rejectWithValue }) => {
    try {
      const res = await imsClient.get("verification/?status=verified&is_started=true");

      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);
export const getVerificationById = createAsyncThunk(
  "getVerificationById",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`verification/${id}/`);

      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);
interface IUpdatereceivedPlan {
  id: string;
  data: {
    is_verified: boolean;
    comment?: string;
    is_started?: boolean;
  };
}

export const updateReceivedPlan = createAsyncThunk(
  "updateReceivedPlan",
  async ({ id, data }: IUpdatereceivedPlan, { rejectWithValue }) => {
    const isApproved = data.is_verified;
    const isYojanaStarted = data.is_started;

    try {
      const res = await imsClient.patch(`verification/${id}/`, data);
      const message = isYojanaStarted
        ? YojanaSuccess("started")
        : !isApproved
        ? YojanaSuccess("discarded")
        : YojanaSuccess("approved");
      toast.success(message);
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      toast.error(err.response.data.message);
      return rejectWithValue(appError.getError());
    }
  }
);
