import { createSlice } from "@reduxjs/toolkit";
import { getStartYojana } from "./startYojanaApi";

export interface StartYojanaI {
  id: string;
  yojana: string;
  yojana_id: string;
  status: string;
  ward: string;
  ward_no: number;
}

interface responseI {
  current_page: number;
  total: number;

  per_page: number;
  total_pages: number;
  results: StartYojanaI[];
}

export interface StartYojanaSliceStateI {
  loading: boolean;
  data: responseI;

  hasSubmitted: boolean;
  error: any;
}

const initialData: responseI = {
  current_page: 0,
  total: 0,
  per_page: 0,
  total_pages: 0,
  results: [],
};

const initialState: StartYojanaSliceStateI = {
  loading: false,
  data: initialData,
  hasSubmitted: false,
  error: null,
};

const startYojanaSlice = createSlice({
  name: "startyojana",
  initialState,
  reducers: {
    StartYojanaCleanUp: (state) => {
      state.data = initialData;
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStartYojana.pending, (state) => {
      state.loading = true;
      state.data = initialData;
      state.error = null;
    });
    builder.addCase(getStartYojana.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getStartYojana.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = initialData;
      state.error = true;
    });
  },
});

export const { StartYojanaCleanUp } = startYojanaSlice.actions;
export default startYojanaSlice.reducer;
