import { Button, Form, Grid } from "@ims/common";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Auth from "../../components/auth/Auth";
import { login, verifyToken } from "../../rtk/feature/auth/authApi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { handleAccountVerification } from "../../utils/imsClient";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import useTranslated from "../../hooks/useTranslated";
import i18n from "../../utils/i18n";
import AuthLayout from "./AuthLayout";

type LoginFormTypeII = {
  username_or_phone: string;
  password: string;
  remember_me: boolean;
};

const LoginPage = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormTypeII>();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const authSelector = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const { keyword } = useTranslated();

  const [searchParams] = useSearchParams();

  const { loading, authenticated, nagarpalika } = authSelector;

  useEffect(() => {
    if (authenticated) {
      if (location.state?.prev) navigate(location.state.prev);
      else navigate("/");
    }
    dispatch(showLoading(loading));
  }, [authenticated, loading, dispatch, navigate, location]);

  const onSubmit: SubmitHandler<LoginFormTypeII> = (data) => {
    const lang = localStorage.getItem("lng");
    !lang && localStorage.setItem("lng", "en");
    dispatch(login(data));
  };

  useEffect(() => {
    const accessToken = searchParams.get("access_token");
    if (!accessToken) return;

    localStorage.setItem("access", accessToken);
    handleAccountVerification();
  }, [searchParams]);

  return (
    <>
      <AuthLayout>
        <div style={{ margin: "auto" }}>
          <h2>{keyword["Login to Yojana"]}</h2>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <Grid.Row gap={1.5}>
                <Grid.Col spanXs={12}>
                  <Form.FormGroup error={Boolean(errors && errors["username_or_phone"])}>
                    <Form.FloatingInput
                      label={keyword["Username or Phone"]}
                      type="text"
                      // placeholder={keyword["Enter your username/Phone"]}
                      register={register("username_or_phone", {
                        required: keyword["Your username or phone is required."],
                      })}
                    />
                    {/* <Form.Label>{keyword["Username or Phone"]}</Form.Label>
                    <Form.Input
                      type="text"
                      placeholder={keyword["Enter your username/Phone"]}
                      register={register("username_or_phone", {
                        required: keyword["Your username or phone is required."],
                      })}
                    /> */}
                    {errors && errors["username_or_phone"] && errors["username_or_phone"].message && (
                      <Form.HelperText>{errors["username_or_phone"].message}</Form.HelperText>
                    )}
                  </Form.FormGroup>
                </Grid.Col>

                <Grid.Col spanXs={12}>
                  <Form.FormGroup error={Boolean(errors && errors["password"])}>
                    <Form.FloatingInput
                      label={keyword.Password}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      register={register("password", {
                        required: keyword["Password is required."],
                      })}
                    />
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Form.Input
                        type={showPassword ? "text" : "password"}
                        placeholder={keyword["Enter your password"]}
                        name="password"
                        register={register("password", {
                          required: keyword["Password is required."],
                        })}
                      /> */}
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ position: "absolute", right: "28px", bottom: "6px", cursor: "pointer" }}
                      >
                        {showPassword ? <FaEyeSlash color="#767676" /> : <FaEye color="#767676" />}
                      </span>
                    </div>
                    {errors && errors["password"] && errors["password"].message && (
                      <Form.HelperText>{errors["password"].message}</Form.HelperText>
                    )}
                  </Form.FormGroup>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {" "}
                    <Form.FormGroup hasCheck>
                      <Form.Input
                        type="checkbox"
                        width="1rem"
                        defaultChecked
                        name="remember_me"
                        id="remember_me"
                        register={register("remember_me")}
                      />
                      <Form.Label style={{ width: "100%" }} htmlFor="remember_me">
                        {keyword["Remember Me"]}
                      </Form.Label>
                    </Form.FormGroup>
                    <Form.Link to="/auth/forgot-password">{keyword["Forgot your password?"]}</Form.Link>
                  </div>
                </Grid.Col>

                <Grid.Col spanMd={12} spanXs={12}>
                  <Button type="submit" variant="auth">
                    {keyword.Login}
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </AuthLayout>
    </>
  );
};

export default LoginPage;
