import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { EquipmentFormTypeI } from "./equipmentSlice";
import { EquipmentSuccess } from "../../../utils/res-mes/response-message-generator";

export const getEquipments = createAsyncThunk(
  "getEquipments",
  async (
    equipmentParams:
      | { per_page: number; type?: string; page: number; search?: string; signal?: AbortSignal }
      | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/equipment-management/", {
        params: equipmentParams,
        signal: equipmentParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getEquipment = createAsyncThunk("getEquipment", async (id: string) => {
  try {
    const res = await imsClient.get(`/equipment-management/${id}/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
  }
});

export const addEquipment = createAsyncThunk(
  "addEquipment",
  async (data: EquipmentFormTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post("/equipment-management/", data);
      if (res.data) toast.success(EquipmentSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateEquipment = createAsyncThunk(
  "updateEquipment",
  async (data: { data: EquipmentFormTypeI | any; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/equipment-management/${data.id}/`, data.data);
      if (res.data) toast.success(EquipmentSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
