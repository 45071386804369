import styled from "styled-components/macro";

export const StyledWrapper = styled.div`
  margin: 2rem;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
`;

export const StyledUserProfile = styled.div`
  min-width: 240px;
`;

export const StyledCard = styled.div`
  padding: 2rem 0rem;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(41, 48, 66, 0.05) 0px 0px 0.875rem 0px;
  border-radius: 0.4rem;
`;

export const StyledAvatar = styled.img`
  border-radius: 50%;
  border: 1px solid #ccc;
  width: 100px;
  height: 100px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  object-fit: cover;
`;

export const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #ccc;
  margin-top: 1rem;
`;

export const StyledButton = styled.button`
  text-align: left;
  padding: 1rem 2rem;
  border: none;
  border-bottom: 1px solid #d5d5d5;
  background: white;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.4rem;

  &:hover {
    color: white;
    background: ${(props) => props.theme.palette.primary.main};
  }
`;

export const StyledTitle = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
`;

export const StyledPostTitle = styled.h5`
  font-weight: 500;
  color: ${(props) => props.theme.palette.primary.main};
  text-transform: uppercase;
`;

export const StyledTab = styled.div`
  border-radius: 4px;
  width: 100%;
`;

export const StyledTabHeader = styled.div`
  background-color: white;
  border-bottom: 3px solid #ccc;
`;

export const StyledTabHeadButton = styled.button<{ disabled?: boolean }>`
  padding: 1rem 2rem;
  position: relative;
  background: transparent;
  margin: 0px;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0px;
  font: inherit;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0px;
  }

  &:hover,
  &.active {
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:hover::after,
  &.active::after {
    background: ${(props) => props.theme.palette.primary.main};
  }

  filter: ${(props) => (props.disabled ? "grayscale(1)" : "grayscale(0)")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const StyledTabBody = styled.div`
  padding: 2rem;
  background: white;

  box-shadow: rgba(41, 48, 66, 0.05) 0px 0px 0.875rem 0px;
`;

export const StyledTabPane = styled.div`
  table {
    width: 100%;
    font-size: 1.5rem;
    overflow: auto;

    tr td:first-child {
      font-weight: 500;
      width: 180px;
    }

    td {
      padding: 0.5rem 0rem;
      font-weight: 300;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;
