import PreviewYojanaBooksDetails from "./components/PreviewYojanaBooksDetails";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { YojanaBookTypeI, YojanaBooksCleanUp } from "../../../rtk/feature/yojana-books/yojanaBooksSlice";
import { getYojanaBook, removeYojanaBook } from "../../../rtk/feature/yojana-books/yojanaBooksApi";
import { showLoading } from "../../../rtk/feature/auth/authSlice";

interface YojanaBooksI {
  budgetID: string;
  isVerified: boolean;
}

export default function YojanaBooks({ isVerified, budgetID }: YojanaBooksI) {
  const [yojanaBooks, setYojanaBooks] = useState<YojanaBookTypeI[]>([
    {
      id: "",
      title: "",
      content: "",
      order: 0,
      pdf_file: "",
      pdf_file_url: "",
    },
  ]);

  const dispatch = useAppDispatch();
  const yojanaBooksSelector = useAppSelector((state) => state.yojanaBooks);
  const { data: yojanaBooksData } = yojanaBooksSelector;

  useEffect(() => {
    if (budgetID) {
      dispatch(getYojanaBook(budgetID));
    }
  }, []);

  useEffect(() => {
    setYojanaBooks(yojanaBooksData);
  }, [yojanaBooksData]);

  useEffect(() => {
    dispatch(showLoading(yojanaBooksSelector.loading));
  }, [dispatch, yojanaBooksSelector.loading]);

  useEffect(() => {
    if (budgetID) {
      dispatch(YojanaBooksCleanUp());
      dispatch(getYojanaBook(budgetID));
    } else {
      dispatch(YojanaBooksCleanUp());
    }
  }, [budgetID, dispatch]);

  const handleRemoveYojanaBook = (yojanaBookId: string) => {
    if (yojanaBookId && budgetID) {
      dispatch(removeYojanaBook({ id: yojanaBookId, budgetID }));
      setYojanaBooks((prevState) => prevState.filter((data) => data.id !== yojanaBookId));
    }
  };

  return (
    <>
      <PreviewYojanaBooksDetails
        budgetId={budgetID}
        handleRemoveYojanaBook={handleRemoveYojanaBook}
        yojanaBooks={yojanaBooks}
        isVerified={isVerified}
      />
    </>
  );
}
