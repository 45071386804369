import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { TransportationTypeFormTypeI } from "./transportationTypeSlice";
import { TransportationTypeSuccess } from "../../../utils/res-mes/response-message-generator";

export const getTransportationTypes = createAsyncThunk(
  "getTransportationTypes",
  async (
    userParams: { per_page: number; page: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/transportation-type/", {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getTransportationType = createAsyncThunk(
  "getTransportationType",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/transportation-type/${id}/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addTransportationType = createAsyncThunk(
  "addTransportationType",
  async (data: TransportationTypeFormTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post("/transportation-type/", data);
      if (res.data) toast.success(TransportationTypeSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteTransportationType = createAsyncThunk(
  "deleteTransportationType",
  async (deleteId: string, { dispatch, rejectWithValue }) => {
    try {
      await imsClient.delete(`/transportation-type/${deleteId}/`);
      dispatch(getTransportationTypes());
      toast.success(TransportationTypeSuccess("deleted"));
      return deleteId;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateTransportationType = createAsyncThunk(
  "updateTransportationType",
  async (data: { data: TransportationTypeFormTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/transportation-type/${data.id}/`, data.data);
      if (res.data) toast.success(TransportationTypeSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
