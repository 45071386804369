import { createSlice } from "@reduxjs/toolkit";
import { INetworkRResponse } from "../../../interfaces/networkResponse.interface";
import { IGeneralWorkData } from "../general-work/generalWorkSlice";
import { getWorkReports } from "./workReportAPI";

export interface EstimateDataI {
  grand_total: number;
  sub_total: number;
  with_vat: number;
  with_contengencies: number;
}

export interface IData {
  electrification_work: IGeneralWorkData[];
  sanitary_work: IGeneralWorkData[];
  general_work: IGeneralWorkData[];
  construction_work: IGeneralWorkData[];
  yojana_name: string;
  fiscal_year: {
    id: string;
    name: string;
    start_date: string;
    end_date: string;
    status: boolean;
  };
  total: EstimateDataI;

  final_analysis: EstimateDataI;
  difference_amount: EstimateDataI;
}

interface IWorkReport extends INetworkRResponse {
  data: IData | null;
}

const initialState: IWorkReport = {
  data: null,
  error: null,
  loading: false,
};

export const workReportSlice = createSlice({
  name: "work-report",
  initialState,
  reducers: {
    cleanupWOrkReport: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getWorkReports.pending, (state) => {
      state.data = null;
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getWorkReports.rejected, (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getWorkReports.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.error = null;
    });
  },
});
export const { cleanupWOrkReport } = workReportSlice.actions;
export default workReportSlice.reducer;
