import { createSlice } from "@reduxjs/toolkit";
import { addAdminMinutes, getAdminMinutes, updateAdminMinutes } from "./adminMinuteApi";

export interface AdminMinuteI {
  id?: string;
  comments: string;
  file_url: string;
}

export interface AdminMinuteStateI {
  loading: boolean;
  data: AdminMinuteI[] | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: AdminMinuteStateI = {
  loading: false,
  data: null,
  hasSubmitted: false,
  error: null,
};

const adminMinuteSlice = createSlice({
  name: "adminMinute",
  initialState,
  reducers: {
    adminMinuteCleanUp: (state) => {
      state.loading = false;
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addAdminMinutes.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addAdminMinutes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addAdminMinutes.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(updateAdminMinutes.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(updateAdminMinutes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(updateAdminMinutes.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getAdminMinutes.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getAdminMinutes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getAdminMinutes.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
  },
});

export const { adminMinuteCleanUp } = adminMinuteSlice.actions;
export default adminMinuteSlice.reducer;
