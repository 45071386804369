export default function getFormattedSort(sorts: any) {
  let ordering = "";

  for (let key in sorts) {
    if (sorts[key].active) {
      if (sorts[key].asc) {
        ordering += key;
      } else {
        ordering += "-" + key;
      }
      ordering += ",";
    }
  }

  if (ordering.endsWith(",")) {
    ordering = ordering.substring(0, ordering.length - 1);
  }

  return ordering;
}
