import { DefaultTheme } from "./defaultTheme";

export const theme: DefaultTheme = {
  colors: {
    primary: "#FF9F43",
    secondary: "#1B2850",
  },
  font: {
    primary: "#637381",
    secondary: "#212b36",
  },
  palette: {
    primary: {
      main: "#0072c6",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff0000",
    },
    warning: {
      main: "#ed6c02",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      contrastText: "#fff",
    },
    info: {
      main: "#0288d1",
      contrastText: "#fff",
    },
    error: {
      main: "#d32f2f",
      contrastText: "#fff",
    },
    background: {
      // paper: "#e5e5e5",
      paper: "#f7f9fc",
      default: "#ffffff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
  },
  breakpoints: {
    xs: "0px",
    sm: "600px",
    md: "900px",
    lg: "1200px",
    xl: "1440px",
    xxl: "1600px",
  },
};
