import { useEffect, useState } from "react";
import { IVerification } from "../../rtk/feature/plan-verification/planVerificationSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import useTranslated, { TranslateKeyTypes } from "../../hooks/useTranslated";
import { getAllVerifications } from "../../rtk/feature/plan-verification/planVerificationAPI";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { Button, Grid, MainLayout, Table } from "@ims/common";
import Page from "../../core-ui/Page";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { styleSN } from "../../utils/Prettify";
import { FiEye, FiSearch } from "react-icons/fi";
import { MdVerified } from "react-icons/md";
import { GoUnverified } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { roles } from "../../constants/constants";
import { useForm } from "react-hook-form";
import { realTimeFilter } from "../../utils/real-time-filter";
import ReuseForm from "../../core-ui/ReuseForm";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import getFormattedSort from "../../utils/getFormattedSort";

const AllowedRoles = [roles.ADMIN];

const StartedYojana = () => {
  const [startedYojana, setStartedYojana] = useState<IVerification[] | null>(null);
  const [perPage, setPerPage] = useState<number>(100);
  const [sorts, setSorts] = useState<SortsTypeI>({});
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();
  const navigate = useNavigate();
  const myRole = useAppSelector((state) => state.auth.user?.role);
  // const isView = !!myRole ? AllowedRoles.includes(myRole) : false;

  const verificationYojanaSelector = useAppSelector((state) => state.sendPlanVerification);

  const columns: TranslateKeyTypes[] = ["S.N.", "Name", "Source", "Status", "Action"];

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    formState: { errors: filterErrors },
    reset: filterReset,
    watch: filterWatch,
  } = useForm();

  useEffect(() => {
    dispatch(getAllVerifications());
  }, [dispatch]);

  useEffect(() => {
    dispatch(showLoading(verificationYojanaSelector.getAllData.loading));
  }, [verificationYojanaSelector.getAllData.loading]);

  const verifiedYojana = verificationYojanaSelector?.getAllData?.data
    ? verificationYojanaSelector?.getAllData?.data.filter((yoj) => yoj.is_started === true)
    : [];

  useEffect(() => {
    if (verifiedYojana.length) setStartedYojana(verifiedYojana);
  }, [verificationYojanaSelector.getAllData.data, verifiedYojana]);

  const viewStartedYojanaHandler = (id: string) => {
    navigate(`/started-yojana-detail/${id}`);
  };

  const onFilterSubmit = (data: any) => {
    dispatch(
      getAllVerifications({
        per_page: perPage,
        search: data.search,
        ordering: getFormattedSort(sorts),
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getAllVerifications({
        per_page: perPage,
        ordering: getFormattedSort(sorts),
      })
    );
    filterReset();
  };

  return (
    <>
      <MainLayout.Main>
        <Page.Container>
          <Page.Title> {keyword["Started Yojana"]} </Page.Title>
        </Page.Container>
        <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
          <Grid style={{ marginBottom: "1rem" }}>
            <Grid.Row gap={0.75}>
              <Grid.Col spanMd={4}>
                <ReuseForm.Input
                  label={keyword["Search"]}
                  name="search"
                  placeholder={keyword["Search here"]}
                  rules={{ required: false }}
                  register={filterRegister("search")}
                  error={filterErrors["search"]}
                  h="34px"
                />
              </Grid.Col>

              <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
                <Button variant="primary">
                  {" "}
                  <FiSearch />
                </Button>
                <Button type="reset" onClick={handleFilterReset}>
                  {keyword["Reset"]}
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </ReuseForm>
        <Table.Container>
          <Table>
            <Table.Head>
              <Table.Row>
                {columns.map((column) => {
                  return <Table.HeadCol>{keyword[column]}</Table.HeadCol>;
                })}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {startedYojana?.length
                ? startedYojana.map((sy, idx) => (
                    <Table.Row key={idx}>
                      <Table.Col>{englishNepaliNumberTranslation(styleSN(idx + 1))}</Table.Col>
                      <Table.Col>{sy.yojana.name}</Table.Col>
                      <Table.Col>{sy.yojana.source}</Table.Col>
                      <Table.Col>
                        <span style={{ marginLeft: "25%" }}>
                          {sy.status === "verified" ? (
                            <MdVerified color="blue" size={20} title={keyword["Verified"]} />
                          ) : (
                            <GoUnverified color="#888" size={20} title={keyword["Not Verified"]} />
                          )}
                        </span>
                      </Table.Col>

                      <Table.Col>
                        <Table.ActionButton
                          onClick={() => {
                            viewStartedYojanaHandler(sy.yojana.id);
                          }}
                          style={{ marginLeft: "25%" }}
                        >
                          <FiEye color="rgb(141, 24, 24)" />
                        </Table.ActionButton>
                      </Table.Col>
                    </Table.Row>
                  ))
                : ""}
            </Table.Body>
          </Table>
        </Table.Container>
      </MainLayout.Main>
    </>
  );
};

export default StartedYojana;
