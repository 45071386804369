import { Button, Form, Grid, MainLayout } from "@ims/common";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Page from "../../core-ui/Page";
import ReuseForm from "../../core-ui/ReuseForm";
import useTranslated from "../../hooks/useTranslated";
import { IOptions } from "../../interfaces/options.interface";
import { getDistrictRate } from "../../rtk/feature/district-rate/districtRateApi";
import {
  getDorEquipmentHireRateById,
  postDorEquipmentHireRate,
  updateDorEquipmentHireRate,
} from "../../rtk/feature/dor-equipment-hire-rate/dorEquipmentHireRateApi";
import { cleanUpDorEquipmentSlice } from "../../rtk/feature/dor-equipment-hire-rate/dorEquipmentHireRateSlice";
import { getUnits } from "../../rtk/feature/unit/unitApi";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
interface IDorEquipmentForm {
  district_rate: IOptions;
  description: string;
  operator_allowance: string;
  unit: IOptions;
  fuel_consumption: number;
  dor_hire_rate: number;
}

export interface IPostDorEquipmentForm extends Omit<IDorEquipmentForm, "district_rate" | "unit"> {
  unit: string;
  district_rate: string;
}

const DOREquipmentHireRateForm = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const iseditMode = location.pathname.includes("/edit");

  const {
    control,
    setValue,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<IDorEquipmentForm>();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDistrictRate({ params: { per_page: 1000 } }));
    dispatch(getUnits());
  }, []);
  const districtRate = useAppSelector((state) => state.districtRate.data?.results);
  const options = districtRate?.map((rate, idx) => {
    return {
      label: rate.particular,
      value: rate.id,
    };
  });
  const units = useAppSelector((state) => state.units.data);
  const unitOptions = units?.results?.map((unit, idx) => {
    return {
      label: unit.name,
      value: unit.id,
    };
  });

  useEffect(() => {
    if (iseditMode && params.id) {
      dispatch(getDorEquipmentHireRateById({ id: params.id }));
    }
    return () => {
      dispatch(cleanUpDorEquipmentSlice());
    };
  }, []);
  const dorEquipmentHireRateById = useAppSelector((state) => state.dorEquipmentHireRate.byId.data);
  const formSubmitted = useAppSelector((state) => state.dorEquipmentHireRate.formSubmitted);
  useEffect(() => {
    if (formSubmitted) {
      navigate("/dor-equipment-hire-rate");
    }
    return () => {
      dispatch(cleanUpDorEquipmentSlice());
    };
  }, [formSubmitted]);

  useEffect(() => {
    if (dorEquipmentHireRateById && iseditMode) {
      const data = dorEquipmentHireRateById;
      setValue("description", data.description);
      setValue("district_rate", { label: data.district_rate.particular, value: data.district_rate.id });
      setValue("operator_allowance", data.operator_allowance);
      setValue("unit", { label: data.unit.name, value: data.unit.id });
      setValue("dor_hire_rate", data.dor_hire_rate);
      setValue("fuel_consumption", data.fuel_consumption);
    }
  }, [dorEquipmentHireRateById]);

  const onSubmit = (data: IDorEquipmentForm) => {
    const finalData = { ...data, unit: data.unit.value, district_rate: data.district_rate.value };
    if (iseditMode && params.id) {
      dispatch(updateDorEquipmentHireRate({ id: params.id, data: finalData }));
    } else {
      dispatch(postDorEquipmentHireRate(finalData));
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const { keyword } = useTranslated();
  return (
    <MainLayout.Main>
      {" "}
      <Page.Title> {keyword["DOR Equipment Hire Rate Form"]}</Page.Title>
      <ReuseForm control={control} onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["description"])}>
                <Form.Label> {keyword.Description} </Form.Label>

                <Form.Input
                  placeholder={keyword["Particular"]}
                  register={register("description", { required: "particular is necessary" })}
                  name="particular"
                />
                <Form.HelperText> {errors.description?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["dor_hire_rate"])}>
                <Form.Label> {keyword["Dor Hire Rate"]} </Form.Label>
                <Form.Input
                  type="number"
                  step="any"
                  placeholder={keyword["Analysis"] + keyword["Dor Hire Rate"]}
                  register={register("dor_hire_rate", { required: "analysis is required" })}
                  name="dor_hire_rate"
                />
                <Form.HelperText> {errors.dor_hire_rate?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>{" "}
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["district_rate"])}>
                <ReuseForm.ReactSelect
                  label={keyword["District Rate"]}
                  name="district_rate"
                  options={options}
                  placeholder={keyword["Select Rate"]}
                  rules={{ required: "Type is required." }}
                  error={errors["district_rate"]}
                />
                {/* <Form.HelperText  > {errors.unit?.message} </Form.HelperText> */}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["fuel_consumption"])}>
                <Form.Label> {keyword["Fuel Consumption (Ltr)"]} </Form.Label>

                <Form.Input
                  placeholder={keyword["Fuel Consumption"]}
                  register={register("fuel_consumption", { required: "fuel_consumption is required" })}
                  name="fuel_consumption"
                  type="number"
                />
                <Form.HelperText> {errors.fuel_consumption?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["operator_allowance"])}>
                <Form.Label> {keyword["Operator Allowance"]} </Form.Label>

                <Form.Input
                  placeholder={keyword["Operator Allowance"]}
                  register={register("operator_allowance")}
                  name="operator_allowance"
                />
                <Form.HelperText> {errors.operator_allowance?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["unit"])}>
                <ReuseForm.ReactSelect
                  label={keyword.Unit}
                  name="unit"
                  options={unitOptions}
                  placeholder={keyword["Select Type"]}
                  rules={{ required: "Unit is required." }}
                  error={errors["unit"]}
                />
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Button type="submit" variant="primary">
                {iseditMode ? keyword.Update : keyword.Add}
              </Button>
              <Button type="button" onClick={handleCancel}>
                {" "}
                {keyword.Cancel}{" "}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
};

export default DOREquipmentHireRateForm;
