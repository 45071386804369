import { Button, Form, Grid, MainLayout } from "@ims/common";
import Page from "../../core-ui/Page";
import ReuseForm from "../../core-ui/ReuseForm";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getBillPlanningById, updateBillPlanning } from "../../rtk/feature/plannning-bill/planningBillAPI";
import { useEffect } from "react";
import useTranslated from "../../hooks/useTranslated";

interface IPlanningBillForm {
  bill_file: FileList;
  bill_name: string;
}

const PlanningBillForm = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    setValue,
  } = useForm<IPlanningBillForm>();

  const { byId: dataById } = useAppSelector((state) => state.billPlanning);

  const onSubmit = async (data: IPlanningBillForm) => {
    const formData = new FormData();
    formData.append("bill_file", data.bill_file[0]);
    formData.append("bill_name", data.bill_name);
    if (id) {
      await dispatch(updateBillPlanning({ data: formData, id }));
      navigate("/planning-bill");
    }
  };

  useEffect(() => {
    if (dataById.data?.bill_name) {
      setValue("bill_name", dataById?.data.bill_name);
    }
  }, [dataById]);

  useEffect(() => {
    if (id) dispatch(getBillPlanningById(id));
  }, [id]);

  const handleCancel = () => {
    navigate("/planning-bill");
  };
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Title> {keyword["Pannning Bill"]}</Page.Title>
      <ReuseForm control={control} onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["bill_name"])}>
                <Form.Label> {keyword["Bill Name"]} </Form.Label>

                <Form.Input
                  placeholder="bill_name"
                  register={register("bill_name", { required: "description is necessary" })}
                  name="bil_name"
                />
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["bill_file"])}>
                <Form.Label>{keyword["Bill file"]} </Form.Label>

                <Controller
                  name="bill_file"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field: { onChange, value }, fieldState, formState }) => {
                    return (
                      <Form.File
                        placeholder="Bill file"
                        name="inspection_report"
                        id="inspection_report"
                        onChange={(e) => onChange(e.target.files)}
                      />
                    );
                  }}
                />

                <Form.HelperText> {errors.bill_file?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {keyword.Update}
              </Button>
              <Button type="button" onClick={handleCancel}>
                {keyword.Cancel}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
};

export default PlanningBillForm;
