import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient, imsSimpleClient } from "../../../utils/imsClient";
import { ChangePasswordFormTypeI, ForgotPasswordFormTypeI, LoginFormTypeI } from "./authSlice";
import { AuthSuccess } from "../../../utils/res-mes/response-message-generator";
import { generateErrorMessage } from "../../../utils/res-mes/response-message";

export const login = createAsyncThunk("login", async (data: LoginFormTypeI, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsSimpleClient.post("/login/token/", data);
    if (res.data) toast.success(AuthSuccess("login"));

    const credentials = res.data;
    credentials["remember_me"] = data.remember_me;
    return credentials;
  } catch (err: any) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const verifyToken = createAsyncThunk("verifyToken", async (_, { dispatch, rejectWithValue }) => {
  const access = localStorage.getItem("access");
  try {
    const res = await imsClient.post(`/token/verify/`, { token: access }).then((res) => {
      dispatch(userVerified());
      return res.data;
    });
    return res;
  } catch (err: any) {
    if (err?.response?.status === 401) dispatch(logout());
    return rejectWithValue(true);
  }
});

export const sendOTP = createAsyncThunk(
  "sendOTP",
  async (data: ForgotPasswordFormTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsSimpleClient.post("/user/send-otp/", data);
      toast.success(res.data.message);
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const changePasswordWithOTP = createAsyncThunk(
  "changePasswordWithOTP",
  async (data: ChangePasswordFormTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsSimpleClient.post("/user/forget-password/", data);
      toast.success(res.data.message);
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const userVerified = createAsyncThunk("userVerified", async () => {
  return true;
});

export const getNagarpalika = createAsyncThunk("getNagarpalika", async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsSimpleClient.get("/palika/");
    return res.data;
  } catch (err: any) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const getUserRoles = createAsyncThunk("getUserRoles", async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.get("/role/");
    return res.data.results;
  } catch (err: any) {
    const appError = new AppError(err);
    appError.show();

    return rejectWithValue(appError.getError());
  }
});

export const getWardList = createAsyncThunk("getWardList", async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.get("/ward/");
    return res.data.results;
  } catch (err: any) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const logout = createAsyncThunk("logout", async () => {
  try {
    await imsSimpleClient.get("/logout/");
    toast.success(AuthSuccess("logout"));
  } catch (err) {
    toast.error(generateErrorMessage());
  }
});

export const getActivityLog = createAsyncThunk(
  "getActivityLog",
  async (
    { userID, per_page, page }: { userID?: string; per_page?: number; page?: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/model-changes/", {
        params: {
          user: userID,
          per_page: per_page,
          page: page,
        },
      });
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
