import styled, { keyframes, css } from "styled-components";

export enum StatusColor {
  verifed = "blue",
  notVerified = "#888",
  correction = "#f1c40f",
}
export enum StatusColorBg {
  verifed = "#0000ffb3",
  notVerified = "#e74c3cb3",
  correction = "#f1c40fb3",
}

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
`;
/*
background: ${(props) =>
  props.variant === "verified"
    ? StatusColorBg.verifed
    : props.variant === "correction"
    ? StatusColorBg.correction
    : StatusColorBg.notVerified}; */

export const StyledStatus = styled.div<{ variant: "correction" | "verified" | "not-verifed" }>`
  cursor: pointer;

  display: flex;
  justify-content: center;
  border-radius: 6px;

  align-items: center;
  color: #fff;
  //   background: #ccc || rgb(0 39 68);
  animation: ${(props) =>
    props.variant === "correction"
      ? css`
          ${bounceAnimation} 2s infinite
        `
      : "none"};

  &:hover {
    animation-play-state: paused;
  }
`;
