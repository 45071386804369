import { Button, Flex, Table } from "@ims/common";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaEdit, FaTrash } from "react-icons/fa";
import { GiStarGate } from "react-icons/gi";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Page from "../../../../core-ui/Page";
import useDiscloure from "../../../../hooks/useDiscloure";
import useTranslated from "../../../../hooks/useTranslated";
import {
  deleteGeneralWork,
  getGeneralWorks,
  updateGeneralWork,
} from "../../../../rtk/feature/general-work/generalWorkApi";
import { IGeneralWorkData } from "../../../../rtk/feature/general-work/generalWorkSlice";
import { useAppDispatch, useAppSelector } from "../../../../rtk/hook";
import WorkTable from "../components/WorkTable/WorkTable";
import Dialog from "../Dialog/Dialog";
interface IProps {
  budgetId: string;
  tableData?: IGeneralWorkData[];
  isAddable?: boolean;
}
const GeneralWork = (props: IProps) => {
  const [activeDeleteItem, setActiveDeleteItem] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getGeneralWorks({ id: props.budgetId }));
    return () => setActiveDeleteItem("");
  }, []);
  const {
    data: generalWorks,
    error,
    loading,
    deleteSuccess,
    formSubmitted,
  } = useAppSelector((state) => state.generalWork);

  const columns = [
    "name",
    "length",
    "breadth",
    "height",
    "unit",
    "quantity",
    "remarks",
    "final measurement",
    "{keyword.Actions}",
  ];

  const deleteItem = (id: string) => {
    dispatch(deleteGeneralWork({ id: props.budgetId, workId: id }));
  };

  const handleEdit = (workId: string) => {
    navigate(`/plan-management/plan/edit/${props.budgetId}/${workId}`);
  };

  const onAddClick = () => {
    navigate(`/plan-management/plan/add/${props.budgetId}`);
  };
  const handleAddRate = (rate: number, workId: string) => {
    dispatch(updateGeneralWork({ data: { rate: rate }, id: props.budgetId, workId }));
  };
  const handleAddFinalQuantity = (finalQuantity: number, workId: string) => {
    dispatch(updateGeneralWork({ data: { final_quantity: finalQuantity }, id: props.budgetId, workId }));
  };
  const { keyword } = useTranslated();
  return (
    <>
      <WorkTable
        rateAddSuccess={formSubmitted}
        addUrl={`/plan-management/plan/add/${props.budgetId}`}
        data={generalWorks}
        deleteSuccess={deleteSuccess}
        editUrl="/"
        onAddClick={onAddClick}
        onEditClick={handleEdit}
        tableName={keyword["General Works"]}
        onDelete={deleteItem}
        onRateAdd={handleAddRate}
        // onFinalQuantityAdd={handleAddFinalQuantity}
        hideAddBtn={!props.isAddable}
      />
    </>
  );
};

export default GeneralWork;
