import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import Tab from "../../components/tab/Tab";
import styled from "styled-components";
import PlanSearch from "./PlanSearch";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { getBudgets } from "../../rtk/feature/budget/budgetApi";
import { roles } from "../../constants/constants";
import ProtectWithRole from "../../components/ProtectWithRole/ProtectWithRole";
import ReviewPlan from "./ReviewPlan/ReviewPlan";
import { getUsers } from "../../rtk/feature/users/usersApi";
import { getPlanVerifications } from "../../rtk/feature/plan-verification/planVerificationAPI";
import PlanDetail from "./PlanDetail";
import { budgetCleanUp } from "../../rtk/feature/budget/budgetSlice";
import StartYojanaList from "./StartYojanaList";

function PlanPage() {
  const dispatch = useAppDispatch();
  const activeBudgetId = useAppSelector((state) => state.planManagement.activeBudgetId);
  const [budgetID, setBudgetID] = useState<string | null>(null);

  const budgetSelector = useAppSelector((state) => state.budgets);
  const planBriefSelector = useAppSelector((state) => state.planBriefs);
  const planEstimateSelector = useAppSelector((state) => state.planEstimates);

  useEffect(() => {
    if (myRole === roles.ADMIN || myRole === roles.WARD_SACHIB) dispatch(getBudgets());
  }, [dispatch]);
  const myRole = useAppSelector((state) => state.auth.user?.role);

  useEffect(() => {
    if (myRole === roles.Prasasakiya_Adhikrit) return;
    dispatch(showLoading(budgetSelector.loading || planBriefSelector.loading || planEstimateSelector.loading));
  }, [dispatch, budgetSelector.loading, planBriefSelector.loading, planEstimateSelector.loading]);

  useEffect(() => {
    if (activeBudgetId) {
      setBudgetID(activeBudgetId);
      const controller = new AbortController();
      dispatch(getUsers({ params: { per_page: 100 }, signal: controller.signal }));
      dispatch(getPlanVerifications({ id: activeBudgetId }));
      return () => controller.abort();
    }
  }, [activeBudgetId]);

  const isAllowed = myRole === roles.Prasasakiya_Adhikrit || myRole === roles.Yojana_Sakha;
  if (isAllowed)
    return (
      <>
        <ReviewPlan />{" "}
      </>
    );
  return (
    <React.Fragment>
      {!budgetID && <StartYojanaList setBudgetID={setBudgetID} />}

      {!!budgetID && <PlanDetail activeBudgetId={budgetID || ""} setBudgetID={setBudgetID} />}
    </React.Fragment>
  );
}

export default PlanPage;

export const StyledTabHeader = styled(Tab.TabHeader)`
  display: flex;
  & > button {
    flex: 1;
  }
`;

export const StyledAlert = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  padding: 2rem 1rem;
  opacity: 0.6;
  text-align: center;
`;
