import MunicipalityBudget from "../municipality-budget/MunicipalityBudget";

const MunicipalityBudgetView = () => {
  return (
    <>
      <MunicipalityBudget isEditable={false} />
    </>
  );
};

export default MunicipalityBudgetView;
