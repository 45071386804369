import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTranslated from "../../../../hooks/useTranslated";
import {
  deleteSanitoryWorks,
  getSanitoryWorks,
  updateSanitoryWorks,
} from "../../../../rtk/feature/sanitory-work/sanitoryWorkAPI";
import { useAppDispatch, useAppSelector } from "../../../../rtk/hook";
import WorkTable from "../components/WorkTable/WorkTable";
interface IProps {
  budgetId: string;
  isAddable: boolean;
}
const SanitoryWork = (props: IProps) => {
  const [rate, setRate] = useState([]);
  const { budgetId } = props;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getSanitoryWorks({ id: budgetId }));
  }, []);
  const { data, deleteSuccess, formSubmitted } = useAppSelector((state) => state.sanitoryWork);
  const addSanitoryWork = () => {};
  const navigate = useNavigate();
  const handleEdit = (id: string) => {
    navigate(`/plan-management/plan/sanitory-work/edit/${budgetId}/${id}`);
  };
  const handleDelete = (id: string) => {
    dispatch(deleteSanitoryWorks({ id: budgetId, workId: id }));
  };
  const handleRateAdd = (value: number, workId: string) => {
    dispatch(updateSanitoryWorks({ id: budgetId, workId: workId, data: { rate: value } }));
  };
  const handleAddFinalQuantity = (finalQuantity: number, workId: string) => {
    dispatch(updateSanitoryWorks({ data: { final_quantity: finalQuantity }, id: props.budgetId, workId }));
  };

  const { keyword } = useTranslated();

  return (
    <WorkTable
      rateAddSuccess={formSubmitted}
      addUrl={`/plan-management/plan/sanitory-work/add/${budgetId}`}
      data={data}
      deleteSuccess={deleteSuccess}
      editUrl="/"
      onAddClick={addSanitoryWork}
      onEditClick={handleEdit}
      onDelete={handleDelete}
      tableName={keyword["Sanitary Work"]}
      onRateAdd={handleRateAdd}
      // onFinalQuantityAdd={handleAddFinalQuantity}
      hideAddBtn={!props.isAddable}
    />
  );
};

export default SanitoryWork;
