import React from "react";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import useTranslated from "../../hooks/useTranslated";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";

export interface BasicInformationProps {
  curUser: any;
}

function BasicInformationPane(props: BasicInformationProps) {
  const { curUser } = props;
  const { keyword } = useTranslated();

  return (
    <React.Fragment>
      <Grid>
        <Grid.Row gap={1.5}>
          <Grid.Col spanXs={12} spanLg={6}>
            <table>
              <tbody>
                <tr>
                  <td> {keyword["User Name"]} </td>
                  <td>{curUser?.username} </td>
                </tr>

                <tr>
                  <td> {keyword.Email} </td>
                  <td>{curUser?.email} </td>
                </tr>

                <tr>
                  <td>{keyword["Phone Number"]} :</td>
                  <td>{curUser?.mobile_number}</td>
                </tr>

                <tr>
                  <td> {keyword.Role} : </td>
                  <td>{curUser?.role}</td>
                </tr>
                <tr>
                  <td> {keyword.Ward} : </td>
                  <td>{curUser?.ward?.ward_name || "N/A"}</td>
                </tr>
              </tbody>
            </table>
          </Grid.Col>

          <Grid.Col spanXs={12} spanLg={6}>
            <table>
              <tbody>
                <tr>
                  <td> {keyword["Full Name (English)"]}: </td>
                  <td>{curUser?.full_name_english} </td>
                </tr>

                <tr>
                  <td> {keyword["Full Name (Nepali)"]} : </td>
                  <td>{curUser?.full_name_nepali} </td>
                </tr>

                <tr>
                  <td> {keyword["Date of Birth (English) "]} : </td>
                  <td>{curUser?.dob_english || "N/A"}</td>
                </tr>

                <tr>
                  <td> {keyword["Date of Birth (Nepali) "]} : </td>
                  <td>{curUser?.dob_nepali || "N/A"}</td>
                </tr>
              </tbody>
            </table>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
}

export default BasicInformationPane;
