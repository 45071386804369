import { createSlice } from "@reduxjs/toolkit";
import { ConstructorMemberTypeI } from "../constructor-member/constructorMemberSlice";
import {
  addConstructor,
  deleteConstructor,
  getConstructor,
  getConstructors,
  updateConstructor,
} from "./constructorApi";

export interface ConstructorFormTypeI {
  name: string;
  type: any;
  yojana?: any;
  pan_or_vat_no: string;
  organize_date: string;
  population_during_organize: number;
  address: string;
  samiti_email: string;
  mobile_number: string;
  bank: string;
  bank_ac_number: string;
  file: any;
  total_budget: number;
  file_consist_of: any[];
}

export interface Ward {
  id: string;
  nagarpalika: Nagarpalika;
  ward_name: string;
  ward_number: number;
}

export interface Nagarpalika {
  id: string;
  url: string;
  name: string;
}

export interface YojanaType {
  id: string;
  name: string;
}

export interface Yojana {
  id: string;
  budget_name: string;
  name: string;
  yojana_type: YojanaType;
  code: string;
  ward: Ward;
  nagarpalika_lagani: string;
  agreement_date: Date;
  agreement_start_date: Date;
  agreement_end_date: Date;
  agreement_payment_date: Date;
  contractor_type: string;
}

export interface ConstructorTypeI {
  id: string;
  name: string;
  code: string;
  type: string;
  yojana: Yojana;
  pan_or_vat_no: string;
  organize_date: string;
  population_during_organize: number;
  address: string;
  samiti_email: string;
  mobile_number: string;
  bank: string;
  bank_ac_number: string;
  file: any;
  total_budget: number;
  file_consist_of: any[];
  yojana_samiti_member: ConstructorMemberTypeI[];
}

export interface ConstructorsSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: ConstructorTypeI[];
  } | null;
  item: ConstructorTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: ConstructorsSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const constructorsSlice = createSlice({
  name: "constructors",
  initialState,
  reducers: {
    constructorsCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addConstructor.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addConstructor.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addConstructor.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getConstructors.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getConstructors.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getConstructors.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getConstructor.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getConstructor.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getConstructor.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateConstructor.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateConstructor.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateConstructor.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteConstructor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteConstructor.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteConstructor.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { constructorsCleanUp } = constructorsSlice.actions;
export default constructorsSlice.reducer;
