import { Button, Form, Grid, MainLayout } from "@ims/common";
import React, { ChangeEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Page from "../../core-ui/Page";
import ReuseForm from "../../core-ui/ReuseForm";
import useTranslated from "../../hooks/useTranslated";
import { IOptions } from "../../interfaces/options.interface";
import {
  getPlanningInspectionById,
  postPlanningInspection,
  updatePlanningInspection,
} from "../../rtk/feature/planning-inspection/planningInspectionAPI";
import {
  cleanupPlanningInspection,
  IPlanningInspectionResult,
} from "../../rtk/feature/planning-inspection/planningInspectionSlice";
import { getYojanas } from "../../rtk/feature/yojana/yojanaApi";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";

const PlanningInspectionForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const editMode = location.pathname.includes("/edit");
  const {
    byId: { data },
  } = useAppSelector((state) => state.planningInspection);

  interface IForm
    extends Omit<
      IPlanningInspectionResult,
      | "id"
      | "samiti_baithak"
      | "yojana"
      | "inspection_report"
      | "expenditure_report"
      | "anugaman_pratibedan"
      | "is_published"
    > {
    name: string;
    inspection_report: FileList;
    expenditure_report: FileList;
    anugaman_pratibedan: FileList;
    is_published: IOptions;
    yojana: { label: string; value: string };
  }

  const {
    control,
    setValue,
    formState: { errors },
    register,
    handleSubmit,
    getValues,
  } = useForm<IForm>({
    defaultValues: {
      is_published: { label: "false", value: "false" },
    },
  });
  const isPublishedOptions = [
    {
      label: "true",
      value: "true",
    },
    {
      label: "false",
      value: "false",
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getPlanningInspectionById(id));
    }
  }, [id]);

  const onSubmit = (data: IForm) => {
    const form = new FormData();
    form.append("remarks", data.remarks);
    form.append("name", data.name);

    form.append("description", data.description);
    form.append("yojana", data.yojana.value);
    form.append("is_published", data.is_published.value);
    !!data.inspection_report && form.append("inspection_report", data.inspection_report[0]);
    !!data.expenditure_report && form.append("expenditure_report", data.expenditure_report[0]);
    !!data.anugaman_pratibedan && form.append("anugaman_pratibedan", data.anugaman_pratibedan[0]);

    if (editMode && id) {
      dispatch(updatePlanningInspection({ data: form, id }));
    } else {
      dispatch(postPlanningInspection(form));
    }
  };
  const handleCancel = () => {
    navigate(`/planning-inspection`);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getYojanas());
  }, []);

  useEffect(() => {
    if (data) {
      setValue("description", data.description);

      setValue("name", data.name);
      setValue("yojana", { label: data.yojana.name, value: data.yojana.id });
    }
  }, [data]);

  const allYojanas = useAppSelector((state) => state.yojanas.data?.results);
  const yojanaOptions = allYojanas?.map((yojana) => {
    return {
      label: yojana.name,
      value: yojana.id,
    };
  });

  type nameTypes = "inspection_report" | "expenditure_report" | "anugaman_pratibedan";

  const { formSubmitted } = useAppSelector((state) => state.planningInspection);
  const navigate = useNavigate();
  useEffect(() => {
    if (formSubmitted) {
      navigate(`/planning-inspection`);
    }
    return () => {
      dispatch(cleanupPlanningInspection());
    };
  }, [formSubmitted]);
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      {" "}
      <Page.Title> {keyword["Planning  Inspection"]} </Page.Title>
      <ReuseForm control={control} onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["name"])}>
                <Form.Label> {keyword["Yojana Name"]} </Form.Label>

                <Form.Input
                  placeholder={keyword.Name}
                  register={register("name", { required: "name is necessary" })}
                  name="name"
                />
                <Form.HelperText> {errors.name?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["description"])}>
                <Form.Label> {keyword.Description} </Form.Label>
                <Form.Input
                  placeholder={keyword.Description}
                  register={register("description", { required: "description is required" })}
                  name="description"
                />
                <Form.HelperText> {errors.description?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["remarks"])}>
                <Form.Label> {keyword["Remarks"]} </Form.Label>
                <Form.Input
                  placeholder={keyword["Remarks"]}
                  register={register("remarks", { required: "remarks is required" })}
                  name="remarks"
                />
                <Form.HelperText> {errors.remarks?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["yojana"])}>
                <ReuseForm.ReactSelect
                  label={keyword.Yojana}
                  name="yojana"
                  options={yojanaOptions}
                  placeholder={keyword["Select Yojana"]}
                  rules={{ required: "Type is required." }}
                  error={errors["yojana"]}
                  isDisabled={true}
                />
                {/* <Form.HelperText  > {errors.remarks?.message} </Form.HelperText> */}
              </Form.FormGroup>
            </Grid.Col>
            {editMode && (
              <Grid.Col spanMd={6} spanXs={12}>
                <Form.FormGroup error={Boolean(errors && errors["is_published"])}>
                  <ReuseForm.ReactSelect
                    label={keyword["Is published"]}
                    name="is_published"
                    options={isPublishedOptions}
                    placeholder="Select Type"
                    rules={{ required: "Type is required." }}
                    error={errors["is_published"]}
                  />
                  {/* <Form.HelperText  > {errors.remarks?.message} </Form.HelperText> */}
                </Form.FormGroup>
              </Grid.Col>
            )}
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["inspection_report"])}>
                <Form.Label> {keyword["Inspection Report"]} </Form.Label>

                <Controller
                  name="inspection_report"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field: { onChange, value }, fieldState, formState }) => {
                    return (
                      <Form.File
                        placeholder="inspection_report "
                        name="inspection_report"
                        id="inspection_report"
                        onChange={(e) => onChange(e.target.files)}
                      />
                    );
                  }}
                />

                <Form.HelperText> {errors.inspection_report?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["expenditure_report"])}>
                <Form.Label> {keyword["Expenditure Report"]} </Form.Label>

                <Controller
                  name="expenditure_report"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field: { onChange, value }, fieldState, formState }) => {
                    return (
                      <Form.File
                        placeholder="expenditure_report "
                        name="expenditure_report"
                        id="expenditure_report"
                        onChange={(e) => onChange(e.target.files)}
                      />
                    );
                  }}
                />

                <Form.HelperText> {errors.expenditure_report?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["anugaman_pratibedan"])}>
                <Form.Label> {keyword["Anugaman Pratibedan"]} </Form.Label>

                <Controller
                  name="anugaman_pratibedan"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  render={({ field: { onChange, value }, fieldState, formState }) => {
                    return (
                      <Form.File
                        placeholder="anugaman_pratibedan "
                        name="anugaman_pratibedan"
                        id="anugaman_pratibedan"
                        onChange={(e) => onChange(e.target.files)}
                      />
                    );
                  }}
                />

                <Form.HelperText> {errors.anugaman_pratibedan?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword.Update : keyword.Add}
              </Button>
              <Button type="button" onClick={handleCancel}>
                {keyword.Cancel}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
};

export default PlanningInspectionForm;
