import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { addFiscalYear, getFiscalYear, updateFiscalYear } from "../../rtk/feature/fiscal-year/fiscalYearApi";
import { useNavigate, useParams } from "react-router-dom";
import { FiscalYearFormTypeI, fiscalYearsCleanUp } from "../../rtk/feature/fiscal-year/fiscalYearSlice";
import ReuseForm from "../../core-ui/ReuseForm";
import { Form } from "@ims/common";
import useTranslated from "../../hooks/useTranslated";

export interface FiscalYearFormTypeII extends FiscalYearFormTypeI {}

function FiscalYearFormPage() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<FiscalYearFormTypeII>({
    defaultValues: {
      name: "",
      start_date: "",
      end_date: "",
      status: false,
    },
  });

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fiscalYearsSelector = useAppSelector((state) => state.fiscalYears);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(fiscalYearsCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getFiscalYear(params.id));
      setEditMode(true);
    } else {
      dispatch(fiscalYearsCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { item } = fiscalYearsSelector;

  useEffect(() => {
    if (item !== null) {
      setValue("name", item.name);
      setValue("start_date", item.start_date);
      setValue("end_date", item.end_date);
      setValue("status", item.status ? true : false);
    } else {
      reset();
    }
  }, [item, setValue, reset]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = fiscalYearsSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/settings/fiscal-year/list");
    }
  }, [fiscalYearsSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<FiscalYearFormTypeII> = (data) => {
    if (editMode && params.id) {
      const newData = { ...data };
      dispatch(updateFiscalYear({ data: newData, id: params.id }));
    } else {
      const newData = { ...data };
      dispatch(addFiscalYear(newData));
    }
  };

  const handleCancel = () => {
    navigate("/settings/fiscal-year/list");
  };
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <h4>{editMode ? "Edit" : "Create"} Fiscal Year</h4>
      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Fiscal Year"]}
                name="name"
                placeholder="Enter fiscal year name"
                rules={{ required: keyword["This is required field."] }}
                error={errors["name"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                type="date"
                label={keyword["Start Date"]}
                name="start_date"
                rules={{ required: keyword["This is required field."] }}
                error={errors["start_date"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                type="date"
                label={keyword["End Date"]}
                name="end_date"
                rules={{ required: keyword["This is required field."] }}
                error={errors["end_date"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <ReuseForm.Input style={{ display: "inline-block" }} type="checkbox" name="status" id="status" />

              <Form.Label style={{ marginLeft: "1rem" }} htmlFor="status">
                {keyword.Active}
              </Form.Label>
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword.Update : keyword["Create Fiscal Year"]}
              </Button>
              <Button onClick={handleCancel}>{keyword.Cancel}</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
}

export default FiscalYearFormPage;
