import { createSlice } from "@reduxjs/toolkit";
import { INetworkRResponse } from "../../../interfaces/networkResponse.interface";
import { UnitTypeI } from "../unit/unitSlice";
import {
  deleteGeneralWork,
  getGeneralWorkById,
  getGeneralWorks,
  postGeneralWork,
  updateGeneralWork,
} from "./generalWorkApi";

export interface IGeneralWorkData {
  id: string;
  name: string;
  length: number;
  breadth: number;
  height: number;
  number: number;
  remarks: string;
  rate: string;
  total: string;
  unit: UnitTypeI;
  quantity: string;
  final_quantity: string | null;
  final_total: string | null;
}

interface IGeneralWorkById extends INetworkRResponse {
  data: IGeneralWorkData | null;
}

interface IInitialData extends INetworkRResponse {
  data: IGeneralWorkData[] | null;
  formSubmitted: boolean;
  deleteSuccess: boolean;
  byId: IGeneralWorkById;
}

const initialState: IInitialData = {
  loading: false,
  data: null,
  deleteSuccess: false,
  error: null,
  formSubmitted: false,
  byId: { data: null, error: null, loading: false },
};

const generalWorkSlice = createSlice({
  name: "generalWorkSlice",
  initialState,

  reducers: {
    generalWorkCleanup: () => {},
    generalWorkFormCleanUp: (state) => {
      state.formSubmitted = false;
      state.deleteSuccess = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getGeneralWorks.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = null;
    });
    builder.addCase(getGeneralWorks.rejected, (state) => {
      state.loading = false;
      state.error = null;
      state.data = null;
    });
    builder.addCase(getGeneralWorks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload;
    });

    builder.addCase(postGeneralWork.pending, (state) => {
      state.formSubmitted = false;
    });
    builder.addCase(postGeneralWork.fulfilled, (state) => {
      state.formSubmitted = true;
    });
    builder.addCase(updateGeneralWork.pending, (state) => {
      state.formSubmitted = false;
    });
    builder.addCase(updateGeneralWork.fulfilled, (state) => {
      state.formSubmitted = true;
    });
    builder.addCase(getGeneralWorkById.pending, (state) => {
      state.byId.loading = true;
      state.byId.error = null;
      state.byId.data = null;
    });
    builder.addCase(getGeneralWorkById.rejected, (state) => {
      state.byId.loading = false;
      state.byId.error = null;
      state.byId.data = null;
    });
    builder.addCase(getGeneralWorkById.fulfilled, (state, { payload }) => {
      state.byId.loading = false;
      state.byId.error = null;
      state.byId.data = payload;
    });
    builder.addCase(deleteGeneralWork.pending, (state, { payload }) => {
      state.deleteSuccess = false;
    });

    builder.addCase(deleteGeneralWork.fulfilled, (state, { payload }) => {
      state.byId.loading = false;
      state.byId.error = null;
      const newData = state.data?.filter((data) => data.id != payload?.deltedWorkId);
      state.data = newData || [];
      state.deleteSuccess = true;
    });
  },
});
export const { generalWorkCleanup, generalWorkFormCleanUp } = generalWorkSlice.actions;
export default generalWorkSlice.reducer;
