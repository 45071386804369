import { createSlice } from "@reduxjs/toolkit";
import { INetworkResponseWithPagination, INetworkRResponse, IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { UnitFormTypeI, UnitTypeI } from "../unit/unitSlice";
import { getDistrictRate, getDistrictRateById, createDistrictRate, updateDistrictRate } from "./districtRateApi";
export interface IDistrictRateForm {
    particular: string,
    analysis_rate: string,
    unit: any,
    remarks: string
}


export interface IDistrictRate {
    particular: string,
    analysis_rate: string,
    unit: UnitTypeI
    remarks: string
    id: string
    type: string


}

interface IinitialDistrictRateDataById extends INetworkRResponse {
    data: IDistrictRate | null
}


interface IinitialData extends INetworkRResponse {

    data: IPaginatedResponse<IDistrictRate> | null,
    byId: IinitialDistrictRateDataById,
    formSubmitted: boolean
}

const initialState: IinitialData = {
    data: null,
    error: null,
    loading: false,
    formSubmitted: false,
    byId: {
        data: null,
        error: false,
        loading: false
    }


}
const districtSlice = createSlice({
    name: "districtSlice",
    initialState,
    reducers: {
        cleanUpDistrictSlice: (state) => {
            return initialState
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getDistrictRate.pending, (state) => {
            state.data = null;
            state.loading = true

        });
        builder.addCase(getDistrictRate.rejected, (state) => {
            state.data = null;
            state.loading = false

        });
        builder.addCase(getDistrictRate.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.loading = false

        });

        builder.addCase(getDistrictRateById.pending, (state) => {
            state.byId.data = null;
            state.byId.loading = true;
            state.error = null
        });
        builder.addCase(getDistrictRateById.rejected, (state) => {
            state.byId.data = null;
            state.byId.loading = false;
            state.error = null
        });
        builder.addCase(getDistrictRateById.fulfilled, (state, { payload }) => {
            state.byId.data = payload;
            state.byId.loading = false;
            state.error = null
        });



        //post 
        builder.addCase(createDistrictRate.pending, (state) => {
            state.formSubmitted = false
        });


        builder.addCase(createDistrictRate.fulfilled, (state) => {
            state.formSubmitted = true
        });

        //update
        builder.addCase(updateDistrictRate.pending, (state) => {
            state.formSubmitted = false
        });


        builder.addCase(updateDistrictRate.fulfilled, (state) => {
            state.formSubmitted = true
        });



    }

});

export const { cleanUpDistrictSlice } = districtSlice.actions

export default districtSlice.reducer