import { createSlice } from "@reduxjs/toolkit";
import { IYojana } from "../plan-verification/planVerificationSlice";
import { addContractorDoc, getContractorDocs, updateContractorDoc } from "./contractorDocsApi";

export interface SamitiDocsFormI {
  id?: string;
  title: string;
  file: string;
}

export interface SamitiDocsI {
  id: string;
  title: string;
  file: string;
}

export interface SamitiMemberI {
  id: string;
  name: string;
  designation: string;
  sex: string;
  mobile_number: string | null;
  contact_number: string | null;
  address: string;
  citizenship_number: string | null;
  district: string | null;
  father: string | null;
  husband: string | null;
}

interface ContractorDocI {
  id: string;
  name: string;
  code: string;
  type: string;
  yojana: IYojana;
  yojana_samiti_member: SamitiMemberI[];
  samiti_docs: SamitiDocsI[];
}

export interface ContractorDocStateI {
  loading: boolean;
  data: ContractorDocI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: ContractorDocStateI = {
  loading: false,
  data: null,
  hasSubmitted: false,
  error: null,
};

const contractorDocsSlice = createSlice({
  name: "contractorDocs",
  initialState,
  reducers: {
    contractorDocsCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addContractorDoc.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addContractorDoc.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addContractorDoc.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getContractorDocs.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getContractorDocs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getContractorDocs.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(updateContractorDoc.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(updateContractorDoc.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(updateContractorDoc.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
  },
});

export const { contractorDocsCleanUp } = contractorDocsSlice.actions;
export default contractorDocsSlice.reducer;
