import { EstimateDataI } from "../../../../../rtk/feature/work-report/workReportSlice";
import { BasicTable } from "../../../PlanSearch";

interface EstimationTablePropsI {
  data: EstimateDataI;
}

export default function EstimationBasicTable({ data }: EstimationTablePropsI) {
  return (
    <>
      <table style={basicTable}>
        <tbody>
          <tr>
            <td style={rowHead}> Sub total </td>
            <td style={rowValue}>Rs. {data?.sub_total}</td>
          </tr>{" "}
          <tr>
            <td style={rowHead}> With Contengencies </td>
            <td style={rowValue}>Rs. {data?.with_contengencies}</td>
          </tr>{" "}
          <tr>
            <td style={rowHead}> With VAT </td>
            <td style={rowValue}>Rs. {data?.with_vat}</td>
          </tr>
          <tr>
            <td style={rowHead}> Grand total </td>
            <td style={rowValue}>Rs. {data?.grand_total}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

const basicTable = {
  color: "#A7A1AE",
  width: "40rem",
};

const tableRow = {
  padding: "5px",
  backgroundColor: "#323C50",
};

const rowHead = {
  ...tableRow,
  color: "#4DC3FA",
};
const rowValue = {
  ...tableRow,
  color: "#eee",
};
