import { Editor } from "@tinymce/tinymce-react";

interface EditorPropsI {
  initialValue: string;
  onEditorChange?: ((a: string) => void) | undefined;
}

export default function EditorPage({ initialValue, onEditorChange }: EditorPropsI) {
  return (
    <Editor
      apiKey="17wi3jiutcl29wknqmixj0q0fc5d6y0bbcv4fmz78pz03lcz"
      init={{
        height: 590,
        menubar: "file insert table preview",
        plugins: "table preview",
        print_params: {
          no_footer: false,
          no_header: false,
        },
        content_style: "body {padding:10px 20px} table { width: 100%;  }",
      }}
      initialValue={initialValue}
      // onInit={(evt, editor) => (editorRef.current = editor)}
      onEditorChange={onEditorChange}
    />
  );
}
