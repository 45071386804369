import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { ImunicipalityData } from "./municipalityBudgetSlice";
import { DataSuccess } from "../../../utils/res-mes/response-message-generator";

export const postMunicipalityBudgetData = createAsyncThunk(
  "postMunicipaityBudgetData",
  async (data: FormData, { dispatch }) => {
    try {
      const res = await imsClient.post(`/budget-planning/excel-upload/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data) toast.success(DataSuccess("posted"));
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return isRejectedWithValue(appError.getError());
    } finally {
      dispatch(getMunicipalityBudgetData({ params: {} }));
    }
  }
);
interface IOptions {
  params: {
    per_page?: number;
    page?: number;
    search?: any;
    ordering?: any;
  };
  signal?: AbortSignal;
}

export const getMunicipalityBudgetData = createAsyncThunk("getMunicipaityBudgetData", async (options: IOptions) => {
  try {
    const res = await imsClient.get(`/budget-planning/`, { params: options.params, signal: options.signal });

    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return isRejectedWithValue(appError.getError());
  }
});

export const getMunicipalityBudgetDataById = createAsyncThunk(
  "getMunicipaityBudgetDataById",
  async (options: { id: string; signal: AbortSignal }) => {
    try {
      const res = await imsClient.get(`/budget-planning/${options.id}/`, { signal: options.signal });

      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return isRejectedWithValue(appError.getError());
    }
  }
);

export const updateMunicipalityBudgetData = createAsyncThunk(
  "updateMunicipaityBudgetDataBy",
  async (options: { data: ImunicipalityData; id: string }) => {
    try {
      const res = await imsClient.patch(`/budget-planning/${options.id}/`, options.data);
      if (res.data) toast.success(DataSuccess("updated"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return isRejectedWithValue(appError.getError());
    }
  }
);
