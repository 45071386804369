import { HtmlHTMLAttributes } from "react";
import {
  StyledAuth,
  StyledAuthAuthBanner,
  StyledAuthAuthHeaderTitle,
  StyledAuthBody,
  StyledAuthFooter,
  StyledAuthFormContainer,
  StyledAuthHeader,
} from "./Auth.style";

interface AuthPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

export default function Auth(props: AuthPropsI) {
  return <StyledAuth {...props} />;
}

interface AuthFormContainerPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

Auth.FormContainer = function AuthFormContainerFunction(props: AuthFormContainerPropsI) {
  return <StyledAuthFormContainer {...props} />;
};

interface AuthAuthBannerPropsI extends HtmlHTMLAttributes<HTMLDivElement> {
  src?: string;
}

Auth.AuthBanner = function AuthAuthBannerFunction(props: AuthAuthBannerPropsI) {
  return <StyledAuthAuthBanner {...props} />;
};

interface AuthAuthHeaderPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}
Auth.AuthHeader = function AuthAuthHeaderFunction(props: AuthAuthHeaderPropsI) {
  return <StyledAuthHeader {...props} />;
};

interface AuthAuthHeaderTitlePropsI extends HtmlHTMLAttributes<HTMLDivElement> {}
Auth.AuthHeaderTitle = function AuthAuthHeaderFunction(props: AuthAuthHeaderTitlePropsI) {
  return <StyledAuthAuthHeaderTitle {...props} />;
};

interface AuthAuthBodyPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}
Auth.AuthBody = function AuthAuthHeaderFunction(props: AuthAuthBodyPropsI) {
  return <StyledAuthBody {...props} />;
};

interface AuthAuthFooterPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}
Auth.AuthFooter = function AuthAuthHeaderFunction(props: AuthAuthFooterPropsI) {
  return <StyledAuthFooter {...props} />;
};
