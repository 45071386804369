import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
interface IGetElectrificationWorks {
    id: string
}
export const getElectrificationWorks = createAsyncThunk("getElectrificationWorks", async ({ id }: IGetElectrificationWorks, { rejectWithValue }) => {
    try {
        const res = await imsClient.get(`/yojana/${id}/electrification-works/`);
        return res.data
    } catch (error) {
        const appError = new AppError(error);
        appError.show();
        rejectWithValue(appError.getError())
    }
});
interface IGetElectrificationWork {
    id: string,
    workId: string
}

export const getElectrificationWork = createAsyncThunk("getElectrificationWork", async ({ id, workId }: IGetElectrificationWork, { rejectWithValue }) => {
    try {
        const res = await imsClient.get(`/yojana/${id}/electrification-works/${workId}/`);
        return res.data
    } catch (error) {
        const appError = new AppError(error);
        appError.show();
        rejectWithValue(appError.getError())
    }
});


interface IPostElectrificationWork {
    id: string,
    data: any

}

export const postElectrificationWorks = createAsyncThunk("postElectrificationWork", async ({ data, id }: IPostElectrificationWork, { rejectWithValue }) => {
    try {
        const res = await imsClient.post(`/yojana/${id}/electrification-works/`, data);
        return res.data
    } catch (error) {
        const appError = new AppError(error);
        appError.show();
        rejectWithValue(appError.getError())
    }
});

interface IUpdateElectrificationWork {
    data: any,
    id: string,
    workId: string
}

export const updateElectrificationWorks = createAsyncThunk("updateElectrificationWork", async ({ id, workId, data }: IUpdateElectrificationWork, { dispatch, rejectWithValue }) => {
    try {
        const res = await imsClient.patch(`/yojana/${id}/electrification-works/${workId}/`, data);
        dispatch(getElectrificationWorks({ id }))
        return res.data
    } catch (error) {
        const appError = new AppError(error);
        appError.show();
        rejectWithValue(appError.getError())
    }
});
interface IDeleteElectrificationWork {
    id: string,
    workId: string
}

export const deleteElectrificationWorks = createAsyncThunk("deleteElectrificationWork", async ({ id, workId }: IDeleteElectrificationWork, { rejectWithValue }) => {
    try {
        const res = await imsClient.delete(`/yojana/${id}/electrification-works/${workId}/`);
        return workId
    } catch (error) {
        const appError = new AppError(error);
        appError.show();
        rejectWithValue(appError.getError())
    }
});