import React from "react";
import { CgMenuBoxed } from "react-icons/cg";
import { BsArrowRight } from "react-icons/bs";
import { StyledDocument, StyledDocumentName } from "./Document.style";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";

type documentProps = {
  documentName: string;
  handleClick?: any;
  order: number;
};
const DocumentCard = ({ documentName, handleClick, order }: documentProps) => {
  return (
    <StyledDocument onClick={handleClick}>
      <StyledDocumentName>
        <CgMenuBoxed size={40} />
        <p>{englishNepaliNumberTranslation(order) && `${englishNepaliNumberTranslation(order)}.`}</p>
        <p>{documentName}</p>
      </StyledDocumentName>

      <BsArrowRight size={20} />
    </StyledDocument>
  );
};

export default DocumentCard;
