import { createAsyncThunk } from "@reduxjs/toolkit";
import { imsClient } from "../../../utils/imsClient";
import { AppError } from "../../../utils/AppError";
import { toast } from "react-toastify";
import { CommunicationTitleSuccess } from "../../../utils/res-mes/response-message-generator";

export const getCommunicationTitles = createAsyncThunk(
  "getContractorDocs",
  async (
    communicationTiteParams: { per_page?: number; page?: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get(`/communication-title/`, {
        params: communicationTiteParams,
        signal: communicationTiteParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addCommunicationTitle = createAsyncThunk(
  "addCommunicationTitle",
  async (data: { title: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/communication-title/`, data);
      if (res.data) toast.success(CommunicationTitleSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
export const updateCommunicationTitle = createAsyncThunk(
  "updateCommunicationTitle",
  async (args: { titleId: string; data: { title: string } }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/communication-title/${args.titleId}/`, args.data);
      if (res.data) toast.success(CommunicationTitleSuccess("updated"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
