import { useEffect, useState } from "react";
import PlanSearch from "../plan/PlanSearch";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { getPlanVerifications } from "../../rtk/feature/plan-verification/planVerificationAPI";
import { getBudgets } from "../../rtk/feature/budget/budgetApi";
import { roles } from "../../constants/constants";
import { MainLayout } from "@ims/common";
import Tab from "../../components/tab/Tab";
import { StyledAlert, StyledTabHeader } from "../plan/PlanPage";
import useTranslated from "../../hooks/useTranslated";
import { useSearchParams } from "react-router-dom";
import AssignWard from "../plan/assign-ward/AssignWard";
import StartYojanaList from "../plan/StartYojanaList";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { setActiveBudgetId } from "../../rtk/feature/plan-management/planManagementSlice";

const StartYojana = () => {
  const [budgetID, setBudgetID] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();
  const tab = searchParams.get("tab");

  const activeBudgetId = useAppSelector((state) => state.planManagement.activeBudgetId);
  const budgetSelector = useAppSelector((state) => state.budgets);

  const myRole = useAppSelector((state) => state.auth.user?.role);

  useEffect(() => {
    if (myRole === roles.ADMIN || myRole === roles.WARD_SACHIB) dispatch(getBudgets());
  }, [dispatch]);

  useEffect(() => {
    //  if (myRole === roles.Prasasakiya_Adhikrit) return;
    dispatch(showLoading(budgetSelector.loading));
  }, [dispatch, budgetSelector.loading]);

  useEffect(() => {
    if (activeBudgetId) {
      setBudgetID(activeBudgetId);
      const controller = new AbortController();
      dispatch(getPlanVerifications({ id: activeBudgetId }));
      return () => controller.abort();
    }
  }, [activeBudgetId]);

  return (
    <>
      <PlanSearch setBudgetID={setBudgetID} />
      {!budgetID && <StartYojanaList title="Started Yojana" hideDetails={true} setBudgetID={setBudgetID} />}

      {!!budgetID && (
        <>
          <MainLayout.Main style={{ padding: 0 }}>
            <Tab>
              <StyledTabHeader>
                <Tab.TabHeadButton id="ward" active={tab === "ward" || tab == null} disabled={!activeBudgetId}>
                  {keyword["Ward"]}
                </Tab.TabHeadButton>
              </StyledTabHeader>

              <Tab.TabBody>
                <Tab.TabPane targetId="ward">
                  <AssignWard budgetID={activeBudgetId} />
                </Tab.TabPane>
              </Tab.TabBody>
            </Tab>
          </MainLayout.Main>
        </>
      )}
    </>
  );
};

export default StartYojana;
