import { createSlice } from "@reduxjs/toolkit";
import { INetworkRResponse, IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { IYojana } from "../plan-verification/planVerificationSlice";
import { getPlanningInspection, getPlanningInspectionById, postPlanningInspection, updatePlanningInspection } from "./planningInspectionAPI";


export interface IPlanningInspectionResult {
    id: string,
    yojana: IYojana,
    name: string,
    description: string,
    remarks: string,
    inspection_report: string,
    samiti_baithak: string,
    expenditure_report: string,
    anugaman_pratibedan: string,
    is_published: string



}



interface IPlanningInspectionById extends INetworkRResponse {
    data: IPlanningInspectionResult | null
}

interface IPlanningInspectionSlice extends INetworkRResponse {
    data: null | IPaginatedResponse<IPlanningInspectionResult>,
    formSubmitted: boolean,
    byId: IPlanningInspectionById
}


const initialState: IPlanningInspectionSlice = {
    data: null,
    error: null,
    loading: false,
    formSubmitted: false,
    byId: {
        data: null,
        error: null,
        loading: false
    }
}



const planningInspectionSlice = createSlice({
    name: "planningInspection",
    initialState,
    reducers: {
        cleanupPlanningInspection: (state) => {
            return initialState
        }
    },
    extraReducers(builder) {
        builder.addCase(getPlanningInspection.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.data = null
        });
        builder.addCase(getPlanningInspection.rejected, (state) => {
            state.loading = false;
            state.error = null;
            state.data = null
        });
        builder.addCase(getPlanningInspection.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.data = payload
        });


        builder.addCase(getPlanningInspectionById.pending, (state) => {
            state.byId.loading = true;
            state.byId.error = null;
            state.byId.data = null
        });
        builder.addCase(getPlanningInspectionById.rejected, (state) => {
            state.byId.loading = false;
            state.byId.error = null;
            state.byId.data = null
        });
        builder.addCase(getPlanningInspectionById.fulfilled, (state, { payload }) => {
            state.byId.loading = false;
            state.byId.error = null;
            state.byId.data = payload
        });


        builder.addCase(postPlanningInspection.fulfilled, (state, { payload }) => {
            state.formSubmitted = true

        });
        builder.addCase(postPlanningInspection.pending, (state, { payload }) => {
            state.formSubmitted = false
        });
        builder.addCase(updatePlanningInspection.fulfilled, (state, { payload }) => {
            state.formSubmitted = true

        });
        builder.addCase(updatePlanningInspection.pending, (state, { payload }) => {
            state.formSubmitted = false
        });




    },
});
export const { cleanupPlanningInspection } = planningInspectionSlice.actions;
export default planningInspectionSlice.reducer