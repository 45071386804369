import { createSlice } from "@reduxjs/toolkit";
import { addEquipment, getEquipment, getEquipments, updateEquipment } from "./equipmentApi";

export interface EquipmentFormTypeI {
  nepali_name: string;
  english_name: string;
  nepali_description: string;
  english_description: string;
  calculate_equipment_cost: boolean;
  type: any;
  equipment_type: any;
  unit: any;
  unit_rate: number;
  transportation_type: any;
  unit_weight: number;
  unit_ton: number;
  load_unload_rate: number;
  load_unload_per: number;
}

export interface EquipmentTypeI {
  id: string;
  nepali_name: string;
  english_name: string;
  nepali_description: string;
  english_description: string;
  type: string;
  equipment_type: string;
  calculate_equipment_cost: boolean;
  unit: {
    id: string;
    name: string;
    code: string;
  };
  unit_rate: string;
  transportation_type: {
    id: string;
    name: string;
  };
  unit_weight: string;
  unit_ton: string;
  load_unload_rate: string;
  load_unload_per: string;
}

export interface EquipmentUpdatePasswordTypeI {
  old_password: string;
  password: string;
  confirm_password: string;
}
export interface EquipmentsSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: EquipmentTypeI[];
  } | null;
  item: EquipmentTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: EquipmentsSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const equipmentSlice = createSlice({
  name: "equipment",
  initialState,
  reducers: {
    equipmentCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addEquipment.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addEquipment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addEquipment.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getEquipments.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getEquipments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getEquipments.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getEquipment.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getEquipment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getEquipment.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateEquipment.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateEquipment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateEquipment.rejected, (state, { payload }: any) => {
      
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
  },
});

export const { equipmentCleanUp } = equipmentSlice.actions;
export default equipmentSlice.reducer;
