import { createSlice } from "@reduxjs/toolkit";
import {
  addConstructorMember,
  deleteConstructorMember,
  getConstructorMember,
  getConstructorMembers,
  updateConstructorMember,
} from "./constructorMemberApi";

export interface ConstructorMemberFormTypeI {
  id: string;
  name: string;
  designation: string;
  sex: string;
  mobile_number: string;
  contact_number: string;
  address: string;
  citizenship_number: string;
  district: string;
  father: string;
  husband: string;
}

export interface ConstructorMemberTypeI {
  id: string;
  name: string;
  designation: string;
  sex: string;
  mobile_number: string;
  contact_number: string;
  address: string;
  citizenship_number: string;
  district: string;
  father: string;
  husband: string;
}

export interface ConstructorMembersSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: ConstructorMemberTypeI[];
  } | null;
  item: ConstructorMemberTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: ConstructorMembersSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const constructorMembersSlice = createSlice({
  name: "constructorMembers",
  initialState,
  reducers: {
    constructorMembersCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addConstructorMember.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addConstructorMember.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addConstructorMember.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getConstructorMembers.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getConstructorMembers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getConstructorMembers.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getConstructorMember.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getConstructorMember.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getConstructorMember.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateConstructorMember.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateConstructorMember.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateConstructorMember.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteConstructorMember.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteConstructorMember.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteConstructorMember.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { constructorMembersCleanUp } = constructorMembersSlice.actions;
export default constructorMembersSlice.reducer;
