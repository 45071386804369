import { combineReducers } from "@reduxjs/toolkit";
import dummyReducer from "./feature/dummy/dummySlice";
import authReducer from "./feature/auth/authSlice";
import usersReducer from "./feature/users/usersSlice";
import yojanaReducer from "./feature/yojana/yojanaSlice";
import unitsReducer from "./feature/unit/unitSlice";
import transportationTypesReducer from "./feature/transportation-type/transportationTypeSlice";
import equipmentsReducer from "./feature/equipment/equipmentSlice";
import budgetReducer from "./feature/budget/budgetSlice";
import planBriefReducer from "./feature/plan-brief/planBriefSlice";
import planEstimateReducer from "./feature/plan-estimate/planEstimateSlice";
import planGroupEsimateReducer from "./feature/plan-group-estimate/planGroupEstimateSlice";
import analyzeRateReducer from "./feature/analyze-rate/analyzeRateSlice";
import rateEquipmentReducer from "./feature/rate-equipment/rateEquipmentSlice";
import fixedRateEquipmentReducer from "./feature/fixed-rate-equipment/fixedRateEquipmentSlice";
import constructorReducer from "./feature/constructor/constructorSlice";
import constructorMemberReducer from "./feature/constructor-member/constructorMemberSlice";
import yojanaTypeReducer from "./feature/yojana-type/yojanaTypeSlice";
import wardReducer from "./feature/ward/wardSlice";
import fiscalYearReducer from "./feature/fiscal-year/fiscalYearSlice";
import generalDocumentReducer from "./feature/general-document/generalDocumentSlice";
import fixedAnalyzeRateReducer from "./feature/fixed-analyze-rate/fixedAnalyzeRateSlice";
import municipalityBudgteReducer from "./feature/municipality-budget/municipalityBudgetSlice";
import sendPlanVerificationReducer from "./feature/plan-verification/planVerificationSlice";
import municipalityInfoReducer from "./feature/municipality-information/municipalityInformationSlice";
import planReportReducer from "./feature/plan-report/planreportSlice";
import districtRatReducer from "./feature/district-rate/districtRateSlice";
import dorEquipmentHireRateReducer from "./feature/dor-equipment-hire-rate/dorEquipmentHireRateSlice";
import generalWorkReducer from "./feature/general-work/generalWorkSlice";
import sanitoryWorkReducer from "./feature/sanitory-work/sanitoryWorkSlice";
import electrificationWorkReducer from "./feature/electrification-work/electrificationWorkSlice";
import planningInspectionReducer from "./feature/planning-inspection/planningInspectionSlice";
import planningContractorReducer from "./feature/planning-contractor/planningContractorSlice";
import billVerificationReducer from "./feature/bill-verification/billVerificationSlice";
import planningBillReducer from "./feature/plannning-bill/planningBillSlice";
import workReportReducer from "./feature/work-report/workReportSlice";
import billPaymentReducer from "./feature/bill-payment/billPaymentSlice";
import chequeVerifyByPrasasakiyaReducer from "./feature/cheque-verify-by-prasasakiya/chequeVerifyByPrasasakiyaSlice";
import planManagementReducer from "./feature/plan-management/planManagementSlice";
import relativeDocsReducer from "./feature/relative-docs/relativeDocsSlice";
import yojanaBooksSlice from "./feature/yojana-books/yojanaBooksSlice";
import yojanaBookTemplateSlice from "./feature/yojana-book-template/yojanaBookTemplateSlice";
import assignConstructorSlice from "./feature/assign-constructor/assignConstructorSlice";
import assignWardSlice from "./feature/assign-ward/assignWardSlice";
import contractorDocsSlice from "./feature/contractor-docs/contractorDocsSlice";
import communicationTitleSlice from "./feature/communication-title/communicationTitleSlice";
import adminMinuteSlice from "./feature/admin-minute/adminMinuteSlice";
import upabhoktaSamitiMinuteSlice from "./feature/upabhokta-samiti-minute/upabhoktaSamitiMinuteSlice";
import estimationDocumentSlice from "./feature/estimation-doument/estimationDocumentSlice";
import startYojanaSlice from "./feature/start-yojana/startYojanaSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  dummy: dummyReducer,
  users: usersReducer,
  yojanas: yojanaReducer,
  equipments: equipmentsReducer,
  units: unitsReducer,
  transportationTypes: transportationTypesReducer,
  budgets: budgetReducer,
  planBriefs: planBriefReducer,
  planEstimates: planEstimateReducer,
  planGroupEstimates: planGroupEsimateReducer,
  analyzeRates: analyzeRateReducer,
  fixedAnalyzeRates: fixedAnalyzeRateReducer,
  rateEquipments: rateEquipmentReducer,
  fixedRateEquipments: fixedRateEquipmentReducer,
  constructors: constructorReducer,
  constructorMembers: constructorMemberReducer,
  yojanaTypes: yojanaTypeReducer,
  wards: wardReducer,
  fiscalYears: fiscalYearReducer,
  generalDocuments: generalDocumentReducer,
  municipalityBudget: municipalityBudgteReducer,
  sendPlanVerification: sendPlanVerificationReducer,
  municipalityInfo: municipalityInfoReducer,
  planReport: planReportReducer,
  districtRate: districtRatReducer,
  dorEquipmentHireRate: dorEquipmentHireRateReducer,
  generalWork: generalWorkReducer,
  sanitoryWork: sanitoryWorkReducer,
  electrificationWork: electrificationWorkReducer,
  planningInspection: planningInspectionReducer,
  planningContractor: planningContractorReducer,
  billVerification: billVerificationReducer,
  billPlanning: planningBillReducer,
  workReport: workReportReducer,
  billPayment: billPaymentReducer,
  chequeVeirfyByPrasasakiya: chequeVerifyByPrasasakiyaReducer,
  planManagement: planManagementReducer,
  relativeDocs: relativeDocsReducer,
  yojanaBooks: yojanaBooksSlice,
  yojanaBookTemplate: yojanaBookTemplateSlice,
  assignConstructor: assignConstructorSlice,
  assignWard: assignWardSlice,
  contractorDocs: contractorDocsSlice,
  communicationTitle: communicationTitleSlice,
  adminMinute: adminMinuteSlice,
  upabhoktaSamitiMinute: upabhoktaSamitiMinuteSlice,
  estimationDocument: estimationDocumentSlice,
  startYojana: startYojanaSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
