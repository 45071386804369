import { HtmlHTMLAttributes } from "react";
import { StyledStatus } from "./Status.style";

export interface StylePropsI extends HtmlHTMLAttributes<HTMLDivElement> {
  variant: "correction" | "verified" | "not-verifed";
}

export default function Status(props: StylePropsI) {
  return <StyledStatus {...props} />;
}
