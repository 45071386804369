import styled, { css } from "styled-components/macro";

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;
`;

export const StyledTableContainer = styled.div``;
export const StyledTableHead = styled.thead``;
export const StyledTableBody = styled.tbody``;

export const StyledTableActionButton = styled.button`
  font-size: 1.8rem;
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
  justify-content: center;
  color: ${(props) => props.theme.palette.primary.main};
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all ease;

  ${(props) =>
    props.disabled &&
    css`
      filter: grayscale(1);
      opacity: 0.5;
    `}

  ${(props) =>
    props.color === "green" && `color: ${props.theme.palette.success.main}`};

  ${(props) =>
    props.color === "red" && `color: ${props.theme.palette.error.main}`};

  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`;
export const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`;

// color:rgb(77, 195, 250)
// background-color: #ececec;
export const StyledTableHeadCol = styled.th`
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: rgb(0 39 68);
  text-align: ${(props) => (props.align ? props.align : "left")};
  color: ${(props) => "#fff" || props.theme.palette.text.primary};
  border: 1px solid rgb(31, 39, 57);
  padding: 8px;
  text-transform: capitalize;
`;

export const StyledTableHeadSortCol = styled(StyledTableHeadCol)`
  padding-right: 28px;
  position: relative;
  cursor: pointer;
  user-select: none;

  & > div {
    margin-left: auto;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    & svg {
      font-size: 1.2rem;
      opacity: 0.4;

      &.active {
        opacity: 1;
      }
    }
  }
`;
export const StyledTableCol = styled.td`
  border: 1px solid #aaa;
  padding: 5px 8px;
  word-break: break-all;
  // text-transform: capitalize;
  text-align: ${(props) => (props.align ? props.align : "left")};
`;

export const StyledTablePagination = styled.div`
  /* display: inline-block; */
  padding-top: 1rem;

  .pagination {
    display: flex;
    flex-wrap: wrap;
  }
`;
export const StyledTablePaginationButton = styled.a<{ disabled?: boolean }>`
  float: left;
  /* padding: 6px 12px; */
  text-decoration: none;
  cursor: pointer;
  background: rgb(238, 238, 238);
  border-radius: 2px;
  font-weight: 500;
  color: #333;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  min-height: 32px;
  min-width: 32px;

  & svg {
    font-size: 18px;
  }

  &.active {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: white;
  }

  &:hover:not(.active) {
    background-color: #ddd;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;

      &:hover:not(.active) {
        background: rgb(238, 238, 238);
      }
    `}
`;

export const StyledTablePaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  flex-wrap: wrap;
  font-size: 1.4rem;

  span {
    display: inline-block;
    margin-right: 2rem;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    justify-content: flex-start;
  }
`;
