import { createSlice } from "@reduxjs/toolkit";
import { addFiscalYear, deleteFiscalYear, getFiscalYear, getFiscalYears, updateFiscalYear } from "./fiscalYearApi";

export interface FiscalYearFormTypeI {
  name: string;
  start_date: string;
  end_date: string;
  status: boolean;
}

export interface FiscalYearTypeI {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
  status: boolean;
}

export interface FiscalYearsSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: FiscalYearTypeI[];
  } | null;
  item: FiscalYearTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: FiscalYearsSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const fiscalYearsSlice = createSlice({
  name: "fiscalYears",
  initialState,
  reducers: {
    fiscalYearsCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addFiscalYear.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addFiscalYear.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addFiscalYear.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getFiscalYears.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getFiscalYears.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getFiscalYears.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getFiscalYear.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getFiscalYear.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getFiscalYear.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateFiscalYear.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateFiscalYear.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateFiscalYear.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteFiscalYear.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteFiscalYear.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteFiscalYear.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { fiscalYearsCleanUp } = fiscalYearsSlice.actions;
export default fiscalYearsSlice.reducer;
