import { createSlice } from "@reduxjs/toolkit";
import { IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { addUnit, deleteUnit, getUnit, getUnits, updateUnit } from "./unitApi";
import { keywordTypes } from "../../../hooks/useTranslated";

export interface UnitFormTypeI {
  name: string;
  code: string;
}

export interface UnitTypeI {
  id: string;
  name: string;
  code: string;
}

export interface UnitsSliceStateI {
  loading: boolean;
  data: IPaginatedResponse<UnitTypeI> | null;

  item: UnitTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: UnitsSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    unitsCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addUnit.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addUnit.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addUnit.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getUnits.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getUnits.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getUnits.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getUnit.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getUnit.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getUnit.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateUnit.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateUnit.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateUnit.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteUnit.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteUnit.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteUnit.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { unitsCleanUp } = unitsSlice.actions;
export default unitsSlice.reducer;
