import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table, { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getUnits } from "../../rtk/feature/unit/unitApi";
import { UnitTypeI } from "../../rtk/feature/unit/unitSlice";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiFilter, FiSearch } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import Page from "../../core-ui/Page";
import { Button, Grid } from "@ims/common";
import useTranslated from "../../hooks/useTranslated";
import ReuseForm from "../../core-ui/ReuseForm";
import { useForm } from "react-hook-form";
import getFormattedSort from "../../utils/getFormattedSort";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { realTimeFilter } from "../../utils/real-time-filter";

function UnitManagePage() {
  const [units, setUnits] = useState<UnitTypeI[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const unitsState = useAppSelector((state) => state.units);
  const [sorts, setSorts] = useState<SortsTypeI>({});

  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    formState: { errors: filterErrors },
    reset: filterReset,
    watch: filterWatch,
  } = useForm();

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getUnits({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    dispatch(showLoading(unitsState.loading));
  }, [dispatch, unitsState.loading]);

  useEffect(() => {
    if (unitsState.data) {
      setUnits(unitsState.data.results);
      setTotalItems(unitsState.data.total);
    }
  }, [unitsState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/equipment/unit/edit/${id}`);
  };
  const { keyword } = useTranslated();
  const searchKeyword = filterWatch("search");

  useEffect(() => {
    if (searchKeyword) setUnits(realTimeFilter(unitsState?.data?.results || [], searchKeyword));
    else setUnits(unitsState?.data?.results || []);
  }, [searchKeyword]);

  const onFilterSubmit = (data: any) => {
    dispatch(
      getUnits({
        per_page: perPage,
        search: data.search,
        ordering: getFormattedSort(sorts),
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getUnits({
        per_page: perPage,
        page: page,
        ordering: getFormattedSort(sorts),
      })
    );
    filterReset();
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {keyword["Unit Manage"]} </Page.Title>
        <Page.ButtonGrp>
          <Button variant="primary" onClick={() => navigate(`/equipment/unit/add`)}>
            {keyword["Add Unit"]}
          </Button>
        </Page.ButtonGrp>
      </Page.Container>

      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>
              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol> {keyword["Unit Name"]} </Table.HeadCol>
              <Table.HeadCol> {keyword["Unit Code"]} </Table.HeadCol>
              <Table.HeadCol align="center"> {keyword.Action} </Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {units &&
              units.length > 0 &&
              units.map((unit, index) => {
                return (
                  <Table.Row key={unit.id}>
                    <Table.Col>{englishNepaliNumberTranslation(getSN(index))}</Table.Col>
                    <Table.Col>{unit.name}</Table.Col>
                    <Table.Col>{unit.code}</Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(unit.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default UnitManagePage;
