import { createSlice } from "@reduxjs/toolkit";
import {
  addTransportationType,
  deleteTransportationType,
  getTransportationType,
  getTransportationTypes,
  updateTransportationType,
} from "./transportationTypeApi";

export interface TransportationTypeFormTypeI {
  name: string;
}

export interface TransportationTypeTypeI {
  id: string;
  name: string;
}

export interface TransportationTypesSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: TransportationTypeTypeI[];
  } | null;
  item: TransportationTypeTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: TransportationTypesSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const transportationTypesSlice = createSlice({
  name: "transportationTypes",
  initialState,
  reducers: {
    transportationTypesCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addTransportationType.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addTransportationType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addTransportationType.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getTransportationTypes.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getTransportationTypes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getTransportationTypes.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getTransportationType.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getTransportationType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getTransportationType.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateTransportationType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(
      updateTransportationType.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.hasSubmitted = true;
        state.error = null;
      }
    );
    builder.addCase(
      updateTransportationType.rejected,
      (state, { payload }: any) => {
        state.error = payload;
        state.loading = false;
        state.hasSubmitted = false;
      }
    );
    builder.addCase(deleteTransportationType.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      deleteTransportationType.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      deleteTransportationType.rejected,
      (state, { payload }: any) => {
        state.error = payload;
        state.loading = false;
      }
    );
  },
});

export const { transportationTypesCleanUp } = transportationTypesSlice.actions;
export default transportationTypesSlice.reducer;
