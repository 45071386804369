import { createSlice } from "@reduxjs/toolkit";
import {
  addGeneralDocument,
  deleteGeneralDocument,
  getGeneralDocument,
  getGeneralDocuments,
  updateGeneralDocument,
} from "./generalDocumentApi";

export interface GeneralDocumentFormTypeI {
  title: string;
  document: FileList | null;
  document_type: string;
}

export interface GeneralDocumentTypeI {
  id: string;
  title: string;
  document: string;
  document_type: string;
}

export interface GeneralDocumentsSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: GeneralDocumentTypeI[];
  } | null;
  item: GeneralDocumentTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: GeneralDocumentsSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const generalDocumentsSlice = createSlice({
  name: "generalDocuments",
  initialState,
  reducers: {
    generalDocumentsCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addGeneralDocument.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addGeneralDocument.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addGeneralDocument.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getGeneralDocuments.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getGeneralDocuments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getGeneralDocuments.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getGeneralDocument.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getGeneralDocument.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getGeneralDocument.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateGeneralDocument.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateGeneralDocument.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateGeneralDocument.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteGeneralDocument.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteGeneralDocument.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteGeneralDocument.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { generalDocumentsCleanUp } = generalDocumentsSlice.actions;
export default generalDocumentsSlice.reducer;
