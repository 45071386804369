import { Button } from "@ims/common";
import PopupModal from "../../components/PopupModal/PopupModal";
import { StyledButton } from "../../components/documents/Document.style";

import useTranslated, { keywordTypes } from "../../hooks/useTranslated";

interface ConfirmModalI {
  isOpen: boolean;

  onClose: () => void;
  onConfirmeHandle: () => void;
  onCancelHandle: () => void;
  text: "save" | "update";
}

export default function ConfirmModal({ isOpen, onClose, onConfirmeHandle, onCancelHandle, text }: ConfirmModalI) {
  const { keyword } = useTranslated();
  return (
    <>
      <PopupModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <h1>{text === "save" ? keyword["Please confirm to save"] : keyword["Please confirm to update"]}</h1>
        <StyledButton style={{ marginRight: "10px" }} onClick={onConfirmeHandle}>
          {keyword["Confirm"]}
        </StyledButton>
        <Button onClick={onCancelHandle}>{keyword.Cancel}</Button>
      </PopupModal>
    </>
  );
}
