import { createSlice } from "@reduxjs/toolkit";
import { INetworkResponseWithPagination, INetworkRResponse, IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { IDistrictRate, IDistrictRateForm } from "../district-rate/districtRateSlice";
import { UnitTypeI } from "../unit/unitSlice";
import { getAllDorEquipmentHireRate, getDorEquipmentHireRateById, postDorEquipmentHireRate, updateDorEquipmentHireRate } from "./dorEquipmentHireRateApi";
export interface IDorEquipmentHireRate {
    id: string
    description: string
    unit: UnitTypeI
    operator_allowance: string
    dor_hire_rate: number
    dor_hire_including_fuel: number
    total_rate: number
    fuel_consumption: number
    fuel_charges: number
    district_rate: IDistrictRate

}

interface iInitialDorEquipmentRate extends INetworkRResponse {
    data: IDorEquipmentHireRate | null,
}

interface IInitialState extends INetworkRResponse {
    data: IPaginatedResponse<IDorEquipmentHireRate> | null,
    byId: iInitialDorEquipmentRate,
    formSubmitted: boolean

}
const initialState: IInitialState = {
    data: null,
    error: null,
    formSubmitted: false,
    loading: false,
    byId: {
        data: null,
        error: null,
        loading: false

    }
}
const dorEquipmentRateSlice = createSlice({
    name: "dorEquipmentRateSlice", initialState, reducers: {
        cleanUpDorEquipmentSlice: (state) => {
            return initialState


        },

    },
    extraReducers: (builder) => {
        builder.addCase(getAllDorEquipmentHireRate.pending, (state) => {
            state.data = null;
            state.loading = true;
            state.error = null
        });
        builder.addCase(getAllDorEquipmentHireRate.rejected, (state) => {
            state.data = null;
            state.loading = false;
            state.error = null
        });
        builder.addCase(getAllDorEquipmentHireRate.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.loading = false;
            state.error = null
        })
        builder.addCase(getDorEquipmentHireRateById.pending, (state) => {
            state.byId.data = null;
            state.loading = true;
            state.error = null
        });
        builder.addCase(getDorEquipmentHireRateById.rejected, (state) => {
            state.byId.data = null;
            state.loading = false;
            state.error = null
        });
        builder.addCase(getDorEquipmentHireRateById.fulfilled, (state, { payload }) => {
            state.byId.data = payload;
            state.loading = false;
            state.error = null
        });
        builder.addCase(postDorEquipmentHireRate.pending, (state) => {
            state.formSubmitted = false
        });
        builder.addCase(postDorEquipmentHireRate.fulfilled, (state) => {
            state.formSubmitted = true
        });

        builder.addCase(updateDorEquipmentHireRate.pending, (state) => {
            state.formSubmitted = false
        });
        builder.addCase(updateDorEquipmentHireRate.fulfilled, (state) => {
            state.formSubmitted = true
        });
    }
});
export const { cleanUpDorEquipmentSlice } = dorEquipmentRateSlice.actions;
export default dorEquipmentRateSlice.reducer