import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const MainNavRoot = styled.div`
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 236px;
  height: 100vh;
  /* background: ${(props) => props.theme.palette.primary.main}; */
  background: rgb(0 39 68);
  transition: 0.15s width cubic-bezier(0.4, 0, 1, 1);
  z-index: 999;

  &::before {
    content: "";
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 240px;
    height: 100vh;
    background: url(/logo.png) bottom center / contain no-repeat;
    z-index: -9999;
    opacity: 0.03;
    background-size: cover;
  }
`;

export const MainNavBrandImage = styled.img`
  padding: 1.5rem;
`;

export const MainNavNav = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
`;

export const MainNavSubNav = styled.ul<{ active?: boolean }>`
  display: ${(props) => (props.active ? "flex" : "flex")};
  flex-direction: column;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  overflow: hidden;
  max-height: ${(props) => (props.active ? "100vh" : "0px")};
  transition: max-height 0.3s ease-out 0s;

  &.mobile-active {
    position: absolute;
    z-index: 99;
    display: inline-flex;
    position: absolute;
    max-height: unset;
    padding: 0.5rem;
    left: 60px;
    top: -5px;
    color: ${(props) => props.theme.palette.main};
    background-color: ${({ theme }) => theme.palette.background.default};
    border: 1px solid rgba(0, 0, 0, 0.05);
    min-width: 280px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    overflow: visible;

    &::after {
      content: " ";
      position: absolute;
      left: -15px;
      top: 7px;
      border-top: 15px solid transparent;
      border-right: 15px solid
        ${({ theme }) => theme.palette.background.default};
      border-left: none;
      border-bottom: 15px solid transparent;
    }

    & > li.sub-item {
      position: relative;
      display: block !important;
    }

    & > li a {
      color: ${(props) => props.theme.palette.primary.main} !important;
      margin-bottom: 0px;
    }

    & > li a:hover {
      color: white !important;
      background-color: ${(props) =>
        props.theme.palette.primary.main} !important;
    }

    & > li a span {
      display: block !important;
    }
  }
`;

export const MainNavNavListItem = styled.li`
  position: relative;

  &.sub-item a {
    padding-left: 4.2rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    &:hover {
      color: white;
      background-color: rgba(255, 255, 255, 0.1) !important;
    }

    &.active {
      color: white;
      background-color: rgba(255, 255, 255, 0.25) !important;
    }
  }

  /* &: hover; */
`;
export const MainNavNavItem = styled(Link)`
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  flex: 1;
  color: white;
  margin-bottom: 0.25rem;
  text-decoration: none;

  /* &:hover {
    color: white;
    cursor: pointer !important;
    background: ${(props) => props.theme.palette.primary.main} !important;
  } */

  &.active,
  &:hover {
    cursor: pointer;
    background: white !important;
    /* color: ${(props) => props.theme.palette.primary.main}; */
    color: ${(props) => props.theme.palette.text.primary};

    &::after {
      content: "";
      position: absolute;
      width: 4px;
      height: 100%;
      background: red;
      top: 0;
      left: 0;
    }
  }
`;

export const MainNavDropNavItem = styled.a`
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  flex: 1;
  color: white;
  margin-bottom: 0.25rem;

  /* &:hover {
    color: white;
    background: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
  } */

  &.active,
  &:hover {
    cursor: pointer;
    background: white;
    /* color: ${(props) => props.theme.palette.primary.main}; */
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

export const MainNavDivider = styled.div`
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
`;

export const StyledBrandContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    padding: 0px;
  }

  & .wrapper-brand-title {
    h2,
    h5 {
      font-size: 1.4rem;
      font-weight: 500;
      color: white;
    }

    h5 {
      font-size: 12px;
    }
  }
`;
