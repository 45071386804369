import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { YojanaFormTypeI } from "./yojanaSlice";
import { YojanaSuccess } from "../../../utils/res-mes/response-message-generator";

export const getYojanas = createAsyncThunk(
  "getYojanas",
  async (
    userParams: { per_page: number; page: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/yojana/", {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getYojana = createAsyncThunk("getYojana", async (id: string, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.get(`/yojana/${id}/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const addYojana = createAsyncThunk("addYojana", async (data: YojanaFormTypeI, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.post("/yojana/", data);

    toast.success(YojanaSuccess("added"), {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "dark",
    });

    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const updateYojana = createAsyncThunk(
  "updateYojana",
  async (data: { data: YojanaFormTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/yojana/${data.id}/`, data.data);

      toast.success(YojanaSuccess("updated"), {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
      });

      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
