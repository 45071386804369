import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getFiscalYears } from "../../rtk/feature/fiscal-year/fiscalYearApi";
import { FiscalYearTypeI } from "../../rtk/feature/fiscal-year/fiscalYearSlice";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import Page from "../../core-ui/Page";
import { Button } from "@ims/common";
import useTranslated from "../../hooks/useTranslated";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";

function FiscalYearManagePage() {
  const [fiscalYears, setFiscalYears] = useState<FiscalYearTypeI[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fiscalYearsState = useAppSelector((state) => state.fiscalYears);

  /* Table pagination states */
  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getFiscalYears({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    dispatch(showLoading(fiscalYearsState.loading));
  }, [dispatch, fiscalYearsState.loading]);

  useEffect(() => {
    if (fiscalYearsState.data) {
      setFiscalYears(fiscalYearsState.data.results);
      setTotalItems(fiscalYearsState.data.total);
    }
  }, [fiscalYearsState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/settings/fiscal-year/edit/${id}`);
  };
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {keyword["Fiscal Year Manage"]}</Page.Title>
        <Button variant="primary" onClick={() => navigate(`/settings/fiscal-year/add`)}>
          {keyword["Add Fiscal Year"]}
        </Button>
      </Page.Container>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol> {keyword["Fiscal Year"]} </Table.HeadCol>
              <Table.HeadCol> {keyword["Start Date"]}</Table.HeadCol>
              <Table.HeadCol> {keyword["End Date"]} </Table.HeadCol>
              <Table.HeadCol>{keyword.Status} </Table.HeadCol>
              <Table.HeadCol align="center"> {keyword.Action} </Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {fiscalYears &&
              fiscalYears.length > 0 &&
              fiscalYears.map((fiscalYear, index) => {
                return (
                  <Table.Row key={fiscalYear.id}>
                    <Table.Col>{englishNepaliNumberTranslation(getSN(index))}</Table.Col>
                    <Table.Col>{fiscalYear.name}</Table.Col>
                    <Table.Col>{fiscalYear.start_date}</Table.Col>
                    <Table.Col>{fiscalYear.end_date}</Table.Col>
                    <Table.Col>{fiscalYear.status ? "Active" : "Not Active"}</Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(fiscalYear.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default FiscalYearManagePage;
