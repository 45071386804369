import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { deleteAnalyzeRate, getAnalyzeRates } from "../../../rtk/feature/analyze-rate/analyzeRateApi";
import {
  AnalyzeRateFormTypeI,
  AnalyzeRateTypeI,
  RateEquipmentTypeII,
} from "../../../rtk/feature/analyze-rate/analyzeRateSlice";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { showLoading } from "../../../rtk/feature/auth/authSlice";
import usePagination from "../../../utils/usePagination";
import Page from "../../../core-ui/Page";
import { Button } from "@ims/common";
import { MdDeleteOutline } from "react-icons/md";
import styled, { css } from "styled-components";
import RateItem from "./RateItem";
import RateIntro from "./RateIntro";
import { toast } from "react-toastify";
import { deleteRateEquipment } from "../../../rtk/feature/rate-equipment/rateEquipmentApi";
import { RateEquipmentFormTypeI } from "../../../rtk/feature/rate-equipment/rateEquipmentSlice";
import useTranslated from "../../../hooks/useTranslated";

interface RateSubTablePropsI {
  budgetID: string | null;
  tableData: RateEquipmentFormTypeI[];
  setTableData: React.Dispatch<React.SetStateAction<RateEquipmentFormTypeI[]>>;
  rateID: string | null;
  type: string;
}

export const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "INR" });

const newItem = {
  id: Date.now().toString(),
  equipment: null,
  quantity: 0,
  total_amount: 0,
  unit_rate: 0,
  _is_latest: true,
};

function RateSubTable(props: RateSubTablePropsI) {
  const { budgetID, tableData, setTableData, rateID, type } = props;
  const [totalAmt, setTotalAmt] = useState(0);
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();

  const handleAdd = () => {
    setTableData((item) => {
      return [...item, newItem];
    });
  };

  const handleChange = (data: any) => {
    setTableData((item) =>
      item.map((el) => {
        if (el.id === data.id) return data;
        return el;
      })
    );
  };

  useEffect(() => {
    calculateTotal(tableData);
  }, [tableData]);

  const handleDelete = (ID: any) => {
    setTableData((item) => item.filter((el) => el.id !== ID));
  };

  const calculateTotal = (tableData: RateEquipmentFormTypeI[]) => {
    const total = tableData.reduce((prev, curr) => {
      return prev + curr.total_amount;
      // if (curr.unit_rate) prev = prev + curr.quantity * curr.unit_rate;
    }, 0);
    setTotalAmt(total);
  };

  return (
    <BasicTable>
      <tbody>
        <tr>
          <td width="40px">{keyword["S.N."]}.</td>
          <td>Material</td>
          <td width="110px">Quantity</td>
          <td width="110px">Analysis rate</td>
          <td width="110px">Total Price</td>
          <td width="80px" align="center">
            Action
          </td>
        </tr>

        {tableData.map((item, index) => (
          <RateItem
            key={item.id}
            index={index + 1}
            value={tableData.find((el) => el.id === item.id)}
            onAdd={handleAdd}
            onChange={handleChange}
            onDelete={handleDelete}
            total={tableData.length}
            budgetID={budgetID}
            rateID={rateID}
            type={type}
            disabled={true}
          />
        ))}

        <tr>
          <td colSpan={4} align="right">
            Total
          </td>
          <td colSpan={2}>{formatter.format(totalAmt)}</td>
        </tr>
      </tbody>
    </BasicTable>
  );
}

export default RateSubTable;

const BasicTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;

  & tr td {
    padding: 5px;
    border: 1px solid #f1f1f1;
    background: #f9f9f9;

    & strong {
      font-weight: 500;
    }

    input {
      width: 100%;
    }
  }
`;
