import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addFixedRateEquipment,
  deleteFixedRateEquipment,
  getFixedRateEquipments,
  updateFixedRateEquipment,
} from "./fixedRateEquipmentApi";

export interface FixedRateEquipmentFormTypeI {
  id?: string;
  equipment: any;
  quantity: number;
  total_amount: number;
  unit_rate: number;
  _is_latest?: boolean;
}

export interface FixedRateEquipmentWorkTypeI {
  id: string;
  name: string;
  code: string;
  measurement: string;
}

export interface FixedRateEquipmentSubTypeI {
  id: string;
  equipment: {
    id: string;
    english_name: string;
    nepali_name: string;
    unit_rate: number;
    type: string;
  };
  quantity: number;
  total_amount: number;
  created_on: string;
  modified_on: string;
}

export interface EquipmentTypeI {
  id: string;
  english_name: string;
  nepali_name: string;
  unit_rate: number;
  type: string;
}
interface ITotalAmount { sub_total: number, grand_total: number, sub_total_after_cp: number, contractor_profit: number, vat_amt: number }

export interface FixedRateEquipmentsSliceStateI {
  loading: boolean;
  data: FixedRateEquipmentSubTypeI[];
  item: FixedRateEquipmentSubTypeI | null;
  hasSubmitted: boolean;
  error: any;
  total_amount: ITotalAmount | null,
  individual_total: {
    material: number | null,
    human_resource: number | null,
    machine: number | null
  }
}

const initialState: FixedRateEquipmentsSliceStateI = {
  loading: false,
  item: null,
  data: [],
  hasSubmitted: false,
  error: null,
  total_amount: null,
  individual_total: {
    material: 0,
    human_resource: 0,
    machine: 0
  }

};

const fixedRateEquipmentSlice = createSlice({
  name: "fixedRateEquipment",
  initialState,
  reducers: {
    fixedRateEquipmentCleanUp: (state) => {
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addFixedRateEquipment.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addFixedRateEquipment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = [...state.data, payload];
      // state.data = payload.equipments;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addFixedRateEquipment.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getFixedRateEquipments.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
      state.individual_total.human_resource = 0;
      state.individual_total.machine = 0;
      state.individual_total.material = 0;
      state.total_amount = null;

    });
    builder.addCase(
      getFixedRateEquipments.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.equipments) {
          state.data = payload.equipments;
          state.total_amount = payload.total_amount;
          state.individual_total = payload.individual_total

        }
        else state.data = [];
        state.error = null;
      }
    );
    builder.addCase(getFixedRateEquipments.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(updateFixedRateEquipment.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateFixedRateEquipment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.data = state.data.map((el) => {
        if (el.id === payload.id) {
          return payload;
        }
        return el;
      });
      // state.data = payload.equipments;
      state.error = null;
    });
    builder.addCase(updateFixedRateEquipment.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteFixedRateEquipment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteFixedRateEquipment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = [...state.data.filter((el) => el.id !== payload)];
      state.error = null;
    });
    builder.addCase(deleteFixedRateEquipment.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { fixedRateEquipmentCleanUp } = fixedRateEquipmentSlice.actions;
export default fixedRateEquipmentSlice.reducer;
