import { Button, Form, Grid } from "@ims/common";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Auth from "../../components/auth/Auth";
import { login, sendOTP } from "../../rtk/feature/auth/authApi";
import { authCleanUp, ForgotPasswordFormTypeI, showLoading } from "../../rtk/feature/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import AuthLayout from "./AuthLayout";

const ForgotPasswordPage = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ForgotPasswordFormTypeI>();
  const dispatch = useAppDispatch();
  const authSelector = useAppSelector((state) => state.auth);
  const usersSelector = useAppSelector((state) => state.users);
  const navigate = useNavigate();

  const { hasSubmitted, loading } = authSelector;

  useEffect(() => {
    if (hasSubmitted) dispatch(authCleanUp());
    dispatch(showLoading(loading));
  }, [dispatch, hasSubmitted, loading]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = authSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/auth/change-password");
    }
  }, [usersSelector, dispatch, navigate, authSelector]);

  const onSubmit: SubmitHandler<ForgotPasswordFormTypeI> = (data) => {
    dispatch(sendOTP(data));
  };

  return (
    <AuthLayout>
      <div style={{ margin: "auto" }}>
        <h2>Password Reset</h2>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row gap={1.5}>
              <Grid.Col spanXs={12}>
                <Form.FormGroup error={Boolean(errors && errors["email"])}>
                  <Form.FloatingInput
                    label="Email"
                    type="text"
                    register={register("email", {
                      required: "Your email is required.",
                    })}
                  />
                  {/* <Form.Label>Email</Form.Label>
                  <Form.Input
                    type="text"
                    placeholder="Enter your email"
                    register={register("email", {
                      required: "Your email is required.",
                    })}
                  /> */}
                  {errors && errors["email"] && errors["email"].message && (
                    <Form.HelperText>{errors["email"].message}</Form.HelperText>
                  )}
                </Form.FormGroup>
              </Grid.Col>

              <Grid.Col spanMd={12} spanXs={12}>
                <Button type="submit" variant="auth">
                  Send Request
                </Button>
                <Form.Link to="/login" style={{ display: "block" }}>
                  Go to login.
                </Form.Link>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
