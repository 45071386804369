import { Button, Grid, MainLayout, Table } from "@ims/common";
import React, { useState } from "react";
import { FiEye, FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Page from "../../../core-ui/Page";
import useTranslated from "../../../hooks/useTranslated";
import { IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { IChequeVerificationByPrasasakiya } from "../../../rtk/feature/cheque-verify-by-prasasakiya/chequeVerifyByPrasasakiyaSlice";
import usePagination from "../../../utils/usePagination";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import { useForm } from "react-hook-form";
import { getChequeVerifyByPrasasakiya } from "../../../rtk/feature/cheque-verify-by-prasasakiya/chequeVerifyByPrasasakiyaAPI";
import getFormattedSort from "../../../utils/getFormattedSort";
import { useAppDispatch } from "../../../rtk/hook";
import ReuseForm from "../../../core-ui/ReuseForm";
import { MdVerified } from "react-icons/md";
import { GoUnverified } from "react-icons/go";

interface IProps {
  data: IPaginatedResponse<IChequeVerificationByPrasasakiya>;
  onFilterSubmitFn?: (data: any) => void;
  handleFilterResetFn?: () => void;
  sorts?: SortsTypeI;
  setSorts?: React.Dispatch<React.SetStateAction<SortsTypeI>>;
}

const ChequeVerifyByPrasasakiya = (props: IProps) => {
  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    reset: filterReset,
    formState: { errors: filterErrors },
    watch: filterWatch,
  } = useForm();
  const dispatch = useAppDispatch();

  const { data, sorts, setSorts, onFilterSubmitFn, handleFilterResetFn } = props;
  const [total, setTotal] = useState(data.total);
  const navigate = useNavigate();

  const { Pagination, perPage, page } = usePagination({ total });

  const showDetails = (id: string) => {
    navigate(`/bill-payments/cheque-details/${id}`);
  };
  const { keyword } = useTranslated();

  const onFilterSubmit = (data: any) => {
    !!onFilterSubmitFn && onFilterSubmitFn(data);
  };

  const handleFilterReset = () => {
    !!handleFilterResetFn && handleFilterResetFn();
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {keyword["Bill Payments"]}</Page.Title>
      </Page.Container>

      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>

              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container>
        <Table id="export-table">
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol> {keyword.From} </Table.HeadCol>

              <Table.HeadCol> {keyword["Sent To"]}</Table.HeadCol>
              <Table.HeadCol> {keyword["Cheque Verified By Lekha"]} </Table.HeadCol>
              <Table.HeadCol>{keyword.Description} </Table.HeadCol>
              <Table.HeadCol> {keyword["Is Verified"]} </Table.HeadCol>
              <Table.HeadCol> {keyword.Actions}</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data?.results?.length > 0 &&
              data?.results?.map((data, index) => {
                return (
                  <Table.Row key={data?.id}>
                    <Table.Col>{index + 1}</Table.Col>
                    <Table.Col>{data?.plan_cheque?.plan_bill_verification?.from_to_user?.first_name}</Table.Col>
                    <Table.Col>{data?.plan_cheque?.send_to_user?.first_name}</Table.Col>

                    <Table.Col>{data?.cheque_verify_by_prasasakiya ? "True" : "False"}</Table.Col>
                    <Table.Col>{data?.plan_cheque?.plan_bill_verification?.description}</Table.Col>

                    <Table.Col>
                      <span style={{ marginLeft: "30%" }}>
                        {data?.cheque_verify_by_prasasakiya ? (
                          <MdVerified color="blue" size={20} title={keyword["Verified"]} />
                        ) : (
                          <GoUnverified color="#888" size={20} title={keyword["Not Verified"]} />
                        )}
                      </span>
                    </Table.Col>
                    <Table.Col>
                      <Table.ActionButton onClick={() => showDetails(data?.id)}>
                        <FiEye />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
};

export default ChequeVerifyByPrasasakiya;
