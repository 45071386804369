export const TypeChoice = ["जनशक्ति", "सामग्री", "मेसिन/साधन"];
export const EquipmentTypeChoice = ["स्थानिय", "अन्य"];
export const ContractorTypeChoice = ["उपभोक्ता समिति", "निर्माणकर्ता"];
export const ShapeChoice = ["pyramid", "cylinder", "circle", "rectangle", "square"];
export const FormulaChoice = ["area", "volume", "surface_area", "curved surface area", "perimeter"];
export const RoleChoice = [
  // "NormalUser",
  "Palika User",
  "Ward Sachib",
  "Prasasakiya Adhikrit",
  "Purwadhar Sakha",
  "Yojana Sakha",
  // "Gaupalika Stariya Anugaman Samiti",
  "Lekha Sakha",
  "Engineer",
  "Sub Engineer",
];

export const getOptionFromValue = (options: String[], value: string) => {
  const found = options.find((opt) => opt === value);
  if (found) return { label: found, value: options.indexOf(found) };
  return null;
};

export const getOptionsFromChoice = (options: String[]) => {
  return options.map((option) => ({
    value: option,
    label: option,
  }));
};
