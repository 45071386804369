import { useState } from "react"

const useDiscloure = () => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const open = () => {
        setIsOpen(true)

    }
    const close = () => {
        setIsOpen(false)
    }

    return { isOpen, toggle, open, close }
}

export default useDiscloure