import { Button, Flex, Form, MainLayout } from "@ims/common";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getBillPaymentById, updateBillPayment } from "../../rtk/feature/bill-payment/billPaymentAPI";
import { cleanupBillPayment } from "../../rtk/feature/bill-payment/billPaymentSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import useTranslated from "../../hooks/useTranslated";

interface IForm {
  cheque_file: FileList;
}
const BillPaymentsDetails = () => {
  const params = useParams();
  const id = params.id as string;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { keyword } = useTranslated();
  const { byId: data, formSubmitted } = useAppSelector((state) => state.billPayment);
  useEffect(() => {
    dispatch(getBillPaymentById({ id }));
    return () => {
      dispatch(cleanupBillPayment());
    };
  }, []);
  const { control, getValues } = useForm<IForm>();

  const submit = () => {
    const chequeFile = getValues("cheque_file");
    const formData = new FormData();
    formData.append("cheque_photo", chequeFile[0]);
    formData.append("cheque_verify_by_lekha", "true");

    if (chequeFile) dispatch(updateBillPayment({ id, data: formData }));
  };

  const handleCancel = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (formSubmitted) {
      navigate(`/bill-payments`);
    }
  }, [formSubmitted]);

  return (
    <MainLayout.Main>
      <h2>{keyword["Bill"]}</h2>
      {data?.data?.plan_bill_verification.planning_bill_id.bill_file && (
        <img
          style={{ width: "100%", height: "35rem", objectFit: "contain" }}
          src={data?.data?.plan_bill_verification?.planning_bill_id.bill_file}
        />
      )}
      <Flex.Col>
        <Form.Label>{keyword["Cheque photo"]}</Form.Label>
        <Controller
          name="cheque_file"
          rules={{
            required: false,
          }}
          control={control}
          render={({ field: { onChange, value }, fieldState, formState }) => {
            return (
              <Form.File
                id="pre_project_photo"
                fileType="image"
                accept="image/*"
                onChange={(e) => {
                  onChange(e.target.files);
                }}
                placeholder="add cheque"
              />
            );
          }}
        ></Controller>
      </Flex.Col>
      <Button style={{ marginTop: "2rem" }} variant="primary" onClick={submit}>
        {keyword.Submit}
      </Button>{" "}
      <Button style={{ marginTop: "2rem" }} onClick={handleCancel}>
        {keyword.Cancel}
      </Button>
    </MainLayout.Main>
  );
};

export default BillPaymentsDetails;
