import React, { KeyboardEvent, useContext, useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import Tab from "../../../components/tab/Tab";
import styled from "styled-components";
import ReuseForm, { FormContext } from "../../../core-ui/ReuseForm";
import { useForm } from "react-hook-form";
import { Form, Table } from "@ims/common";
import { FiDelete, FiSave, FiTrash } from "react-icons/fi";
import { EstimationDetailTypeI, PlanEstimateFormTypeI } from "../../../rtk/feature/plan-estimate/planEstimateSlice";
import {
  addPlanEstimate,
  deletePlanEstimate,
  updatePlanEstimate,
} from "../../../rtk/feature/plan-estimate/planEstimateApi";
import { toast } from "react-toastify";
import { AnalyzeRateFormTypeI, RateEquipmentTypeII } from "../../../rtk/feature/analyze-rate/analyzeRateSlice";
import { addAnalyzeRate } from "../../../rtk/feature/analyze-rate/analyzeRateApi";
import AsyncSelect from "react-select/async";
import { getEquipment, getEquipments } from "../../../rtk/feature/equipment/equipmentApi";
import { StyledAsyncReactSelect } from "@ims/common/src/core-ui/forms/Form.style";
import { EquipmentTypeI } from "../../../rtk/feature/equipment/equipmentSlice";
import {
  addRateEquipment,
  deleteRateEquipment,
  updateRateEquipment,
} from "../../../rtk/feature/rate-equipment/rateEquipmentApi";
import { RateEquipmentFormTypeI, RateEquipmentSubTypeI } from "../../../rtk/feature/rate-equipment/rateEquipmentSlice";

interface AnalyzeRateViewPropsI {
  value?: RateEquipmentFormTypeI;
  onChange: (data: RateEquipmentFormTypeI) => void;
  onDelete: (id: any) => void;
  onAdd: () => void;
  index: number;
  total: number;
  budgetID: string | null;
  rateID: string | null;
  type: string;
  disabled?: boolean;
}

function RateItem(props: AnalyzeRateViewPropsI) {
  const dispatch = useAppDispatch();
  const { onChange, value: itemValue, budgetID, rateID, onDelete, type } = props;

  const {
    formState: { errors, isDirty },
    control,
    getValues,
    watch,
    setValue,

    reset,
  } = useForm<RateEquipmentFormTypeI>({
    defaultValues: {},
  });

  const equipmentSelector = useAppSelector((state) => state.equipments);
  const [equipments, setEquipments] = useState<EquipmentTypeI[]>([]);

  const equipmentIdField = watch("equipment");
  const quantityField = watch("quantity");

  useEffect(() => {
    if (equipmentSelector.data?.results) setEquipments(equipmentSelector.data?.results);
  }, [equipmentSelector.data]);

  useEffect(() => {
    if (!itemValue) return;

    console.warn("item value equipment ", itemValue.equipment);

    if (itemValue.equipment?.id) {
      setValue("equipment", {
        label: itemValue.equipment.english_name,
        value: itemValue.equipment.id,
        unit_rate: itemValue.equipment.unit_rate,
      });
    }
  }, [setValue, dispatch, itemValue, rateID]);

  useEffect(() => {
    if (props.value?.id) setValue("id", props.value.id);
    if (props.value?.quantity) setValue("quantity", +props.value.quantity);
    if (props.value?.unit_rate) setValue("unit_rate", +props.value.equipment.unit_rate);
    if (props.value?._is_latest) setValue("_is_latest", props.value._is_latest);
  }, [setValue, rateID]);

  // useEffect(() => {
  //   watch((value, { name, type }) => {
  //     const finalData = { ...itemValue, ...getValues() };
  //     const equipmentId = getValues("equipment");
  //     if (equipmentId?.value) {
  //       finalData.equipment = equipmentId?.value;
  //     }
  //     // onChange(finalData);
  //   });
  // }, [watch, getValues, onChange, itemValue]);

  const handleDelete = (id: string | undefined) => {
    if (!budgetID || !id || !rateID) return toast.warning("Budget ID or Rate Equipment ID is not valid.");
    dispatch(deleteRateEquipment({ budgetID: budgetID, deleteID: id, id: rateID }));
  };

  useEffect(() => {
    const equipment = getValues("equipment");
    setValue("unit_rate", +equipment?.unit_rate);
    if (equipmentIdField && quantityField) {
      const result = +quantityField * +equipment?.unit_rate;
      setValue("total_amount", +result.toFixed(2));
    }
  }, [equipmentIdField, getValues, quantityField, setValue]);

  const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    setTimeout(async () => {
      await dispatch(getEquipments({ page: 1, per_page: 5, search: inputValue, type })).then((data) => {
        const options = data.payload.results.map((item: EquipmentTypeI) => ({
          label: item.english_name,
          value: item.id,
          unit_rate: item.unit_rate,
        }));

        callback(options);
      });
    }, 1500);
  };

  const handleSubmit = (id: string | undefined) => {
    const fields = getValues();
    if (!budgetID || !rateID || !fields.equipment || !id)
      return toast.warning("Budget ID or Rate Equipment ID is not valid.");

    if (fields.equipment && fields.equipment.value) {
      fields.equipment = fields.equipment.value;
    }

    if (!fields.equipment) return toast.warning("no equipment selected");

    const formData = fields;

    if (formData._is_latest) {
      props.onDelete(id);

      dispatch(addRateEquipment({ data: formData, budgetID: budgetID, id: rateID }));
    } else {
      dispatch(
        updateRateEquipment({
          data: formData,
          budgetID: budgetID,
          id: rateID,
          updateID: id,
        })
      );
    }
  };

  const handleEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSubmit(props?.value?.id);
    }
  };

  return (
    <ReuseForm.ReuseFormTr control={control} onKeyDown={handleEnter}>
      <td>{props.index < 10 ? "0" + props.index : props.index}</td>
      <td>
        <ReuseForm.AsyncSelect
          name="equipment"
          cacheOptions
          loadOptions={loadOptions}
          isClearable
          defaultOptions
          isDisabled={props.disabled}
        />
      </td>
      <td>
        <ReuseForm.Input type="number" name="quantity" placeholder="Quantity" disabled={props.disabled} />
      </td>
      <td>
        <ReuseForm.Input type="number" name="unit_rate" placeholder="Rs." readOnly disabled />
      </td>
      <td>
        <ReuseForm.Input type="number" name="total_amount" placeholder="Rs." readOnly disabled />
      </td>
      <td align="center">
        <Table.ActionButton
          color="green"
          type="button"
          onClick={() => handleSubmit(props?.value?.id)}
          // disabled={!isDirty}
          disabled={props.disabled}
        >
          <FiSave />
        </Table.ActionButton>

        <Table.ActionButton
          color="red"
          // disabled={props?.value?._is_latest}
          disabled={props.disabled}
          onClick={() => {
            // props.onDelete(props?.value?.id);
            handleDelete(props?.value?.id);
          }}
        >
          <FiTrash />
        </Table.ActionButton>
      </td>
    </ReuseForm.ReuseFormTr>
  );
}

export default RateItem;

export const StyledTabHeader = styled(Tab.TabHeader)`
  display: flex;
  & > button {
    flex: 1;
  }
`;
