import { MainLayout, Table } from "@ims/common";
import React, { useEffect, useState } from "react";
import { FiEye, FiUpload } from "react-icons/fi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Tab from "../../../components/tab/Tab";
import { roles } from "../../../constants/constants";
import { getPlanBriefs } from "../../../rtk/feature/plan-brief/planBriefApi";
import { getPlanEstimates } from "../../../rtk/feature/plan-estimate/planEstimateApi";
import { getAllVerifications } from "../../../rtk/feature/plan-verification/planVerificationAPI";
import { IVerification, Yojana, planStatus } from "../../../rtk/feature/plan-verification/planVerificationSlice";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { userFriendlyDate } from "../../../utils/date";
import { StyledTabHeader } from "../PlanPage";
import PopupModal from "../../../components/PopupModal/PopupModal";
import UploadDocument from "../ReviewPlanDetails/UploadDocument";
import YojanaBookTemplateChoice from "../yojana-books/components/YojanaBookTemplateChoice";
import { BudgetsSliceStateI } from "../../../rtk/feature/budget/budgetSlice";
import useTranslated from "../../../hooks/useTranslated";

const ReviewPlan = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedYojana, setSelectedYojana] = useState<Yojana>({
    id: "",
    serial_no: -1,
    hint: "",
    name: "",
    expenditure_heading: "",
    source: "",
    attention: "",
    unit: "",
    allocation: "",
    expenditure: "",
    expenditure_rate: "",
    balance: "",
    yojana_brief: "",
    planning_estimation: "",
  });
  const [isSelectTemplate, setSelectTemplate] = useState<boolean>(false);
  const { keyword } = useTranslated();
  const dispatch = useAppDispatch();
  const allVerification = useAppSelector((state) => state.sendPlanVerification.getAllData.data);
  const allBudgets = useAppSelector((state) => state.budgets.data);

  const [searchParams, setSearchParams] = useSearchParams();
  const columns: ["Name", "Sent From", "Received Date", "Status", "Yojana Started", "File", "View"] = [
    "Name",
    "Sent From",
    "Received Date",
    "Status",
    "Yojana Started",
    "File",
    "View",
  ];

  useEffect(() => {
    dispatch(getAllVerifications());
  }, []);

  const tab = searchParams.get("tab");
  const navigate = useNavigate();
  const handleShow = (id: string, verification: IVerification) => {
    // if (verification.is_started && isYojanaSakha) {
    //   toast.error("you don't have permissions");
    //   return;
    // }
    if (
      (verification.is_started || verification.is_verified) &&
      /* isPrasasakiya || */ planStatus.CORRECTION === verification.status
    ) {
      toast.error(keyword["You don't have permissions"]);
      return;
    }
    navigate(`/plan-management/review-plan/${id}`);
  };
  const myRole = useAppSelector((state) => state.auth.user?.role);
  const showYojanaStarted =
    myRole === roles.ADMIN ||
    myRole === roles.Prasasakiya_Adhikrit ||
    myRole === roles.Engineer ||
    myRole === roles.SUB_ENGINNER ||
    myRole === roles.Yojana_Sakha;
  const areAllVerified = allVerification?.every((el) => !el.is_verified);
  const isYojanaSakha = myRole === roles.Yojana_Sakha;
  const isPrasasakiya = myRole === roles.Prasasakiya_Adhikrit;
  if (isYojanaSakha && (allVerification?.length === 0 || areAllVerified))
    return (
      <MainLayout.Main>
        {" "}
        <h1>No plans pervied yet </h1>{" "}
      </MainLayout.Main>
    );

  const openModalHandler = (y: Yojana) => {
    setSelectedYojana(y);
    setIsModalOpen(true);
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
    setSelectTemplate(false);
  };

  const getPdf = (id: string) => {
    const matchedBudget = allBudgets && allBudgets.find((budget) => budget.id === id);

    if (matchedBudget) return matchedBudget.pdf_file;

    return "";
  };

  return (
    <MainLayout.Main>
      <Table.Container>
        <Table id="user-table">
          <Table.Row>
            {columns.map((col, i) => {
              if (col === "Yojana Started" && !showYojanaStarted) return;
              return <Table.HeadCol key={col}> {keyword[col]} </Table.HeadCol>;
            })}
          </Table.Row>
          <Table.Body>
            {allVerification &&
              allVerification.length > 0 &&
              allVerification.map((verification, index) => {
                if (!verification.is_verified && isYojanaSakha) return null;

                return (
                  <Table.Row key={verification.id}>
                    <Table.Col>{verification.yojana.name}</Table.Col>

                    <Table.Col>{verification.send_from}</Table.Col>

                    <Table.Col>{userFriendlyDate(verification.created_on)}</Table.Col>
                    {/* <Table.Col>{userFriendlyDate(verification.modified_on)}</Table.Col> */}
                    <Table.Col> {verification.status} </Table.Col>
                    {showYojanaStarted && <Table.Col>{verification.is_started ? "started" : "Not started"}</Table.Col>}
                    <Table.Col>
                      {" "}
                      <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <FiUpload
                          cursor="pointer"
                          onClick={() => {
                            openModalHandler(verification.yojana);
                          }}
                        />
                        {!!getPdf(verification.yojana.id) ? (
                          <a href={getPdf(verification.yojana.id)} target="_black">
                            <AiOutlineFilePdf cursor="pointer" color="black" />
                          </a>
                        ) : (
                          <AiOutlineFilePdf cursor="not-allowed" color="gray" title="No File" />
                        )}
                      </div>
                    </Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton color="green" onClick={() => handleShow(verification.id, verification)}>
                        <FiEye />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Table.Container>
      <PopupModal isOpen={isModalOpen} onClose={closeModalHandler}>
        {isSelectTemplate ? (
          <YojanaBookTemplateChoice budgetId={selectedYojana.id} onClose={closeModalHandler} />
        ) : (
          <UploadDocument setSelectTemplate={setSelectTemplate} onClose={closeModalHandler} yojana={selectedYojana} />
        )}
      </PopupModal>
    </MainLayout.Main>
  );
};

export default ReviewPlan;
