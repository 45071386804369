import { Button, Form } from "@ims/common";
import { useNavigate } from "react-router-dom";
import useTranslated from "../../../hooks/useTranslated";
import { useState } from "react";
import { addYojanaBook, getYojanaBook } from "../../../rtk/feature/yojana-books/yojanaBooksApi";
import { useAppDispatch } from "../../../rtk/hook";
import { toast } from "react-toastify";
import { Yojana } from "../../../rtk/feature/plan-verification/planVerificationSlice";
import { generateErrorMessage } from "../../../utils/res-mes/response-message";

interface UploadDocumentPropsI {
  yojana: Yojana;
  onClose: () => void;
  setSelectTemplate: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UploadDocument({ yojana, onClose, setSelectTemplate }: UploadDocumentPropsI) {
  const [file, setFile] = useState<string | ArrayBuffer | null>(null);
  const { keyword } = useTranslated();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const useTemplateHandler = () => {
    setSelectTemplate(true);
  };

  const convertFileToBase64 = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFile(reader.result);
      };
    }
  };

  const handleSubmit = async () => {
    try {
      if (file) {
        const successfullySend = await dispatch(
          addYojanaBook({
            data: { order: yojana.serial_no, title: yojana.name, pdf_file: file?.toString() },
            id: yojana.id,
          })
        );
        if (successfullySend.meta.requestStatus !== "rejected") {
          onClose();
          toast.success(keyword["Successfully file sent."]);
          await dispatch(getYojanaBook(yojana.id));
        }
      } else {
        toast.error(keyword["No file choosen."]);
      }
    } catch (error) {
      generateErrorMessage();
    }
  };

  return (
    <>
      <h1>{keyword["Upload Document"]}</h1>
      <div style={{ display: "flex", marginTop: "1rem", marginBottom: "1rem" }}>
        <Form.File
          fileType="xlsx"
          id="budget"
          type="file"
          accept="application/pdf"
          onChange={convertFileToBase64}
        ></Form.File>
        {!file && <Button onClick={useTemplateHandler}>{keyword["Use Template"]}</Button>}
      </div>
      {file && (
        <Button variant="primary" onClick={handleSubmit}>
          Send
        </Button>
      )}
    </>
  );
}
