import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { addYojana, getYojana, updateYojana } from "../../rtk/feature/yojana/yojanaApi";
import { useNavigate, useParams } from "react-router-dom";
import { YojanaFormTypeI, yojanasCleanUp } from "../../rtk/feature/yojana/yojanaSlice";
import ReuseForm from "../../core-ui/ReuseForm";
import useTranslated from "../../hooks/useTranslated";

const statusOptions = [
  { value: "Created", label: "Created" },
  { value: "Processing", label: "Processing" },
  { value: "Finalized", label: "Finalized" },
];

export interface YojanaFormTypeII extends Omit<YojanaFormTypeI, "status"> {
  status: {
    value: string;
    label: string;
  };
}

function YojanaFormPage() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<YojanaFormTypeII>({
    defaultValues: {
      title: "",
      address: "",
      total_cost: "",
      agreement_date: "",
      start_date: "",
      end_date: "",
    },
  });

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const yojanasSelector = useAppSelector((state) => state.yojanas);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(yojanasCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getYojana(params.id));
      setEditMode(true);
    } else {
      dispatch(yojanasCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { item } = yojanasSelector;

  useEffect(() => {
    if (item !== null) {
      const status = statusOptions.find((status) => status.value === item.status);
      setValue("title", item.name);
      setValue("address", item.address);
      setValue("total_cost", item.total_cost);
      setValue("agreement_date", item.agreement_date);
      setValue("start_date", item.start_date);
      setValue("end_date", item.end_date);
      if (status) setValue("status", status);
    } else {
      reset();
    }
  }, [item, setValue, reset]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = yojanasSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/yojana/list");
    }
  }, [yojanasSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<YojanaFormTypeII> = (data) => {
    if (editMode && params.id) {
      const newData = { ...data, status: data.status.value };
      dispatch(updateYojana({ data: newData, id: params.id }));
    } else {
      const newData = { ...data, status: data.status.value };
      dispatch(addYojana(newData));
    }
  };

  const handleCancel = () => {
    navigate("/yojana/list");
  };
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <h4>{editMode ? "Edit" : "Create"} Yojana</h4>
      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label="Title"
                name="title"
                placeholder="Enter your title"
                rules={{ required: keyword["This is required field."] }}
                error={errors["title"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label="Total Cost"
                name="total_cost"
                placeholder="Enter your total cost"
                rules={{ required: keyword["This is required field."] }}
                error={errors["total_cost"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label="Address"
                name="address"
                placeholder="Enter your address"
                rules={{ required: keyword["This is required field."] }}
                error={errors["address"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label="Agreement Date"
                name="agreement_date"
                type="date"
                placeholder="Enter your agreement date"
                rules={{ required: keyword["This is required field."] }}
                error={errors["agreement_date"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label="Start Date"
                name="start_date"
                type="date"
                placeholder="Enter your start date"
                rules={{ required: keyword["This is required field."] }}
                error={errors["agreement_date"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label="End Date"
                name="end_date"
                type="date"
                placeholder="Enter your end date"
                rules={{ required: keyword["This is required field."] }}
                error={errors["end_date"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.ReactSelect
                label={keyword.Status}
                name="status"
                options={statusOptions}
                rules={{ required: keyword["This is required field."] }}
                error={errors["status"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? "Update Yojana" : "Create Yojana"}
              </Button>
              <Button onClick={handleCancel}>{keyword.Cancel}</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
}

export default YojanaFormPage;
