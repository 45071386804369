import React, { useState } from "react";
import { AiFillFileText } from "react-icons/ai";
import styled from "styled-components";

interface CardProps {
  title: string;
  fileUrl: string;
}

// box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid rgb(0 39 68);
  padding: 10px;
  border-radius: 4px;
  max-width: 180px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const CardTitle = styled.p`
  margin-left: 0.8rem;
  margin-bottom: 0;
`;

const FileCard = ({ title, fileUrl }: CardProps) => {
  const openFileInNewTab = () => {
    window.open(fileUrl, "_blank"); // Open the PDF URL in a new tab
  };

  return (
    <CardWrapper onClick={openFileInNewTab}>
      <AiFillFileText color="rgb(0 39 68)" size={20} />
      <CardTitle>{title}</CardTitle>
    </CardWrapper>
  );
};

export default FileCard;
