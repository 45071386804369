import { HtmlHTMLAttributes } from "react";
import {
  StyledContainer,
  StyledPage,
  StyledTitle,
  StyledButtonGrp,
  StyledSubTitle,
  StyledMiniTitle,
  StyledCorrectionText,
} from "./Page.style";

export interface PagePropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

export default function Page(props: PagePropsI) {
  return <StyledPage {...props} />;
}

export interface PageContainerPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

Page.Container = function PageContainer(props: PageContainerPropsI) {
  return <StyledContainer {...props} />;
};

export interface PageButtonGrpPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

Page.ButtonGrp = function PageButtonGrp(props: PageButtonGrpPropsI) {
  return <StyledButtonGrp {...props} />;
};

export interface PageTitlePropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

Page.Title = function PageTitle(props: PageTitlePropsI) {
  return <StyledTitle {...props} />;
};
Page.SubTitle = function PageTitle(props: PageTitlePropsI) {
  return <StyledSubTitle {...props} />;
};
Page.MiniTitle = function PageTitle(props: PageTitlePropsI) {
  return <StyledMiniTitle {...props} />;
};

Page.CorrectionText = function PageCorrectionText(props: PageTitlePropsI) {
  return <StyledCorrectionText {...props} />;
};
