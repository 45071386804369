import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import MainNav from "@ims/common/src/core-ui/nav/MainNav";
import { HiDocumentText, HiOutlineHome } from "react-icons/hi";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillRightCircle, AiOutlineClose } from "react-icons/ai";
import MainHeader from "@ims/common/src/core-ui/header/MainHeader";
import { FiTruck, FiUserPlus } from "react-icons/fi";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import Profile from "./profile/Profile";
import { useAppDispatch, useAppSelector } from "../rtk/hook";
import { logout } from "../rtk/feature/auth/authApi";
import { BiCog, BiLabel } from "react-icons/bi";
import styled from "styled-components/macro";
import { FaClipboard, FaCopy, FaMoneyCheck, FaRegCalendarAlt, FaUserCog, FaUserEdit } from "react-icons/fa";
import { BsHouseAdd } from "react-icons/bs";
import { TbTargetArrow } from "react-icons/tb";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { Button, Form } from "@ims/common";
import { trimText } from "@ims/common/src/utils/trimText";
import ProtectWithRole from "./ProtectWithRole/ProtectWithRole";
import { roles } from "../constants/constants";
import { getMunicipalityInformation } from "../rtk/feature/municipality-information/municipalityInformationAPI";
import { GrDocumentText, GrInspect, GrLanguage } from "react-icons/gr";
import { FiscalYearTypeI } from "../rtk/feature/fiscal-year/fiscalYearSlice";
import useTranslated from "../hooks/useTranslated";
import { MdAssignment } from "react-icons/md";
import { LangI } from "../utils/res-mes/response-message";
import { FiscalYearCalculator } from "../utils/fiscal-year";
export const StyledLoaderContainer = styled.div`
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }

  svg {
    width: 40px;
    height: 40px;
    color: white;
    animation-name: animate;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transition: none;
    animation-play-state: running;
    animation-timing-function: linear;
  }

  @keyframes animate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

interface DashboardLayoutPropsI {
  children?: React.ReactNode;
}

function DashboardLayout(props: DashboardLayoutPropsI) {
  const authSelector = useAppSelector((state) => state.auth);
  const [language, setLanguage] = useState<"English" | "नेपाली">("नेपाली");
  const [userId, setUserId] = useState<string>("");

  const { authenticated } = useAppSelector((state) => state.auth);
  const access = localStorage.getItem("access");
  const authUser = localStorage.getItem("user");
  const hasTokens = Boolean(access && authUser);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { keyword } = useTranslated();

  const path = location.pathname;
  const { user, nagarpalika } = authSelector;

  useEffect(() => {
    if (user) setUserId(user.id);
  }, [user]);

  const handleLogout = () => {
    navigate("/login");
    dispatch(logout());
  };

  useEffect(() => {
    hanleOnLoadAuthentication();
    handleOnLoad();
  }, []);

  const handleChange = () => {
    // const lng = event.target.value;

    if (language === "नेपाली") {
      i18n.changeLanguage("np");

      setLanguage("English");
      localStorage.setItem("lng", "np");
    } else if (language === "English") {
      i18n.changeLanguage("en");
      setLanguage("नेपाली");
      localStorage.setItem("lng", "en");
    }
  };

  const hanleOnLoadAuthentication = () => {
    if (!authenticated && !hasTokens) {
      navigate("/login");
    }
  };

  const handleOnLoad = () => {
    const lng = localStorage.getItem("lng");

    if (lng === "np") {
      setLanguage("English");
      i18n.changeLanguage("np");
    } else if (lng === "en") {
      setLanguage("नेपाली");
      i18n.changeLanguage("en");
    }
  };
  useEffect(() => {
    dispatch(getMunicipalityInformation());
  }, []);
  const municipalityInfo = useAppSelector((state) => state.municipalityInfo.data)?.logo;

  return (
    <MainLayout>
      <MainLayout.Aside>
        <MainNav>
          <MainHeader.ToggleButton closeButton>
            <AiOutlineClose color="white" />
          </MainHeader.ToggleButton>

          <MainNav.BrandContainer>
            <MainNav.BrandImage
              width="50%"
              src={municipalityInfo || "/logo.png"}
              alt="Information Management System (IMS) - Government of Nepal (Gov)"
            />

            <div className="wrapper-brand-title">
              <h2>{nagarpalika?.name}</h2>
              <h5>
                {nagarpalika?.fiscal_year ? FiscalYearCalculator(nagarpalika.fiscal_year) : new Date().getFullYear()}
              </h5>
            </div>
          </MainNav.BrandContainer>

          <MainNav.Nav>
            <ProtectWithRole
              notAllowedRoles={[roles.Prasasakiya_Adhikrit, roles.Yojana_Sakha, roles.WARD_SACHIB, roles.Lekha_Sakha]}
            >
              <MainNav.NavItem className="active" icon={<HiOutlineHome />} to="/">
                {t("Dashboard")}
              </MainNav.NavItem>
            </ProtectWithRole>
            <ProtectWithRole
              notAllowedRoles={[
                roles.UPABHOKTA_SAMITI,
                roles.WARD_SACHIB,
                roles.Prasasakiya_Adhikrit,
                roles.Yojana_Sakha,
                roles.Lekha_Sakha,
                roles.Purwadhar_Sakha,
              ]}
            >
              <MainNav.NavItem
                icon={<BsHouseAdd />}
                to="/ward/list"
                active={path.includes("/ward/edit") || path.includes("/ward/add")}
              >
                {t("Ward Registration")}
              </MainNav.NavItem>
            </ProtectWithRole>
            <ProtectWithRole
              notAllowedRoles={[
                roles.UPABHOKTA_SAMITI,
                roles.WARD_SACHIB,
                roles.Prasasakiya_Adhikrit,
                roles.Yojana_Sakha,
                roles.Lekha_Sakha,
                roles.Purwadhar_Sakha,
              ]}
            >
              <MainNav.DropNavItem icon={<FaUserCog />} title={t("User Registration")}>
                <MainNav.NavItem subItem={true} to="/profile" active={path.includes("/profile")}>
                  {t("Profile")}
                </MainNav.NavItem>
                <MainNav.NavItem subItem={true} to="/user/list">
                  {t("Manage User")}
                </MainNav.NavItem>
              </MainNav.DropNavItem>
            </ProtectWithRole>
            <MainNav.Divider />
            {/* <MainNav.DropNavItem
              icon={<FaProjectDiagram />}
              title={t("{keyword.Yojana}Management)}"
            >
              <MainNav.NavItem
                subItem={true}
                to="/yojana/add"
                active={path.includes("/yojana/edit")}
              >
                Create Yojana
              </MainNav.NavItem>
              <MainNav.NavItem subItem={true} to="/yojana/list">
                Manage Yojana
              </MainNav.NavItem>
            </MainNav.DropNavItem> */}
            <ProtectWithRole
              notAllowedRoles={[
                roles.UPABHOKTA_SAMITI,
                roles.WARD_SACHIB,
                roles.Prasasakiya_Adhikrit,
                roles.Yojana_Sakha,
                roles.Lekha_Sakha,
                roles.Purwadhar_Sakha,
              ]}
            >
              <MainNav.DropNavItem icon={<FiTruck />} title={t("Equipment Management")}>
                <MainNav.NavItem
                  subItem={true}
                  to="/equipment/unit/list"
                  active={path.includes("/equipment/unit/edit") || path.includes("/equipment/unit/add")}
                >
                  {t("Unit Manage")}
                </MainNav.NavItem>
                {/* 
                <MainNav.NavItem
                  subItem={true}
                  to="/equipment/transportation-type/list"
                  active={
                    path.includes("/equipment/transportation-type/edit") ||
                    path.includes("/equipment/transportation-type/add")
                  }
                >
                  {t("Transportation Type Manage")}
                </MainNav.NavItem>

                <MainNav.NavItem
                  subItem={true}
                  to="/equipment/list"
                  active={path.includes("/equipment/edit") || path.includes("/equipment/add")}
                >
                  {t("Equipment Manage")}
                </MainNav.NavItem> */}
                <MainNav.NavItem
                  subItem={true}
                  to="/district-rate"
                  active={path.includes("/district-rate") || path.includes("/district-rate")}
                >
                  {t("District Rate")}
                </MainNav.NavItem>
                <MainNav.NavItem
                  subItem={true}
                  to="/dor-equipment-hire-rate"
                  active={path.includes("/dor-equipment-hire-rate") || path.includes("/dor-equipment-hire-rate")}
                >
                  {t("DOR Equipment Hire Rate")}
                </MainNav.NavItem>

                <MainNav.NavItem
                  subItem={true}
                  to="/fixed-rate-analysis/list"
                  active={path.includes("/fixed-rate-analysis/edit") || path.includes("/fixed-rate-analysis/add")}
                >
                  {t("Fixed Rate Analysis")}
                </MainNav.NavItem>
              </MainNav.DropNavItem>
            </ProtectWithRole>
            <ProtectWithRole
              notAllowedRoles={[
                roles.UPABHOKTA_SAMITI,
                roles.Prasasakiya_Adhikrit,
                roles.Yojana_Sakha,
                roles.WARD_SACHIB,
                roles.Lekha_Sakha,
                roles.Purwadhar_Sakha,
              ]}
            >
              <MainNav.DropNavItem icon={<FaRegCalendarAlt />} title={t("Budget Planning")}>
                {/* <MainNav.NavItem
                  subItem={true}
                  to="/budget/yojana-type/list"
                  active={path.includes("/budget/yojana-type/edit") || path.includes("/budget/yojana-type/add")}
                >
                  {t("{keyword.Yojana}Type")}
                </MainNav.NavItem> */}

                {/* <MainNav.NavItem
                subItem={true}
                to="/budget/list"
                active={path.includes("/budget/edit") || path.includes("/budget/add")}
              >
                {t("Budget Manage")}
              </MainNav.NavItem> */}
                <ProtectWithRole
                  notAllowedRoles={[
                    roles.UPABHOKTA_SAMITI,
                    roles.Prasasakiya_Adhikrit,
                    roles.Yojana_Sakha,
                    roles.Lekha_Sakha,
                  ]}
                >
                  <MainNav.NavItem
                    subItem={true}
                    to="/budget/constructor/list"
                    active={path.includes("/budget/constructor/edit") || path.includes("/budget/constructor/add")}
                  >
                    {t("Constructor Manage")}
                  </MainNav.NavItem>
                  <MainNav.NavItem
                    subItem={true}
                    to="/budget/municipality-budget"
                    active={
                      path.includes("/budget/municipality-budget") || path.includes("/budget/municipality-budget")
                    }
                  >
                    {t("Municipality Budget")}
                  </MainNav.NavItem>

                  <MainNav.NavItem
                    subItem={true}
                    to="/budget/constructor/member/list"
                    active={
                      path.includes("/budget/constructor/member/edit") ||
                      path.includes("/budget/constructor/member/add")
                    }
                  >
                    {t("Constructor Member Manage")}
                  </MainNav.NavItem>
                </ProtectWithRole>
              </MainNav.DropNavItem>
            </ProtectWithRole>
            <ProtectWithRole notAllowedRoles={[roles.UPABHOKTA_SAMITI, roles.Lekha_Sakha]}>
              <ProtectWithRole notAllowedRoles={[roles.WARD_SACHIB]}>
                <MainNav.DropNavItem icon={<TbTargetArrow />} title={t("Plan Management")}>
                  <MainNav.NavItem
                    subItem={true}
                    to="/plan-management/plan"
                    active={
                      path.includes("/plan-management/plan") ||
                      path.includes("plan-management/plan/constructor-detail/:id")
                    }
                  >
                    {t("Plan Manage")}
                  </MainNav.NavItem>
                </MainNav.DropNavItem>
              </ProtectWithRole>
              <ProtectWithRole allowedRoles={[roles.WARD_SACHIB]}>
                <MainNav.NavItem
                  icon={<AiFillRightCircle />}
                  active={path.includes("/start-yojana")}
                  to="/start-yojana"
                >
                  {t("Start Yojana")}
                </MainNav.NavItem>
              </ProtectWithRole>

              <MainNav.NavItem
                icon={<HiDocumentText />}
                active={path.includes("/started-yojana") || path.includes("started-yojana-detail/:id")}
                to="/started-yojana"
              >
                {t("Started Yojana")}
              </MainNav.NavItem>

              <ProtectWithRole allowedRoles={[roles.WARD_SACHIB]}>
                <MainNav.NavItem
                  icon={<FaMoneyCheck />}
                  active={path.includes("/municipality-budget/view")}
                  to="/municipality-budget/view"
                >
                  {t("Municipality Budget")}
                </MainNav.NavItem>
              </ProtectWithRole>
              <ProtectWithRole
                notAllowedRoles={[
                  roles.UPABHOKTA_SAMITI,
                  roles.Prasasakiya_Adhikrit,
                  roles.Yojana_Sakha,
                  roles.Lekha_Sakha,
                  roles.Purwadhar_Sakha,
                ]}
              >
                <MainNav.NavItem
                  icon={<FaCopy />}
                  active={
                    path.includes("/planning-contractor") ||
                    path.includes("/planning-contractor/add") ||
                    path.includes("/planning-contractor/edit/:id")
                  }
                  to="/planning-contractor"
                >
                  {t("Planning  Contractor")}
                </MainNav.NavItem>
                <ProtectWithRole notAllowedRoles={[roles.ADMIN]}>
                  <MainNav.NavItem
                    icon={<MdAssignment />}
                    active={path.includes("/assign-constructor")}
                    to="/assign-constructor"
                  >
                    {t("Assign Constructor")}
                  </MainNav.NavItem>
                </ProtectWithRole>
                <MainNav.NavItem
                  icon={<FaClipboard />}
                  active={
                    path.includes("/planning-inspection") ||
                    path.includes("/planning-inspection/add") ||
                    path.includes("/planning-inspection/edit/:id")
                  }
                  to="/planning-inspection"
                >
                  {t("Planning  Inspection")}
                </MainNav.NavItem>
              </ProtectWithRole>
              <ProtectWithRole>
                <MainNav.NavItem
                  icon={<BiLabel />}
                  active={
                    path.includes("/bill-verification") ||
                    path.includes("/bill-verification/add") ||
                    path.includes("/bill-verification/edit/:id")
                  }
                  to="/bill-verification"
                >
                  {t("Bill Verification")}
                </MainNav.NavItem>
              </ProtectWithRole>
              <ProtectWithRole
                notAllowedRoles={[
                  roles.UPABHOKTA_SAMITI,
                  roles.Prasasakiya_Adhikrit,
                  roles.Yojana_Sakha,
                  roles.Lekha_Sakha,
                  roles.Purwadhar_Sakha,
                  roles.WARD_SACHIB,
                ]}
              >
                <MainNav.NavItem
                  icon={<BiLabel />}
                  active={
                    path.includes("/planning-bill") ||
                    path.includes("/planning-bill/add") ||
                    path.includes("/planning-bill/edit/:id")
                  }
                  to="/planning-bill"
                >
                  {t("Planning  Bill")}
                </MainNav.NavItem>
              </ProtectWithRole>
            </ProtectWithRole>
            <MainNav.Divider />
            <ProtectWithRole
              notAllowedRoles={[
                roles.UPABHOKTA_SAMITI,
                roles.Purwadhar_Sakha,
                roles.Yojana_Sakha,
                roles.WARD_SACHIB,
                roles.NORMAL_USER,
              ]}
            >
              <MainNav.NavItem
                icon={<BiLabel />}
                active={
                  path.includes("/bill-payment") ||
                  path.includes("/bill-payment/add") ||
                  path.includes("/bill-payment/edit/:id")
                }
                to="/bill-payments"
              >
                {t("Bill Payments")}
              </MainNav.NavItem>
            </ProtectWithRole>{" "}
            <ProtectWithRole
              notAllowedRoles={[
                roles.ADMIN,
                roles.Purwadhar_Sakha,
                roles.Yojana_Sakha,
                roles.WARD_SACHIB,
                roles.NORMAL_USER,
              ]}
            >
              <MainNav.NavItem
                icon={<BiLabel />}
                active={path.includes("/minute-document/:id")}
                to={`/minute-document/${userId}`}
              >
                {t("Minute Document")}
              </MainNav.NavItem>
            </ProtectWithRole>
            <ProtectWithRole notAllowedRoles={[roles.UPABHOKTA_SAMITI, roles.Lekha_Sakha, roles.Purwadhar_Sakha]}>
              <MainNav.DropNavItem icon={<BiCog />} title={t("Settings")}>
                <ProtectWithRole
                  notAllowedRoles={[
                    roles.UPABHOKTA_SAMITI,
                    roles.Prasasakiya_Adhikrit,
                    roles.Yojana_Sakha,
                    roles.WARD_SACHIB,
                    roles.NORMAL_USER,
                  ]}
                >
                  <MainNav.NavItem
                    subItem={true}
                    to="/settings/fiscal-year/list"
                    active={path.includes("/settings/fiscal-year/edit") || path.includes("/settings/fiscal-year/add")}
                  >
                    {t("Fiscal Year")}
                  </MainNav.NavItem>
                </ProtectWithRole>
                <ProtectWithRole
                  notAllowedRoles={[
                    roles.Prasasakiya_Adhikrit,
                    roles.Yojana_Sakha,
                    roles.WARD_SACHIB,
                    roles.NORMAL_USER,
                  ]}
                >
                  <MainNav.NavItem
                    subItem={true}
                    to="/settings/communication-title"
                    active={path.includes("/settings/communication-title")}
                  >
                    {t("Communitcation Title")}
                  </MainNav.NavItem>
                </ProtectWithRole>
                <ProtectWithRole
                  notAllowedRoles={[
                    roles.UPABHOKTA_SAMITI,
                    roles.Prasasakiya_Adhikrit,
                    roles.Yojana_Sakha,
                    roles.WARD_SACHIB,
                    roles.NORMAL_USER,
                  ]}
                >
                  <MainNav.NavItem
                    subItem={true}
                    to="/settings/municipality-information"
                    active={path.includes("/settings/municipality-information")}
                  >
                    {t("Municipality Information")}
                  </MainNav.NavItem>
                </ProtectWithRole>
                {/*                 <ProtectWithRole notAllowedRoles={[roles.Yojana_Sakha, roles.WARD_SACHIB, roles.NORMAL_USER]}>
                  <MainNav.NavItem
                    subItem={true}
                    to="/settings/general-document/list"
                    active={
                      path.includes("/settings/general-document/edit") ||
                      path.includes("/settings/general-document/add")
                    }
                  >
                    {t("General Documents")}
                  </MainNav.NavItem>
                </ProtectWithRole> */}
                <ProtectWithRole notAllowedRoles={[roles.UPABHOKTA_SAMITI, roles.NORMAL_USER]}>
                  <MainNav.NavItem
                    subItem={true}
                    to="settings/documents/list"
                    active={path.includes("/settings/documents") || path.includes("/settings/documents/add")}
                  >
                    {t("Documents")}
                  </MainNav.NavItem>
                </ProtectWithRole>
              </MainNav.DropNavItem>
            </ProtectWithRole>
            <MainNav.Divider />
          </MainNav.Nav>
        </MainNav>
      </MainLayout.Aside>

      <MainLayout.Section>
        <MainLayout.Header>
          <MainHeader>
            <MainHeader.ToggleButton>
              <GiHamburgerMenu />
            </MainHeader.ToggleButton>

            <Profile>
              <Button onClick={handleChange}>
                <GrLanguage /> {language}
              </Button>
              {/* <Form.Select placeholder="Enter your username" onChange={handleChange} value={language}>
                <Form.Option value="np">नेपाली</Form.Option>
                <Form.Option value="en">English</Form.Option>
              </Form.Select> */}

              <Profile.Container>
                <Profile.Card>
                  {keyword["Namaste"]} {trimText(`${user?.first_name}` || "Admin", 35)} <FiChevronDown />
                </Profile.Card>

                <Profile.MenuContainer>
                  <Profile.Button
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    Profile
                  </Profile.Button>

                  <Profile.Button style={{ zIndex: 10000000 }} onClick={handleLogout}>
                    Logout
                  </Profile.Button>
                </Profile.MenuContainer>
              </Profile.Container>
            </Profile>
          </MainHeader>
        </MainLayout.Header>

        {props.children ? props.children : <Outlet />}
      </MainLayout.Section>
    </MainLayout>
  );
}

export default DashboardLayout;
