import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useTranslated from "../../../../hooks/useTranslated";
import {
  getElectrificationWork,
  getElectrificationWorks,
  postElectrificationWorks,
  updateElectrificationWorks,
} from "../../../../rtk/feature/electrification-work/electrificationWorkAPI";
import { cleanupElectrificationWork } from "../../../../rtk/feature/electrification-work/electrificationWorkSlice";
import { setActiveTab, tabEnum } from "../../../../rtk/feature/plan-management/planManagementSlice";
import { useAppDispatch, useAppSelector } from "../../../../rtk/hook";
import WorkForm, { IWorkForm } from "../components/WorkForm/WorkForm";

const ElectrificationWorkForm = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isEditMode = pathname.includes("/edit");
  const { byId, formSubmitted } = useAppSelector((state) => state.electrificationWork);
  const params = useParams();
  const workId = params.workid;
  const { keyword } = useTranslated();
  const handleSubmit = (data: IWorkForm) => {
    const finalData = { ...data, unit: data.unit.value };
    if (params.id) {
      dispatch(postElectrificationWorks({ data: finalData, id: params.id }));
      return;
    }
    if (isEditMode && !!workId && params.id) {
      dispatch(updateElectrificationWorks({ data: finalData, id: params.id, workId: workId }));
    }
  };
  useEffect(() => {
    if (params.id && workId) dispatch(getElectrificationWork({ id: params.id, workId }));
  }, [params.id, workId]);

  useEffect(() => {
    if (formSubmitted) {
      navigate(`/plan-management/plan?tab=detailed-estimate`);
      dispatch(setActiveTab(tabEnum.electrificationWork));
    }
    return () => {
      dispatch(cleanupElectrificationWork());
    };
  }, [formSubmitted]);

  return <WorkForm title={keyword["Electrification Work Form"]} handleFormSubmit={handleSubmit} work={byId.data} />;
};

export default ElectrificationWorkForm;
