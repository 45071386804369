import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { UnitFormTypeI } from "./unitSlice";
import { UnitSuccess } from "../../../utils/res-mes/response-message-generator";

export const getUnits = createAsyncThunk(
  "getUnits",
  async (
    userParams: { per_page?: number; search?: string; page?: number; ordering?: any; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/units/", {
        params: {
          page: userParams?.page,
          per_page: userParams?.per_page,
          search: userParams?.search,
          ordering: userParams?.ordering,
        },
        signal: userParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getUnit = createAsyncThunk("getUnit", async (id: string, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.get(`/units/${id}/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const addUnit = createAsyncThunk("addUnit", async (data: UnitFormTypeI, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.post("/units/", data);
    toast.success(UnitSuccess("created"));
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const deleteUnit = createAsyncThunk("deleteUnit", async (deleteId: string, { dispatch, rejectWithValue }) => {
  try {
    await imsClient.delete(`/units/${deleteId}/`);
    dispatch(getUnits());
    toast.success(UnitSuccess("deleted"));
    return deleteId;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const updateUnit = createAsyncThunk(
  "updateUnit",
  async (data: { data: UnitFormTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/units/${data.id}/`, data.data);
      toast.success(UnitSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
