import { MouseEvent, useEffect, useState } from "react";
import { getRelativeDoc, removeRelativeDoc } from "../../../rtk/feature/relative-docs/relativeDocsApi";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import {
  RelativeDocTypeI,
  RelativeDocsCleanUp,
  RelativeDocsFormTypeI,
} from "../../../rtk/feature/relative-docs/relativeDocsSlice";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PreviewRelativeDocs from "./components/PreviewRelativeDocs";
import EditRelativeDocs from "./components/EditRelatieDocs";
import { showLoading } from "../../../rtk/feature/auth/authSlice";

interface RelativeDocsPropsI {
  budgetID: string;
  setBudgetId?: (id: null) => void;
  isVerified: boolean;
}

export default function RelativeDocs({ isVerified, budgetID, setBudgetId }: RelativeDocsPropsI) {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,

    register,
    control,
  } = useForm<RelativeDocsFormTypeI>({
    defaultValues: {},
  });

  const [selectedDataId, setSelectedDataId] = useState("");
  const [createNewData, setCreateNewData] = useState(false);
  const [relativeData, setRelativeData] = useState<RelativeDocTypeI[]>([
    {
      id: "",
      yojana: "",
      name: "",
      file: "",
      image: "",
      file_url: "",
    },
  ]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const relativeDocsSelector = useAppSelector((state) => state.relativeDocs);
  const { data: relativeDocsData } = relativeDocsSelector;

  useEffect(() => {
    dispatch(showLoading(relativeDocsSelector.loading));
  }, [dispatch, relativeDocsSelector.loading]);

  useEffect(() => {
    setRelativeData(relativeDocsData);
  }, [relativeDocsData]);

  useEffect(() => {
    if (budgetID) {
      dispatch(RelativeDocsCleanUp());
      dispatch(getRelativeDoc(budgetID));
    } else {
      dispatch(RelativeDocsCleanUp());
    }
  }, [budgetID, dispatch]);

  const handleEdit = (dataId: string) => {
    setSelectedDataId(dataId);
  };

  const handleCreateNewData = () => {
    setCreateNewData(true);
  };

  const handleRemoveDocsData = (dataId: string) => {
    if (dataId && budgetID) {
      dispatch(removeRelativeDoc({ id: dataId, budgetID }));
      setRelativeData((prevState) => prevState.filter((data) => data.id !== dataId));
    }
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    !!setBudgetId && setBudgetId(null);
    setCreateNewData(false);
    setSelectedDataId("");
    navigate(-1);
  };

  const findDoc = (docId: string) => relativeData.find((data) => data.id === docId);

  return (
    <>
      {createNewData || selectedDataId ? (
        <EditRelativeDocs
          budgetID={budgetID}
          selectedDataId={selectedDataId}
          handleCancel={handleCancel}
          doc={findDoc(selectedDataId)}
          setCreateNewData={setCreateNewData}
        />
      ) : (
        <PreviewRelativeDocs
          handleRemoveDoc={handleRemoveDocsData}
          handleCreateNewDoc={handleCreateNewData}
          handleEdit={handleEdit}
          data={relativeData}
          isVerified={isVerified}
        />
      )}
    </>
  );
}
