import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { IMunicipalityInformation } from "./municipalityInformationSlice";
import { InformationSuccess } from "../../../utils/res-mes/response-message-generator";

export const getMunicipalityInformation = createAsyncThunk("getMunicipalityData", async (_, { rejectWithValue }) => {
  try {
    const res = await imsClient.get("/palika/");
    return res.data;
  } catch (error) {
    const appError = new AppError(error);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const updateMunicipalityInformation = createAsyncThunk(
  "updateMunicipalityInformation",
  async ({ data, id }: { data: FormData; id: string }, { rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/palika/${id}/`, data, { headers: { "Content-Type": "multipart/form-data" } });
      if (res.data) toast.success(InformationSuccess("updated"));
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
