import { Button, Grid, MainLayout, Table } from "@ims/common";
import Page from "../../core-ui/Page";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import useTranslated from "../../hooks/useTranslated";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  CommunicationTitleI,
  CommunicationTitleFormI,
} from "../../rtk/feature/communication-title/communicationTitleSlice";
import {
  addCommunicationTitle,
  getCommunicationTitles,
  updateCommunicationTitle,
} from "../../rtk/feature/communication-title/communicationTitleApi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import ReuseForm from "../../core-ui/ReuseForm";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { styleSN } from "../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";

export default function CommunicationTitle() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,

    register,
    control,
  } = useForm<CommunicationTitleFormI>({
    defaultValues: {},
  });
  const [communicationTitleList, setCommunicationTitleList] = useState<CommunicationTitleI[] | []>([]);
  const [selectedTitle, setSelecteTitle] = useState<CommunicationTitleI | null>(null);
  const [allowedEdit, setAllowedEdit] = useState<boolean>(false);
  const { keyword } = useTranslated();
  const dispatch = useAppDispatch();

  const communicationTitleSelector = useAppSelector((state) => state.communicationTitle);
  const myRole = useAppSelector((state) => state.auth.user?.role);

  const columns: ["S.N.", "Title", "Action"] = ["S.N.", "Title", "Action"];

  useEffect(() => {
    if (myRole === "Admin") setAllowedEdit(true);
  }, [myRole]);

  useEffect(() => {
    dispatch(showLoading(communicationTitleSelector.loading));
  }, [dispatch, communicationTitleSelector.loading]);

  useEffect(() => {
    dispatch(getCommunicationTitles());
  }, [dispatch]);

  useEffect(() => {
    if (communicationTitleSelector.data) setCommunicationTitleList(communicationTitleSelector.data);
  }, [communicationTitleSelector.data]);

  const onSubmit = async (data: any) => {
    if (selectedTitle) {
      await dispatch(updateCommunicationTitle({ titleId: selectedTitle.id, data: { title: data.title } }));
      await dispatch(getCommunicationTitles());
    } else {
      await dispatch(addCommunicationTitle({ title: data.title }));
      await dispatch(getCommunicationTitles());
    }
  };

  return (
    <>
      <MainLayout.Main>
        <Page.Container>
          <h1>{keyword["Communitcation Title"]}</h1>
        </Page.Container>
        <ColumnContainer>
          {allowedEdit && (
            <FirstColumn>
              <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
                <Grid>
                  <Grid.Row gap={1.5}>
                    <Grid.Col spanMd={12} spanXs={12}>
                      <ReuseForm.Input
                        label={keyword["Title"]}
                        name="title"
                        value={selectedTitle}
                        placeholder={keyword["Enter communication title"]}
                        rules={{ required: keyword["This is required field."] }}
                        error={errors["title"]}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  <br />
                  <Grid.Row>
                    <Grid.Col spanMd={6}>
                      <Button variant="primary">{selectedTitle ? keyword.Update : keyword["Create"]}</Button>
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </ReuseForm>
            </FirstColumn>
          )}
          <SecondColumn>
            <Table.Container>
              <Table id="communication-title">
                {!!communicationTitleList?.length && (
                  <Table.Head>
                    <Table.Row>
                      {columns.map((column, i) => {
                        if (!allowedEdit && column === "Action") return;
                        return <Table.HeadCol key={i}>{keyword[column]} </Table.HeadCol>;
                      })}
                    </Table.Row>
                  </Table.Head>
                )}
                <Table.Body>
                  {communicationTitleList?.length ? (
                    communicationTitleList.map((communication, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Col>{englishNepaliNumberTranslation(styleSN(idx + 1))}</Table.Col>
                          <Table.Col>{communication.title}</Table.Col>
                          <Table.Col>
                            {" "}
                            {allowedEdit && (
                              <Table.ActionButton
                                onClick={() => {
                                  setSelecteTitle(communication);
                                  setValue("title", communication.title);
                                }}
                              >
                                <FaEdit />
                              </Table.ActionButton>
                            )}
                          </Table.Col>
                        </Table.Row>
                      );
                    })
                  ) : (
                    <p>{keyword["No communication title found."]}</p>
                  )}
                </Table.Body>
              </Table>
            </Table.Container>
          </SecondColumn>
        </ColumnContainer>
      </MainLayout.Main>
    </>
  );
}

const FirstColumn = styled.div`
  flex: 2;
  width: 40%;
  margin-right: 10px;
  padding: 16px;
`;

const SecondColumn = styled.div`
  border-left: 2px solid rgb(204, 204, 204);
  flex: 3;
  width: 60%;
  padding: 16px;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
`;
