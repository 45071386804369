import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Table from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { BudgetTypeI } from "../../rtk/feature/budget/budgetSlice";
import { useNavigate } from "react-router-dom";

import { FiEdit } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import { getBudgets } from "../../rtk/feature/budget/budgetApi";
import { Button } from "@ims/common";
import Page from "../../core-ui/Page";
import { AiOutlinePlus } from "react-icons/ai";
import { BsPrinter } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import TableUtility from "../user/TableUtility";
import useTranslated from "../../hooks/useTranslated";

function BudgetManagePage() {
  const [budgets, setBudgets] = useState<BudgetTypeI[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const budgetsState = useAppSelector((state) => state.budgets);

  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getBudgets({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    if (budgetsState.data) {
      setBudgets(budgetsState.data);
      setTotalItems(budgetsState.data.length);
    }

    dispatch(showLoading(budgetsState.loading));
  }, [budgetsState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/budget/edit/${id}`);
  };

  const tableUtility = new TableUtility();
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>{keyword["Budgets Manage"]}</Page.Title>

        <Page.ButtonGrp>
          <Button startIcon={<CiExport />} onClick={tableUtility.export}>
            {keyword["Export"]}
          </Button>

          <Button startIcon={<BsPrinter />} onClick={tableUtility.print}>
            {keyword["Print"]}
          </Button>

          <Button variant="primary" startIcon={<AiOutlinePlus />} onClick={() => navigate(`/budget/add`)}>
            {keyword["Add Budget"]}
          </Button>
        </Page.ButtonGrp>
      </Page.Container>

      <Table.Container>
        <Table id="export-table">
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Budget Name"]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Yojana Name"]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Yojana Type"]}</Table.HeadCol>
              <Table.HeadCol>{keyword.Code}</Table.HeadCol>
              <Table.HeadCol>{keyword["Ward No."]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Municipality Investment"]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Agreement Date"]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Agreement Payment Date"]}</Table.HeadCol>
              <Table.HeadCol align="center">{keyword["Action"]}</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {budgets &&
              budgets.length > 0 &&
              budgets.map((budget, index) => {
                return (
                  <Table.Row key={budget.id}>
                    {/* <Table.Col>{getSN(index)}</Table.Col>
                    <Table.Col>{budget.budget_name}</Table.Col>
                    <Table.Col>{budget.yojana_name}</Table.Col>
                    <Table.Col>{budget.yojana_type?.name || "N/A"}</Table.Col>
                    <Table.Col>{budget.code}</Table.Col>
                    <Table.Col>{budget.ward.ward_number}</Table.Col>
                    <Table.Col>{budget.nagarpalika_lagani}</Table.Col>
                    <Table.Col>{budget.agreement_date}</Table.Col>
                    <Table.Col>{budget.agreement_payment_date}</Table.Col> */}

                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(budget.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default BudgetManagePage;
