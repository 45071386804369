import { Button, MainLayout } from "@ims/common";
import Tab from "../../components/tab/Tab";
import { StyledAlert, StyledTabHeader } from "./PlanPage";
import useTranslated from "../../hooks/useTranslated";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { useEffect, useState } from "react";
import AssignWard from "./assign-ward/AssignWard";
import PlanBriefForm from "./brief/PlanBriefForm";
import DetailesEstimateContainer from "./estimate";
import DetailedEstimateReport from "./report/DetailedEstimateReport";
import AssingnConstructor from "./assign-constructor/AssignConstructor";
import AbstractOfCost from "./AbstractOfCost/AbstractOfCost";
import RelativeDocs from "./RelativeDocs/RelativeDocs";
import YojanaBooks from "./yojana-books/YojanaBooks";
import SendForVerification from "./SendForVerification/SendForVerification";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { useSearchParams } from "react-router-dom";
import { getPlanVerifications } from "../../rtk/feature/plan-verification/planVerificationAPI";
import { BsArrowLeft } from "react-icons/bs";
import Page from "../../core-ui/Page";

interface Props {
  activeBudgetId: string;
  setBudgetID?: (id: null) => void;
}

const PlanDetail = ({ activeBudgetId, setBudgetID }: Props) => {
  const [isCorrection, setIsCorrection] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const { keyword } = useTranslated();
  const dispatch = useAppDispatch();

  const planVerificationData = useAppSelector((state) => state.sendPlanVerification.getData.data);
  const planBriefSelector = useAppSelector((state) => state.planBriefs);
  const assignWardSelector = useAppSelector((state) => state.assignWard);
  const planGroupEsimate = useAppSelector((state) => state.planGroupEstimates);
  const reports = useAppSelector((state) => state.planReport.data);
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab");

  useEffect(() => {
    dispatch(showLoading(planBriefSelector.loading || assignWardSelector.loading || planGroupEsimate.loading));
  }, [dispatch, planBriefSelector.loading, assignWardSelector.loading, planGroupEsimate.loading]);

  useEffect(() => {
    if (activeBudgetId) dispatch(getPlanVerifications({ id: activeBudgetId }));
  }, [activeBudgetId]);

  useEffect(() => {
    if (planVerificationData?.status === "verified") setIsVerified(true);
    else setIsVerified(false);
    if (!planVerificationData?.id) setIsCorrection(true);
    else if (planVerificationData?.status === "correction" || planVerificationData?.status === "not verified")
      setIsCorrection(true);
    else setIsCorrection(false);
  }, [planVerificationData]);

  return (
    <MainLayout.Main style={{ padding: 0 }}>
      <section style={{ paddingTop: "1rem", paddingLeft: "1rem" }}>
        {" "}
        <Button
          onClick={() => {
            !!setBudgetID && setBudgetID(null);
          }}
        >
          <BsArrowLeft /> Go Back
        </Button>
      </section>
      {planVerificationData?.status === "correction" && (
        <Page.CorrectionText>{planVerificationData?.comment}</Page.CorrectionText>
      )}

      <Tab>
        <StyledTabHeader>
          {/* <Tab.TabHeadButton id="ward" active={tab === "ward" || tab == null} disabled={!activeBudgetId}>
            {keyword["Ward"]}
          </Tab.TabHeadButton> */}
          <Tab.TabHeadButton
            id="brief"
            active={tab === "brief" || tab == null}
            disabled={!activeBudgetId /* || !assignWardSelector.data?.length */}
          >
            {keyword["Plan Brief"]}
          </Tab.TabHeadButton>
          <Tab.TabHeadButton
            id="detailed-estimate"
            active={tab === "detailed-estimate"}
            disabled={!activeBudgetId /* || !planBriefSelector.item */}
          >
            {keyword["Detailed Estimate"]}
          </Tab.TabHeadButton>
          <Tab.TabHeadButton
            id="assign-constructor"
            active={tab === "assign-constructor"}
            disabled={!activeBudgetId /*  || !planBriefSelector.item */}
          >
            {keyword["Assign Constructor"]}
          </Tab.TabHeadButton>
          {/* <Tab.TabHeadButton
              id="rate-analysis"
              active={tab === "rate-analysis"}
              disabled={!activeBudgetId || !planGroupEsimate.data.results.length}
            >
              {keyword["Rate Analysis"]}
            </Tab.TabHeadButton> */}
          {/* <Tab.TabHeadButton id="cost-estimate" active={tab === "cost-estimate"} disabled={!activeBudgetId}>
              Cost Estimation
            </Tab.TabHeadButton> */}
          <Tab.TabHeadButton
            id="report"
            active={tab === "report"}
            disabled={!activeBudgetId /* || !planGroupEsimate.data.results.length */}
          >
            {keyword["Reports"]}
          </Tab.TabHeadButton>
          <Tab.TabHeadButton id="verification" active={tab === "verification"} disabled={!activeBudgetId || !reports}>
            {keyword["Send For Verification"]}
          </Tab.TabHeadButton>
          <Tab.TabHeadButton id="abstract-of-cost" active={tab === "abstract-of-cost"} disabled={!activeBudgetId}>
            {keyword["Abstract of cost"]}
          </Tab.TabHeadButton>
          <Tab.TabHeadButton
            id="relative-docs"
            active={tab === "relative-docs"}
            disabled={!activeBudgetId || !isVerified}
          >
            {keyword["Relative Docs"]}
          </Tab.TabHeadButton>{" "}
          <Tab.TabHeadButton
            id="yojana-books"
            active={tab === "yojana-books"}
            disabled={!activeBudgetId || !isVerified}
          >
            {keyword["Yojana Books"]}
          </Tab.TabHeadButton>{" "}
        </StyledTabHeader>

        {activeBudgetId ? (
          <Tab.TabBody>
            {/* <Tab.TabPane targetId="ward">
              <AssignWard budgetID={activeBudgetId} />
            </Tab.TabPane> */}
            <Tab.TabPane targetId="brief">
              <PlanBriefForm budgetID={activeBudgetId} isAddable={isCorrection} setBudgetId={setBudgetID} />
            </Tab.TabPane>
            <Tab.TabPane targetId="detailed-estimate">
              {/* <EstimateTable budgetID={budgetID} />
               */}
              <DetailesEstimateContainer isAddable={isCorrection} budgetId={activeBudgetId} />
            </Tab.TabPane>
            {/* <Tab.TabPane targetId="rate-analysis">
                <RateAnalysisTable budgetID={activeBudgetId} />
              </Tab.TabPane> */}
            {/* <Tab.TabPane targetId="cost-estimate">
                <CostEstimateReport />
              </Tab.TabPane> */}
            <Tab.TabPane targetId="report">
              <DetailedEstimateReport id={activeBudgetId} />
            </Tab.TabPane>
            <Tab.TabPane targetId="assign-constructor">
              <AssingnConstructor budgetID={activeBudgetId} />
            </Tab.TabPane>
            <Tab.TabPane targetId="abstract-of-cost">
              <AbstractOfCost budgetId={activeBudgetId} />
            </Tab.TabPane>
            <Tab.TabPane targetId="relative-docs">
              <RelativeDocs budgetID={activeBudgetId} setBudgetId={setBudgetID} isVerified={isVerified} />
            </Tab.TabPane>
            <Tab.TabPane targetId="yojana-books">
              <YojanaBooks budgetID={activeBudgetId} isVerified={isVerified} />
            </Tab.TabPane>
            <Tab.TabPane targetId="verification">
              <SendForVerification planVerificationData={planVerificationData} budgetId={activeBudgetId} />
            </Tab.TabPane>
          </Tab.TabBody>
        ) : (
          <StyledAlert>{keyword["Please select a budget to continue further"]}:)</StyledAlert>
        )}
      </Tab>
    </MainLayout.Main>
  );
};

export default PlanDetail;
