import styled from "styled-components";

export const HoverableIcon = styled.div`
  display: inline-block;
  cursor: pointer;
  transform: scale(0.8);
  transition: transform 0.2s;
  transition: margin-right 0.3s;

  &:hover {
    margin-right: 8px;
    transform: scale(1.2);
  }
`;
