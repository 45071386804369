import { createSlice } from "@reduxjs/toolkit";
import { Yojana } from "../constructor/constructorSlice";
import {
  addPlanEstimate,
  deletePlanEstimate,
  getPlanEstimate,
  getPlanEstimates,
  updatePlanEstimate,
} from "./planEstimateApi";

export interface PlanEstimateFormTypeI {
  id?: string;
  name: string;
  shapes: string;
  formula: string;
  description: string;
  number: number;
  remarks: string;
  length: number;
  breadth: number;
  height: number;
  final_measurement: number;
  _is_latest?: boolean;
}
export interface PlanEstimateTypeI {
  estimation_details: EstimationDetailTypeI[];
}

export interface EstimationDetailTypeI {
  id: string;
  shapes: string;
  formula: string;
  name: string;
  length: number | null;
  breadth: number | null;
  height: number | null;
  quantity: number;
  number: number,
  remarks: string;
  final_measurement: number;
  _is_latest?: boolean;
}

export interface PlanEstimatesSliceStateI {
  loading: boolean;
  data: EstimationDetailTypeI[];
  item: EstimationDetailTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: PlanEstimatesSliceStateI = {
  loading: false,
  data: [],
  item: null,
  hasSubmitted: false,
  error: null,
};

const planBriefSlice = createSlice({
  name: "planBrief",
  initialState,
  reducers: {
    planEstimateCleanUp: (state) => {
      state.data = [];
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPlanEstimate.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addPlanEstimate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
      state.data = [...state.data, payload];
    });
    builder.addCase(addPlanEstimate.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getPlanEstimates.pending, (state) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(getPlanEstimates.fulfilled, (state, { payload }) => {


      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getPlanEstimates.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
    builder.addCase(getPlanEstimate.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getPlanEstimate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getPlanEstimate.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updatePlanEstimate.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updatePlanEstimate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.data = state.data.map((el) => {
        if (el.id === payload.id) {
          return payload;
        }
        return el;
      });
      state.error = null;
    });
    builder.addCase(updatePlanEstimate.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deletePlanEstimate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deletePlanEstimate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = state.data.filter((el) => el.id !== payload);
      state.error = null;
    });
    builder.addCase(deletePlanEstimate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { planEstimateCleanUp } = planBriefSlice.actions;
export default planBriefSlice.reducer;
