import { useEffect } from "react";
import styled from "styled-components";
import useTranslated from "../../../hooks/useTranslated";
import { getAnalyzeRate } from "../../../rtk/feature/analyze-rate/analyzeRateApi";
import { getFixedAnalyzeRate } from "../../../rtk/feature/fixed-analyze-rate/fixedAnalyzeRateApi";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { formatter } from "./RateSubTable";

export interface RateIntroPropsI {
  rateID: string | null;
  sub_total_after_cp?: number | undefined;
}

export default function RateIntro(props: RateIntroPropsI) {
  const { rateID } = props;

  const dispatch = useAppDispatch();

  const fixedAnalyzeRateSelector = useAppSelector((state) => state.fixedAnalyzeRates);
  const { item: fixedAnalyzeRateItem } = fixedAnalyzeRateSelector;

  useEffect(() => {
    if (rateID) dispatch(getFixedAnalyzeRate(rateID));
  }, [dispatch, rateID]);

  const { keyword } = useTranslated();

  return (
    <BasicTable>
      <tr>
        <td>
          <strong>{keyword["Name (English)"]}</strong>:
        </td>
        <td>{fixedAnalyzeRateItem?.english_name}</td>

        <td>
          <strong>{keyword.Description}</strong>:
        </td>
        <td>{fixedAnalyzeRateItem?.english_description}</td>
      </tr>

      <tr>
        <td>
          <strong>{keyword["Rate"]}</strong>:
        </td>
        <td>{formatter.format(props.sub_total_after_cp as number)}</td>

        <td>
          <strong>{keyword["Unit"]} </strong>:
        </td>
        <td>{fixedAnalyzeRateItem?.unit?.name || "---"}</td>
      </tr>

      <tr>
        <td>
          <strong>{keyword["Code"]}</strong>:
        </td>
        <td>{fixedAnalyzeRateItem?.code}</td>

        <td>
          <strong>{keyword["Working type"]}</strong>:
        </td>
        <td>{fixedAnalyzeRateItem?.working_type}</td>
      </tr>
    </BasicTable>
  );
}

const BasicTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;

  & tr td {
    padding: 5px;
    border: 1px solid #f1f1f1;
    background: #f9f9f9;

    & strong {
      font-weight: 500;
    }

    input {
      width: 100%;
    }
  }
`;
