import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import useTranslated from "../../hooks/useTranslated";
import { MainLayout } from "@ims/common";
import Page from "../../core-ui/Page";
import { useEffect, useState } from "react";
import { getBudget } from "../../rtk/feature/budget/budgetApi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import { BiArrowBack } from "react-icons/bi";
import { HoverableIcon } from "../../core-ui/HoverableIcon";
import PlanInfo from "../plan/PlanInfo";
import PlanDetail from "../plan/PlanDetail";

const StartedYojanaDetail = () => {
  const { id: yojanaId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();

  const budgetSelector = useAppSelector((state) => state.budgets);
  const { item: budget } = budgetSelector;

  useEffect(() => {
    if (yojanaId) dispatch(getBudget(yojanaId));
  }, [yojanaId]);

  useEffect(() => {
    dispatch(showLoading(budgetSelector.loading));
  }, [dispatch, budgetSelector.loading]);

  const returnBackHanler = () => {
    navigate("/started-yojana");
  };

  return (
    <>
      <MainLayout.Main>
        <Page.Container>
          <Page.Title>
            <HoverableIcon>
              <BiArrowBack onClick={returnBackHanler} />
            </HoverableIcon>
            {keyword["Plan Detail"]}
          </Page.Title>
        </Page.Container>
        <PlanInfo budget={budget} />
      </MainLayout.Main>
      <PlanDetail activeBudgetId={yojanaId || ""} />
    </>
  );
};

export default StartedYojanaDetail;
