import { Button, Flex, Form, Grid, MainLayout, Table } from "@ims/common";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiEye } from "react-icons/fi";
import ReuseForm from "../../../core-ui/ReuseForm";
import {
  getPlanVerifications,
  getVerificationById,
  submitPlanVerification,
  updatePlanVerification,
  updateReceivedPlan,
} from "../../../rtk/feature/plan-verification/planVerificationAPI";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { StyledContainerDrawer } from "../analyze-rate/RateAnalysisTable";
import EquipmentQuanityAnalysis from "./EquipmentQuanityAnalysis";
import { userFriendlyDate } from "../../../utils/date";
import ReviewReport from "../ReviewPlan/Reviewreport/ReviewReport";
import { IVerification } from "../../../rtk/feature/plan-verification/planVerificationSlice";
import DetailedEstimateReport from "../report/DetailedEstimateReport";
import useTranslated, { TranslateKeyTypes } from "../../../hooks/useTranslated";
import { styleSN } from "../../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../../utils/translateNumber";
interface IProps {
  data: IVerification;
}

const ReviewPlanDetail = (props: IProps) => {
  const { data: verificationData } = props;
  const { keyword } = useTranslated();
  const dispatch = useAppDispatch();
  const [activeQuantity, setActiveQuantity] = useState<string | null>(null);

  const yojanaColumns: TranslateKeyTypes[] = [
    "S.N.",
    "Name",
    "Allocation",
    "Attention",
    "Balance",
    "Expenditure",
    "Expenditure Heading",
    "Expenditure Rate",
    "Hint",
    "Source",
    "Unit",
  ];
  const yojanaBriefColumns: TranslateKeyTypes[] = [
    "S.N.",
    "Yojana Head",
    "Tole",
    "Completion Date",
    "Contingency",
    "Contribution Donation",
    "Final Measurement",
    "Measurement",
    "Beneficiaries",

    "Yojana Status",
    "Yojana Subtype",
    "Description",
  ];
  const estimateColumns: TranslateKeyTypes[] = [
    "S.N.",
    "Length",
    "Breadth",
    "Height",
    "Formula",
    "Final Measurement",
    "Quantity",
    "Created On",
    "Modified On",
    "Remarks",
  ];
  const viewQuantityDetails = (id: string) => {
    setActiveQuantity(id);
  };
  return (
    <Flex.Col gap="2rem">
      <Table.Container>
        <Table.Head> {keyword.Yojana} </Table.Head>
        <Table id="verification-table">
          <Table.Row>
            {yojanaColumns.map((col, i) => {
              return <Table.HeadCol key={i}>{keyword[col]} </Table.HeadCol>;
            })}
          </Table.Row>
          <Table.Body>
            {verificationData && (
              <Table.Row>
                <Table.Col>{englishNepaliNumberTranslation(styleSN(verificationData.yojana.serial_no))}</Table.Col>
                <Table.Col>{verificationData?.yojana?.name}</Table.Col>
                <Table.Col>{verificationData?.yojana?.allocation}</Table.Col>
                <Table.Col>{verificationData?.yojana?.attention}</Table.Col>
                <Table.Col>{verificationData?.yojana?.balance}</Table.Col>
                <Table.Col>{verificationData?.yojana?.expenditure}</Table.Col>
                <Table.Col>{verificationData?.yojana?.expenditure_heading}</Table.Col>
                <Table.Col>{verificationData?.yojana?.expenditure_rate}</Table.Col>
                <Table.Col>{verificationData?.yojana?.hint}</Table.Col>
                <Table.Col>{verificationData?.yojana?.source}</Table.Col>
                <Table.Col>{verificationData?.yojana?.unit}</Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Table.Container>

      <Table.Container>
        <Table.Head> {keyword["Yojana Brief"]}</Table.Head>
        <Table id="verification-table">
          <Table.Row>
            {yojanaBriefColumns.map((col, i) => {
              return <Table.HeadCol key={col}> {keyword[col]} </Table.HeadCol>;
            })}
          </Table.Row>
          <Table.Body>
            {verificationData && (
              <Table.Row key={verificationData.yojana_brief?.yojana_head}>
                <Table.Col>{englishNepaliNumberTranslation("01")}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.yojana_head}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.tole}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.completion_date}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.contingency}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.contribution_donation}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.final_measurement}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.measurement}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.beneficiaries}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.yojana_status}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.yojana_subtype}</Table.Col>
                <Table.Col>{verificationData?.yojana_brief?.description}</Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Table.Container>

      {verificationData?.yojana_estimation?.map((estimate, index) => {
        return (
          estimate?.equipment_measurement_analysis?.length !== 0 && (
            <Table.Container>
              <Table.Head>
                {" "}
                <h4>
                  {" "}
                  {keyword.Yojana}Estimation {index + 1}{" "}
                </h4>{" "}
              </Table.Head>
              <Flex>
                {" "}
                <h5>
                  {keyword.Description} : {estimate?.description}{" "}
                </h5>{" "}
              </Flex>
              <Table id="verification-table">
                <Table.Row>
                  {estimateColumns.map((col, i) => {
                    return <Table.HeadCol key={col}> {keyword[col]} </Table.HeadCol>;
                  })}
                </Table.Row>
                <Table.Body>
                  {estimate &&
                    estimate?.equipment_measurement_analysis?.map((estimate, index) => {
                      return (
                        <Table.Row>
                          <Table.Col> {englishNepaliNumberTranslation(styleSN(index + 1))} </Table.Col>

                          <Table.Col> {estimate?.length} </Table.Col>
                          <Table.Col> {estimate?.breadth} </Table.Col>

                          <Table.Col> {estimate?.height} </Table.Col>
                          <Table.Col> {estimate?.formula} </Table.Col>
                          <Table.Col> {estimate?.final_measurement} </Table.Col>
                          <Table.Col> {estimate?.quantity} </Table.Col>
                          <Table.Col> {userFriendlyDate(estimate?.created_on)} </Table.Col>
                          <Table.Col> {userFriendlyDate(estimate?.modified_on)} </Table.Col>
                          <Table.Col> {estimate?.remarks} </Table.Col>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </Table.Container>
          )
        );
      })}
      {verificationData?.equipment_quantity_analysis && (
        <EquipmentQuanityAnalysis data={verificationData?.equipment_quantity_analysis || []} />
      )}
    </Flex.Col>
  );
};

export default ReviewPlanDetail;
