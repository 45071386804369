import { createSlice } from "@reduxjs/toolkit";

export enum tabEnum {
  constructionWork,
  generalWork,
  sanitoryWork,
  electrificationWork,
  finalEstimation,
}

interface IPlanManagement {
  activeBudgetId: string;
  activeTab: tabEnum;
}

const initialState: IPlanManagement = {
  activeBudgetId: "",
  activeTab: tabEnum.generalWork,
};

const planManagementSlice = createSlice({
  name: "plan-management",
  initialState,
  reducers: {
    cleanupPlanManagement: (state) => {
      return initialState;
    },
    setActiveBudgetId: (state, { payload }) => {
      state.activeBudgetId = payload;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
  },
});
export const { cleanupPlanManagement, setActiveBudgetId, setActiveTab } = planManagementSlice.actions;
export default planManagementSlice.reducer;
