import React, { useEffect, useId, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import styled, { css } from "styled-components";
import EstimateItemForm from "./EstimateItemForm";
import { deletePlanEstimate, getPlanEstimates } from "../../../rtk/feature/plan-estimate/planEstimateApi";
import { EstimationDetailTypeI, planEstimateCleanUp } from "../../../rtk/feature/plan-estimate/planEstimateSlice";
import { toast } from "react-toastify";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import { Button, Flex, Form, Table } from "@ims/common";
import Dialog from "./Dialog/Dialog";
import { FiSave, FiTrash } from "react-icons/fi";
import ReuseForm from "../../../core-ui/ReuseForm";
import { useForm } from "react-hook-form";
import { GroupEstimateListTypeI } from "./EstimateTable";
import { getFixedAnalyzeRate, getFixedAnalyzeRates } from "../../../rtk/feature/fixed-analyze-rate/fixedAnalyzeRateApi";
import {
  addEstimateItem,
  addGroupPlanEstimate,
  updateGroupPlanEstimate,
} from "../../../rtk/feature/plan-group-estimate/planGroupEstimateApi";
import { EstimationDetailFormTypeI } from "../../../rtk/feature/plan-group-estimate/planGroupEstimateSlice";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { getUnits } from "../../../rtk/feature/unit/unitApi";
import { getValue } from "@testing-library/user-event/dist/utils";
import { UnitTypeI } from "../../../rtk/feature/unit/unitSlice";
import useTranslated from "../../../hooks/useTranslated";

export interface EstimateItemGroupPropsI {
  budgetID: string | null;
  onDelete: (group_id: string) => void;
  onAdd: (data: GroupEstimateListTypeI) => void;
  onUpdate: (data: GroupEstimateListTypeI) => void;
  maxI?: number;
  value?: GroupEstimateListTypeI;
  index: string;
  onClearRow?: (index: number) => void;
  onAddRow: () => void;
  total_measurement?: {
    quantity: number;
    rate: number;
    total: number;
  };

  hideActionBtn?: boolean;
}
export interface IGroupEstimateTotalMeasurement {
  quantity: number;
  rate: number;
  total: number;
}
interface IProps {
  total_measurement: IGroupEstimateTotalMeasurement;
  value: any;
  budgetID: string;
  onAddRow: () => void;
  onDelete: () => void;
  id: string | number;
}

interface IUnit {
  label: string;
  value: string;
  code: string;
}

function EstimateItemGroup(props: EstimateItemGroupPropsI) {
  const highIdx: number = props.maxI || 1;
  const estimateItem = {
    id: Date.now().toString(),
    shapes: "",
    formula: "",
    name: "",
    description: "",
    length: 0,
    breadth: 0,
    height: 0,
    quantity: 0,
    remarks: "",
    final_measurement: 0,
    _is_latest: true,
    number: 0,
  };

  const groupID = useId();
  const { budgetID } = props;
  const dispatch = useAppDispatch();
  const [estimateList, setEstimateList] = useState<EstimationDetailTypeI[]>([estimateItem]);
  const [delDialogOpen, setDelDialogOpen] = useState(false);
  const [itemID, setItemID] = useState<string | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<IUnit | null>(null);
  const allUnits = useAppSelector((state) => state.units.data);

  const unitOptions = allUnits?.results?.map((unit: UnitTypeI) => {
    return {
      label: unit.name,
      value: unit.id,
      code: unit.code,
    };
  });
  const {
    control,
    getValues,
    setValue,
    watch,

    formState: { isDirty, errors },
  } = useForm<GroupEstimateListTypeI>();

  const planEstimateSelector = useAppSelector((state) => state.planEstimates);
  const fixedAnalyzeRateSelector = useAppSelector((state) => state.fixedAnalyzeRates);

  useEffect(() => {
    // if (budgetID) {
    //   dispatch(planEstimateCleanUp());
    //   dispatch(getPlanEstimates({ budgetID: budgetID }));
    // }

    dispatch(
      getFixedAnalyzeRates({
        per_page: 1000,
        page: 1,
      })
    );
  }, [budgetID, dispatch]);

  const handleAdd = () => {
    if (!budgetID) {
      return toast.warning(keyword["No budget is selected right now."]);
    }
    // setEstimateList((estimateList) => {
    //   return [...estimateList, newItem];
    // });
  };

  const rateAnalysisArray = fixedAnalyzeRateSelector.data?.results.filter((item) => item.equipment_rate_id !== null);

  const rateAnalysisOptions = rateAnalysisArray?.map((el) => ({
    value: el.id,
    label: el.english_name,
    equipment_rate_id: el.equipment_rate_id,
  }));

  useEffect(() => {
    if (props.value?.rate_analysis_id) {
      const rateAnalysisId = rateAnalysisOptions?.find(
        (opt) => opt?.equipment_rate_id === props.value?.rate_analysis_id
      );

      if (rateAnalysisId) {
        setValue("rate_analysis_id", rateAnalysisId);
      }
    }
  }, [props.value?.rate_analysis_id, rateAnalysisOptions]);

  useEffect(() => {
    if (props.value) {
      setValue("group_id", props.value.group_id);
      // setValue("rate_analysis_id", props.value.rate_analysis_id);
      setValue("name", props.value.name);
      setValue("_is_latest", props.value._is_latest);
      if (props.value.unit)
        setSelectedUnit({
          label: props.value.unit.name,
          value: props.value.unit?.id,
          code: props.value.unit?.code,
        });

      if (props.value.estimate_list.length === 0) {
        setEstimateList([estimateItem]);
      } else {
        setEstimateList(props.value.estimate_list);
      }
    }
    dispatch(getUnits({ per_page: 100000000000 }));
  }, [props.value]);

  const handleChange = (data: any) => {
    setEstimateList((estimateList) =>
      estimateList.map((el) => {
        if (el.id === data.id) return data;
        return el;
      })
    );
  };

  const handleSave = async () => {
    const finalValues = { ...getValues(), estimate_list: estimateList };

    const tempData: EstimationDetailFormTypeI = {
      name: finalValues.name,
      equipment_quantity_analysis: finalValues.rate_analysis_id.equipment_rate_id,
      unit: selectedUnit?.value || "",
    };

    if (props.value?.group_id && budgetID) {
      if (finalValues._is_latest) {
        dispatch(addGroupPlanEstimate({ budgetID, data: tempData }));
      } else {
        dispatch(updateGroupPlanEstimate({ budgetID, data: tempData, id: finalValues.group_id }));
      }
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter" && !props?.hideActionBtn) {
      props.onAddRow();
    }
  };

  const handleGroupDelete = (id?: string) => {
    if (id) props.onDelete(id);
  };

  const handleEstimateItemAddRow = () => {
    setEstimateList((items) => [...items, estimateItem]);
  };

  const [collapse, setCollapse] = useState(true);

  // useEffect(() => {
  //   if (rateAnalysisID && !getValues("description")) {
  //     setValue("description", rateAnalysisID.label);
  //   }
  // }, [getValues("rate_analysis_id")]);

  const handleDelete = (ID: any) => {
    setDelDialogOpen(true);
    setItemID(ID);
  };

  const handleDialogClose = () => {
    setDelDialogOpen(false);
  };

  const handleDeleteConfirm = () => {
    if (itemID && budgetID) {
      handleGroupDelete(itemID);
      handleDialogClose();
    }
  };
  const { keyword } = useTranslated();

  // useEffect(() => {
  //   const dropDownData = watch("rate_analysis_id");
  //
  //   setValue("description", dropDownData?.label)
  // }, [])

  // useEffect(() => {
  //   if (props.value?.unit) setValue("unit", { label: props.value?.unit.name, value: props.value?.unit?.id });
  // }, [props.value?.unit]);

  return (
    <React.Fragment>
      <ReuseForm.ReuseFormTr control={control} {...props}>
        <td>
          {props.index}{" "}
          <StyledButton onClick={() => setCollapse((a) => !a)}>
            {collapse ? <BsChevronDown /> : <BsChevronUp />}
          </StyledButton>
        </td>
        <td>
          <ReuseForm.ReactSelect
            name="rate_analysis_id"
            options={rateAnalysisOptions}
            placeholder="Search Rate Analysis"
          />
        </td>

        <td>
          <ReuseForm.ReactSelect
            name="unit"
            value={selectedUnit}
            options={unitOptions}
            onChange={(val: IUnit) => {
              setSelectedUnit(val);
            }}
            placeholder="select unit"
          />
        </td>
        <td>
          <ReuseForm.Input name="name" placeholder="name" onKeyDown={handleEnter} />
        </td>
        <td>
          {!props.hideActionBtn && (
            <>
              <Table.ActionButton color="green" type="button" onClick={handleSave} disabled={!isDirty}>
                <FiSave />
              </Table.ActionButton>

              {!props.value?._is_latest && (
                <Table.ActionButton
                  color="red"
                  disabled={props.value?._is_latest}
                  onClick={() => {
                    if (props.value?.group_id) {
                      setDelDialogOpen(true);
                      setItemID(props.value.group_id);
                    }
                  }}
                >
                  <FiTrash />
                </Table.ActionButton>
              )}
            </>
          )}

          {props.value?._is_latest && +props.index > 1 && highIdx === +props?.index && (
            <Table.ActionButton
              type="button"
              onClick={() => {
                props?.onClearRow && props.onClearRow(+props?.index);
              }}
            >
              <AiOutlineCloseCircle />
            </Table.ActionButton>
          )}
          {/* {!props.hideActionBtn &&  (
           
          ) } */}

          <Dialog active={delDialogOpen} onClose={handleDialogClose}>
            <Dialog.Container className="container-dialog">
              <div className="container-body">
                <h2 className="sub-title">Are you sure, you want to delete?</h2>
                <div className="container-button">
                  <Button onClick={handleDialogClose}>{keyword.Cancel}</Button>
                  <Button variant="error" onClick={handleDeleteConfirm}>
                    Confirm
                  </Button>
                </div>
              </div>
            </Dialog.Container>
          </Dialog>
        </td>
      </ReuseForm.ReuseFormTr>

      {!collapse && (
        <tr>
          <td colSpan={4}>
            <SmartTableContainer>
              <BasicTable>
                <tbody>
                  <tr>
                    {/* <td width="40px">{keyword["S.N."]}</td> */}
                    <td width="190px"> {keyword.Name} </td>
                    <td width="90px"> {keyword.Number} </td>
                    <td width="90px"> {keyword.Length}</td>
                    <td width="90px"> {keyword.Breadth}</td>
                    <td width="90px"> {keyword.Height}</td>
                    <td width="90px"> {keyword.Quantity} </td>
                    <td width="140px"> {keyword.Remarks} </td>
                    <td width="80px"> {keyword.Action}</td>
                  </tr>

                  {estimateList.map((item, index) => (
                    <EstimateItemForm
                      key={item.id}
                      index={index + 1}
                      value={estimateList.find((el) => el.id === item.id)}
                      onAdd={handleAdd}
                      onChange={handleChange}
                      onDelete={handleDelete}
                      total={estimateList.length}
                      budgetID={budgetID}
                      onAddRow={handleEstimateItemAddRow}
                      groupID={props.value?.group_id}
                    />
                  ))}
                </tbody>
              </BasicTable>
            </SmartTableContainer>
            <Flex justify="flex-end">
              {" "}
              <p style={{ marginRight: "35rem", padding: ".4rem", fontWeight: "bold", fontSize: "1.5rem" }}>
                {" "}
                {keyword.Quantity} :{props.total_measurement?.quantity}{" "}
              </p>{" "}
            </Flex>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}

const SmartTableContainer = styled.div<{ minWidth?: string }>`
  overflow-x: auto;

  table {
    min-width: ${(props) => props.minWidth || "none"};
  }
`;

const StyledButton = styled.button`
  display: inline-flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const BasicTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;

  & tr td,
  & tr th {
    padding: 5px;
    border: 1px solid #f1f1f1;
    /* background: #f9f9f9; */
    background: #ffffff !important;

    & strong {
      font-weight: 500;
    }

    input {
      width: 100%;
    }
  }
`;

export default EstimateItemGroup;
