import { createSlice } from "@reduxjs/toolkit";
import {
  addUser,
  getProfile,
  getUpabhoktaSamitiUsers,
  getUser,
  getUsers,
  updatePassword,
  updateUser,
} from "./usersApi";
import { keywordTypes } from "../../../hooks/useTranslated";

export type UserFormTypeII = {
  user_name: string;
  email: string;
  last_name: string;
  first_name: string;
  mobile_number: string;
  role: string;
  ward: string;
  password: string;
  confirm_password: string;
  dob_nepali?: string;
  dob_english?: string;
};

export interface UserFormTypeI {
  username: string;
  email: string;
  last_name: string;
  first_name: string;
  mobile_number: string;
  role: string;
  password: string;
  confirm_password: string;
}

export interface UserTypeI {
  id: string;
  username: string;
  last_name: string;
  first_name: string;
  email: string;
  url: string;
  role: string;
  ward: {
    id: string;
    nagarpalika: string;
    ward_name: keyof keywordTypes;
    ward_number: number;
  };
  profile_image: string;
  mobile_number: string;
  dob_english: string;
  dob_nepali: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  last_login: string;
}

export interface UserUpdatePasswordTypeI {
  data: {
    old_password: string;
    password: string;
    confirm_password: string;
  };
  id: string;
}
export interface UsersSliceStateI {
  loading: boolean;
  data: {
    links: {
      next: string;
      previous: string;
    };
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: UserTypeI[];
  } | null;
  item: UserTypeI | null;
  hasSubmitted: boolean;
  hasPasswordChanged: boolean;
  error: any;
}

const initialState: UsersSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  hasPasswordChanged: false,
  error: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    usersCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.hasPasswordChanged = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addUser.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getUsers.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getUpabhoktaSamitiUsers.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getUpabhoktaSamitiUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getUpabhoktaSamitiUsers.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getProfile.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateUser.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });

    builder.addCase(updatePassword.pending, (state) => {
      state.hasPasswordChanged = false;
    });
    builder.addCase(updatePassword.fulfilled, (state, { payload }) => {
      state.hasPasswordChanged = true;
    });
    builder.addCase(updatePassword.rejected, (state, { payload }: any) => {
      state.hasPasswordChanged = false;
    });
  },
});

export const { usersCleanUp } = usersSlice.actions;
export default usersSlice.reducer;
