import { Button, Grid, MainLayout, Table } from "@ims/common";
import Page from "../../core-ui/Page";
import useTranslated from "../../hooks/useTranslated";
import { useEffect, useState } from "react";
import { StartYojanaI } from "../../rtk/feature/start-yojana/startYojanaSlice";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { FiEdit, FiSearch } from "react-icons/fi";
import usePagination from "../../utils/usePagination";
import { styleSN } from "../../utils/Prettify";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getStartYojana } from "../../rtk/feature/start-yojana/startYojanaApi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import getFormattedSort from "../../utils/getFormattedSort";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import { useForm } from "react-hook-form";
import ReuseForm from "../../core-ui/ReuseForm";
import Status from "../../components/status/Status";
import { MdVerified } from "react-icons/md";
import { StatusColor } from "../../components/status/Status.style";
import { GoUnverified } from "react-icons/go";

interface PropsI {
  setBudgetID: Function;
  title?: string;
  hideDetails?: boolean;
}

const StartYojanaList = ({ setBudgetID, title, hideDetails }: PropsI) => {
  const [yojanList, setYojanaList] = useState<StartYojanaI[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});
  const { keyword } = useTranslated();
  const dispatch = useAppDispatch();

  const startYojanaSelector = useAppSelector((state) => state.startYojana);

  const [totalItems, setTotalItems] = useState(1);
  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    reset: filterReset,
    formState: { errors: filterErrors },
    watch: filterWatch,
  } = useForm();

  useEffect(() => {
    dispatch(
      getStartYojana({
        per_page: perPage,
        page: page,
        ordering: getFormattedSort(sorts),
      })
    );
  }, [dispatch, page, perPage]);

  useEffect(() => {
    dispatch(showLoading(startYojanaSelector.loading));
  }, [startYojanaSelector.loading]);

  useEffect(() => {
    if (startYojanaSelector?.data?.results) {
      setYojanaList(startYojanaSelector.data.results);
      setTotalItems(startYojanaSelector.data?.total);
    }
  }, [startYojanaSelector?.data]);

  const onFilterSubmit = (data: any) => {
    dispatch(
      getStartYojana({
        per_page: perPage,
        search: data.search,
        ordering: getFormattedSort(sorts),
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getStartYojana({
        per_page: perPage,
        ordering: getFormattedSort(sorts),
      })
    );
    filterReset();
  };

  return (
    <>
      <MainLayout.Main>
        <Page.Container>
          <Page.Title>{title || keyword["Plan Management"]}</Page.Title>
        </Page.Container>
        <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
          <Grid style={{ marginBottom: "1rem" }}>
            <Grid.Row gap={0.75}>
              <Grid.Col spanMd={4}>
                <ReuseForm.Input
                  label={keyword["Search"]}
                  name="search"
                  placeholder={keyword["Search here"]}
                  rules={{ required: false }}
                  register={filterRegister("search")}
                  error={filterErrors["search"]}
                  h="34px"
                />
              </Grid.Col>

              <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
                <Button variant="primary">
                  {" "}
                  <FiSearch />
                </Button>
                <Button type="reset" onClick={handleFilterReset}>
                  {keyword["Reset"]}
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </ReuseForm>
        <Table.Container>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
                <Table.HeadCol>{keyword.Yojana}</Table.HeadCol>
                <Table.HeadCol> {keyword.Ward} </Table.HeadCol>
                <Table.HeadCol> {keyword.Status} </Table.HeadCol>
                {!hideDetails && <Table.HeadCol>{keyword["Explore Details"]}</Table.HeadCol>}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {yojanList?.length > 0 &&
                yojanList?.map((yojana, index) => {
                  return (
                    <Table.Row key={yojana?.id}>
                      <Table.Col>{englishNepaliNumberTranslation(styleSN(index + 1))}</Table.Col>
                      <Table.Col>{yojana?.yojana}</Table.Col>
                      <Table.Col>{yojana?.ward}</Table.Col>
                      <Table.Col>
                        {yojana?.status === "verified" ? (
                          <Status variant="verified">
                            <MdVerified color={StatusColor.verifed} size={20} title={keyword["Approved"]} />
                          </Status>
                        ) : yojana?.status === "correction" ? (
                          <Status variant="correction">
                            <GoUnverified color={StatusColor.correction} size={20} title={keyword["For correction"]} />
                          </Status>
                        ) : (
                          <Status variant="not-verifed">
                            <GoUnverified color={StatusColor.notVerified} size={20} title={keyword["Sent"]} />
                          </Status>
                        )}
                      </Table.Col>

                      {!hideDetails && (
                        <Table.Col align="center">
                          <Table.ActionButton onClick={() => setBudgetID(yojana?.yojana_id || "")}>
                            <FiEdit />
                          </Table.ActionButton>
                        </Table.Col>
                      )}
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          <Pagination />
        </Table.Container>
      </MainLayout.Main>
    </>
  );
};

export default StartYojanaList;
