import React, {
  HTMLAttributes,
  HtmlHTMLAttributes,
  TableHTMLAttributes,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  StyledTable,
  StyledTableActionButton,
  StyledTableBody,
  StyledTableCol,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeadCol,
  StyledTableHeadSortCol,
  StyledTablePagination,
  StyledTablePaginationButton,
  StyledTablePaginationContainer,
  StyledTableRow,
} from "./Table.style";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";

interface TablePropsI extends TableHTMLAttributes<HTMLTableElement> { }

export default function Table(props: TablePropsI) {
  return <StyledTable {...props} />;
}

interface TableContainerPropsI extends TableHTMLAttributes<HTMLDivElement> { }

Table.Container = function TableContainerFunction(props: TableContainerPropsI) {
  return <StyledTableContainer {...props} />;
};

interface TableHeadPropsI
  extends TableHTMLAttributes<HTMLTableCaptionElement> { }

Table.Head = function TableHeadFunction(props: TableHeadPropsI) {
  return <StyledTableHead {...props} />;
};

export interface SortTypeI {
  active: boolean;
  asc: boolean;
}

export interface SortsTypeI {
  [x: string]: SortTypeI;
}

interface TableHeadSortPropsI
  extends TableHTMLAttributes<HTMLTableCaptionElement> {
  sorts: SortsTypeI;
}

export const HeadSortContext = React.createContext<{ sorts: SortsTypeI }>({
  sorts: {},
});

Table.HeadSort = function TableHeadSortFunction(props: TableHeadSortPropsI) {
  const [sorts, setSorts] = useState<SortsTypeI>({});

  useEffect(() => {
    setSorts(props.sorts);
  }, [props.sorts]);

  return (
    <HeadSortContext.Provider value={{ sorts: sorts }}>
      <StyledTableHead {...props} />
    </HeadSortContext.Provider>
  );
};

interface TableBodyPropsI
  extends TableHTMLAttributes<HTMLTableSectionElement> { }

Table.Body = function TableBodyFunction(props: TableBodyPropsI) {
  return <StyledTableBody {...props} />;
};

interface TableHeadColPropsI extends TableHTMLAttributes<HTMLTableCellElement> {
  colSpan?: number;
  rowSpan?: number;
}

Table.HeadCol = function TableHeadColFunction(props: TableHeadColPropsI) {
  return <StyledTableHeadCol {...props} />;
};

interface TableHeadSortColPropsI
  extends TableHTMLAttributes<HTMLTableCaptionElement> {
  onSort: any;
  field: string;
}

Table.HeadSortCol = function TableHeadSortColFunction(
  props: TableHeadSortColPropsI
) {
  const { sorts } = useContext(HeadSortContext);

  const { children, onSort, field, ...otherProps } = props;
  const [asc, setAsc] = useState(false);
  const [active, setActive] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const item = sorts[field];
    if (item) setActive(item.active);
  }, [sorts, field]);

  const handleClick = () => {
    setAsc((act) => !act);
    setActive(true);
    setClicked(true);
  };

  useEffect(() => {
    if (clicked) onSort({ field, active, asc });
    setClicked(false);
  }, [onSort, field, active, asc, clicked]);

  return (
    <StyledTableHeadSortCol {...otherProps} onClick={handleClick}>
      {children}

      <div>
        <VscTriangleUp className={asc && active ? "active" : ""} />
        <VscTriangleDown className={!asc && active ? "active" : ""} />
      </div>
    </StyledTableHeadSortCol>
  );
};

interface TableRowPropsI extends TableHTMLAttributes<HTMLTableRowElement> { }

Table.Row = function TableRowFunction(props: TableRowPropsI) {
  return <StyledTableRow {...props} />;
};

interface TableColPropsI extends TableHTMLAttributes<HTMLTableCellElement> {

  colSpan?: number
  rowSpan?: number
}

Table.Col = function TableColFunction(props: TableColPropsI) {
  return <StyledTableCol rowSpan={props.rowSpan} colSpan={props.colSpan} {...props} />;
};

interface TableActionButtonPropsI
  extends HtmlHTMLAttributes<HTMLButtonElement> {
  type?: any;
  disabled?: boolean;
}

Table.ActionButton = function TableActionButtonFunction(
  props: TableActionButtonPropsI
) {
  return <StyledTableActionButton {...props} />;
};

interface TablePaginationPropsI extends HtmlHTMLAttributes<HTMLDivElement> { }

Table.Pagination = function TablePaginationFunction(
  props: TablePaginationPropsI
) {
  return <StyledTablePagination {...props} />;
};

interface TablePaginationContainerPropsI
  extends HtmlHTMLAttributes<HTMLDivElement> { }

Table.PaginationContainer = function TablePaginationContainerFunction(
  props: TablePaginationContainerPropsI
) {
  return <StyledTablePaginationContainer {...props} />;
};

interface TablePaginationButtonPropsI
  extends HtmlHTMLAttributes<HTMLAnchorElement> { }

Table.PaginationButton = function TablePaginationButtonFunction(
  props: TablePaginationButtonPropsI
) {
  return <StyledTablePaginationButton {...props} />;
};

interface TablePaginationItemPropsI
  extends HtmlHTMLAttributes<HTMLAnchorElement> {
  disabled?: boolean;
}

Table.PaginationItem = function TablePaginationItemFunction(
  props: TablePaginationItemPropsI
) {
  return <StyledTablePaginationButton {...props} />;
};
