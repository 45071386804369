/**
 * The function `englishToNepaliNumber` converts an English number to its equivalent Nepali number.
 * @param {number} number - The `number` parameter is a number that you want to convert from English to
 * Nepali representation.
 * @returns a string representation of the input number in Nepali numerals.
 */

import { LangI, getActiveLang } from "./res-mes/response-message";

export const englishNepaliNumberTranslation = (num: number | string): string => {
  const nepaliNumbers: string[] = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
  const lang: LangI = getActiveLang();

  if (num === null) {
    return "N/A";
  }
  if (typeof +num !== "number" || isNaN(+num)) {
    return lang === "en" ? "Not a number" : "नम्बर होइन";
  }

  const englishNumberString: string = num.toString();
  const nepaliNumberString: string = englishNumberString.replace(/\d/g, (digit) => nepaliNumbers[parseInt(digit, 10)]);
  return lang === "np" ? nepaliNumberString : englishNumberString;
};
