import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteElectrificationWorks,
  getElectrificationWork,
  getElectrificationWorks,
} from "../../../../rtk/feature/electrification-work/electrificationWorkAPI";
import { useAppDispatch, useAppSelector } from "../../../../rtk/hook";
import WorkTable from "../components/WorkTable/WorkTable";
import { updateElectrificationWorks } from "../../../../rtk/feature/electrification-work/electrificationWorkAPI";
import useTranslated from "../../../../hooks/useTranslated";
interface IProps {
  budgetId: string;
  isAddable: boolean;
}

const ElectrificationWork = (props: IProps) => {
  const { budgetId } = props;
  const { data, error, loading, deleteSuccess, formSubmitted } = useAppSelector((state) => state.electrificationWork);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getElectrificationWorks({ id: props.budgetId }));
  }, []);

  const addElectrificationWork = () => {};

  const handleEdit = (id: string) => {
    navigate(`/plan-management/plan/electrification-work/edit/${budgetId}/${id}`);
  };

  const onDelete = (id: string) => {
    dispatch(deleteElectrificationWorks({ id: budgetId, workId: id }));
  };
  const onRateAdd = (rate: number, workId: string) => {
    dispatch(updateElectrificationWorks({ data: { rate }, id: budgetId, workId }));
  };

  const handleAddFinalQuantity = (finalQuantity: number, workId: string) => {
    dispatch(updateElectrificationWorks({ data: { final_quantity: finalQuantity }, id: props.budgetId, workId }));
  };

  const { keyword } = useTranslated();

  return (
    <WorkTable
      rateAddSuccess={formSubmitted}
      addUrl={`/plan-management/plan/electrification-work/add/${props.budgetId}`}
      data={data}
      deleteSuccess={deleteSuccess}
      editUrl="/"
      onAddClick={addElectrificationWork}
      onEditClick={handleEdit}
      tableName={keyword["Electrification Work"]}
      onDelete={onDelete}
      onRateAdd={onRateAdd}
      // onFinalQuantityAdd={handleAddFinalQuantity}
      hideAddBtn={!props.isAddable}
    />
  );
};

export default ElectrificationWork;
