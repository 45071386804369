import { createSlice } from "@reduxjs/toolkit";
import {
  addUpahboktaSamitiMinute,
  getUpahboktaSamitiMinute,
  updateUpahboktaSamitiMinute,
} from "./upabhoktaSamitiMinuteApi";

interface TitleI {
  id: string;
  title: string;
}

export interface UpabhoktaSamitiMinuteI {
  id?: string;
  upabhokta_samiti: string;
  comments: string;
  title: TitleI;
  file_url: string;
}

export interface UpabhoktaSamitiMinuteStateI {
  loading: boolean;
  data: UpabhoktaSamitiMinuteI[] | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: UpabhoktaSamitiMinuteStateI = {
  loading: false,
  data: null,
  hasSubmitted: false,
  error: null,
};

const upabhoktaSamitiMinuteSlice = createSlice({
  name: "upabhoktaSamitiMinute",
  initialState,
  reducers: {
    upabhoktaSamitiMinuteCleanUp: (state) => {
      state.loading = false;
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addUpahboktaSamitiMinute.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addUpahboktaSamitiMinute.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addUpahboktaSamitiMinute.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(updateUpahboktaSamitiMinute.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(updateUpahboktaSamitiMinute.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(updateUpahboktaSamitiMinute.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getUpahboktaSamitiMinute.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getUpahboktaSamitiMinute.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getUpahboktaSamitiMinute.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
  },
});

export const { upabhoktaSamitiMinuteCleanUp } = upabhoktaSamitiMinuteSlice.actions;
export default upabhoktaSamitiMinuteSlice.reducer;
