import { Button, Grid, MainLayout, Table } from "@ims/common";
import React, { useEffect, useState } from "react";
import { FiEdit, FiEye, FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Page from "../../core-ui/Page";
import useTranslated from "../../hooks/useTranslated";
import { getPlanningInspection } from "../../rtk/feature/planning-inspection/planningInspectionAPI";
import { getYojanas } from "../../rtk/feature/yojana/yojanaApi";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import usePagination, { getSN } from "../../utils/usePagination";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { useForm } from "react-hook-form";
import { realTimeFilter } from "../../utils/real-time-filter";
import ReuseForm from "../../core-ui/ReuseForm";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import getFormattedSort from "../../utils/getFormattedSort";

const PlanningInspection = () => {
  const dispatch = useAppDispatch();
  const [totalItems, setTotalItems] = useState(1);
  const [planningInspectionData, setPlanningInspectionData] = useState<any[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    formState: { errors: filterErrors },
    reset: filterReset,
    watch: filterWatch,
  } = useForm();

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });
  useEffect(() => {
    dispatch(getPlanningInspection({ params: { per_page: perPage, page } }));
    dispatch(getYojanas());
  }, [perPage, page]);
  const { data: planningInspectionDataSelector } = useAppSelector((state) => state.planningInspection);

  useEffect(() => {
    if (planningInspectionDataSelector) {
      setTotalItems(planningInspectionDataSelector?.total);
      setPlanningInspectionData(planningInspectionDataSelector?.results);
    }
  }, [planningInspectionDataSelector]);
  const navigate = useNavigate();
  const handleEdit = (id: string) => {
    navigate(`/planning-inspection/edit/${id}`);
  };

  const navigateTo = (url: string) => {
    window.open(url);
  };
  const { keyword } = useTranslated();

  const onFilterSubmit = (data: any) => {
    dispatch(
      getPlanningInspection({
        params: { per_page: perPage, search: data.search, ordering: getFormattedSort(sorts) },
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getPlanningInspection({
        params: { per_page: perPage, page: page, ordering: getFormattedSort(sorts) },
      })
    );
    filterReset();
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {keyword["Plan Inspection"]} </Page.Title>
        <Button variant="primary" onClick={() => navigate(`/planning-inspection/add`)}>
          {keyword["Add Inspection"]}
        </Button>
      </Page.Container>

      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>

              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>{keyword.Name}</Table.HeadCol>
              <Table.HeadCol>{keyword.Description}</Table.HeadCol>
              <Table.HeadCol>remarks</Table.HeadCol>
              <Table.HeadCol> {keyword["Inspection Report"]} </Table.HeadCol>
              <Table.HeadCol> {keyword["Anugaman Pratibedan"]}</Table.HeadCol>
              <Table.HeadCol> {keyword["Expenditure Report"]} </Table.HeadCol>
              <Table.HeadCol>{keyword.Actions}</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {planningInspectionData?.length > 0 &&
              planningInspectionData?.map((inspection, index) => {
                return (
                  <Table.Row key={inspection.id}>
                    <Table.Col>{englishNepaliNumberTranslation(getSN(index))}</Table.Col>
                    <Table.Col>{inspection.name}</Table.Col>
                    <Table.Col>{inspection.description}</Table.Col>
                    <Table.Col>{inspection.remarks}</Table.Col>

                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => navigateTo(inspection.inspection_report)}>
                        <FiEye />
                      </Table.ActionButton>
                    </Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => navigateTo(inspection.anugaman_pratibedan)}>
                        <FiEye />
                      </Table.ActionButton>
                    </Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => navigateTo(inspection.expenditure_report)}>
                        <FiEye />
                      </Table.ActionButton>
                    </Table.Col>

                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(inspection.id)}>
                        <FiEdit />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
};

export default PlanningInspection;
