import { createSlice } from "@reduxjs/toolkit";
import { Yojana } from "../constructor/constructorSlice";
import { UnitTypeI } from "../unit/unitSlice";
import {
  addGroupPlanEstimate,
  deleteGroupPlanEstimate,
  getGroupPlanEstimate,
  getGroupPlanEstimates,
  updateGroupPlanEstimate,
} from "./planGroupEstimateApi";

export interface GroupPlanEstimateFormTypeI {
  id?: string;
  name: string;
  shapes: string;
  number: number;
  formula: string;
  description: string;
  quantity: number;
  remarks: string;
  length: number;
  breadth: number;
  height: number;
  final_measurement: number;
  _is_latest?: boolean;
  final_quantity?: string | null;
}

export interface EstimationDetailFormTypeI {
  equipment_measurement_analysis?: string[];
  equipment_quantity_analysis: any;
  name: string;
  unit: string;
}

export interface EstimationDetailTypeI {
  id: string;
  yojana: string;
  equipment_quantity_analysis: any;
  name: string;
  unit: UnitTypeI;
  equipment_measurement_analysis: EquipmentMeasurementAnalysis[];
  total_measurement: {
    quantity: number;
    rate: number;
    total: number;
  };
}

export interface EquipmentMeasurementAnalysis {
  id: string;
  name: string;
  shapes: string;
  formula: string;
  length: string;
  breadth: string;
  height: null | string;
  number: number;
  quantity: number;
  remarks: string;
  final_measurement: string;
  created_on: Date;
  modified_on: Date;
}

export interface GroupPlanEstimatesSliceStateI {
  loading: boolean;
  data: { results: EstimationDetailTypeI[]; count: number; next: null | number; previous: null | number };
  item: EstimationDetailTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: GroupPlanEstimatesSliceStateI = {
  loading: false,
  data: { results: [], count: 0, next: null, previous: null },
  item: null,
  hasSubmitted: false,
  error: null,
};

const planGroupEstimateSlice = createSlice({
  name: "planGroupEstimate",
  initialState,
  reducers: {
    groupPlanEstimateCleanUp: (state) => {
      state.data.results = [];
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addGroupPlanEstimate.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addGroupPlanEstimate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
      state.data.results = [...state.data.results, payload];
    });
    builder.addCase(addGroupPlanEstimate.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getGroupPlanEstimates.pending, (state) => {
      state.loading = true;
      state.data.results = [];
      state.error = null;
    });
    builder.addCase(getGroupPlanEstimates.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getGroupPlanEstimates.rejected, (state, { payload }) => {
      state.loading = false;
      state.data.results = [];
      state.error = true;
    });
    builder.addCase(getGroupPlanEstimate.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getGroupPlanEstimate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;

      state.data.results = state.data.results.map((el) => {
        if (el.id === payload.id) {
          return payload;
        }
        return el;
      });
    });
    builder.addCase(getGroupPlanEstimate.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateGroupPlanEstimate.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateGroupPlanEstimate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.data.results = state.data.results.map((el) => {
        if (el.id === payload.id) {
          return payload;
        }
        return el;
      });
      state.error = null;
    });
    builder.addCase(updateGroupPlanEstimate.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteGroupPlanEstimate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteGroupPlanEstimate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.results = state.data.results.filter((el) => el.id !== payload);
      state.error = null;
    });
    builder.addCase(deleteGroupPlanEstimate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { groupPlanEstimateCleanUp } = planGroupEstimateSlice.actions;
export default planGroupEstimateSlice.reducer;
