import { createSlice } from "@reduxjs/toolkit";
import { IplanVerificationData, IVerification } from "../plan-verification/planVerificationSlice";
import { getPlanReport } from "./planReportAPI";

interface IReport {
    loading: boolean;
    data: IVerification | null;
    error: null;
}


const initialState: IReport = {
    data: null,
    loading: false,
    error: null
}

const planReportSlice = createSlice({
    name: "planReport",
    initialState,
    reducers: {
        cleanUpPlanReport: (state) => {
            state.data = null;
            state.loading = false;
            state.error = null
        }
    }
    ,
    extraReducers: (builder) => {
        builder.addCase(getPlanReport.pending, (state) => {

            state.data = null;
            state.error = null;
            state.loading = true

        })
        builder.addCase(getPlanReport.rejected, (state) => {

            state.data = null;
            state.error = null;
            state.loading = false

        })
        builder.addCase(getPlanReport.fulfilled, (state, { payload }) => {

            state.data = payload;
            state.error = null;
            state.loading = false

        })
    }
});

export const { cleanUpPlanReport } = planReportSlice.actions
export default planReportSlice.reducer