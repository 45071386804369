import { createSlice } from "@reduxjs/toolkit";
import { GiSattelite } from "react-icons/gi";
import {
  getAllVerifications,
  getAllVerifiedVerifications,
  getPlanVerifications,
  getVerificationById,
  updateReceivedPlan,
} from "./planVerificationAPI";

export interface IYojana {
  id: string;
  serial_no: number;
  hint: string;
  name: string;
  expenditure_heading: string;
  source: string;
  attention: string;
  unit: string;
  allocation: string;
  expenditure: string;
  expenditure_rate: string;
  balance: string;
  yojana_brief: string;
  planning_estimation?: any;
  pdf_url?: string;
}

export interface IplanVerificationData {
  id: string;
  comment: string;
  yojana: IYojana;
  description: string;
  send_from: string;
  send_to: string;
  is_prasasakiya_adhikrit: boolean;
  is_verified: boolean;
  is_started: boolean;
  created_on: string;
  modified_on: string;
  status?: string;
}

export interface IVerification {
  id: string;
  yojana: Yojana;
  yojana_brief: YojanaBrief;
  yojana_estimation: YojanaEstimation[];
  equipment_quantity_analysis: IEquipmentQuantityAnalysis[];
  description: string;
  comment: any;
  send_from: string;
  send_to: string;
  is_prasasakiya_adhikrit: boolean;
  is_verified: boolean;
  is_started: boolean;
  created_on: string;
  status: planStatus;
  modified_on: string;
}

interface IPlanVerificationBaseResponse {
  loading: boolean;
  error: any;
  data: any;
}

interface IFormPlanVerification extends IPlanVerificationBaseResponse {
  data: null | IplanVerificationData;
}

interface IResponse extends IPlanVerificationBaseResponse {
  data: null | IplanVerificationData;
}

export interface Yojana {
  id: string;
  serial_no: number;
  hint: string;
  name: string;
  expenditure_heading: string;
  source: string;
  attention: string;
  unit: string;
  allocation: string;
  expenditure: string;
  expenditure_rate: string;
  balance: string;
  yojana_brief: string;
  planning_estimation: any;
}

export interface YojanaBrief {
  id: string;
  contingency: string;
  yojana_head: string;
  tole: string;
  beneficiaries: number;
  yojana_status: string;
  measurement: string;
  yojana_subtype: string;
  completion_date: string;
  contribution_donation: string;
  description: string;
  notice_published: boolean;
  yojana_completion: boolean;
  final_measurement: string;
  pre_project_photo: any;
  mid_project_photo: any;
  final_project_photo: any;
  completed_photo: any;
}

export interface YojanaEstimation {
  id: string;
  yojana: string;
  equipment_quantity_analysis: EquipmentQuantityAnalysis[];
  description: string;
  equipment_measurement_analysis: Analysis[];
}

export interface EquipmentQuantityAnalysis {
  id: string;
  planning_rate_analysis: string;
}
export interface PlanningRateAnalysis {
  id: string;
  equipment_rate_id: string;
  english_name: string;
  nepali_name: string;
  english_description: string;
  nepali_description: string;
  per_rate: string;
  unit: {
    id: string;
    name: string;
    code: string;
  };
  rate_id: string;
  code: string;
}

export interface IEquipment {
  id: string;
  equipment: Equipment2;
  quantity: number;
  total_amount: number;
  created_on: string;
  modified_on: string;
}

export interface Equipment2 {
  id: string;
  english_name: string;
  nepali_name: string;
  unit: number;
  type: string;
}

export interface TotalAmount {
  sub_total: number;
  contractor_profit: number;
  sub_total_after_cp: number;
  vat_amt: number;
  grand_total: number;
}
export interface IEquipmentQuantityAnalysis {
  id: string;
  planning_rate_analysis: PlanningRateAnalysis;
  equipments: IEquipment[];
  total_amount: TotalAmount;
}

export interface Analysis {
  id: string;
  name: string;
  shapes: string;
  formula: string;
  length: string;
  breadth: string;
  height: string;
  quantity: number;
  remarks: string;
  final_measurement: string;
  created_on: string;
  modified_on: string;
}
export enum planStatus {
  NOT_VERIFIED = "not_verified",
  VERIFIED = "verified",
  PENDING = "pending",
  CORRECTION = "correction",
  IS_STARTED = "is_started",
}

interface IAllVerifications extends IPlanVerificationBaseResponse {
  data: null | IVerification[];
}

export interface IReceiveVerification extends IPlanVerificationBaseResponse {
  data: null | IVerification;
}

interface IinitialState {
  postData: IFormPlanVerification;
  getData: IResponse;
  getAllData: IAllVerifications;
  verificationReceive: IReceiveVerification;
  formSubmitted: boolean;
}
const initialState: IinitialState = {
  postData: {
    data: null,
    error: null,
    loading: false,
  },
  getData: {
    data: null,
    error: null,
    loading: false,
  },
  getAllData: {
    data: null,
    error: null,
    loading: false,
  },
  verificationReceive: {
    data: null,
    error: null,
    loading: false,
  },
  formSubmitted: false,
};

const planVerificationSlice = createSlice({
  initialState,
  name: "planVerificationSlice",
  reducers: {
    planVerificationCleanUp: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlanVerifications.pending, (state, { payload }) => {
      state.getData.data = null;
      state.getData.loading = true;
      state.getData.error = null;
    });
    builder.addCase(getPlanVerifications.fulfilled, (state, { payload }) => {
      state.getData.data = payload;
      state.getData.loading = false;
      state.getData.error = null;
    });
    builder.addCase(getPlanVerifications.rejected, (state, { payload }) => {
      state.getData.data = null;
      state.getData.loading = false;
    });

    builder.addCase(getAllVerifications.pending, (state) => {
      state.getAllData.data = null;
      state.getAllData.loading = true;
      state.getAllData.error = null;
    });
    builder.addCase(getAllVerifications.rejected, (state) => {
      state.getAllData.data = null;
      state.getAllData.loading = false;
      // state.getAllData.error = null
    });
    builder.addCase(getAllVerifications.fulfilled, (state, { payload }) => {
      state.getAllData.data = payload;
      state.getAllData.loading = false;
      state.getAllData.error = null;
    });
    builder.addCase(getAllVerifiedVerifications.pending, (state) => {
      state.getAllData.data = null;
      state.getAllData.loading = true;
      state.getAllData.error = null;
    });
    builder.addCase(getAllVerifiedVerifications.rejected, (state) => {
      state.getAllData.data = null;
      state.getAllData.loading = false;
      // state.getAllData.error = null
    });
    builder.addCase(getAllVerifiedVerifications.fulfilled, (state, { payload }) => {
      state.getAllData.data = payload;
      state.getAllData.loading = false;
      state.getAllData.error = null;
    });

    builder.addCase(getVerificationById.pending, (state) => {
      state.verificationReceive.loading = true;
      state.verificationReceive.error = null;
      state.verificationReceive.data = null;
    });

    builder.addCase(getVerificationById.rejected, (state) => {
      state.verificationReceive.loading = false;
      state.verificationReceive.error = null;
      state.verificationReceive.data = null;
    });
    builder.addCase(getVerificationById.fulfilled, (state, { payload }) => {
      state.verificationReceive.loading = false;
      state.verificationReceive.error = null;
      state.verificationReceive.data = payload;
    });

    builder.addCase(updateReceivedPlan.pending, (state) => {
      state.formSubmitted = false;
    });
    builder.addCase(updateReceivedPlan.fulfilled, (state) => {
      state.formSubmitted = true;
    });
  },
});
export const { planVerificationCleanUp } = planVerificationSlice.actions;
export default planVerificationSlice.reducer;
