import { Button, Form, Grid, MainLayout } from "@ims/common";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Page from "../../core-ui/Page";
import {
  cleanUpDistrictSlice,
  IDistrictRate,
  IDistrictRateForm,
} from "../../rtk/feature/district-rate/districtRateSlice";
import { getUnits } from "../../rtk/feature/unit/unitApi";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import ReuseForm from "../../core-ui/ReuseForm";
import { ReactSelectOptionI } from "../plan/PlanSearch";
import {
  createDistrictRate,
  getDistrictRateById,
  updateDistrictRate,
} from "../../rtk/feature/district-rate/districtRateApi";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { UnitTypeI } from "../../rtk/feature/unit/unitSlice";
import { IOptions } from "../../interfaces/options.interface";
import { TypeChoice } from "../../utils/constants";
import useTranslated from "../../hooks/useTranslated";

const DistrictRateForm = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  interface IForm extends IDistrictRateForm {
    unit: IOptions;
    type: IOptions;
  }
  const units = useAppSelector((state) => state.units.data);
  const {
    setValue,
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<IForm>();
  useEffect(() => {
    dispatch(getUnits({ per_page: 100000000 }));
  }, []);
  const navigate = useNavigate();
  const params = useParams();

  const iseditMode = location.pathname.includes("/edit") && params.id;
  const {
    byId: { data: districtRateyId },
    formSubmitted,
  } = useAppSelector((state) => state.districtRate);

  const onSubmit = (incoming: IForm) => {
    const data = { ...incoming, type: incoming.type.value };

    if (iseditMode && params.id) {
      dispatch(updateDistrictRate({ data: { ...data, unit: data.unit.value }, id: params.id }));
    } else {
      dispatch(createDistrictRate({ data: { ...data, unit: data.unit.value } }));
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const options = units?.results.map((el) => {
    return {
      label: el.name,
      value: el.id,
    };
  });

  useEffect(() => {
    if (!location) return;
    if (iseditMode && params.id) {
      dispatch(getDistrictRateById({ id: params.id }));
    }
  }, [location]);

  useEffect(() => {
    if (districtRateyId) {
      setValue("analysis_rate", districtRateyId?.analysis_rate);
      setValue("particular", districtRateyId?.particular);
      setValue("remarks", districtRateyId?.remarks);

      setValue("unit", { label: districtRateyId.unit.name, value: districtRateyId?.unit.id });
    }
  }, [districtRateyId]);

  useEffect(() => {
    if (formSubmitted) {
      navigate(`/district-rate`);
    }
    return () => {
      dispatch(cleanUpDistrictSlice());
    };
  }, [formSubmitted]);

  const typeOptions = TypeChoice.map((type) => {
    return {
      label: type,
      value: type,
    };
  });
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Title> {keyword["District Rate Form"]} </Page.Title>
      <ReuseForm control={control} onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["particular"])}>
                <Form.Label> {keyword.Particular} </Form.Label>

                <Form.Input
                  placeholder={keyword.Particular}
                  register={register("particular", { required: "particular is necessary" })}
                  name="particular"
                />
                <Form.HelperText> {errors.particular?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["analysis_rate"])}>
                <Form.Label> {keyword["Analysis Rate"]} </Form.Label>
                <Form.Input
                  type="number"
                  step="any"
                  placeholder={keyword["Analysis Rate"]}
                  register={register("analysis_rate", { required: "analysis is required" })}
                  name="analysis_rate"
                />
                <Form.HelperText> {errors.analysis_rate?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>{" "}
            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["unit"])}>
                <ReuseForm.ReactSelect
                  label={keyword.Unit}
                  name="unit"
                  options={options}
                  placeholder={keyword["Select Unit"]}
                  rules={{ required: "Type is required." }}
                  error={errors["unit"]}
                />
                {/* <Form.HelperText  > {errors.unit?.message} </Form.HelperText> */}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["unit"])}>
                <ReuseForm.ReactSelect
                  label={keyword.Type}
                  name="type"
                  options={typeOptions}
                  placeholder={keyword["Select Type"]}
                  rules={{ required: "Type is required." }}
                  error={errors["type"]}
                />
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={12} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["remarks"])}>
                <Form.Label> {keyword.Remarks} </Form.Label>

                <Form.Input
                  placeholder={keyword.Remarks}
                  register={register("remarks", { required: "remarks is required" })}
                  name="remarks"
                />
                <Form.HelperText> {errors.remarks?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {iseditMode ? keyword.Update : keyword.Add}
              </Button>
              <Button onClick={handleCancel}> {keyword.Cancel} </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
};

export default DistrictRateForm;
