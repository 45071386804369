import { createAsyncThunk } from "@reduxjs/toolkit";
import { imsClient } from "../../../utils/imsClient";
import { AppError } from "../../../utils/AppError";
import { toast } from "react-toastify";
import { DocumentSuccess } from "../../../utils/res-mes/response-message-generator";

export const getEstimationDocument = createAsyncThunk("getEstimationDocument", async (id: string) => {
  try {
    const res = await imsClient.get(`/yojana/${id}/final-estimation/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
  }
});

export const addEstimationDocument = createAsyncThunk(
  "addEstimationDocument",
  async (args: { id: string; data: any }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/yojana/${args.id}/final-estimation/`, args.data, {
        headers: { "Content-Type": "form/multipart" },
      });
      if (res.data) toast.success(DocumentSuccess("created"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
