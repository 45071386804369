import React, { useRef, useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StyledButton } from "../../components/documents/Document.style";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { YojanaBookTemplateTypeI } from "../../rtk/feature/yojana-book-template/yojanaBookTemplateSlice";
import { YojanaBookTypeI } from "../../rtk/feature/yojana-books/yojanaBooksSlice";
import { addYojanaBook, getYojanaBook, updateYojanaBook } from "../../rtk/feature/yojana-books/yojanaBooksApi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import useTranslated from "../../hooks/useTranslated";
import { Button } from "@ims/common";
import PdfGenerator from "../../components/handle-pdf/PdfGenerator";
import PopupModal from "../../components/PopupModal/PopupModal";
import {
  getYojanaBookTemplate,
  updateYojanaBookTemplate,
} from "../../rtk/feature/yojana-book-template/yojanaBookTemplateApi";
import ConfirmModal from "./ConfirmModal";
import { LayoutContext } from "@ims/common/src/core-ui/layout/MainLayout";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import EditorPage from "./EditorPage";
interface YojanTemplateI {
  content?: string;
}

function YojanaTemplate() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editorContent, setEditorContent] = useState<string>("");
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [yojana, setYojana] = useState<YojanaBookTemplateTypeI | YojanaBookTypeI>({
    id: "",
    title: "",
    content: "",
    order: -1,
    pdf_file: "",
    pdf_file_url: "",
  });

  const navigate = useNavigate();
  const { keyword } = useTranslated();
  const dispatch = useAppDispatch();
  const editorRef = useRef<any>(null);
  const { budgetId, documentId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isEditTemplate = queryParams.get("edit");

  const { setExpanded } = useContext(LayoutContext);
  const yojanaBookTemplateSelector = useAppSelector((state) => state.yojanaBookTemplate);
  const yojanaBooksSelector = useAppSelector((state) => state.yojanaBooks);

  const getYojanaData = () => {
    const yojanaData = isEditTemplate === "true" ? yojanaBooksSelector.data : yojanaBookTemplateSelector.data;

    const selectedYojana = yojanaData.find((item) => item.id === documentId);
    if (selectedYojana) {
      setYojana(selectedYojana);
      setEditorContent(selectedYojana.content);
    }
  };

  useEffect(() => {
    if (budgetId && isEditTemplate === "true") dispatch(getYojanaBook(budgetId));
    else dispatch(getYojanaBookTemplate());
  }, [budgetId, documentId]);

  useEffect(() => {
    getYojanaData();
  }, [yojanaBooksSelector.data, yojanaBookTemplateSelector.data]);

  useEffect(() => {
    dispatch(showLoading(yojanaBookTemplateSelector.loading || yojanaBooksSelector.loading));
  }, [yojanaBookTemplateSelector.loading, yojanaBooksSelector.loading]);
  useEffect(() => {
    if (!isModalOpen && !showConfirmModal) {
      setExpanded(false);
    }
  }, [isModalOpen, showConfirmModal]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateSourceCodeToBackend = async () => {
    let sendFunction: any;
    let params: { id: string; budgetID?: string };
    if (isEditTemplate === "true") {
      sendFunction = updateYojanaBook;
      params = { id: documentId || "", budgetID: budgetId || "" };
    } else {
      sendFunction = addYojanaBook;
      params = { id: budgetId || "" };
    }

    const successfullySend = await dispatch(sendFunction({ data: yojana, ...params }));
    if (successfullySend) {
      closeModal();
      navigate(-1);
    }
  };
  const handleCancel = () => {
    navigate(-1);
    setExpanded(true);
  };
  const onSavehandle = () => {
    openModal();
    setYojana((prevState) => {
      return {
        ...prevState,
        content: editorContent,
      };
    });
  };

  const handleYojanaTemplateUpdate = async () => {
    if (documentId) {
      const updateData = { ...yojana, content: editorContent };
      const params = { id: documentId };
      const successfullySend = await dispatch(updateYojanaBookTemplate({ data: updateData, ...params }));
      if (successfullySend) {
        navigate(-1);
      }
    }
  };
  const downloadPDF = async (pdfUrl: string, yojanaBookTitle: string) => {
    if (pdfUrl) {
      const response = await fetch(pdfUrl);

      const blob = await response.blob();

      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.style.display = "none";

      a.href = url;

      a.download = yojanaBookTitle;

      document.body.appendChild(a);

      a.click();

      URL.revokeObjectURL(url);
    }
  };

  return (
    <div style={{ padding: "0% 2%" }}>
      {!isModalOpen && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            margin: "15px 2px",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: "bold", fontSize: "17px" }}>
            {englishNepaliNumberTranslation(yojana.order)}. {yojana?.title ?? ""}
          </p>
          <div style={{ display: "flex", gap: "10px" }}>
            <StyledButton
              onClick={() => {
                downloadPDF(yojana.pdf_file_url || "", yojana.title);
              }}
            >
              {keyword["Download PDF"]}
            </StyledButton>
            {budgetId !== "budgetId" && <StyledButton onClick={onSavehandle}>{keyword.Save}</StyledButton>}
            {budgetId === "budgetId" && (
              <StyledButton onClick={() => setShowConfirmModal(true)}>{keyword.Update}</StyledButton>
            )}
            <Button onClick={handleCancel}>{keyword.Cancel}</Button>
          </div>
        </div>
      )}
      {!isModalOpen && !showConfirmModal && (
        <EditorPage
          initialValue={yojana.content || ""}
          onEditorChange={(content) => {
            setEditorContent(content);
          }}
        />
      )}

      {/* {isModalOpen && (
        <PdfGenerator
          updateSourceCodeToBackend={updateSourceCodeToBackend}
          setYojana={setYojana}
          closeModal={closeModal}
          content={editorContent}
        />
      )} */}

      {isModalOpen && (
        <ConfirmModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          text="save"
          onConfirmeHandle={updateSourceCodeToBackend}
          onCancelHandle={() => {
            setIsModalOpen(false);
          }}
        />
      )}

      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        text="update"
        onConfirmeHandle={handleYojanaTemplateUpdate}
        onCancelHandle={() => {
          setShowConfirmModal(false);
        }}
      />
      {/* <PopupModal
        isOpen={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
      >
        <h1>Please confirm to update?</h1>
        <StyledButton style={{ marginRight: "10px" }} onClick={handleYojanaTemplateUpdate}>
          Confirm
        </StyledButton>
        <Button
          onClick={() => {
            setShowConfirmModal(false);
          }}
        >
          {keyword.Cancel}
        </Button>
      </PopupModal> */}
    </div>
  );
}

export default YojanaTemplate;
