import { Button, Flex, Form, Grid, MainLayout } from "@ims/common";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Page from "../../../../../core-ui/Page";
import ReuseForm from "../../../../../core-ui/ReuseForm";
import useTranslated from "../../../../../hooks/useTranslated";
import FileCard from "./FileCard";
import PopupModal from "../../../../../components/PopupModal/PopupModal";
import { useAppDispatch, useAppSelector } from "../../../../../rtk/hook";
import {
  addEstimationDocument,
  getEstimationDocument,
} from "../../../../../rtk/feature/estimation-doument/estimationDocumentApi";
import {
  EstimatimationDocumentFormTypeI,
  EstimationDocumetnTypeI,
} from "../../../../../rtk/feature/estimation-doument/estimationDocumentSlice";
import { showLoading } from "../../../../../rtk/feature/auth/authSlice";
import { toast } from "react-toastify";

interface EstimationDocumentPropsI {
  budgetId: string;
}

export default function EstimationDocument({ budgetId }: EstimationDocumentPropsI) {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    register,
    control,
  } = useForm<EstimatimationDocumentFormTypeI>({
    defaultValues: {},
  });

  const [documents, setDocuments] = useState<EstimationDocumetnTypeI[] | null>(null);
  const [docFile, setDocFile] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { keyword } = useTranslated();
  const dispatch = useAppDispatch();
  const documentSelector = useAppSelector((state) => state.estimationDocument);

  useEffect(() => {
    dispatch(showLoading(documentSelector.loading));
  }, [documentSelector.loading]);

  useEffect(() => {
    dispatch(getEstimationDocument(budgetId));
  }, [dispatch, budgetId]);

  useEffect(() => {
    if (documentSelector.data) setDocuments(documentSelector.data);
  }, [documentSelector.data]);

  const openPopup = () => {
    setIsOpen(true);
  };
  const closePopup = () => {
    setIsOpen(false);
    reset();
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!budgetId) {
      toast.warning(keyword["Please select a plan."]);
      return;
    }

    if (!docFile) {
      toast.error(keyword["No file choosen."]);
      return;
    }

    const formData = new FormData();
    formData.append("file", docFile);
    formData.append("name", getValues("name"));

    await dispatch(addEstimationDocument({ id: budgetId, data: formData }));
    closePopup();
    await dispatch(getEstimationDocument(budgetId));
  };

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setDocFile(selectedFile);
    }
  };

  return (
    <>
      <Page.SubTitle>{keyword["Document"]}</Page.SubTitle>
      <Flex style={{ flexWrap: "wrap", marginBottom: "1rem", gap: "1rem" }}>
        {!!documents &&
          documents.map((document) => <FileCard key={document.id} title={document.name} fileUrl={document.file_url} />)}
        <Button type="button" onClick={openPopup}>
          + {keyword.Add}
        </Button>
      </Flex>

      <PopupModal isOpen={isOpen} onClose={closePopup}>
        <div style={{ padding: "1rem" }}>
          <Page.Title>{keyword["Upload Document"]}</Page.Title>

          {/* <Form.File id="file" register type="file" onChange={() => {}}></Form.File> */}

          <ReuseForm control={control}>
            <Grid>
              <Grid.Row gap={1.5}>
                <Grid.Col spanMd={12} spanXs={12}>
                  <ReuseForm.Input
                    label={keyword["Name"]}
                    name="name"
                    placeholder={keyword["Enter file name"]}
                    rules={{ required: keyword["This is required field."] }}
                    error={errors["name"]}
                  />
                </Grid.Col>
              </Grid.Row>
              <br />
              <Grid.Row gap={1.5}>
                <Grid.Col spanMd={12} spanXs={12}>
                  <Flex.Col>
                    <Form.Label> {keyword["Document"]} </Form.Label>
                    <Controller
                      name="file"
                      rules={{
                        required: true,
                      }}
                      control={control}
                      render={({ field: { onChange, value }, fieldState, formState }) => {
                        return (
                          <Form.File
                            id="file"
                            accept=".pdf, .xlsx"
                            onChange={(e: any) => {
                              handleFile(e);
                            }}
                          />
                        );
                      }}
                    ></Controller>
                    {errors && errors["file"] && errors["file"].message && (
                      <Form.HelperText>{errors["file"].message}</Form.HelperText>
                    )}
                  </Flex.Col>
                </Grid.Col>
                <Grid.Col spanMd={12} spanXs={12}>
                  {/* <Page.ButtonGrp> */}
                  <Button type="submit" variant="primary" onClick={onSubmit}>
                    {keyword.Submit}
                  </Button>
                  <Button type="button" onClick={closePopup}>
                    {keyword.Cancel}
                  </Button>
                  {/* </Page.ButtonGrp> */}
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </ReuseForm>
        </div>
      </PopupModal>
    </>
  );
}
