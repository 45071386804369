import { Button, Flex, Form, Grid, MainLayout, Table } from "@ims/common";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { BiLink } from "react-icons/bi";
import { roles } from "../../constants/constants";
import Page from "../../core-ui/Page";
import ReuseForm from "../../core-ui/ReuseForm";
import useDiscloure from "../../hooks/useDiscloure";
import useTranslated from "../../hooks/useTranslated";
import { IOptions } from "../../interfaces/options.interface";
import {
  getBillVerifications,
  sendBillToLekha,
  updatetBillVerification,
} from "../../rtk/feature/bill-verification/billVerificationAPI";
import { cleanupVerificationSlice } from "../../rtk/feature/bill-verification/billVerificationSlice";
import { getUsers } from "../../rtk/feature/users/usersApi";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import usePagination from "../../utils/usePagination";
import Dialog from "../plan/estimate/Dialog/Dialog";
import { KeywordBeautify, styleSN } from "../../utils/Prettify";
import { MdVerified } from "react-icons/md";
import { GoUnverified } from "react-icons/go";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";
import { realTimeFilter } from "../../utils/real-time-filter";
import { FiSearch } from "react-icons/fi";
import { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import getFormattedSort from "../../utils/getFormattedSort";

interface ILekhaForm {
  user: IOptions;
}

const BillVerification = () => {
  const [billVerifications, setBillVerifications] = useState<any[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});
  const dispatch = useAppDispatch();
  const { close, isOpen, open, toggle } = useDiscloure();
  const { close: lekhaClose, isOpen: isLekhaOpen, open: openLekha } = useDiscloure();

  const perPage = 100;

  const { data: billVerificationsDataSelector, formSubmitted } = useAppSelector((state) => state.billVerification);
  const users = useAppSelector((state) => state.users.data?.results);
  const userOptions = users
    ?.filter((user) => user.role === roles.Lekha_Sakha && user.is_active)
    ?.map((user) => {
      return {
        label: user.first_name + user.last_name,
        value: user.id,
      };
    });
  const [activeVerifyBill, setActiveVerifyBill] = useState("");
  const {
    formState: { errors },
    register,
    getValues,
    setValue,
  } = useForm<IForm>();
  const {
    formState: { errors: lekhaErrors },
    register: registerLekha,
    getValues: getLekhaValues,
    reset: filterReset,
    control,
    handleSubmit: handleLekhaSubmit,
  } = useForm<ILekhaForm>();

  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    formState: { errors: filterErrors },
    watch: filterWatch,
  } = useForm();

  useEffect(() => {
    dispatch(getBillVerifications());
    dispatch(getUsers({ params: { per_page: 10000 } }));
    return () => {
      dispatch(cleanupVerificationSlice());
    };
  }, []);

  const handleEdit = (id: string) => {};
  const [total, setTotal] = useState(1);
  const { Pagination } = usePagination({ total });
  useEffect(() => {
    if (billVerificationsDataSelector) {
      setTotal(billVerificationsDataSelector?.total);
      setBillVerifications(billVerificationsDataSelector?.results);
    }
  }, [billVerificationsDataSelector]);
  const handleOpenFile = (url: string) => {
    window.open(url);
  };

  const confirmSendToLekha = () => {
    const data = {
      plan_bill_verification: activeVerifyBill,
      user_id: getLekhaValues("user.value"),
    };

    dispatch(sendBillToLekha(data));
  };

  const confirmVerifyBill = () => {
    const comment = getValues("comment");
    const data = {
      is_verified: true,
      comment,
    };
    dispatch(updatetBillVerification({ data, id: activeVerifyBill }));
  };

  const verifyBill = (id: string, comment: string) => {
    setActiveVerifyBill(id);
    setValue("comment", comment);
    open();
  };
  const sendToLekha = (id: string) => {
    setActiveVerifyBill(id);
    openLekha();
  };
  interface IForm {
    comment: string;
  }
  const returnBack = () => {
    const comment = getValues("comment");
    const data = {
      is_verified: false,
      comment,
    };
    dispatch(updatetBillVerification({ data, id: activeVerifyBill }));
  };
  useEffect(() => {
    if (formSubmitted) {
      close();
    }
  }, [formSubmitted]);

  const onFormSubmit = (data: ILekhaForm) => {};
  const { keyword } = useTranslated();
  const onFilterSubmit = (data: any) => {
    dispatch(
      getBillVerifications({
        per_page: perPage,
        search: data.search,
        ordering: getFormattedSort(sorts),
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getBillVerifications({
        per_page: perPage,

        ordering: getFormattedSort(sorts),
      })
    );
    filterReset();
  };

  return (
    <MainLayout.Main>
      <Dialog active={isOpen} onClose={close}>
        <Dialog.Container className="container-dialog">
          <div className="container-body">
            <h2 className="sub-title">{keyword["Do you want to verify Bill?"]}</h2>

            <Flex gap="1rem" dir="column" justify="space-between" height="auto">
              <h4 className="title">{keyword["Verify!"]} </h4>
              <Form.FormGroup error={!!errors.comment}>
                <Form.TextArea register={register("comment", { required: keyword["Comment is required"] })} />
                <Form.HelperText> {errors.comment?.message} </Form.HelperText>
              </Form.FormGroup>

              <Flex>
                <Button variant="primary" onClick={() => confirmVerifyBill()}>
                  {keyword.Add}
                </Button>
                <Button type="button" onClick={() => returnBack()}>
                  {keyword["Return Back"]}
                </Button>
              </Flex>
            </Flex>
          </div>
        </Dialog.Container>
      </Dialog>

      <Dialog active={isLekhaOpen} onClose={lekhaClose}>
        <Dialog.Container className="container-dialog" style={{ height: "32vh" }}>
          <div className="container-body">
            <h2 className="sub-title">{keyword["Want to send to lekha?"]}</h2>
            <Flex.Col gap="1rem" justify="space-between">
              <ReuseForm control={control} onSubmit={handleLekhaSubmit(onFormSubmit)}>
                <h4 className="title">{keyword["Select User"]} </h4>
                <Form.FormGroup error={!!lekhaErrors.user}>
                  <ReuseForm.ReactSelect options={userOptions} name="user" register={registerLekha("user")} />
                  <Form.HelperText> {lekhaErrors.user?.message} </Form.HelperText>
                </Form.FormGroup>
                <br />
                <Flex height="auto">
                  <Button variant="primary" onClick={() => confirmSendToLekha()}>
                    {keyword.Send}
                  </Button>
                  <Button type="button" onClick={lekhaClose}>
                    {keyword.Cancel}
                  </Button>
                </Flex>
              </ReuseForm>
            </Flex.Col>
          </div>
        </Dialog.Container>
      </Dialog>

      <Page.Container>
        <Page.Title> {keyword["Bill  Verification"]} </Page.Title>
      </Page.Container>

      <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search"]}
                name="search"
                placeholder={keyword["Search here"]}
                rules={{ required: false }}
                register={filterRegister("search")}
                error={filterErrors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                {" "}
                <FiSearch />
              </Button>

              <Button type="reset" onClick={handleFilterReset}>
                {keyword["Reset"]}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container>
        <Table id="export-table">
          <Table.Head>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Yojana Name"]}</Table.HeadCol>

              <Table.HeadCol> {keyword["Bill Name"]} </Table.HeadCol>
              <Table.HeadCol> {keyword.Comment} </Table.HeadCol>
              <Table.HeadCol> {keyword.Description} </Table.HeadCol>
              <Table.HeadCol> {keyword["Is Verified"]}</Table.HeadCol>
              <Table.HeadCol>{keyword["Status"]}</Table.HeadCol>
              <Table.HeadCol align="center"> {keyword["Bill File"]}</Table.HeadCol>
              <Table.HeadCol align="center">{keyword.Actions}</Table.HeadCol>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {billVerifications.length > 0 &&
              billVerifications.map((verification, index) => {
                return (
                  <Table.Row key={verification.id}>
                    <Table.Col>{englishNepaliNumberTranslation(styleSN(index + 1))}</Table.Col>
                    <Table.Col>{verification.planning_bill_id.yojana.name}</Table.Col>

                    <Table.Col>{verification.planning_bill_id.bill_name}</Table.Col>
                    <Table.Col>{verification.comment}</Table.Col>
                    <Table.Col>{verification.description}</Table.Col>
                    <Table.Col>
                      <span style={{ marginLeft: "25%" }}>
                        {verification.is_verified ? (
                          <MdVerified color="blue" size={20} />
                        ) : (
                          <GoUnverified color="#888" size={20} />
                        )}
                      </span>
                    </Table.Col>
                    <Table.Col>{KeywordBeautify(verification.status)}</Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleOpenFile(verification.planning_bill_id.bill_file)}>
                        <BiLink />
                      </Table.ActionButton>
                    </Table.Col>
                    <Table.Col align="center">
                      <Flex>
                        <Table.ActionButton
                          disabled={false}
                          style={{ padding: ".4rem 2rem" }}
                          onClick={() => verifyBill(verification.id, verification.comment)}
                        >
                          Verify
                        </Table.ActionButton>
                        <Table.ActionButton
                          color="lime"
                          disabled={false}
                          style={{ padding: ".4rem 2rem" }}
                          onClick={() => sendToLekha(verification.id)}
                        >
                          Send to lekha
                        </Table.ActionButton>
                      </Flex>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
};

export default BillVerification;
