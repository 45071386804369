import { FiscalYearTypeI } from "../rtk/feature/fiscal-year/fiscalYearSlice";

export const FiscalYearCalculator = (fiscalYearData: FiscalYearTypeI) => {
  const startDate = new Date(fiscalYearData?.start_date);
  const endDate = new Date(fiscalYearData?.end_date);

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  // return `Fiscal Year: ${startYear}-${endYear}`;
  return `${startYear}-${endYear}`;
};
