import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { SelectOptionTypeI } from "../budget/BudgetFormPage";
import { Form, Table } from "@ims/common";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

import { AiOutlineSave } from "react-icons/ai";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ConstructorFormTypeII, StyledActionButton } from "../../constructor/ConstructorFormPage";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { getBudgets } from "../../../rtk/feature/budget/budgetApi";
import {
  addMemberInContractor,
  deleteMemberInContractor,
  getConstructorMember,
  getConstructorMembers,
} from "../../../rtk/feature/constructor-member/constructorMemberApi";
import { constructorsCleanUp } from "../../../rtk/feature/constructor/constructorSlice";
import { addConstructor, getConstructor, updateConstructor } from "../../../rtk/feature/constructor/constructorApi";
import usePagination from "../../../utils/usePagination";
import { ContractorTypeChoice, getOptionFromValue } from "../../../utils/constants";
import useTranslated from "../../../hooks/useTranslated";
import Tab from "../../../components/tab/Tab";
import ContractorDocs from "../../constructor/ContractorDocs";
import ReuseForm from "../../../core-ui/ReuseForm";

function ConstructorDetail() {
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
    control,
  } = useForm<ConstructorFormTypeII>({
    defaultValues: {
      name: "",
    },
  });

  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAssignConstructor = queryParams.get("assign-constructor");
  const isWard = queryParams.get("back");
  const userRole = queryParams.get("user");
  const createNew = queryParams.get("create");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const constructorsSelector = useAppSelector((state) => state.constructors);
  const budgetsState = useAppSelector((state) => state.budgets);
  const [editMode, setEditMode] = useState(false);
  const [constructorMode, setConstructorMode] = useState<"Create Constructor" | "Update Constructor">(
    "Create Constructor"
  );

  const { data: budgetList } = budgetsState;

  useEffect(() => {
    dispatch(getBudgets());
    dispatch(getConstructorMembers());
    return () => {
      dispatch(constructorsCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getConstructor(id));
      setEditMode(true);
    } else {
      dispatch(constructorsCleanUp());
      setEditMode(false);
    }
  }, [id, dispatch]);

  const { item } = constructorsSelector;
  const constructorMembersState = useAppSelector((state) => state.constructorMembers);
  const constructorMembers = constructorMembersState.data?.results;

  const { Pagination, page, perPage } = usePagination({
    total: constructorMembersState.data?.total_pages || 0,
  });
  useEffect(() => {
    if (item !== null) {
      setValue("name", item.name);
      setValue("pan_or_vat_no", item.pan_or_vat_no);
      setValue("organize_date", item.organize_date);
      setValue("population_during_organize", item.population_during_organize);
      setValue("address", item.address);
      setValue("samiti_email", item.samiti_email);
      setValue("mobile_number", item.mobile_number);
      setValue("bank", item.bank);
      setValue("bank_ac_number", item.bank_ac_number);
      setValue("total_budget", item.total_budget);

      const typeCh = getOptionFromValue(ContractorTypeChoice, item.type);
      const budget = budgetList?.find((budget) => budget.id === item.yojana.id);

      if (typeCh) setValue("type", typeCh);

      if (budget) {
        const budgetObj = { label: budget.name, value: budget.id };
        setValue("yojana", budgetObj);
      }
    } else {
      reset();
    }
  }, [item, setValue, reset, budgetList]);

  useEffect(() => {
    editMode ? setConstructorMode("Update Constructor") : setConstructorMode("Create Constructor");
  }, [editMode]);
  useEffect(() => {
    const { error, loading, hasSubmitted } = constructorsSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/budget/constructor/list");
    }
  }, [constructorsSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<ConstructorFormTypeII> = (data) => {
    data.yojana = data.yojana.value;
    data.type = data.type.label;

    if (editMode && id) {
      const newData = { ...data };
      dispatch(updateConstructor({ data: newData, id }));
    } else {
      const newData = { ...data };
      dispatch(addConstructor(newData));
    }
  };

  const handleCancel = () => {
    navigate("/budget/constructor/list");
  };

  const returnToAssignConstructor = () => {
    if (isWard) navigate("/assign-constructor");
    else if (userRole === "upabhokta") navigate("/minute-document");
    else navigate("/plan-management/plan?tab=assign-constructor");
  };

  const { keyword } = useTranslated();

  const { item: currentMember } = constructorMembersState;

  const genStaticCol = (data: { id: number; title: string; link: string }) => {
    const { id, title, link } = data;

    return (
      <React.Fragment>
        <Table.Col>{title}</Table.Col>
        <Table.Col>
          <a href={link} download id={"static" + id} target="_blank" hidden rel="noreferrer">
            Hidden Content
          </a>

          <StyledActionButton
            color="green"
            onClick={() => {
              window.document.getElementById("static" + id)!?.click();
            }}
          >
            {keyword["Generate"]}
          </StyledActionButton>
        </Table.Col>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <MainLayout.Main style={{ padding: 0 }}>
        <Tab>
          <Tab.TabHeader>
            <Tab.TabHeadButton id="form" active>
              {keyword["Constructor Details"]}
            </Tab.TabHeadButton>
            <Tab.TabHeadButton id="member" disabled={!editMode}>
              {keyword["Committee Members"]}
            </Tab.TabHeadButton>
            {/* {!isAssignConstructor && ( */}
            <Tab.TabHeadButton id="document">{keyword["Generate Documents"]}</Tab.TabHeadButton>
            {/* )} */}
          </Tab.TabHeader>

          <Tab.TabBody>
            <Tab.TabPane targetId="document">
              <ContractorDocs contractorId={id || ""} type="read-only" />
              {/* <Table.Container>
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCol colSpan={2}>{keyword["Generate Documents"]}</Table.HeadCol>
                      <Table.HeadCol colSpan={2}>{keyword["Uploaded Documents"]}</Table.HeadCol>
                    </Table.Row>
                  </Table.Head>

                  <Table.Body>
                    <Table.Row>
                      {genStaticCol({
                        id: 1,
                        title: "Static Useful Document 01",
                        link: "http://139.59.65.100/general_docs/YojanaDocGenerate01_l3r7LNw.pdf",
                      })}

                      {genStaticCol({
                        id: 2,
                        title: "Already Uploaded Document 01",
                        link: "http://139.59.65.100/general_docs/YojanaDocGenerate01_l3r7LNw.pdf",
                      })}
                    </Table.Row>

                    <Table.Row>
                      {genStaticCol({
                        id: 3,
                        title: "Static Useful Document 02",
                        link: "http://139.59.65.100/general_docs/YojanaDocGenerate01_l3r7LNw.pdf",
                      })}

                      {genStaticCol({
                        id: 4,
                        title: "Already Uploaded Document 02",
                        link: "http://139.59.65.100/general_docs/YojanaDocGenerate01_l3r7LNw.pdf",
                      })}
                    </Table.Row>

                    <Table.Row>
                      {genStaticCol({
                        id: 5,
                        title: "Static Useful Document 03",
                        link: "http://139.59.65.100/general_docs/YojanaDocGenerate01_l3r7LNw.pdf",
                      })}
                    </Table.Row>

                    <Table.Row>
                      {genStaticCol({
                        id: 6,
                        title: "Static Useful Document 04",
                        link: "http://139.59.65.100/general_docs/YojanaDocGenerate01_l3r7LNw.pdf",
                      })}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Table.Container> */}
            </Tab.TabPane>

            <Tab.TabPane targetId="member">
              <Table.Container>
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCol>{keyword["S.N."]}</Table.HeadCol>
                      <Table.HeadCol>{keyword["Post"]}</Table.HeadCol>
                      <Table.HeadCol>{keyword["Name"]}</Table.HeadCol>
                      <Table.HeadCol>{keyword["Sex"]}</Table.HeadCol>
                      <Table.HeadCol>{keyword["Mobile Number"]}</Table.HeadCol>
                      <Table.HeadCol>{keyword["Citizenship No."]}</Table.HeadCol>
                      <Table.HeadCol>{keyword["Father"]}</Table.HeadCol>
                      {!isAssignConstructor && <Table.HeadCol align="left">{keyword["Action"]}</Table.HeadCol>}
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {item &&
                      item.yojana_samiti_member.length > 0 &&
                      item.yojana_samiti_member.map((constructorMember, index) => {
                        let serialNumber: string = "";

                        let key = index + 1 + (page - 1) * perPage;
                        if (key < 10) serialNumber = "0" + key;
                        else serialNumber = key.toString();

                        return (
                          <Table.Row key={serialNumber}>
                            <Table.Col>{serialNumber}</Table.Col>
                            <Table.Col>{constructorMember.designation}</Table.Col>
                            <Table.Col>{constructorMember.name}</Table.Col>
                            <Table.Col>{constructorMember.sex}</Table.Col>
                            <Table.Col>{constructorMember.mobile_number || "N/A"}</Table.Col>
                            <Table.Col>{constructorMember.citizenship_number || "N/A"}</Table.Col>
                            <Table.Col>{constructorMember.father || "N/A"}</Table.Col>
                            {!isAssignConstructor && (
                              <Table.Col align="left">
                                <Table.ActionButton
                                  color="red"
                                  onClick={() => {
                                    if (item) {
                                      dispatch(
                                        deleteMemberInContractor({
                                          contractor_details: item!?.id,
                                          contractor_member: constructorMember.id,
                                        })
                                      );
                                    }
                                  }}
                                >
                                  <MdDeleteOutline />
                                </Table.ActionButton>
                              </Table.Col>
                            )}
                          </Table.Row>
                        );
                      })}

                    {!isAssignConstructor && (
                      <Table.Row key="new">
                        <Table.Col>New</Table.Col>
                        <Table.Col>{currentMember!?.designation}</Table.Col>
                        <Table.Col width="280px">
                          <Form.ReactSelect
                            name="type"
                            options={constructorMembers?.map((mem, index) => ({
                              value: mem.id,
                              label: mem.name,
                            }))}
                            size="small"
                            placeholder={keyword["Select Contractor Type"]}
                            onChange={(selectedOption: any) => {
                              dispatch(getConstructorMember(selectedOption.value));
                            }}
                          />
                        </Table.Col>
                        <Table.Col>{currentMember!?.sex}</Table.Col>
                        <Table.Col>{currentMember!?.mobile_number}</Table.Col>
                        <Table.Col>{currentMember!?.citizenship_number}</Table.Col>
                        <Table.Col>{currentMember!?.father}</Table.Col>
                        <Table.Col align="left">
                          <Table.ActionButton
                            color="green"
                            onClick={() => {
                              if (item && currentMember) {
                                dispatch(
                                  addMemberInContractor({
                                    contractor_details: item!?.id,
                                    contractor_member: currentMember!?.id,
                                  })
                                );
                              } else {
                                toast("Something went wrong");
                              }
                            }}
                          >
                            <AiOutlineSave />
                          </Table.ActionButton>
                        </Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                <Pagination />
              </Table.Container>
            </Tab.TabPane>

            <Tab.TabPane targetId="form">
              <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
                <Grid>
                  <Grid.Row gap={1.5}>
                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.Input
                        label={keyword["Constructor Name"]}
                        showRequired={true}
                        name="name"
                        placeholder={keyword["Enter constructor name"]}
                        rules={{ required: keyword["This is required field."] }}
                        error={errors["name"]}
                      />
                    </Grid.Col>

                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.ReactSelect
                        label={keyword["Contractor Type"]}
                        showRequired={true}
                        name="type"
                        options={ContractorTypeChoice.map((type, index) => ({
                          value: index,
                          label: type,
                        }))}
                        placeholder={keyword["Select Contractor Type"]}
                        rules={{ required: "Contractor Type is required." }}
                        error={errors["type"]}
                      />
                    </Grid.Col>

                    {/*    (
                      <Grid.Col spanMd={6} spanXs={12}>
                        <ReuseForm.ReactSelect
                          label={keyword["Yojana"]}
                          name="yojana"
                          options={budgetList?.map((budget) => ({
                            value: budget.id,
                            label: budget.name,
                          }))}
                          placeholder={keyword["Select Yojana"]}
                          rules={{ required: "Contractor Type is required." }}
                          error={errors["yojana"]}
                        />
                      </Grid.Col>
                    ) */}

                    {watch("type") && watch("type").label === ContractorTypeChoice[1] && (
                      <Grid.Col spanMd={6} spanXs={12}>
                        <ReuseForm.Input
                          label={keyword["Pan / Vat No."]}
                          name="pan_or_vat_no"
                          placeholder={keyword["Enter pan or vat number"]}
                          error={errors["pan_or_vat_no"]}
                        />
                      </Grid.Col>
                    )}

                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.Input
                        label={keyword["Organize Date"]}
                        type="date"
                        name="organize_date"
                        placeholder={keyword["Enter organize date"]}
                        error={errors["organize_date"]}
                      />
                    </Grid.Col>

                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.Input
                        type="number"
                        label={keyword["Population During Organize"]}
                        name="population_during_organize"
                        placeholder={keyword["Enter population during organize"]}
                        error={errors["population_during_organize"]}
                      />
                    </Grid.Col>

                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.Input
                        label={keyword["Address"]}
                        name="address"
                        placeholder={keyword["Enter address"]}
                        error={errors["address"]}
                      />
                    </Grid.Col>

                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.Input
                        label={keyword["Email"]}
                        type="email"
                        name="samiti_email"
                        placeholder={keyword["Enter committee email"]}
                        error={errors["samiti_email"]}
                      />
                    </Grid.Col>

                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.Input
                        label={keyword["Mobile Number"]}
                        name="mobile_number"
                        placeholder={keyword["Enter mobile number"]}
                        error={errors["mobile_number"]}
                      />
                    </Grid.Col>

                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.Input
                        label={keyword["Bank Name"]}
                        name="bank"
                        placeholder={keyword["Enter bank name"]}
                        error={errors["bank"]}
                      />
                    </Grid.Col>

                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.Input
                        label={keyword["Bank Ac. No."]}
                        name="bank_ac_number"
                        type="number"
                        placeholder={keyword["Enter bank account number"]}
                        error={errors["bank_ac_number"]}
                      />
                    </Grid.Col>

                    <Grid.Col spanMd={6} spanXs={12}>
                      <ReuseForm.Input
                        label={keyword["Total Budget"]}
                        name="total_budget"
                        type="number"
                        placeholder={keyword["Enter total budget"]}
                        error={errors["total_budget"]}
                      />
                    </Grid.Col>

                    <Grid.Col spanMd={12} spanXs={12}>
                      {(createNew === "new" || !isAssignConstructor) && (
                        <Button type="submit" variant="primary">
                          {keyword[constructorMode]}
                        </Button>
                      )}
                      {!isAssignConstructor && (
                        <Button type="button" onClick={handleCancel}>
                          {keyword.Cancel}
                        </Button>
                      )}
                      {isAssignConstructor && (
                        <Button type="button" variant="primary" onClick={returnToAssignConstructor}>
                          {keyword["Return Back"]}
                        </Button>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </ReuseForm>
            </Tab.TabPane>
          </Tab.TabBody>
        </Tab>
      </MainLayout.Main>
    </React.Fragment>
  );
}

export default ConstructorDetail;
