import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";

export const getPlanReport = createAsyncThunk("getPlanReport", async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
        const res = await imsClient.get(`/yojana/${id}/reports/`);
        return res.data
    } catch (error) {
        const appError = new AppError(error);
        appError.show();
        return rejectWithValue(appError.getError());

    }
})