import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { getConstructor } from "../constructor/constructorApi";
import { ConstructorMemberFormTypeI } from "./constructorMemberSlice";
import { ConstructorMemberSuccess } from "../../../utils/res-mes/response-message-generator";

export const getConstructorMembers = createAsyncThunk(
  "getConstructorMembers",
  async (
    userParams: { per_page: number; page?: number; search?: any; ordering?: any; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/contractor-members/", {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getConstructorMember = createAsyncThunk(
  "getConstructorMember",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/contractor-members/${id}/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addMemberInContractor = createAsyncThunk(
  "addMemberInContractor",
  async (data: { contractor_details: string; contractor_member: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/yojana-contractor-members/`, data);
      dispatch(getConstructor(data.contractor_details));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteMemberInContractor = createAsyncThunk(
  "deleteMemberInContractor",
  async (data: { contractor_details: string; contractor_member: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.delete(`/yojana-contractor-members/${data.contractor_member}/`);
      dispatch(getConstructor(data.contractor_details));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addConstructorMember = createAsyncThunk(
  "addConstructorMember",
  async (data: ConstructorMemberFormTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post("/contractor-members/", data);
      if (res.data) toast.success(ConstructorMemberSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteConstructorMember = createAsyncThunk(
  "deleteConstructorMember",
  async (deleteId: string, { dispatch, rejectWithValue }) => {
    try {
      await imsClient.delete(`/contractor-members/${deleteId}/`);
      dispatch(getConstructorMembers());
      toast.success(ConstructorMemberSuccess("deleted"));
      return deleteId;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateConstructorMember = createAsyncThunk(
  "updateConstructorMember",
  async (data: { data: ConstructorMemberFormTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/contractor-members/${data.id}/`, data.data);
      if (res.data) toast.success(ConstructorMemberSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
