import { createSlice } from "@reduxjs/toolkit";
import { addEstimationDocument, getEstimationDocument } from "./estimationDocumentApi";

export interface EstimatimationDocumentFormTypeI {
  name: string;
  file: string;
}

export interface EstimationDocumetnTypeI {
  id: string;
  name: string;
  file_url: string;
}

interface DocumentSliceStateI {
  loading: boolean;
  data: EstimationDocumetnTypeI[] | null;

  hasSubmitted: boolean;
  error: any;
}

const initialState: DocumentSliceStateI = {
  loading: false,
  data: null,
  hasSubmitted: false,
  error: null,
};

const estimationDocumentSlice = createSlice({
  name: "estimationDocument",
  initialState,
  reducers: {
    estimationDocumentCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addEstimationDocument.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addEstimationDocument.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addEstimationDocument.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getEstimationDocument.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getEstimationDocument.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getEstimationDocument.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
  },
});

export const { estimationDocumentCleanUp } = estimationDocumentSlice.actions;
export default estimationDocumentSlice.reducer;
