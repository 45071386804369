import { Button, Form, Grid } from "@ims/common";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Auth from "../../components/auth/Auth";
import { changePasswordWithOTP, sendOTP } from "../../rtk/feature/auth/authApi";
import { authCleanUp, ChangePasswordFormTypeI, ForgotPasswordFormTypeI } from "../../rtk/feature/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import AuthLayout from "./AuthLayout";

const ChangePasswordPage = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ChangePasswordFormTypeI>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const authSelector = useAppSelector((state) => state.auth);
  const usersSelector = useAppSelector((state) => state.users);
  const navigate = useNavigate();

  useEffect(() => {
    if (authSelector.hasSubmitted) dispatch(authCleanUp());
  }, [dispatch, authSelector.hasSubmitted]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = authSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/login");
    }
  }, [usersSelector, dispatch, navigate, authSelector]);

  const onSubmit: SubmitHandler<ChangePasswordFormTypeI> = (data) => {
    dispatch(changePasswordWithOTP(data));
  };

  return (
    <AuthLayout>
      <div style={{ margin: "auto" }}>
        <h2>Change Password</h2>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row gap={1.5}>
              <Grid.Col spanXs={12}>
                <Form.FormGroup error={Boolean(errors && errors["otp"])}>
                  <Form.FloatingInput
                    label="OTP"
                    type="text"
                    register={register("otp", {
                      required: "Your otp is required.",
                    })}
                  />
                  {/* <Form.Label>OTP</Form.Label>
                  <Form.Input
                    type="text"
                    placeholder="Enter your otp"
                    register={register("otp", {
                      required: "Your otp is required.",
                    })} 
                  />
                    */}
                  {errors && errors["otp"] && errors["otp"].message && (
                    <Form.HelperText>{errors["otp"].message}</Form.HelperText>
                  )}
                </Form.FormGroup>
              </Grid.Col>
              <Grid.Col spanXs={12}>
                <Form.FormGroup error={Boolean(errors && errors["password"])}>
                  <Form.FloatingInput
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    register={register("password", {
                      required: "Your password is required.",
                    })}
                  />
                  {/* <Form.Label>New Password</Form.Label>
                  <Form.Input
                    type="password"
                    placeholder="Enter your password"
                    register={register("password", {
                      required: "Your password is required.",
                    })}
                  /> */}
                  {errors && errors["password"] && errors["password"].message && (
                    <Form.HelperText>{errors["password"].message}</Form.HelperText>
                  )}
                </Form.FormGroup>
              </Grid.Col>
              <Grid.Col spanXs={12}>
                <Form.FormGroup error={Boolean(errors && errors["confirm_password"])}>
                  <Form.FloatingInput
                    label="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    register={register("confirm_password", {
                      required: "Your confirm password is required.",
                    })}
                  />
                  {/* <Form.Label>Confirm Password</Form.Label>
                  <Form.Input
                    type="password"
                    placeholder="Enter your confirm password"
                    register={register("confirm_password", {
                      required: "Your confirm password is required.",
                    })}
                  /> */}
                  {errors && errors["confirm_password"] && errors["confirm_password"].message && (
                    <Form.HelperText>{errors["confirm_password"].message}</Form.HelperText>
                  )}
                </Form.FormGroup>
              </Grid.Col>
              <Grid.Col spanMd={12} spanXs={12}>
                <div style={{ textAlign: "left", marginBottom: "1rem" }}>
                  <input
                    type="checkbox"
                    checked={showPassword}
                    onChange={() => setShowPassword((prevState) => !prevState)}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  <span
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  >
                    Show password
                  </span>
                </div>
              </Grid.Col>
              <Grid.Col spanMd={12} spanXs={12}>
                <Button type="submit" variant="auth">
                  Send Request
                </Button>
                <Form.Link to="/login" style={{ display: "block" }}>
                  Go to login.
                </Form.Link>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default ChangePasswordPage;
