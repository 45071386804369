import React, { useEffect } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import LoginPage from "./pages/auth/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import UserFormPage from "./pages/user/UserFormPage";
import DashboardLayout, { StyledLoaderContainer } from "./components/DashboardLayout";
import UserManagePage from "./pages/user/UserManagePage";
import ProfilePage from "./pages/ProfilePage";
import ProtectedRoute from "./utils/ProtectedRoute";
import YojanaFormPage from "./pages/yojana/YojanaFormPage";
import YojanaManagePage from "./pages/yojana/YojanaManagePage";
import { getAxiosInterceptors } from "./utils/imsClient";
import EquipmentManagePage from "./pages/equipment/EquipmentManagePage";
import UnitFormPage from "./pages/unit/UnitFormPage";
import UnitManagePage from "./pages/unit/UnitManagePage";
import TransportationTypeFormPage from "./pages/transportation-type/TransportationTypeFormPage";
import TransportationTypeManagePage from "./pages/transportation-type/TransportationTypeManagePage";
import EquipmentFormPage from "./pages/equipment/EquipmentFormPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ChangePasswordPage from "./pages/auth/ChangePasswordPage";
import { BiLoader } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "./rtk/hook";
// import BudgetFormPage from "./pages/budget/BudgetFormPage";
import BudgetManagePage from "./pages/budget/BudgetManagePage";
import ConstructorFormPage from "./pages/constructor/ConstructorFormPage";
import ConstructorManagePage from "./pages/constructor/ConstructorManagePage";
import YojanaTypeFormPage from "./pages/yojana-type/YojanaTypeFormPage";
import YojanaTypeManagePage from "./pages/yojana-type/YojanaTypeManagePage";
import WardFormPage from "./pages/ward/WardFormPage";
import WardManagePage from "./pages/ward/WardManagePage";
import FiscalYearFormPage from "./pages/fiscal-year/FiscalYearFormPage";
import FiscalYearManagePage from "./pages/fiscal-year/FiscalYearManagePage";
import ConstructorMemberFormPage from "./pages/constructor-member/ConstructorMemberFormPage";
import ConstructorMemberManagePage from "./pages/constructor-member/ConstructorMemberManagePage";
import GeneralDocumentsFormPage from "./pages/general-document/GeneralDocumentsFormPage";
import GeneralDocumentsManagePage from "./pages/general-document/GeneralDocumentsManagePage";
import PlanPage from "./pages/plan/PlanPage";
import { getNagarpalika } from "./rtk/feature/auth/authApi";
import { NagarpalikaTypeI } from "./rtk/feature/auth/authSlice";
import FixedRateAnalysisTable from "./pages/fixed-analyze-rate/analyze-rate/FixedRateAnalysisTable";
import FixedRateAnalysisFormPage from "./pages/fixed-analyze-rate/analyze-rate/FixedRateAnalysisFormPage";
import MunicipalityBudget from "./pages/municipality-budget/MunicipalityBudget";
import MunicipalityBudgetEditPage from "./pages/municipality-budget/MunicipalityBudgetEditPage";
import ErrorBoundary from "./components/Error/Error";
import RoleProtectedRoute from "./Routes/RoleProtectedRoutes/RoleProtectedRoute";
import { roles } from "./constants/constants";
import ReviewPlanDetail from "./pages/plan/ReviewPlanDetails/ReviewPlanDetail";
import MunicipalityInformationForm from "./pages/municipality-information/MunicipalityInformationForm";
import ReviewPlanDetailsPage from "./pages/plan/ReviewPlanDetails";
import DistrictRateForm from "./pages/DistrictRate/DistrictRateForm";
import DisctrictRate from "./pages/DistrictRate/DisctrictRate";
import DistrictEditForm from "./pages/DistrictRate/DistrictEditForm";
import DorEquipmentHireRateSlice from "./rtk/feature/dor-equipment-hire-rate/dorEquipmentHireRateSlice";
import DOREquipmentHireRate from "./pages/DOREquipmentHireRate/DorEquipmentHireRate";
import DOREquipmentHireRateForm from "./pages/DOREquipmentHireRate/DorEquipmentHireRateForm";
import GeneralWorkForm from "./pages/plan/estimate/general-work/GeneralWorkForm";
import SanitoryWorkForm from "./pages/plan/estimate/sanitory-work/SanitoryWorkForm";
import ElectrificationWorkForm from "./pages/plan/estimate/electrification-work/electrificationWorkForm";
import PlanningInspection from "./pages/planning-inspection/PlanningInspection";
import PlanningInspectionForm from "./pages/planning-inspection/PlanningInspectionForm";
import PlanningContractor from "./pages/planning-contractor/PlanningContractor";
import PlanningContractorForm from "./pages/planning-contractor/PlanningContractorForm";
import BillVerification from "./pages/bill-verification/BillVerification";
import PlanningBill from "./pages/planning-bill/PlanningBill";
import PlanningBillDetails from "./pages/planning-bill/planning-bill-detail/PlanningBillDetails";
import PlanningBillForm from "./pages/planning-bill/PlanningBillForm";
import BillPayments from "./pages/bill-payments/BillPayments";
import BillPaymentsDetails from "./pages/bill-payments/BillPaymentsDetails";
import ChequeDetails from "./pages/cheque-details/ChequeDetails";
import DocumentPage from "./pages/documents/DocumentPage";
import YojanaTemplate from "./pages/documents/YojanaTemplate";
import AssingnConstructor from "./pages/plan/assign-constructor/AssignConstructor";
import StartedVerifiedPlan from "./pages/plan/assign-constructor/StartedVerifiedPlan";
import CommunicationTitle from "./components/comunication-title/CommunicationTitle";
import ConstructorDetail from "./pages/plan/assign-constructor/ConstructorDetail";
import MinuteDocument from "./pages/minute-document/MinuteDocument";
import StartedYojana from "./pages/started-yojana/StartedYojana";
import StartedYojanaDetail from "./pages/started-yojana/StartedYojanaDetail";
import MunicipalityBudgetView from "./pages/municipality-budget-view/MunicipalityBudgetView";
import StartYojana from "./pages/start-yojana/StartYojana";

export const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/auth/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/auth/change-password",
    element: <ChangePasswordPage />,
  },
  {
    path: "*",
    element: <ProtectedRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "*",
        element: <DashboardLayout />,
        children: [
          {
            path: "",
            element: (
              <RoleProtectedRoute
                redirectPath="/login"
                allowedRoles={[
                  roles.Gaupalika_Stariya_Anugaman_Samiti,
                  roles.Prasasakiya_Adhikrit,
                  roles.IT_OFFICER,
                  roles.WARD_SACHIB,
                  roles.ADMIN,
                  roles.Yojana_Sakha,
                  roles.Lekha_Sakha,
                  roles.UPABHOKTA_SAMITI,
                  roles.Purwadhar_Sakha,
                ]}
              />
            ),

            children: [
              { index: true, element: <DashboardPage /> },
              {
                path: "user/add",
                element: <UserFormPage />,
              },
              {
                path: "user/edit/:id",
                element: <UserFormPage />,
              },
              {
                path: "user/list",
                element: <UserManagePage />,
              },
              {
                path: "ward/add",
                element: <WardFormPage />,
              },
              {
                path: "ward/edit/:id",
                element: <WardFormPage />,
              },
              {
                path: "ward/list",
                element: <WardManagePage />,
              },
              {
                path: "yojana/add",
                element: <YojanaFormPage />,
              },
              {
                path: "yojana/edit/:id",
                element: <YojanaFormPage />,
              },
              {
                path: "yojana/list",
                element: <YojanaManagePage />,
              },
              {
                path: "equipment/add",
                element: <EquipmentFormPage />,
              },
              {
                path: "equipment/edit/:id",
                element: <EquipmentFormPage />,
              },
              {
                path: "equipment/list",
                element: <EquipmentManagePage />,
              },
              {
                path: "equipment/unit/add",
                element: <UnitFormPage />,
              },
              {
                path: "equipment/unit/edit/:id",
                element: <UnitFormPage />,
              },
              {
                path: "equipment/unit/list",
                element: <UnitManagePage />,
              },
              {
                path: "minute-document/:id",
                element: <MinuteDocument />,
              },
              {
                path: "equipment/transportation-type/add",
                element: <TransportationTypeFormPage />,
              },
              {
                path: "equipment/transportation-type/edit/:id",
                element: <TransportationTypeFormPage />,
              },
              {
                path: "equipment/transportation-type/list",
                element: <TransportationTypeManagePage />,
              },
              // {
              //   path: "budget/add",
              //   element: <BudgetFormPage />,
              // },
              // {
              //   path: "budget/edit/:id",
              //   element: <BudgetFormPage />,
              // },
              {
                path: "budget/list",
                element: <BudgetManagePage />,
              },
              {
                path: "budget/yojana-type/add",
                element: <YojanaTypeFormPage />,
              },
              {
                path: "budget/yojana-type/edit/:id",
                element: <YojanaTypeFormPage />,
              },
              {
                path: "budget/yojana-type/list",
                element: <YojanaTypeManagePage />,
              },
              {
                path: "budget/constructor/add",
                element: <ConstructorFormPage />,
              },
              {
                path: "budget/constructor/edit/:id",
                element: <ConstructorFormPage />,
              },
              {
                path: "budget/constructor/list",
                element: <ConstructorManagePage />,
              },
              {
                path: "budget/constructor/member/add",
                element: <ConstructorMemberFormPage />,
              },
              {
                path: "budget/constructor/member/edit/:id",
                element: <ConstructorMemberFormPage />,
              },
              {
                path: "budget/constructor/member/list",
                element: <ConstructorMemberManagePage />,
              },
              {
                path: "budget/municipality-budget",
                element: <MunicipalityBudget />,
              },
              {
                path: "municipality-budget/view",
                element: <MunicipalityBudgetView />,
              },
              {
                path: "budget/municipality-budget/edit/:id",
                element: <MunicipalityBudgetEditPage />,
              },
              {
                path: "plan-management/plan",
                element: <PlanPage />,
              },
              {
                path: "start-yojana",
                element: <StartYojana />,
              },
              {
                path: "started-yojana",
                element: <StartedYojana />,
              },
              {
                path: "started-yojana-detail/:id",
                element: <StartedYojanaDetail />,
              },
              {
                path: "plan-management/plan/constructor-detail/:id",
                element: <ConstructorDetail />,
              },
              {
                path: "plan-management/plan/add/:id",
                element: <GeneralWorkForm />,
              },
              {
                path: "plan-management/plan/sanitory-work/add/:id",
                element: <SanitoryWorkForm />,
              },

              {
                path: "plan-management/plan/edit/:id/:workid",
                element: <GeneralWorkForm />,
              },
              {
                path: "plan-management/plan/electrification-work/add/:id/",
                element: <ElectrificationWorkForm />,
              },
              {
                path: "plan-management/plan/electrification-work/edit/:id/:workid",
                element: <ElectrificationWorkForm />,
              },
              {
                path: "plan-management/plan/sanitory-work/edit/:id/:workid",
                element: <SanitoryWorkForm />,
              },
              {
                path: "plan-management/review-plan/:id",
                element: <ReviewPlanDetailsPage />,
              },
              {
                path: "planning-inspection",
                element: <PlanningInspection />,
              },

              {
                path: "planning-inspection/add",
                element: <PlanningInspectionForm />,
              },
              {
                path: "planning-inspection/edit/:id",
                element: <PlanningInspectionForm />,
              },

              {
                path: "planning-contractor",
                element: <PlanningContractor />,
              },
              {
                path: "planning-contractor/add",
                element: <PlanningContractorForm />,
              },
              {
                path: "planning-contractor/edit/:id",
                element: <PlanningContractorForm />,
              },
              {
                path: "assign-constructor",
                element: <StartedVerifiedPlan />,
              },
              {
                path: "bill-verification",
                element: <BillVerification />,
              },

              {
                path: "planning-bill",
                element: <PlanningBill />,
              },
              {
                path: "bill-payments",
                element: <BillPayments />,
              },
              {
                path: "bill-payments/details/:id",
                element: <BillPaymentsDetails />,
              },
              {
                path: "planning-bill/details/:id",
                element: <PlanningBillDetails />,
              },
              {
                path: "bill-payments/cheque-details/:id",
                element: <ChequeDetails />,
              },
              {
                path: "planning-bill/edit/:id",
                element: <PlanningBillForm />,
              },
              {
                path: "settings/fiscal-year/add",
                element: <FiscalYearFormPage />,
              },
              {
                path: "settings/fiscal-year/edit/:id",
                element: <FiscalYearFormPage />,
              },
              {
                path: "settings/fiscal-year/list",
                element: <FiscalYearManagePage />,
              },
              {
                path: "settings/communication-title",
                element: <CommunicationTitle />,
              },
              {
                path: "settings/general-document/add",
                element: <GeneralDocumentsFormPage />,
              },
              {
                path: "settings/documents/list",
                element: <DocumentPage />,
              },

              {
                path: "editor/:budgetId/:documentId",
                element: <YojanaTemplate />,
              },

              {
                path: "settings/general-document/edit/:id",
                element: <GeneralDocumentsFormPage />,
              },
              {
                path: "settings/general-document/list",
                element: <GeneralDocumentsManagePage />,
              },
              {
                path: "settings/municipality-information",
                element: <MunicipalityInformationForm />,
              },
              {
                path: "fixed-rate-analysis/list",
                element: <FixedRateAnalysisTable budgetID="23f877a0-9dc1-4710-a4f4-ad68be90bef5" />,
              },

              {
                path: "fixed-rate-analysis/add",
                element: <FixedRateAnalysisFormPage />,
              },
              {
                path: "district-rate",
                element: <DisctrictRate />,
              },
              {
                path: "district-rate/add",
                element: <DistrictRateForm />,
              },
              {
                path: "dor-equipment-hire-rate",
                element: <DOREquipmentHireRate />,
              },
              {
                path: "dor-equipment-hire-rate/add",
                element: <DOREquipmentHireRateForm />,
              },
              {
                path: "dor-equipment-hire-rate/edit/:id",
                element: <DOREquipmentHireRateForm />,
              },
              {
                path: "district-rate/edit/:id",
                element: <DistrictRateForm />,
              },
              {
                path: "fixed-rate-analysis/edit/:id",
                element: <FixedRateAnalysisFormPage />,
              },
              {
                path: "home",
                element: <HomePage />,
              },
              {
                path: "profile",
                element: <ProfilePage />,
              },
              {
                path: "profile/:id",
                element: <ProfilePage />,
              },
            ],
          },
          {
            path: "*",
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  },
]);

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAxiosInterceptors();
  }, []);

  useEffect(() => {
    dispatch(getNagarpalika());
  }, [dispatch]);

  const authSelector = useAppSelector((state) => state.auth.showLoading);
  const showLoading = authSelector;

  return (
    <React.Fragment>
      <ErrorBoundary>
        <RouterProvider router={router} />
        <StyledLoaderContainer id="overlay" className={showLoading ? "active" : ""}>
          <BiLoader />
        </StyledLoaderContainer>
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default App;
