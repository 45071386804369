import { ButtonHTMLAttributes } from "react";
import { StyledButton } from "./Button.style";

export interface ButtonPropsI extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "error" | "auth";
  startIcon?: React.ReactNode;
}

export default function Button(props: ButtonPropsI) {
  const { children, startIcon, ...otherProps } = props;
  return (
    <StyledButton {...otherProps}>
      {startIcon && startIcon}
      <span>{children}</span>
    </StyledButton>
  );
}
