import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addRateEquipment, deleteRateEquipment, getRateEquipments, updateRateEquipment } from "./rateEquipmentApi";

export interface RateEquipmentFormTypeI {
  id?: string;
  equipment: any;
  quantity: number;
  planning_rate_analysis?: string;
  total_amount: number;
  unit_rate: number;
  _is_latest?: boolean;
}

export interface RateEquipmentWorkTypeI {
  id: string;
  name: string;
  code: string;
  measurement: string;
}

export interface RateEquipmentSubTypeI {
  id: string;
  equipment: EquipmentTypeI;
  quantity: number;
  total: number;
  created_on: Date;
  modified_on: Date;
  _is_latest?: boolean;
}

export interface EquipmentTypeI {
  id: string;
  english_name: string;
  nepali_name: string;
  unit_rate: number;
  type: string;
}

export interface RateEquipmentsSliceStateI {
  loading: boolean;
  data: RateEquipmentSubTypeI[];
  item: RateEquipmentSubTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: RateEquipmentsSliceStateI = {
  loading: false,
  item: null,
  data: [],
  hasSubmitted: false,
  error: null,
};

const rateEquipmentSlice = createSlice({
  name: "rateEquipment",
  initialState,
  reducers: {
    rateEquipmentCleanUp: (state) => {
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addRateEquipment.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addRateEquipment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = [...state.data, payload];
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addRateEquipment.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getRateEquipments.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getRateEquipments.fulfilled,
      (state, { payload }: PayloadAction<{ equipment: RateEquipmentSubTypeI[] | null }>) => {
        state.loading = false;
        if (payload.equipment) state.data = payload.equipment;
        else state.data = [];
        state.error = null;
      }
    );
    builder.addCase(getRateEquipments.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(updateRateEquipment.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateRateEquipment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.data = state.data.map((el) => {
        if (el.id === payload.id) {
          return payload;
        }
        return el;
      });
      state.error = null;
    });
    builder.addCase(updateRateEquipment.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(deleteRateEquipment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteRateEquipment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = [...state.data.filter((el) => el.id !== payload)];
      state.error = null;
    });
    builder.addCase(deleteRateEquipment.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { rateEquipmentCleanUp } = rateEquipmentSlice.actions;
export default rateEquipmentSlice.reducer;
