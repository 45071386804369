import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { GeneralDocumentFormTypeI } from "./generalDocumentSlice";
import { GeneralDocumentSuccess } from "../../../utils/res-mes/response-message-generator";

export const getGeneralDocuments = createAsyncThunk(
  "getGeneralDocuments",
  async (
    userParams: { per_page: number; page: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/general-docs/", {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getGeneralDocument = createAsyncThunk(
  "getGeneralDocument",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/general-docs/${id}/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addGeneralDocument = createAsyncThunk(
  "addGeneralDocument",
  async (data: FormData, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post("/general-docs/", data, {
        headers: {
          "Content-type": "multipart/form-data",
        },
        onUploadProgress(progressEvent) {},
      });
      if (res.data) toast.success(GeneralDocumentSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteGeneralDocument = createAsyncThunk(
  "deleteGeneralDocument",
  async (deleteId: string, { dispatch, rejectWithValue }) => {
    try {
      await imsClient.delete(`/general-docs/${deleteId}/`);
      dispatch(getGeneralDocuments());
      toast.success(GeneralDocumentSuccess("deleted"));
      return deleteId;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateGeneralDocument = createAsyncThunk(
  "updateGeneralDocument",
  async (data: { data: FormData; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/general-docs/${data.id}/`, data.data, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      if (res.data) toast.success(GeneralDocumentSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
