import React, { HTMLAttributes, HtmlHTMLAttributes, ReactNode } from "react";
import { FlexStyles, justifyFlexTypes } from "./Flex.style";
interface IFlexProps extends HtmlHTMLAttributes<HTMLDivElement> {
  gap?: string;
  dir?: "column" | "row";
  justify?: justifyFlexTypes;
  align?: justifyFlexTypes;
  children: ReactNode | ReactNode[];
  height?: string;
}
const Flex = (props: IFlexProps) => {
  const {
    gap = "0",
    justify = "flex-start",
    align = "flex-start",
    children,
    ...rest
  } = props;
  return (
    <FlexStyles
      style={{
        flexDirection: props.dir,
        alignItems: props.align,
        justifyContent: props.justify,
      }}
      align={props.align}
      gap={props.gap}
      dir={props.dir}
      justify={props.justify}
      {...rest}
    >
      {" "}
      {children}{" "}
    </FlexStyles>
  );
};
interface IFlexCol extends IFlexProps {}

Flex.Col = (props: IFlexCol) => {
  return (
    <FlexStyles style={{ flexDirection: "column" }} {...props}>
      {" "}
      {props.children}{" "}
    </FlexStyles>
  );
};
export default Flex;
