import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { FiscalYearFormTypeI } from "./fiscalYearSlice";
import { FiscalYearSuccess } from "../../../utils/res-mes/response-message-generator";

export const getFiscalYears = createAsyncThunk(
  "getFiscalYears",
  async (
    userParams: { per_page: number; page: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/fiscal-year/", {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getFiscalYear = createAsyncThunk("getFiscalYear", async (id: string, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.get(`/fiscal-year/${id}/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const addFiscalYear = createAsyncThunk(
  "addFiscalYear",
  async (data: FiscalYearFormTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post("/fiscal-year/", data);
      if (res.data) toast.success(FiscalYearSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteFiscalYear = createAsyncThunk(
  "deleteFiscalYear",
  async (deleteId: string, { dispatch, rejectWithValue }) => {
    try {
      await imsClient.delete(`/fiscal-year/${deleteId}/`);
      dispatch(getFiscalYears());
      toast.success(FiscalYearSuccess("deleted"));
      return deleteId;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateFiscalYear = createAsyncThunk(
  "updateFiscalYear",
  async (data: { data: FiscalYearFormTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/fiscal-year/${data.id}/`, data.data);
      if (res.data) toast.success(FiscalYearSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
