export const realTimeFilter = (arr: any[], keyword: string) => {
  console.log(arr);
  return arr.filter((item) => {
    for (const key in item) {
      //@ts-ignore
      if (item[key] && item[key].toString().toLowerCase().includes(keyword.toLowerCase().trim())) {
        return true;
      }
    }
    return false;
  });
};
