import { createSlice } from "@reduxjs/toolkit";
import {
  getMunicipalityBudgetData,
  getMunicipalityBudgetDataById,
  postMunicipalityBudgetData,
  updateMunicipalityBudgetData,
} from "./municipalityBudgetAPI";

export interface IMunicipalityBudgetResults {
  id: string;
  name: string;
  budget: number;
}

export interface ImunicipalityData {
  allocation: string;
  attention: string;
  balance: string;
  expenditure: string;
  expenditure_heading: string;
  expenditure_rate: string;
  hint: string;
  id: string;
  name: string;
  source: string;
  serial_no: number;
  ward: string;
  unit: string;
  pdf_file?: string;
}

export interface ImunicipalityBudget {
  data: {
    results: ImunicipalityData[];
    current_page: number;
    per_page: number;
    total: number;
    total_pages: number;
  } | null;
  loading: boolean;
  error: any;
  item: ImunicipalityData | null;
  formSubmitted: boolean;
}

const initialState: ImunicipalityBudget = {
  data: null,
  loading: false,
  error: null,
  item: null,
  formSubmitted: false,
};
const municipalityBudgetSlice = createSlice({
  name: "municipalityBudgetSlice",
  initialState,
  reducers: {
    municipalityBudgetCleanUp: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMunicipalityBudgetData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMunicipalityBudgetData.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getMunicipalityBudgetData.rejected, (state, { payload }) => {
      state.data = null;
      state.error = payload;
      state.loading = false;
    });

    builder.addCase(getMunicipalityBudgetDataById.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.item = null;
    });

    builder.addCase(getMunicipalityBudgetDataById.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    });
    builder.addCase(getMunicipalityBudgetDataById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.item = payload;
    });

    //update
    builder.addCase(updateMunicipalityBudgetData.pending, (state) => {
      state.formSubmitted = false;
    });

    builder.addCase(updateMunicipalityBudgetData.fulfilled, (state) => {
      state.formSubmitted = true;
    });
  },
});
export const { municipalityBudgetCleanUp } = municipalityBudgetSlice.actions;
export default municipalityBudgetSlice.reducer;
