import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { IPageOptions } from "../../../interfaces/page.interface";
import { IPostDorEquipmentForm } from "../../../pages/DOREquipmentHireRate/DorEquipmentHireRateForm";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { DataSuccess } from "../../../utils/res-mes/response-message-generator";

export const getAllDorEquipmentHireRate = createAsyncThunk(
  "getAllDorEquipmentHireRate",
  async (options: IPageOptions, { rejectWithValue }) => {
    try {
      const res = await imsClient.get("/dor-equipment-hire-rate/", { params: options.params, signal: options.signal });
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(appError.getError());
    }
  }
);

export const postDorEquipmentHireRate = createAsyncThunk(
  "postAllDorEquipmentHireRate",
  async (data: IPostDorEquipmentForm, { rejectWithValue }) => {
    try {
      const res = await imsClient.post("/dor-equipment-hire-rate/", data);
      if (res.data) toast.success(DataSuccess("posted"));
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(appError.getError());
    }
  }
);

export const updateDorEquipmentHireRate = createAsyncThunk(
  "updateAllDorEquipmentHireRate",
  async ({ id, data }: { id: string; data: IPostDorEquipmentForm }, { rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/dor-equipment-hire-rate/${id}/`, data);
      if (res.data) toast.success(DataSuccess("updated"));
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(appError.getError());
    }
  }
);

export const getDorEquipmentHireRateById = createAsyncThunk(
  "getDorEuipmentById",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/dor-equipment-hire-rate/${id}/`);
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(appError.getError());
    }
  }
);
