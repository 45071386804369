import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { getBudget } from "../budget/budgetApi";
import { EstimationDetailFormTypeI, GroupPlanEstimateFormTypeI } from "./planGroupEstimateSlice";
import {
  EquipmentRateSuccess,
  PlanEstimateItemSuccess,
  PlanEstimateSuccess,
} from "../../../utils/res-mes/response-message-generator";

export const getGroupPlanEstimates = createAsyncThunk(
  "getGroupPlanEstimates",
  async (planbriefParams: { budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/yojana/${planbriefParams?.budgetID}/measurement-analysis/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getGroupPlanEstimate = createAsyncThunk(
  "getGroupPlanEstimate",
  async (data: { budgetID: string; groupID: string }) => {
    try {
      const res = await imsClient.get(`/yojana/${data.budgetID}/measurement-analysis/${data.groupID}/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
    }
  }
);

export const addGroupPlanEstimate = createAsyncThunk(
  "addGroupPlanEstimate",
  async (data: { data: EstimationDetailFormTypeI; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/yojana/${data.budgetID}/measurement-analysis/`, data.data);
      if (res.data) toast.success(PlanEstimateSuccess("added"));

      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateGroupPlanEstimate = createAsyncThunk(
  "updateGroupPlanEstimate",
  async (
    data: { data: GroupPlanEstimateFormTypeI | any; id: string; budgetID: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.patch(`/yojana/${data.budgetID}/measurement-analysis/${data.id}/`, data.data);
      if (res.data) toast.success(PlanEstimateSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteGroupPlanEstimate = createAsyncThunk(
  "deleteGroupPlanEstimate",
  async (data: { id: string; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.delete(`/yojana/${data?.budgetID}/measurement-analysis/${data.id}/`);
      if (res.data) toast.success(PlanEstimateSuccess("deleted"));
      return data.id;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export interface EstimateItemTypeI {
  shapes: string;
  formula: string;
  total: number;
  length: number | null;
  breadth: number | null;
  remarks: string;
  name: string;
  number: number;
}

export const addEstimateItem = createAsyncThunk(
  "addEstimateItem",
  async (data: { data: EstimateItemTypeI }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/estimation-details/`, data.data);
      if (res.data) toast.success(PlanEstimateItemSuccess("updated"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateEstimateItem = createAsyncThunk(
  "updateEstimateItem",
  async (data: { data: EstimateItemTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/estimation-details/${data.id}/`, data.data);
      if (res.data) toast.success(PlanEstimateItemSuccess("updated"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteEstimateItem = createAsyncThunk(
  "deleteEstimateItem",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.delete(`/estimation-details/${id}/`);
      if (res.data) toast.success(PlanEstimateItemSuccess("deleted"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
