import { Flex, Table } from "@ims/common";
import React from "react";
import { EquipmentTypeI } from "../../../rtk/feature/fixed-rate-equipment/fixedRateEquipmentSlice";
import { IEquipment, IEquipmentQuantityAnalysis } from "../../../rtk/feature/plan-verification/planVerificationSlice";
import { StyledContainerDrawer } from "../analyze-rate/RateAnalysisTable";
import useTranslated from "../../../hooks/useTranslated";
import { prettifyNumberValue } from "../../../utils/Prettify";
interface IProps {
  data: IEquipmentQuantityAnalysis;
}
const EquipmentAnalysisDetails = (props: IProps) => {
  const { data } = props;
  const { keyword } = useTranslated();
  const groupedData: Record<string, IEquipment[]> = {};
  for (const element of data?.equipments) {
    const type = element?.equipment?.type;
    if (groupedData[type]) {
      groupedData[type].push(element);
    } else {
      groupedData[type] = [element];
    }
  }

  const equipmentData = [...Object.values(groupedData)];
  return (
    <main>
      <Flex.Col gap="3rem">
        {equipmentData?.map((equipments, idex) => {
          return (
            <Table.Container key={idex}>
              <h4> {equipments[0]?.equipment?.type} </h4>
              <Table id="verification-table">
                <Table.Body>
                  <Table.Row>
                    <Table.HeadCol> {keyword["English name"]}</Table.HeadCol>
                    <Table.HeadCol> {keyword["Equipment quantity"]}</Table.HeadCol>
                    <Table.HeadCol> {keyword["Equipment unit rate"]}</Table.HeadCol>
                    <Table.HeadCol> {keyword.Total}</Table.HeadCol>
                  </Table.Row>
                  {equipments?.map((equipment, i) => {
                    return (
                      <Table.Row>
                        <Table.Col> {equipment?.equipment?.english_name || "___"} </Table.Col>
                        <Table.Col> {prettifyNumberValue(equipment?.quantity)} </Table.Col>
                        <Table.Col> {equipment?.equipment?.unit} </Table.Col>
                        <Table.Col> {prettifyNumberValue(equipment?.total_amount)} </Table.Col>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Table.Container>
          );
        })}
      </Flex.Col>
    </main>
  );
};

export default EquipmentAnalysisDetails;
