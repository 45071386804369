import { Form, Table } from "@ims/common";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdClose, MdDone, MdOutlineNotInterested, MdReplay, MdVerified } from "react-icons/md";
import { VscVerifiedFilled } from "react-icons/vsc";
import { updatePlanVerification } from "../../../rtk/feature/plan-verification/planVerificationAPI";
import { IplanVerificationData } from "../../../rtk/feature/plan-verification/planVerificationSlice";
import { useAppDispatch } from "../../../rtk/hook";
import { userFriendlyDate } from "../../../utils/date";
import { getValue } from "@testing-library/user-event/dist/utils";
import { toast } from "react-toastify";
import useTranslated from "../../../hooks/useTranslated";
import { AiFillCloseCircle } from "react-icons/ai";
import { GoUnverified } from "react-icons/go";

interface IProps {
  index: number;
  data: IplanVerificationData;
  budgetId: string;
}

const UpdateVerificationRow = (props: IProps) => {
  const { data, index, budgetId } = props;
  const { keyword } = useTranslated();

  const dispatch = useAppDispatch();
  interface IupdateVerification {
    description: string;
    is_verified: boolean;
  }
  const { register, getValues: updateFormValues, setValue } = useForm<IupdateVerification>();
  const handleResend = (data: IplanVerificationData) => {
    if (data?.status === "verified") {
      return;
    }
    if (data?.status === "correction") {
      const values = updateFormValues();

      const description = updateFormValues("description");
      const iSVerified = updateFormValues("is_verified");
      dispatch(
        updatePlanVerification({
          data: { description: description, is_verified: iSVerified },
          id: props.budgetId,
          yojanaId: data.id,
        })
      );
      data.status = "verification sent";
    } else {
      toast.error(keyword["Already Sent"]);
    }
  };

  useEffect(() => {
    if (props.data) setValue("description", props.data.description);
    setValue("is_verified", props.data.is_verified);
  }, []);
  if (Object.entries(data).length === 0) return null;

  return (
    <Table.Row key={data.id}>
      <Table.Col>{index + 1}</Table.Col>

      <Table.Col>{data.yojana?.name}</Table.Col>
      <Table.Col>
        {" "}
        <Form.Input
          name="description"
          register={register("description", {
            required: "Your old password is required.",
          })}
        />
      </Table.Col>
      <Table.Col>{data.send_from}</Table.Col>
      <Table.Col>{data.send_to}</Table.Col>
      <Table.Col align="center">
        {" "}
        {data.status === "verified" ? (
          <MdVerified color="blue" title={keyword["Verified"]} size={20} />
        ) : (
          <GoUnverified color="#888" title={keyword["Not Verified"]} size={20} />
        )}{" "}
      </Table.Col>
      <Table.Col>{userFriendlyDate(data.created_on)}</Table.Col>
      <Table.Col>{userFriendlyDate(data.modified_on)}</Table.Col>
      <Table.Col>{data?.comment ? data.comment : "No comment"}</Table.Col>

      <Table.Col align="center">
        <Table.ActionButton onClick={() => handleResend(data)}>
          {data?.status !== "correction" ? (
            <MdOutlineNotInterested color="#888" title={keyword["Not Allowed"]} cursor="help" size={20} />
          ) : (
            //
            <MdReplay title={data?.status === "correction" ? keyword["Resend"] : keyword["Already Sent"]} />
          )}
        </Table.ActionButton>
      </Table.Col>
    </Table.Row>
  );
};

export default UpdateVerificationRow;
