import { Button, Flex, Form, Table } from "@ims/common";
import Page from "../../../../core-ui/Page";
import useTranslated from "../../../../hooks/useTranslated";
import { IGeneralWorkData } from "../../../../rtk/feature/general-work/generalWorkSlice";
import { FaPlus } from "react-icons/fa";
import useDiscloure from "../../../../hooks/useDiscloure";
import { useAppDispatch } from "../../../../rtk/hook";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Dialog from "../../estimate/Dialog/Dialog";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { prettifyNumberValue, styleSN } from "../../../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../../../utils/translateNumber";

interface IProps {
  data: IGeneralWorkData[] | null;
  tableName: string;
  showHead: boolean;
  onFinalQuantityAdd?: (value: number, workId: string) => void;
}

const AbstractCostTable = (props: IProps) => {
  const { data, showHead } = props;
  const { keyword } = useTranslated();

  const { close: closePopup, open: openPopup, isOpen: isPopupOpen } = useDiscloure();
  const [addActiveID, setAddActiveID] = useState<string>("");
  const [workData, setWorkData] = useState<IGeneralWorkData[] | []>([]);

  interface IForm {
    final_quantity: number;
  }

  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IForm>({});

  const handleAddFinalQuantity = (workId: string) => {
    const finalQuantity = getValues("final_quantity");
    props.onFinalQuantityAdd?.(finalQuantity, workId);

    closePopup();
  };

  const handleOpenPopup = (id: string) => {
    setAddActiveID(id);
    openPopup();
  };

  useEffect(() => {
    if (data) setWorkData(data);
  }, [data]);

  useEffect(() => {
    const activeId = workData?.find((el) => el.id === addActiveID);

    if (activeId) {
      setValue("final_quantity", parseInt(activeId.final_quantity || "0"));
    }
  }, [workData, addActiveID]);

  const columns = [
    keyword["S.N."],
    keyword["Name"],
    keyword["Quantity"],
    keyword["Final Quantity"],
    keyword["Unit"],
    keyword["Rate"],
    keyword["Amount"],
    keyword["Total Amount"],
    keyword["Remarks"],
    keyword["Action"],
  ];
  if (workData && workData.length < 1) return null;
  return (
    <>
      <Page.Container>
        <Flex align="center" justify="flex-start">
          <Page.SubTitle> {props.tableName} </Page.SubTitle>
        </Flex>
      </Page.Container>
      <Dialog active={isPopupOpen} className="no-print" onClose={closePopup}>
        <Dialog.Container className="container-dialog">
          <div className="container-body">
            <h2 className="sub-title">{keyword["Do you want to add final quantity?"]}</h2>

            <Flex justify="space-between" height="auto">
              <Form.FormGroup error={!!errors.final_quantity}>
                <h3 className="title">{keyword["Add Final Quantity!"]}</h3>
                <Form.Input
                  type="number"
                  register={register("final_quantity", { required: keyword["Final quantity value is required"] })}
                />
                <Form.HelperText> {errors.final_quantity?.message} </Form.HelperText>
              </Form.FormGroup>
              <Button
                variant="primary"
                style={{ marginLeft: ".5rem" }}
                onClick={() => {
                  handleAddFinalQuantity(addActiveID);
                }}
              >
                {keyword.Add}
              </Button>
              <Button type="button" onClick={closePopup}>
                {keyword.Cancel}
              </Button>
            </Flex>
          </div>
        </Dialog.Container>
      </Dialog>

      <Table.Container>
        <Table id="export-table">
          {showHead && (
            <Table.Head>
              <Table.Row>
                {columns.map((column, idx) => {
                  if (!props.onFinalQuantityAdd && column === "Action") return;
                  return <Table.HeadCol width={idx === 0 ? "100" : "600"}>{column} </Table.HeadCol>;
                })}
              </Table.Row>
            </Table.Head>
          )}
          <Table.Body>
            {workData?.map((work, i) => {
              return (
                <Table.Row>
                  <Table.Col width="100"> {englishNepaliNumberTranslation(styleSN(i + 1))} </Table.Col>

                  <Table.Col width="1200"> {work?.name} </Table.Col>
                  <Table.Col width="600"> {work?.quantity || "___"} </Table.Col>
                  <Table.Col width="600"> {work?.final_quantity || "___"} </Table.Col>
                  <Table.Col width="600"> {work?.unit?.name || "___"} </Table.Col>
                  <Table.Col width="600"> {prettifyNumberValue(work?.rate)} </Table.Col>
                  <Table.Col width="600"> {prettifyNumberValue(work?.total)} </Table.Col>
                  <Table.Col width="600"> {prettifyNumberValue(work?.final_total)} </Table.Col>
                  <Table.Col width="600"> {work?.remarks || "___"} </Table.Col>
                  {!!props.onFinalQuantityAdd && (
                    <Table.Col>
                      <Table.ActionButton
                        color="green"
                        onClick={() => {
                          if (!work.rate) toast.error(keyword["Please add rate first."]);
                          else handleOpenPopup(work.id);
                        }}
                      >
                        <FaPlus />
                      </Table.ActionButton>
                    </Table.Col>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Table.Container>
      <br />
    </>
  );
};

export default AbstractCostTable;
