import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { AnalyzeRateFormTypeI, RateEquipmentTypeII } from "./analyzeRateSlice";
import { AnalyzeRateSuccess } from "../../../utils/res-mes/response-message-generator";

export const getAnalyzeRates = createAsyncThunk(
  "getAnalyzeRates",
  async (data: { budgetID: string; perPage: number; page: number }, { dispatch, rejectWithValue }) => {
    const { budgetID, perPage, page } = data;
    try {
      const res = await imsClient.get(`/yojana/${budgetID}/planning-rate-analysis/`, {
        params: { per_page: perPage, page: page },
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getAnalyzeRate = createAsyncThunk(
  "getAnalyzeRate",
  async (data: { budgetID: string; id: string }, { dispatch, rejectWithValue }) => {
    const { budgetID, id } = data;

    try {
      const res = await imsClient.get(`/yojana/${budgetID}/planning-rate-analysis/${id}/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addAnalyzeRate = createAsyncThunk(
  "addAnalyzeRate",
  async (data: { data: RateEquipmentTypeII; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post("/analyze-rate/", data);
      toast.success(AnalyzeRateSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteAnalyzeRate = createAsyncThunk(
  "deleteAnalyzeRate",
  async (deleteId: string, { dispatch, rejectWithValue }) => {
    try {
      await imsClient.delete(`/analyze-rate/${deleteId}/`);
      toast.success(AnalyzeRateSuccess("deleted"));

      return deleteId;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateAnalyzeRate = createAsyncThunk(
  "updateAnalyzeRate",
  async (data: { data: AnalyzeRateFormTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/analyze-rate/${data.id}/`, data.data);
      toast.success(AnalyzeRateSuccess("updated"));

      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
