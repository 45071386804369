import React, { MouseEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { useNavigate, useParams } from "react-router-dom";
import { constructorsCleanUp } from "../../../rtk/feature/constructor/constructorSlice";
import ReuseForm from "../../../core-ui/ReuseForm";
import { getBudgets } from "../../../rtk/feature/budget/budgetApi";
import { getConstructorMembers } from "../../../rtk/feature/constructor-member/constructorMemberApi";
import { planbriefCleanUp, PlanBriefFormTypeI } from "../../../rtk/feature/plan-brief/planBriefSlice";
import { addPlanBrief, getPlanBrief, updatePlanBrief } from "../../../rtk/feature/plan-brief/planBriefApi";
import { budgetCleanUp } from "../../../rtk/feature/budget/budgetSlice";
import { toast } from "react-toastify";
import { Flex, Form } from "@ims/common";
import useTranslated from "../../../hooks/useTranslated";
import { getPlanVerifications } from "../../../rtk/feature/plan-verification/planVerificationAPI";

interface PlanBriefFormPropsI {
  budgetID: string | null;
  setBudgetId?: (id: null) => void;
  isAddable: boolean;
}

function PlanBriefForm(props: PlanBriefFormPropsI) {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,

    register,
    control,
  } = useForm<PlanBriefFormTypeI>({
    defaultValues: {},
  });
  const { keyword } = useTranslated();

  const budgetID = props.budgetID;
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const constructorsSelector = useAppSelector((state) => state.constructors);
  const planBriefSelector = useAppSelector((state) => state.planBriefs);
  const budgetsState = useAppSelector((state) => state.budgets);
  const [editMode, setEditMode] = useState(false);

  const { data: budgetList, item: budgetItem } = budgetsState;
  const { item } = constructorsSelector;
  const { item: planBriefItem } = planBriefSelector;

  useEffect(() => {
    if (budgetID) {
      dispatch(planbriefCleanUp());
      dispatch(getPlanBrief(budgetID));
    } else {
      reset();
      dispatch(planbriefCleanUp());
    }
  }, [budgetID, budgetItem, dispatch, reset]);

  useEffect(() => {
    if (budgetID === null) {
      dispatch(budgetCleanUp());
      dispatch(planbriefCleanUp());
    }
  }, [dispatch, budgetID]);

  useEffect(() => {
    if (planBriefItem) {
      setEditMode(true);
    } else {
      dispatch(constructorsCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch, planBriefItem]);

  useEffect(() => {
    if (planBriefItem !== null && budgetID !== null) {
      setValue("contingency", planBriefItem?.contingency);
      setValue("yojana_head", planBriefItem?.yojana_head);
      setValue("tole", planBriefItem?.tole);
      setValue("beneficiaries", planBriefItem?.beneficiaries);
      setValue("yojana_status", planBriefItem?.yojana_status);
      setValue("measurement", planBriefItem?.measurement);
      setValue("yojana_subtype", planBriefItem?.yojana_subtype);
      setValue("completion_date", planBriefItem?.completion_date);
      setValue("contribution_donation", planBriefItem?.contribution_donation);
      setValue("description", planBriefItem?.description);
      setValue("final_measurement", planBriefItem?.final_measurement);
    } else {
      reset();
    }
  }, [item, setValue, reset, budgetList, planBriefItem, budgetID]);

  const onSubmit = (data: any) => {
    if (!budgetID) {
      toast.warning("Please select a plan.");
      return;
    }

    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.delete("pre_project_photo");
    formData.delete("mid_project_photo");
    formData.delete("completed_photo");

    if (data.pre_project_photo) formData.append("pre_project_photo", data.pre_project_photo[0]);
    if (data.mid_project_photo) formData.append("mid_project_photo", data.mid_project_photo[0]);
    if (data.completed_photo) {
      formData.append("completed_photo", data.completed_photo[0]);
    }

    if (editMode && planBriefItem) {
      dispatch(updatePlanBrief({ id: planBriefItem.id, budgetID: budgetID, data: formData }));
    } else {
      dispatch(addPlanBrief({ data: formData, budgetID: budgetID }));
    }
  };
  const [imagesNames, setImagesNames] = useState({
    pre: "",
    mid: "",
    completed: "",
  });

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    !!props.setBudgetId && props?.setBudgetId(null);
    navigate("/plan-management/plan");
  };

  return (
    <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
      <Grid>
        <Grid.Row gap={1.5}>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword.Contingency}
              name="contingency"
              type="number"
              placeholder="Enter plan contingency"
              isDisable={!props.isAddable}
              rules={{ required: keyword["This is required field."] }}
              error={errors["contingency"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword["Yojana Head"]}
              name="yojana_head"
              placeholder="Enter plan head"
              isDisable={!props.isAddable}
              rules={{ required: keyword["This is required field."] }}
              error={errors["yojana_head"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword.Tole}
              name="tole"
              placeholder="Enter tole"
              isDisable={!props.isAddable}
              rules={{ required: keyword["This is required field."] }}
              error={errors["tole"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword.Beneficiaries}
              name="beneficiaries"
              type="number"
              isDisable={!props.isAddable}
              placeholder="Enter house beneficiaries"
              rules={{ required: keyword["This is required field."] }}
              error={errors["beneficiaries"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword["Yojana Status"]}
              name="yojana_status"
              placeholder="Enter plan status"
              isDisable={!props.isAddable}
              rules={{ required: keyword["This is required field."] }}
              error={errors["yojana_status"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword["Estimate Cost"]}
              name="measurement"
              type="number"
              isDisable={!props.isAddable}
              placeholder="Enter estimate cost"
              rules={{ required: keyword["This is required field."] }}
              error={errors["measurement"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword["Yojana Subtype"]}
              name="yojana_subtype"
              isDisable={!props.isAddable}
              placeholder="Enter plan sub type"
              rules={{ required: keyword["This is required field."] }}
              error={errors["yojana_subtype"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword["Completion Date"]}
              name="completion_date"
              type="date"
              isDisable={!props.isAddable}
              placeholder="Enter completion date"
              rules={{ required: keyword["This is required field."] }}
              error={errors["completion_date"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword["Contribution Donation"]}
              name="contribution_donation"
              type="number"
              isDisable={!props.isAddable}
              placeholder="Enter contribution donation"
              rules={{ required: keyword["This is required field."] }}
              error={errors["contribution_donation"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <ReuseForm.Input
              label={keyword["Final Measurement"]}
              name="final_measurement"
              type="number"
              isDisable={!props.isAddable}
              placeholder="Enter final estimation cost"
              rules={{ required: keyword["This is required field."] }}
              error={errors["final_measurement"]}
            />
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <Flex.Col>
              <Form.Label> {keyword["Pre Project Photo"]} </Form.Label>

              <Controller
                name="pre_project_photo"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field: { onChange, value }, fieldState, formState }) => {
                  return (
                    <Form.File
                      id="pre_project_photo"
                      fileType="image"
                      onChange={(e) => {
                        onChange(e.target.files);
                      }}
                      placeholder="Enter final measurement"
                      previewImage={planBriefItem?.pre_project_photo}
                    />
                  );
                }}
              ></Controller>
              {errors && errors["pre_project_photo"] && errors["pre_project_photo"].message && (
                <Form.HelperText>{errors["pre_project_photo"].message}</Form.HelperText>
              )}
            </Flex.Col>
          </Grid.Col>
          <Grid.Col spanMd={6} spanXs={12}>
            <Flex.Col>
              <Form.Label> {keyword["Mid Project Photo"]}</Form.Label>

              <Controller
                name="mid_project_photo"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field: { onChange, value }, fieldState, formState }) => {
                  return (
                    <Form.File
                      fileType="image"
                      id="mid_project_photo"
                      onChange={(e) => onChange(e.target.files)}
                      placeholder="Enter final measurement"
                      previewImage={planBriefItem?.mid_project_photo}
                    />
                  );
                }}
              ></Controller>
              {errors && errors["mid_project_photo"] && errors["mid_project_photo"].message && (
                <Form.HelperText>{errors["mid_project_photo"].message}</Form.HelperText>
              )}
            </Flex.Col>
          </Grid.Col>{" "}
          <Grid.Col spanMd={6} spanXs={12}>
            <Flex.Col>
              <Form.Label> {keyword["Completed Project Photo"]}</Form.Label>

              <Controller
                name="completed_photo"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field: { onChange, value }, fieldState, formState }) => {
                  return (
                    <Form.File
                      fileType="image"
                      id="completed_photo"
                      onChange={(e) => onChange(e.target.files)}
                      placeholder="Enter completed photo"
                      previewImage={planBriefItem?.completed_photo}
                    />
                  );
                }}
              ></Controller>
            </Flex.Col>
          </Grid.Col>
          <Grid.Col spanMd={12} spanXs={12}>
            <ReuseForm.TextArea
              label={keyword.Description}
              name="description"
              isDisable={!props.isAddable}
              placeholder="Write plan description"
              rules={{ required: keyword["This is required field."] }}
              error={errors["description"]}
            />
          </Grid.Col>
          {props.isAddable && (
            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword["Update Plan Brief"] : "Create Plan Brief"}
              </Button>

              <Button onClick={handleCancel}>{keyword.Cancel}</Button>
            </Grid.Col>
          )}
        </Grid.Row>
      </Grid>
    </ReuseForm>
  );
}

export default PlanBriefForm;
