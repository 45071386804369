import Form from "@ims/common/src/core-ui/forms/Form";
import { createContext, HtmlHTMLAttributes, TableHTMLAttributes, useContext, useState } from "react";
import { Controller } from "react-hook-form";

export const FormContext = createContext<{
  control: any;
  setControl: any;
}>({
  control: null,
  setControl: () => {},
});

export interface ReuseFormPropsI extends HtmlHTMLAttributes<HTMLFormElement> {
  control: any;
}

export default function ReuseForm(props: ReuseFormPropsI) {
  const [control, setControl] = useState(props.control);

  if (!!control) {
    return (
      <FormContext.Provider value={{ control, setControl }}>
        <Form {...props} />
      </FormContext.Provider>
    );
  }

  return <>Loading...</>;
}

export interface ReuseFormTrPropsI extends TableHTMLAttributes<HTMLTableRowElement> {
  control: any;
  children: any;
}

ReuseForm.ReuseFormTr = function ReuseFormTr({ control, ...props }: ReuseFormTrPropsI) {
  return (
    <FormContext.Provider value={{ control: control, setControl: () => {} }}>
      <tr {...props} />
    </FormContext.Provider>
  );
};

export interface ReuseInputPropsI {
  label?: string | null;
  name: string;
  error?: any;
  rules?: any;
  [x: string]: any;
  showRequired?: boolean;
  h?: string;
  isDisable?: boolean;
}

ReuseForm.Input = function ReuseInput(props: ReuseInputPropsI) {
  const { name, h, label, error, showRequired, style, isDisable, rules, ...textProps } = props;
  const { control } = useContext(FormContext);

  if (!control) return <></>;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Form.FormGroup
          error={Boolean(error)}
          style={props.type === "checkbox" ? { display: "inline-block" } : { display: "flex" }}
        >
          {label && (
            <Form.Label>
              {label} {showRequired && "*"}
            </Form.Label>
          )}
          <Form.Input
            {...textProps}
            onChange={onChange}
            value={!value ? "" : value}
            ref={ref}
            checked={value}
            onBlur={onBlur}
            height={h}
            isDisable={isDisable}
          />
          {error && error["message"] && <Form.HelperText>{error["message"]}</Form.HelperText>}
        </Form.FormGroup>
      )}
    />
  );
};

export interface ReuseTextAreaPropsI {
  label: string;
  name: string;
  error?: any;
  rules?: any;
  [x: string]: any;
  isDisable?: boolean;
}

ReuseForm.TextArea = function ReuseTextArea(props: ReuseTextAreaPropsI) {
  const { name, label, error, isDisable, rules, ...textProps } = props;
  const { control } = useContext(FormContext);

  if (!control) return <></>;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Form.FormGroup error={Boolean(error)}>
          {label && <Form.Label>{label}</Form.Label>}
          <Form.TextArea
            {...textProps}
            onChange={onChange}
            isDisable={isDisable}
            value={!value ? "" : value}
            ref={ref}
            onBlur={onBlur}
          />
          {error && error["message"] && <Form.HelperText>{error["message"]}</Form.HelperText>}
        </Form.FormGroup>
      )}
    />
  );
};

export interface ReuseReactSelectPropsI extends ReactSelectPropsI {
  label?: string;
  name: string;
  error?: any;
  rules?: any;
  options: any;
  [x: string]: any;
  showRequired?: boolean;
}

ReuseForm.ReactSelect = function ReuseReactSelect(props: ReuseReactSelectPropsI) {
  const { name, label, error, showRequired, readonly, rules, options, ...otherProps } = props;
  const { control } = useContext(FormContext);

  if (!control) return <></>;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Form.FormGroup error={Boolean(error)}>
          {label && (
            <Form.Label>
              {label} {showRequired && "*"}
            </Form.Label>
          )}
          <Form.ReactSelect options={options} value={value} onChange={onChange} onBlur={onBlur} {...otherProps} />
          {error && error["message"] && <Form.HelperText>{error["message"]}</Form.HelperText>}
        </Form.FormGroup>
      )}
    />
  );
};

export interface ReuseAsyncSelectPropsI {
  label?: string;
  name: string;
  error?: any;
  rules?: any;
  options?: any;
  [x: string]: any;
}

ReuseForm.AsyncSelect = function ReuseAsyncSelect(props: ReuseAsyncSelectPropsI) {
  const { name, label, error, rules, ...otherProps } = props;
  const { control } = useContext(FormContext);

  if (!control) return <></>;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Form.FormGroup error={Boolean(error)}>
          {label && <Form.Label>{label}</Form.Label>}
          <Form.AsyncSelect {...otherProps} value={value} onChange={onChange} onBlur={onBlur} />
          {error && error["message"] && <Form.HelperText>{error["message"]}</Form.HelperText>}
        </Form.FormGroup>
      )}
    />
  );
};

export interface ReactSelectPropsI {
  label?: string;
  name: string;
  error?: any;
  rules?: any;
  children?: any;
  style?: any;
  [x: string]: any;
}

ReuseForm.Select = function ReuseSelect(props: ReactSelectPropsI) {
  const { name, label, error, rules, children, ...otherProps } = props;
  const { control } = useContext(FormContext);
  if (!control) return <></>;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Form.FormGroup error={Boolean(error)}>
          {label && <Form.Label>{label}</Form.Label>}
          <Form.Select onChange={onChange} onBlur={onBlur} value={value} {...otherProps}>
            {children}
          </Form.Select>
          {error && error["message"] && <Form.HelperText>{error["message"]}</Form.HelperText>}
        </Form.FormGroup>
      )}
    />
  );
};
