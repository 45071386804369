import React, { useEffect, useMemo, useState } from "react";
import DropzoneDemo, { useDropzone } from "react-dropzone";

const baseStyle: any = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle: any = {
  borderColor: "#2196f3",
};

const acceptStyle: any = {
  borderColor: "#00e676",
};

const rejectStyle: any = {
  borderColor: "#ff1744",
};

const thumbsContainer: any = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb: any = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner: any = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img: any = {
  display: "block",
  width: "auto",
  height: "100%",
};

interface DropzonePropsI {
  value: FileList | null;
  onChange: (files: any) => void;
}

export default function Dropzone(props: DropzonePropsI) {
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    if (props.value) setFiles(props.value);
  }, []);

  useEffect(() => {
    props.onChange(files);
  }, [files]);

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    // accept: { "image/*": [] },
    accept: { "application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [] },
    maxFiles: 5,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview:
              // file.type === "image/png"
              ["image/png", "image/jpeg"].includes(file.type)
                ? URL.createObjectURL(file)
                : "https://e7.pngegg.com/pngimages/67/491/png-clipart-google-docs-computer-icons-document-android-google-template-blue-thumbnail.png",
          });
        })
      );
    },
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  // const files = acceptedFiles.map((file: any) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt="Preview"
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>

      <aside style={thumbsContainer}>{thumbs}</aside>
    </div>
  );
}
