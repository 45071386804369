import React, { useEffect, useState } from "react";
import Table, { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { FiEdit, FiFilter, FiSearch } from "react-icons/fi";
import { showLoading } from "../../../rtk/feature/auth/authSlice";
import usePagination from "../../../utils/usePagination";
import { Button, Flex, Form, Grid, MainLayout } from "@ims/common";
import Page from "../../../core-ui/Page";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FixedAnalyzeRateTypeI } from "../../../rtk/feature/fixed-analyze-rate/fixedAnalyzeRateSlice";
import { getFixedAnalyzeRates } from "../../../rtk/feature/fixed-analyze-rate/fixedAnalyzeRateApi";
import { TbPencilPlus } from "react-icons/tb";
import { newItem, StyledContainerDrawer } from "../../plan/analyze-rate/RateAnalysisTable";
import { TypeChoice } from "../../../utils/constants";
import { toast } from "react-toastify";
import { getFixedRateEquipments } from "../../../rtk/feature/fixed-rate-equipment/fixedRateEquipmentApi";
import RateSubTable, { formatter } from "./RateSubTable";
import RateIntro from "./RateIntro";
import { BasicTable, SimpleTable } from "../../plan/PlanSearch";
import ReuseForm from "../../../core-ui/ReuseForm";
import { FixedRateEquipmentFormTypeI } from "../../../rtk/feature/fixed-rate-equipment/fixedRateEquipmentSlice";
import { getAnalyzeRates } from "../../../rtk/feature/analyze-rate/analyzeRateApi";
import { useForm } from "react-hook-form";
import { AnalyzeRateTypeI } from "../../../rtk/feature/analyze-rate/analyzeRateSlice";
import DisctrictRate from "../../DistrictRate/DisctrictRate";
import useTranslated, { keywordTypes } from "../../../hooks/useTranslated";
import { BsPrinter } from "react-icons/bs";
import TableUtility from "../../user/TableUtility";
import { englishNepaliNumberTranslation } from "../../../utils/translateNumber";
import getFormattedSort from "../../../utils/getFormattedSort";
import { realTimeFilter } from "../../../utils/real-time-filter";

interface FixedRateAnalysisTablePropsI {
  budgetID: string | null;
}

export interface TotalAnalyzeRateTypeI {
  is_vat_percent: string;
  vat_amount: number;
}

const rateTableStyle = `
    <style>
  table * {
    color: black;
    background: transparent!important;
  }

  table {
    width: 100%;
    text-align: left;
    border: 1px solid #000;
    border-width: 1px 1px 1px 1px !important;
    border-collapse: collapse;
    font-size: 14px;
  }

  th, td {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
    width:17rem;
    color:#000 !important;
  }
   


   th:nth-child(2), td:nth-child(2) {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
    width:17rem;
  }

    th:nth-child(1), td:nth-child(1) {
    border: 1px solid #000;
    border-width: 0 1px 1px 0 !important;
    padding: 0.5rem;
    width:1rem;
  }



  td *{
    display: inline-block;
    margin-right: 5px;
  }
  
  
  td img {
    width: 40px;
  }

 
  button, svg{
    display: none!important;
  }
  table tr td:last-child,
  table tr th:last-child {
    display: inline-block;

  }
  .seperate{
    border:none;
    margin-top:1rem;
    float:right;
    width:40rem;
    word-break:no-break;
  }

  .print-visible-input {
  display: inline-block !important;
  outline:none;
  border : none;
  font-size:1rem;          
}

        

  @media print{    
    .no-print, .no-print *{
      display: none !important;
    }

    
  }

</style>


  `;

function FixedRateAnalysisTable(props: FixedRateAnalysisTablePropsI) {
  const [fixedAnalyzeRates, setFixedAnalyzeRates] = useState<FixedAnalyzeRateTypeI[]>([]);
  const [sorts, setSorts] = useState<SortsTypeI>({});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fixedAnalyzeRatesState = useAppSelector((state) => state.fixedAnalyzeRates);

  /* Table pagination states */
  const [totalItems, setTotalItems] = useState(1);

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getFixedAnalyzeRates({
        per_page: perPage,
        page: page,
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, perPage, page]);

  useEffect(() => {
    dispatch(showLoading(fixedAnalyzeRatesState.loading));
  }, [dispatch, fixedAnalyzeRatesState.loading]);

  useEffect(() => {
    if (fixedAnalyzeRatesState.data) {
      setFixedAnalyzeRates(fixedAnalyzeRatesState.data.results);
      setTotalItems(fixedAnalyzeRatesState.data.total);
    }
  }, [fixedAnalyzeRatesState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/fixed-rate-analysis/edit/${id}`);
  };

  const { budgetID } = props;

  const { control, getValues, setValue, watch } = useForm<TotalAnalyzeRateTypeI>();
  const {
    handleSubmit: handleFilterSubmit,
    control: filterControl,
    register: filterRegister,
    formState: { errors: filterErrors },
    reset: filterReset,
    watch: filterWatch,
  } = useForm();

  // const [analyzeRates, setAnalyzeRates] = useState<AnalyzeRateTypeI[]>([]);

  // const analyzeRatesState = useAppSelector((state) => state.analyzeRates);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rateID, setRateID] = useState<null | string>(null);
  const [fixedRateEquipments, setFixedRateEquipments] = useState<FixedRateEquipmentFormTypeI[]>([]);
  const [rateManpowers, setRateManpowers] = useState<FixedRateEquipmentFormTypeI[]>([]);
  const [rateMachines, setRateMachines] = useState<FixedRateEquipmentFormTypeI[]>([]);
  const [subTotal, setSubTotal] = useState(0);
  const [grandTotalAmt, setGrandTotalAmt] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);

  const fixedRateEquipmentSelector = useAppSelector((state) => state.fixedRateEquipments);

  const individualTotal = fixedRateEquipmentSelector.individual_total;
  const totalAmount = fixedRateEquipmentSelector.total_amount;

  // useEffect(() => {
  //   if (budgetID) dispatch(getAnalyzeRates({ budgetID, perPage, page }));
  // }, [dispatch, perPage, page, budgetID]);

  useEffect(() => {
    const el = document.getElementById("drawer");
    if (!el) return;

    const handleClickAwayListener = (e: any) => {
      const parentNode = document.getElementById("content-drawer");
      if (!parentNode || !e.target) return;

      if (e.target !== parentNode && !parentNode.contains(e.target)) {
        setOpenDrawer(false);
        setRateID(null);
      }
    };

    el.addEventListener("click", handleClickAwayListener);
    return () => el.removeEventListener("click", handleClickAwayListener);
  }, []);

  useEffect(() => {
    setFixedRateEquipments([newItem]);
    setRateManpowers([newItem]);
    setRateMachines([newItem]);
  }, []);

  const calculateTotal = (tableData: FixedRateEquipmentFormTypeI[]) => {
    const total = tableData.reduce((prev, curr) => {
      if (curr.unit_rate) prev = prev + curr.quantity * curr.unit_rate;
      return prev;
    }, 0);
    return total;
  };

  const is_vat_percent = JSON.parse(watch("is_vat_percent") || "false");
  let vat_amount = watch("vat_amount") || 0;
  vat_amount = +vat_amount;

  useEffect(() => {
    const equipmentTotal = calculateTotal(fixedRateEquipments);
    const manpowerTotal = calculateTotal(rateManpowers);
    const machineTotal = calculateTotal(rateMachines);
    const total = equipmentTotal + manpowerTotal + machineTotal;

    let tempVat: number;

    if (is_vat_percent) {
      tempVat = (+vat_amount * +total) / 100;
    } else {
      tempVat = +vat_amount;
    }

    setVatAmt(+tempVat);
    setSubTotal(total);
    setGrandTotalAmt(+total + vat_amount);
  }, [fixedRateEquipments, rateManpowers, rateMachines, is_vat_percent, vat_amount]);

  // useEffect(() => {
  //   dispatch(showLoading(analyzeRatesState.loading));
  // }, [dispatch, analyzeRatesState.loading]);

  // useEffect(() => {
  //   if (analyzeRatesState.data) {
  //     setAnalyzeRates(analyzeRatesState.data.results);
  //     setTotalItems(analyzeRatesState.data.total);
  //   }
  // }, [analyzeRatesState, dispatch]);

  useEffect(() => {
    const { data: fixedRateEquipmentList } = fixedRateEquipmentSelector;
    let results: any;

    if (!fixedRateEquipmentList) return;

    results = fixedRateEquipmentList.reduce((total: any, current) => {
      const type = current.equipment.type;
      if (!type) return total;

      if (total[type]) {
        total[type] = [...total[type], current];
      } else {
        total[type] = [current];
      }
      return total;
    }, {});

    const resultManpower = results[TypeChoice[0]];
    const resultEquipment = results[TypeChoice[1]];

    const resultMachine = results[TypeChoice[2]];

    if (resultEquipment) {
      resultEquipment.push(newItem);
      setFixedRateEquipments(resultEquipment);
    } else {
      setFixedRateEquipments([newItem]);
    }

    if (resultManpower) {
      resultManpower.push(newItem);
      setRateManpowers(resultManpower);
    } else {
      setRateManpowers([newItem]);
    }

    if (resultMachine) {
      resultMachine.push(newItem);
      setRateMachines(resultMachine);
    } else {
      setRateMachines([newItem]);
    }
  }, [fixedRateEquipmentSelector, rateID]);

  const handleAnalysisShow = async (id: string) => {
    if (!id) return toast.warning(keyword["No budget ID or rate ID"]);
    setRateID(id);
    await dispatch(getFixedRateEquipments({ rateID: id }));
    setOpenDrawer(true);
  };

  const vatAmount = 0.13 * subTotal; // 13% VAT on total
  const grandTotal = subTotal + vatAmount;
  const { keyword } = useTranslated();

  // const tableUtility = new TableUtility();
  const tableUtility = new TableUtility("export-table", rateTableStyle);

  const onFilterSubmit = (data: any) => {
    dispatch(
      getFixedAnalyzeRates({
        per_page: perPage,
        search: data.search,
        ordering: getFormattedSort(sorts),
      })
    );
  };

  const handleFilterReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getFixedAnalyzeRates({
        per_page: perPage,
        page: page,
        ordering: getFormattedSort(sorts),
      })
    );
    filterReset();
  };

  return (
    <Flex.Col>
      <MainLayout.Main>
        <Page.Container>
          <Page.Title>{keyword["Fixed Rate Analysis"]}</Page.Title>
          <Page.ButtonGrp>
            <Button
              startIcon={<AiOutlinePlus />}
              variant="primary"
              onClick={() => navigate(`/fixed-rate-analysis/add`)}
            >
              {keyword["Fixed Rate Analysis"]}
            </Button>
          </Page.ButtonGrp>
        </Page.Container>

        <ReuseForm onSubmit={handleFilterSubmit(onFilterSubmit)} control={filterControl}>
          <Grid style={{ marginBottom: "1rem" }}>
            <Grid.Row gap={0.75}>
              <Grid.Col spanMd={4}>
                <ReuseForm.Input
                  label={keyword["Search"]}
                  name="search"
                  placeholder={keyword["Search here"]}
                  rules={{ required: false }}
                  register={filterRegister("search")}
                  error={filterErrors["search"]}
                  h="34px"
                />
              </Grid.Col>

              <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
                <Button variant="primary">
                  {" "}
                  <FiSearch />
                </Button>

                <Button type="reset" onClick={handleFilterReset}>
                  {keyword["Reset"]}
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </ReuseForm>

        <Table.Container>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadCol> {keyword["S.N."]} </Table.HeadCol>
                <Table.HeadCol> {keyword.Name} </Table.HeadCol>
                <Table.HeadCol> {keyword.Code} </Table.HeadCol>
                <Table.HeadCol>{keyword.Description}</Table.HeadCol>
                <Table.HeadCol align="center"> {keyword.Action} </Table.HeadCol>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {fixedAnalyzeRates &&
                fixedAnalyzeRates.length > 0 &&
                fixedAnalyzeRates.map((analyzeRate, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Col>{englishNepaliNumberTranslation(getSN(index))}</Table.Col>
                      <Table.Col>{analyzeRate.english_name}</Table.Col>
                      <Table.Col>{analyzeRate.code}</Table.Col>

                      <Table.Col>{analyzeRate.english_description}</Table.Col>
                      <Table.Col align="center">
                        <Table.ActionButton onClick={() => handleEdit(analyzeRate.id)}>
                          <FiEdit />
                        </Table.ActionButton>

                        <Table.ActionButton color="green" onClick={() => handleAnalysisShow(analyzeRate.id)}>
                          <TbPencilPlus />
                        </Table.ActionButton>
                      </Table.Col>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          <Pagination />
        </Table.Container>

        <StyledContainerDrawer open={openDrawer} id="drawer">
          <div id="content-drawer">
            <Button startIcon={<BsPrinter />} onClick={tableUtility.print} style={{ margin: "1rem" }}>
              {keyword.Print}
            </Button>{" "}
            <Button
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              {keyword.Close}
            </Button>
            <div className="container" id="export-table">
              <RateIntro sub_total_after_cp={totalAmount?.sub_total_after_cp} rateID={rateID} />
              <br />
              <h4>{keyword["Material"]}</h4>
              <RateSubTable
                budgetID={budgetID}
                tableData={fixedRateEquipments}
                setTableData={setFixedRateEquipments}
                total={individualTotal.material}
                rateID={rateID}
                type={TypeChoice[1]}
              />
              <br />
              <h4>{keyword["Manpower"]}</h4>
              <RateSubTable
                budgetID={budgetID}
                tableData={rateManpowers}
                setTableData={setRateManpowers}
                rateID={rateID}
                total={individualTotal.human_resource}
                type={TypeChoice[0]}
              />
              <br />
              <h4>{keyword["Machines"]}</h4>
              <RateSubTable
                budgetID={budgetID}
                tableData={rateMachines}
                setTableData={setRateMachines}
                rateID={rateID}
                total={individualTotal.machine}
                type={TypeChoice[2]}
              />
              <br />
              <ReuseForm control={control}>
                <SimpleTable className="seperate">
                  <tbody>
                    <TableRow heading="Sub Total" value={totalAmount ? totalAmount.sub_total : 0} />
                    <TableRow heading="Contractor Profit" value={totalAmount ? totalAmount.contractor_profit : 0} />
                    <TableRow heading="VAT Amount" value={totalAmount ? totalAmount.vat_amt : 0} />
                    <TableRow heading="Sub total after CP" value={totalAmount ? totalAmount.sub_total_after_cp : 0} />
                    <TableRow heading="Grand Total (After VAT)" value={totalAmount ? totalAmount.grand_total : 0} />

                    {/* <tr>
                      <td width="80%" align="right">
                        <ReuseForm.Select
                          disabled
                          name="is_vat_percent"
                          style={{ marginLeft: "auto", width: "150px" }}
                          defaultValue="true"
                        >
                          <Form.Option value="false">VAT Amount</Form.Option>
                          <Form.Option value="true">VAT Percent</Form.Option>
                        </ReuseForm.Select>
                      </td>
                      <td width="110px">
                        <ReuseForm.Input type="number" name="vat_amount" placeholder="Rs." disabled />
                      </td>
                    </tr> */}

                    {/* {is_vat_percent && (
                      <tr>
                        <td width="80%" align="right">
                          VAT Amount
                        </td>
                        <td width="110px">{formatter.format(vatAmt)}</td>
                      </tr>
                    )}
                    <tr>
                      <td width="80%" align="right">
                        Grand Total
                      </td>
                      <td width="120px">{formatter.format(grandTotalAmt)}</td>
                    </tr> */}
                  </tbody>
                </SimpleTable>
              </ReuseForm>
            </div>
          </div>
        </StyledContainerDrawer>
      </MainLayout.Main>
    </Flex.Col>
  );
}

export default FixedRateAnalysisTable;

const TableRow = ({ heading, value }: { heading: keyof keywordTypes; value: number }) => {
  const { keyword } = useTranslated();
  return (
    <tr>
      <td align="right" style={totalHeadStyle}>
        <strong> {keyword[heading]}</strong>
      </td>
      <td width="110px" style={totalValueStyle}>
        {formatter.format(value)}
      </td>
    </tr>
  );
};

const totalHeadStyle = {
  color: "#002744",
};

const totalValueStyle = {
  color: "#444",
};
