import axios from "axios";
import { logout, verifyToken } from "../rtk/feature/auth/authApi";

let store: any;
export const injectStore = (_store: any) => {
  store = _store;
};

const BASE_URL = process.env.REACT_APP_API_URL;

export const imsSimpleClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

const TOKEN = localStorage.getItem("access");

export const imsClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
});

export const getAxiosInterceptors = async () => {
  // Response interceptor to refresh token on receiving token expired error
  imsClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: any) => {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem("refresh");

      if (
        refreshToken &&
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        try {
          const res = await imsSimpleClient.post(`/token/refresh/`, {
            refresh: refreshToken,
          });

          if (res?.status === 200) {
            localStorage.setItem("access", res.data.access);
            handleAccountVerification();
            return imsClient(originalRequest);
          }
        } catch (err: any) {
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            store.dispatch(logout());
            return Promise.reject(error);
          }
        }
      }
      return Promise.reject(error);
    }
  );

  // Add token on every request
  imsClient.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("access");
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  await handleAccountVerification();
};

export const handleAccountVerification = async () => {
  const access = localStorage.getItem("access");

  if (!access) return;
  await store.dispatch(verifyToken());
};
