import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import Tab from "../../../components/tab/Tab";
import styled from "styled-components";
import ReuseForm from "../../../core-ui/ReuseForm";
import { useForm } from "react-hook-form";
import { Table } from "@ims/common";
import { FiSave, FiTrash } from "react-icons/fi";
import { toast } from "react-toastify";
import { EquipmentTypeI } from "../../../rtk/feature/equipment/equipmentSlice";
import { RateEquipmentFormTypeI } from "../../../rtk/feature/rate-equipment/rateEquipmentSlice";
import {
  addFixedRateEquipment,
  deleteFixedRateEquipment,
  updateFixedRateEquipment,
} from "../../../rtk/feature/fixed-rate-equipment/fixedRateEquipmentApi";
import { getDistrictRate } from "../../../rtk/feature/district-rate/districtRateApi";
import useTranslated from "../../../hooks/useTranslated";

interface AnalyzeRateViewPropsI {
  value?: RateEquipmentFormTypeI;
  onChange: (data: RateEquipmentFormTypeI) => void;
  onDelete: (id: any) => void;
  onAdd: () => void;
  index: number;
  total: number;
  budgetID: string | null;
  rateID: string | null;
  type: string;
}

function RateItem(props: AnalyzeRateViewPropsI) {
  useEffect(() => {
    dispatch(getDistrictRate({ params: { per_page: 10000 } }));
  }, []);

  const dispatch = useAppDispatch();
  const { onChange, value: itemValue, budgetID, rateID, onDelete, type } = props;

  const {
    formState: { errors, isDirty },
    control,
    getValues,
    watch,
    setValue,

    reset,
  } = useForm<RateEquipmentFormTypeI>({
    defaultValues: {},
  });

  const fixedRateEquipmentSelector = useAppSelector((state) => state.fixedRateEquipments);
  const [equipments, setEquipments] = useState<EquipmentTypeI[]>([]);

  const { loading, data } = fixedRateEquipmentSelector;

  const equipmentIdField = watch("equipment");
  const quantityField = watch("quantity");
  const dropDownValue = watch("equipment");
  const { keyword } = useTranslated();

  const districtRate = useAppSelector((state) => state.districtRate.data?.results);
  // const currentEquipment=dropDownValue.

  const districtRateOptions = districtRate
    ?.filter((el) => el.type === props.type)
    .map((rate) => {
      return {
        label: rate.particular,
        value: rate.id,
        unit_rate: rate.analysis_rate,
        id: rate.id,
      };
    });
  useEffect(() => {
    if (!itemValue || !districtRate) return;

    if (itemValue.equipment?.id && districtRateOptions) {
      const actualRate = itemValue.equipment;

      setValue("equipment", {
        label: itemValue.equipment.english_name,
        value: itemValue.equipment.id,
        unit_rate: actualRate?.analysis_rate,
      });
    }
  }, [setValue, dispatch, itemValue, rateID, districtRateOptions]);

  useEffect(() => {
    if (loading) return;

    if (props.value?.id) setValue("id", props.value.id);
    if (props.value?.quantity) setValue("quantity", +props.value.quantity);
    if (dropDownValue) setValue("unit_rate", dropDownValue.unit_rate);
    if (props.value?._is_latest) setValue("_is_latest", props.value._is_latest);
  }, [loading, setValue, dropDownValue]);

  useEffect(() => {
    watch((value, { name, type }) => {
      const finalData = { ...itemValue, ...getValues() };
      const equipmentId = getValues("equipment");
      if (equipmentId?.value) {
        finalData.equipment = equipmentId?.value;
      }
      onChange(finalData);
    });
  }, [watch, getValues, onChange, itemValue]);

  const handleDelete = (id: string | undefined) => {
    if (!budgetID || !id || !rateID) return toast.warning("Budget ID or Rate Equipment ID is not valid.");
    dispatch(deleteFixedRateEquipment({ deleteID: id, id: rateID }));
  };

  const equipmentValue = getValues("equipment");

  useEffect(() => {
    // setValue("unit_rate", equipmentValue?.unit_rate);
    if (equipmentIdField && quantityField) {
      const result = +quantityField * +equipmentValue?.unit_rate;
      setValue("total_amount", +result.toFixed(2));
    }
  }, [equipmentValue, getValues, quantityField, setValue]);
  const handleSubmit = (id: string | undefined) => {
    const fields = getValues();
    if (!budgetID || !rateID || !fields.equipment || !id)
      return toast.warning("Budget ID or Rate Equipment ID is not valid.");

    if (fields.equipment && fields.equipment.value) {
      fields.equipment = fields.equipment.value;
    }

    if (!fields.equipment) return toast.warning("no equipment selected");

    const formData = fields;
    formData["planning_rate_analysis"] = rateID;
    setValue("_is_latest", fields._is_latest);

    if (formData._is_latest) {
      props.onDelete(id);

      dispatch(addFixedRateEquipment({ data: formData, id: rateID }));
    } else {
      setValue("_is_latest", false);
      dispatch(
        updateFixedRateEquipment({
          data: formData,
          id: rateID,
          updateID: id,
        })
      );
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit(props?.value?.id);
    }
  };

  return (
    <ReuseForm.ReuseFormTr
      control={control}
      onKeyDown={handleEnter}
      className={props.value?._is_latest ? "no-print" : ""}
    >
      <td>{props.index < 10 ? "0" + props.index : props.index}</td>
      <td>
        <ReuseForm.ReactSelect
          name="equipment"
          placeholder={keyword["Select"] + "..."}
          cacheOptions
          options={districtRateOptions}
          Clearable
          defaultOptions
        />
      </td>
      <td>
        <ReuseForm.Input
          type="number"
          name="quantity"
          placeholder={keyword["Quantity"]}
          className="print-visible-input"
        />
      </td>
      <td>
        <ReuseForm.Input
          type="number"
          name="unit_rate"
          placeholder={keyword.Rs + "."}
          className="print-visible-input"
          readOnly
          disabled
        />
      </td>
      <td>
        <ReuseForm.Input
          type="number"
          name="total_amount"
          placeholder={keyword.Rs + "."}
          className="print-visible-input"
          readOnly
          disabled
        />
      </td>
      <td align="center" className="no-print">
        <Table.ActionButton
          color="green"
          type="button"
          onClick={() => handleSubmit(props?.value?.id)}
          disabled={!isDirty}
        >
          <FiSave />
        </Table.ActionButton>

        <Table.ActionButton
          color="red"
          disabled={props?.value?._is_latest}
          onClick={() => {
            // props.onDelete(props?.value?.id);
            handleDelete(props?.value?.id);
          }}
        >
          <FiTrash />
        </Table.ActionButton>
      </td>
    </ReuseForm.ReuseFormTr>
  );
}

export default RateItem;

export const StyledTabHeader = styled(Tab.TabHeader)`
  display: flex;
  & > button {
    flex: 1;
  }
`;
