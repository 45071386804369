import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { theme, GlobalStyles } from "@ims/common";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import store from "./rtk/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { injectStore } from "./utils/imsClient";
import "./utils/i18n";

/*
 * @store main store of the redux
 * Making store available to plain js file.
 */

injectStore(store);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.Fragment>
    <React.StrictMode>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />

          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  </React.Fragment>
);
