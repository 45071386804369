import { createSlice } from "@reduxjs/toolkit";
import { INetworkRResponse, IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { IYojana } from "../plan-verification/planVerificationSlice";
import { getBillplanning, getBillPlanningById, updateBillPlanning } from "./planningBillAPI";

export interface IPlanningBillData {
    bill_file: string;
    bill_name: string;
    bill_review: boolean;
    id: string;
    yojana: IYojana
}
interface IPlanningBillSliceById extends INetworkRResponse {
    data: IPlanningBillData | null;
}

interface IPlanningBillSlice extends INetworkRResponse {
    data: IPaginatedResponse<IPlanningBillData> | null;
    formSubmitted: boolean;
    byId: IPlanningBillSliceById;
}



const initialState: IPlanningBillSlice = {
    data: null,
    error: null,
    loading: false,
    formSubmitted: false,
    byId: {
        data: null,
        error: null,
        loading: false,
    },
};
const planniingBill = createSlice({
    name: "planniingBill",
    initialState,
    reducers: {
        cleanupVerificationSlice: (state) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder.addCase(getBillplanning.pending, (state) => {
            state.data = null;
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getBillplanning.rejected, (state) => {
            state.data = null;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getBillplanning.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.loading = false;
            state.error = null;
        });


        //update

        builder.addCase(updateBillPlanning.pending, (state) => {

            state.formSubmitted = false

        });
        builder.addCase(updateBillPlanning.fulfilled, (state) => {
            state.formSubmitted = false

        })



        //by id

        builder.addCase(getBillPlanningById.pending, (state) => {
            state.byId.data = null;
            state.byId.loading = true;
            state.byId.error = null;
        });
        builder.addCase(getBillPlanningById.rejected, (state) => {
            state.byId.data = null;
            state.byId.loading = false;
            state.byId.error = null;
        });
        builder.addCase(getBillPlanningById.fulfilled, (state, { payload }) => {
            state.byId.data = payload;
            state.byId.loading = false;
            state.byId.error = null;
        });


    },
});
export const { cleanupVerificationSlice } = planniingBill.actions;
export default planniingBill.reducer;
