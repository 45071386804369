import React, { createContext, HTMLAttributes, HtmlHTMLAttributes, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { StyledContainer } from "../profile/Profile.style";
import { StyledTab, StyledTabBody, StyledTabHeadButton, StyledTabHeader, StyledTabPane } from "./Tab.style";

export const TabContext = createContext<{
  currentTab: string;
  setCurrentTab: Function;
}>({
  currentTab: "basic",
  setCurrentTab: () => { },
});

export interface TabTabPropsI extends HtmlHTMLAttributes<HTMLDivElement> { }

export default function Tab(props: TabTabPropsI) {
  const [currentTab, setCurrentTab] = useState("basic");

  return (
    <TabContext.Provider value={{ currentTab, setCurrentTab }}>
      <StyledTab {...props} />
    </TabContext.Provider>
  );
}

export interface TabTabHeaderPropsI extends HtmlHTMLAttributes<HTMLDivElement> { }

Tab.TabHeader = function TabTabHeaderFunction(props: TabTabHeaderPropsI) {
  return <StyledTabHeader {...props} />;
};

export interface TabTabHeadButtonPropsI extends HtmlHTMLAttributes<HTMLButtonElement> {
  id: string;
  disabled?: boolean;
  active?: boolean;
}

Tab.TabHeadButton = function TabTabHeadButtonFunction(props: TabTabHeadButtonPropsI) {
  const { currentTab, setCurrentTab } = useContext(TabContext);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (props.active) {
      setCurrentTab(props.id);
    }
  }, [props.id, props.active, setCurrentTab]);
  return (
    <StyledTabHeadButton
      className={currentTab === props.id ? "active" : ""}
      onClick={() => {
        if (!props.disabled) {
          setCurrentTab(props.id);
          setSearchParams((prevParams: any) => {
            return new URLSearchParams({
              ...Object.fromEntries(prevParams.entries()),
              ...{ tab: props.id },
            });
          });
        }
      }}
      {...props}
    />
  );
};

export interface TabTabBodyPropsI extends HtmlHTMLAttributes<HTMLDivElement> { }

Tab.TabBody = function TabTabBodyFunction(props: TabTabBodyPropsI) {
  return <StyledTabBody {...props} />;
};

export interface TabTabPanePropsI extends HtmlHTMLAttributes<HTMLDivElement> {
  targetId: string;
}

Tab.TabPane = function TabTabPaneFunction(props: TabTabPanePropsI) {
  const { currentTab } = useContext(TabContext);
  return currentTab === props.targetId ? <StyledTabPane {...props} /> : <React.Fragment />;
};
