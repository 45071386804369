import styled from "styled-components/macro";
import {
  MainNavDropNavItem,
  MainNavNavItem,
  MainNavRoot,
  StyledBrandContainer,
} from "../nav/MainNav.style";

export const MainLayoutRoot = styled.div`
  display: flex;
`;

export const MainLayoutMain = styled.main`
  padding: 2rem;
  margin: 2rem 1.5rem;
  background: ${(props) => props.theme.palette.background.default};
  box-shadow: rgba(41, 48, 66, 0.05) 0px 0px 0.875rem 0px;
  border-radius: 0.4rem;
`;

export const MainLayoutHeader = styled.header`
  height: auto;
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  background: white;
  box-shadow: 0 0 2rem 0 rgb(41 48 66 / 10%);
`;

export const MainLayoutSection = styled.section`
  flex: 1;
  width: calc(100% - 236px);
`;
export const MainLayoutAside = styled.aside<{ overlay: boolean }>`
  width: 236px;
  height: 100vh;
  overflow-y: auto;
  background: ${(props) => props.theme.palette.background.default};
  transition: 0.15s width cubic-bezier(0.4, 0, 1, 1);
  z-index: 9999;

  li a span {
    margin-right: auto;
  }

  &.expanded {
    width: 45px;
    z-index: 9999;

    ${MainNavRoot} {
      &::before {
        display: none;
      }
    }

    .sub-item {
      ${MainNavNavItem}, ${MainNavDropNavItem} {
        padding: 0.75rem 1rem;
      }
    }

    & ${MainNavNavItem} {
      &.active,
      &:hover {
        &::after {
          display: none;
        }
      }
    }

    & ${MainNavNavItem}, & ${MainNavDropNavItem} {
      padding: 1.2rem;

      & > svg {
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
      }
    }
  }

  &.expanded > div {
    overflow: visible;
    width: 45px;

    li a span {
      display: none;
    }

    li a .icon-drop {
      position: absolute;
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      color: inherit;
      top: 50%;
      transform: rotate(-90deg) translateX(0.58rem);
      right: 2px;
    }

    li.sub-item {
      display: none;
    }

    ${StyledBrandContainer} {
      padding: 0px;

      & img {
        width: 100%;
        height: 53px;
        padding: 0.5rem;
        object-fit: contain;
        background: rgba(0, 0, 0, 0.1);
      }

      & .wrapper-brand-title {
        display: none;
      }
    }
  }

  @media screen and (max-width: 920px) {
    & > div {
      position: fixed;
      top: 0;
      left: 0;
      box-shadow: 0px 0px 0px 100vw rgb(0 0 0 / 10%);
      z-index: 9999;
    }
    ${(props) =>
      props.overlay
        ? `position: fixed; top: 0; left: 0; box-shadow: 0px 0px 0px 100vw rgb(0 0 0 /10%);`
        : `display: none;`}
  }
`;
