import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import styled from "styled-components";
import { deletePlanEstimate } from "../../../rtk/feature/plan-estimate/planEstimateApi";
import { EstimationDetailTypeI } from "../../../rtk/feature/plan-estimate/planEstimateSlice";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "@ims/common";
import Dialog from "./Dialog/Dialog";
import EstimateGroup from "./EstimateItemGroup";
import {
  deleteGroupPlanEstimate,
  getGroupPlanEstimates,
} from "../../../rtk/feature/plan-group-estimate/planGroupEstimateApi";
import { showLoading } from "../../../rtk/feature/auth/authSlice";
import { UnitTypeI } from "../../../rtk/feature/unit/unitSlice";
import useTranslated from "../../../hooks/useTranslated";
import { englishNepaliNumberTranslation } from "../../../utils/translateNumber";

export interface EstimateTablePropsI {
  budgetID: string;
  isAddable: boolean;
}

export interface GroupEstimateListTypeI {
  group_id: string;
  rate_analysis_id: any;
  name: string;
  estimate_list: any[];
  _is_latest: boolean;
  unit?: UnitTypeI;
  // | {
  //     label: string;
  //     value: string;
  //   };
}

const itemGroup = {
  group_id: Date.now().toString(),
  rate_analysis_id: "",
  name: "",
  estimate_list: [
    {
      id: "1",
      shapes: "",
      formula: "",
      name: "",
      description: "",
      length: 0,
      breadth: 0,
      height: 0,
      quantity: "0",
      remarks: "",
      final_measurement: 0,
      _is_latest: true,
    },
  ],
  _is_latest: true,
};

function EstimateTable(props: EstimateTablePropsI) {
  const { budgetID } = props;
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();

  const [estimateList, setEstimateList] = useState<EstimationDetailTypeI[]>([]);
  const [delDialogOpen, setDelDialogOpen] = useState(false);
  const [itemID, setItemID] = useState<string | null>(null);

  const [groupEstimateList, setGroupEstimateList] = useState<GroupEstimateListTypeI[]>([itemGroup]);

  const planGroupEstimateSelector = useAppSelector((state) => state.planGroupEstimates);

  useEffect(() => {
    if (budgetID) {
      dispatch(getGroupPlanEstimates({ budgetID }));
    }
  }, [budgetID, dispatch]);

  const { data } = planGroupEstimateSelector;
  useEffect(() => {
    const tempData: any[] = data?.results?.map((el) => ({
      group_id: el.id,
      unit: el.unit,
      name: el.name,
      rate_analysis_id: el.equipment_quantity_analysis.id,
      _is_latest: false,
      estimate_list: el.equipment_measurement_analysis.map((item) => ({
        id: item.id,
        shapes: item.shapes,
        formula: item.formula,
        name: item.name,
        description: "",
        length: item.length,
        breadth: item.breadth,
        number: item.number,
        height: item.height,
        quantity: item.quantity,
        remarks: item.remarks,
        final_measurement: item.final_measurement,
        _is_latest: false,
      })),
    }));

    if (tempData.length === 0) {
      setGroupEstimateList([itemGroup]);
    } else {
      setGroupEstimateList(tempData);
    }
  }, [planGroupEstimateSelector, itemGroup]);

  useEffect(() => {
    dispatch(showLoading(planGroupEstimateSelector.loading));
  }, [dispatch, planGroupEstimateSelector]);

  const handleAdd = () => {
    if (!budgetID) {
      return toast.warning(keyword["No budget is selected right now."]);
    }
    // setEstimateList((estimateList) => {
    //   return [...estimateList, newItem];
    // });
  };

  const handleChange = (data: any) => {
    setEstimateList((estimateList) =>
      estimateList.map((el) => {
        if (el.id === data.id) return data;
        return el;
      })
    );
  };

  const handleDelete = (ID: any) => {
    setDelDialogOpen(true);
    setItemID(ID);
  };

  const handleDialogClose = () => {
    setDelDialogOpen(false);
  };

  const handleDeleteConfirm = () => {
    if (itemID && budgetID) {
      dispatch(deletePlanEstimate({ budgetID: budgetID, id: itemID }));
      handleDialogClose();
    }
  };

  const handleItemGroupAddRow = () => {
    setGroupEstimateList((items) => [...items, itemGroup]);
  };

  function removeListRow(list: any[], index: number) {
    let firstPartList = list.slice(0, index - 1);
    let secondPartList = list.slice(index, list.length);
    return [...firstPartList, ...secondPartList];
  }

  const handleItemGroupRemoveRow = (index: number) => {
    setGroupEstimateList((prevState) => removeListRow(prevState, index));
  };

  const handleItemGroupAdd = (data: GroupEstimateListTypeI) => {
    setGroupEstimateList((items) => [...items, data]);
  };

  const handleItemGroupDelete = (group_id: string) => {
    if (budgetID) {
      const tempItem = groupEstimateList.find((item) => item.group_id === group_id);

      if (tempItem?._is_latest) {
        setGroupEstimateList((items) => items.filter((item) => item.group_id !== group_id));
      } else {
        dispatch(deleteGroupPlanEstimate({ budgetID: budgetID, id: group_id }));
      }
    }
  };

  const handleItemGroupUpdate = (data: GroupEstimateListTypeI) => {
    setGroupEstimateList((items) =>
      items.map((item) => {
        if (item.group_id === data.group_id) {
          return data;
        }
        return item;
      })
    );
  };

  return (
    <React.Fragment>
      <SmartTableContainer>
        <BasicTable>
          <thead>
            <tr>
              <td align="left" width={60}>
                {keyword["S.N."]}.
              </td>
              <td align="left" width={350}>
                {keyword.Description}
              </td>

              <td align="left"> {keyword.Unit} </td>
              <td align="left">
                {" "}
                {keyword.Estimate} {keyword.Name}{" "}
              </td>
              {props.isAddable && <td width={80}>{keyword.Action} </td>}
            </tr>
          </thead>

          <tbody>
            {groupEstimateList.map((groupEstimate, index) => {
              const { group_id } = groupEstimate;
              let sn: any = index + 1;
              sn = sn < 10 ? "0" + sn : "" + sn;
              sn = englishNepaliNumberTranslation(sn);

              return (
                <EstimateGroup
                  total_measurement={data.results[index]?.total_measurement}
                  key={group_id}
                  budgetID={budgetID}
                  onDelete={handleItemGroupDelete}
                  onAdd={handleItemGroupAdd}
                  onUpdate={handleItemGroupUpdate}
                  onAddRow={handleItemGroupAddRow}
                  onClearRow={handleItemGroupRemoveRow}
                  maxI={groupEstimateList.length}
                  value={groupEstimateList.find((el) => el.group_id === group_id)}
                  index={sn}
                  hideActionBtn={!props.isAddable}
                />
              );
            })}
          </tbody>
        </BasicTable>
      </SmartTableContainer>

      <br />
      {props?.isAddable && (
        <small>
          <em>
            {keyword["Note:"]}
            {
              keyword[
                'To enter new item or save, press "Enter" on your keyboard after clicking in any input row (only last three).'
              ]
            }
          </em>
        </small>
      )}

      <Dialog active={delDialogOpen} onClose={handleDialogClose}>
        <Dialog.Container className="container-dialog">
          <div className="container-body">
            <h2 className="sub-title">Are you sure, you want to delete?</h2>
            <div className="container-button">
              <Button onClick={handleDialogClose}>{keyword.Cancel}</Button>
              <Button variant="error" onClick={handleDeleteConfirm}>
                Confirm
              </Button>
              <Button type="button" onClick={handleDialogClose}>
                {keyword.Cancel}
              </Button>
            </div>
          </div>
        </Dialog.Container>
      </Dialog>
    </React.Fragment>
  );
}

const SmartTableContainer = styled.div<{ minWidth?: string }>`
  table {
    width: 100%;
    min-width: none;
  }
`;

const BasicTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;

  & tr td,
  & tr th {
    padding: 5px;
    border: 1px solid #f1f1f1;
    background: #f9f9f9;

    & strong {
      font-weight: 500;
    }

    input {
      width: 100%;
    }
  }
`;

export default EstimateTable;
