import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Form from "@ims/common/src/core-ui/forms/Form";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../core-ui/Page";
import { equipmentCleanUp, EquipmentFormTypeI } from "../../rtk/feature/equipment/equipmentSlice";
import { addEquipment, getEquipment, updateEquipment } from "../../rtk/feature/equipment/equipmentApi";
import { getTransportationTypes } from "../../rtk/feature/transportation-type/transportationTypeApi";
import { getUnits } from "../../rtk/feature/unit/unitApi";
import ReuseForm from "../../core-ui/ReuseForm";
import { EquipmentTypeChoice, getOptionFromValue, TypeChoice } from "../../utils/constants";

function EquipmentFormPage() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<EquipmentFormTypeI>();

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const equipmentsSelector = useAppSelector((state) => state.equipments);
  const transportationTypesSelector = useAppSelector((state) => state.transportationTypes);
  const unitsSelector = useAppSelector((state) => state.units);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    dispatch(getTransportationTypes());
    dispatch(getUnits({ per_page: 100000 }));

    return () => {
      dispatch(equipmentCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getEquipment(params.id));
      setEditMode(true);
    } else {
      dispatch(equipmentCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { item } = equipmentsSelector;
  const { data: transportationTypeList } = transportationTypesSelector;
  const { data: unitList } = unitsSelector;

  useEffect(() => {
    if (item !== null) {
      setValue("nepali_name", item.nepali_name);
      setValue("english_name", item.english_name);
      setValue("nepali_description", item.nepali_description);
      setValue("english_description", item.english_description);
      setValue("unit_rate", +item.unit_rate);
      setValue("unit_weight", +item.unit_weight);
      setValue("unit_ton", +item.unit_ton);
      setValue("load_unload_rate", +item.load_unload_rate);
      setValue("load_unload_per", +item.load_unload_per);
      setValue("equipment_type", item.equipment_type);

      let unit = unitList?.results?.find((el) => el.id === item.unit?.id);
      let transType = transportationTypeList?.results.find((el) => el.id === item.transportation_type?.id);

      const typeCh = getOptionFromValue(TypeChoice, item.type);
      const equipmentTypeCh = getOptionFromValue(EquipmentTypeChoice, item.equipment_type);

      if (typeCh) setValue("type", typeCh);
      if (equipmentTypeCh) setValue("equipment_type", equipmentTypeCh);

      if (unit) {
        const unitOjb = { label: unit.name, value: unit.id };
        setValue("unit", unitOjb);
      }

      if (transType) {
        const transTypeObj = { label: transType.name, value: transType.id };
        setValue("transportation_type", transTypeObj);
      }
    } else {
      reset();
    }
  }, [item, setValue, reset, transportationTypeList, unitList]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = equipmentsSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/equipment/list");
    }
  }, [equipmentsSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<EquipmentFormTypeI> = (data) => {
    data.transportation_type = data.transportation_type.value;
    data.unit = data.unit.value;
    data.type = data.type.label;
    data.equipment_type = data.equipment_type.label;

    if (editMode && params.id) {
      dispatch(updateEquipment({ data, id: params.id }));
    } else {
      dispatch(addEquipment(data));
    }
  };

  const handleCancel = () => {
    navigate("/equipment/list");
  };

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>{editMode ? "Equipment Update" : "Equipment Create"}</Page.Title>
      </Page.Container>

      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label="Equipment Name(Nepali)"
                name="nepali_name"
                placeholder="Enter equipment name"
                rules={{ required: "Equipment name in nepali is required." }}
                error={errors["nepali_name"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label="Equipment Name (English)"
                name="english_name"
                placeholder="Enter equipment name in english"
                rules={{ required: "Equipment name in english is required." }}
                error={errors["english_name"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.TextArea
                label="Equipment Name (Nepali)"
                name="nepali_description"
                placeholder="Enter your equipment description in Nepali"
                rules={{ required: "Equipment description in Nepali is required." }}
                error={errors["nepali_description"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.TextArea
                label="Equipment Name (English)"
                name="english_description"
                placeholder="Enter your equipment description in English"
                rules={{ required: "Equipment description in English is required." }}
                error={errors["english_description"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.ReactSelect
                label="Type"
                name="type"
                options={TypeChoice.map((type, index) => ({ value: index, label: type }))}
                placeholder="Select Type"
                rules={{ required: "Type is required." }}
                error={errors["type"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.ReactSelect
                label="Equipment Type"
                name="equipment_type"
                options={EquipmentTypeChoice.map((type, index) => ({ value: index, label: type }))}
                placeholder="Select Equipment Type"
                rules={{ required: "Equipment Type is required." }}
                error={errors["equipment_type"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.ReactSelect
                label="Transportation Type"
                name="transportation_type"
                options={transportationTypeList?.results.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
                placeholder="Select transportation type"
                rules={{ required: "Transportation Type is required." }}
                error={errors["transportation_type"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.ReactSelect
                label="Unit"
                name="unit"
                options={unitList?.results?.map((unit) => ({
                  value: unit.id,
                  label: unit.name,
                }))}
                placeholder="Select unit"
                rules={{ required: "Unit is required." }}
                error={errors["unit"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                type="number"
                label="Unit Rate"
                name="unit_rate"
                placeholder="Enter unit rate"
                rules={{ required: "Unit rate is required." }}
                error={errors["unit_rate"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                type="number"
                label="Unit Weight"
                name="unit_weight"
                placeholder="Enter unit weight"
                rules={{ required: "Unit weight is required." }}
                error={errors["unit_weight"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                type="number"
                label="Unit Ton"
                name="unit_ton"
                placeholder="Enter unit ton"
                rules={{ required: "Unit ton is required." }}
                error={errors["unit_ton"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                type="number"
                label="Load/Unload Rate"
                name="load_unload_rate"
                placeholder="Enter load/unload rate"
                rules={{ required: "Load/Unload rate is required." }}
                error={errors["load_unload_rate"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                type="number"
                label="Load Unload Per"
                name="load_unload_per"
                placeholder="Enter load unload per"
                rules={{ required: "Load unload per is required." }}
                error={errors["load_unload_per"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? "Update Equipment" : "Create Equipment"}
              </Button>
              <Button onClick={handleCancel}></Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
}

export default EquipmentFormPage;
