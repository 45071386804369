import styled from "styled-components";

const DisabledButton = styled.button`
  background-color: gray;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 1rem;
  cursor: not-allowed;
  opacity: 0.6;
`;

export default DisabledButton;
