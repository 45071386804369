import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import {
  addGeneralDocument,
  getGeneralDocument,
  updateGeneralDocument,
} from "../../rtk/feature/general-document/generalDocumentApi";
import { useNavigate, useParams } from "react-router-dom";
import {
  GeneralDocumentFormTypeI,
  generalDocumentsCleanUp,
} from "../../rtk/feature/general-document/generalDocumentSlice";
import ReuseForm from "../../core-ui/ReuseForm";
import Dropzone from "../../components/Dropzone";
import { Form } from "@ims/common";
import useTranslated from "../../hooks/useTranslated";

export interface GeneralDocumentFormTypeII extends GeneralDocumentFormTypeI {}

function GeneralDocumentsFormPage() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    watch,
  } = useForm<GeneralDocumentFormTypeII>({
    defaultValues: {
      title: "",
      document_type: "",
    },
  });

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const generalDocumentsSelector = useAppSelector((state) => state.generalDocuments);
  const [editMode, setEditMode] = useState(false);

  const watchFields = watch();

  useEffect(() => {
    return () => {
      dispatch(generalDocumentsCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getGeneralDocument(params.id));
      setEditMode(true);
    } else {
      dispatch(generalDocumentsCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { item } = generalDocumentsSelector;

  useEffect(() => {
    if (item !== null) {
      setValue("title", item.title);
      setValue("document_type", item.document_type);
    } else {
      reset();
    }
  }, [item, setValue, reset]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = generalDocumentsSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/settings/general-document/list");
    }
  }, [generalDocumentsSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<GeneralDocumentFormTypeII> = (data: any) => {
    const formData = new FormData();

    if (data.document) {
      formData.append("document", data.document[0]);
    } else {
      delete data["document"];
    }

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    if (editMode && params.id) {
      dispatch(updateGeneralDocument({ data: formData, id: params.id }));
    } else {
      dispatch(addGeneralDocument(formData));
    }
  };

  const handleCancel = () => {
    navigate("/settings/general-document/list");
  };

  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <h4>
        {editMode ? keyword.Update : "Create"} {keyword["General Documents"]}{" "}
      </h4>
      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["General Document Name"]}
                name="title"
                placeholder="Enter fiscal year name"
                rules={{ required: keyword["This is required field."] }}
                error={errors["title"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Document Type"]}
                name="document_type"
                placeholder="Enter document type"
                rules={{ required: keyword["This is required field."] }}
                error={errors["document_type"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              {/* <ReuseForm.Input
                type="file"
                label="Document"
                name="document"
                rules={{ required: keyword["This is required field." ] }}
                error={errors["document"]}
              /> */}
              <Form.Label> {keyword["Upload *PDF Files (Only One)"]}</Form.Label>
              <Controller
                name="document"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field: { onChange, value }, fieldState, formState }) => {
                  return <Dropzone value={value} onChange={(files: any) => onChange(files)} />;
                }}
              ></Controller>
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword["Update General Document"] : keyword["Create General Document"]}
              </Button>
              <Button onClick={handleCancel}>{keyword.Cancel}</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
}

export default GeneralDocumentsFormPage;
