import { createSlice } from "@reduxjs/toolkit";

interface dummyDataProps {
  id: string;
  title: string;
  price: number;
  brand: string;
}

interface initialStateProps {
  loading: boolean;
  data: dummyDataProps[];
}

const initialState: initialStateProps = {
  loading: false,
  data: [],
};

const dummySlice = createSlice({
  name: "dummy",
  initialState,
  reducers: {
    cleanUp: (state) => {
      state.loading = false;
      state.data = [];
    },
  },
  extraReducers: (builder) => {},
});

export const { cleanUp } = dummySlice.actions;
export default dummySlice.reducer;
