import { createSlice } from "@reduxjs/toolkit";
import { ImunicipalityData } from "../municipality-budget/municipalityBudgetSlice";
import { addBudget, getBudget, getBudgets, updateBudget } from "./budgetApi";

export interface BudgetFormTypeI {
  nagarpalika_lagani: string;
  agreement_date: string;
  agreement_start_date: string;
  agreement_end_date: string;
  agreement_payment_date: string;
  contractor_type: any;
  budget_name: string;
  yojana_name: string;
  ward: string;
  yojana_type: string;
}

export interface BudgetTypeI extends ImunicipalityData {}

export interface BudgetUpdatePasswordTypeI {
  old_password: string;
  password: string;
  confirm_password: string;
}
export interface BudgetsSliceStateI {
  loading: boolean;
  data: BudgetTypeI[] | null;
  item: BudgetTypeI | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: BudgetsSliceStateI = {
  loading: false,
  data: null,
  item: null,
  hasSubmitted: false,
  error: null,
};

const budgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {
    budgetCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addBudget.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addBudget.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addBudget.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getBudgets.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getBudgets.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getBudgets.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(getBudget.pending, (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    });
    builder.addCase(getBudget.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    });
    builder.addCase(getBudget.rejected, (state, { payload }) => {
      state.loading = false;
      state.item = null;
      state.error = true;
    });
    builder.addCase(updateBudget.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateBudget.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateBudget.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
  },
});

export const { budgetCleanUp } = budgetSlice.actions;
export default budgetSlice.reducer;
