import React, {
  createContext,
  HtmlHTMLAttributes,
  useContext,
  useRef,
  useState,
} from "react";
import { useEffect } from "react";
import { VscChevronDown } from "react-icons/vsc";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { trimText } from "../../utils/trimText";
import { LayoutContext } from "../layout/MainLayout";
import {
  MainNavBrandImage,
  MainNavDivider,
  MainNavDropNavItem,
  MainNavNav,
  MainNavNavItem,
  MainNavNavListItem,
  MainNavRoot,
  MainNavSubNav,
  StyledBrandContainer,
} from "./MainNav.style";

const DropdownContext = createContext<{
  active: boolean;
  setActive: Function;
  highlighted: boolean;
  setHighlighted: Function;
}>({
  active: false,
  setActive: () => {},
  highlighted: false,
  setHighlighted: () => {},
});

interface MainNavPropsI {
  children: React.ReactNode;
}

export default function MainNav(props: MainNavPropsI) {
  const { children } = props;
  return <MainNavRoot>{children}</MainNavRoot>;
}

interface MainNavBrandImagePropsI {
  src: string;
  alt: string;
  width?: string;
  height?: string;
}

MainNav.BrandImage = function MainNavBrandImageFunction(
  props: MainNavBrandImagePropsI
) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };
  return <MainNavBrandImage onClick={handleClick} {...props} />;
};

MainNav.BrandContainer = function MainNavBrandContainerFunction(
  props: HtmlHTMLAttributes<HTMLDivElement>
) {
  return <StyledBrandContainer {...props} />;
};

interface MainNavNavPropsI {
  children?: React.ReactNode;
}

MainNav.Nav = function MainNavNavFunction(props: MainNavNavPropsI) {
  const { children } = props;
  return <MainNavNav>{children}</MainNavNav>;
};

interface MainNavSubNavPropsI {
  children?: React.ReactNode;
}

MainNav.SubNav = function MainNavSubNavFunction(props: MainNavSubNavPropsI) {
  const { children } = props;
  return <MainNavSubNav>{children}</MainNavSubNav>;
};

interface MainNavNavItemPropsI {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  subItem?: boolean;
  to: string;
  active?: boolean;
}

MainNav.NavItem = function MainNavNavItemFunction(props: MainNavNavItemPropsI) {
  const { setHighlighted } = useContext(DropdownContext);
  const { expanded } = useContext(LayoutContext);
  const location = useLocation();
  const { children, subItem, icon: NavIcon, to } = props;

  useEffect(() => {
    if (location.pathname === to || props.active) {
      setHighlighted(true);
    }
  }, [location.pathname, setHighlighted, to, props.active]);

  return (
    <MainNavNavListItem className={subItem ? "sub-item" : ""}>
      <MainNavNavItem
        className={location.pathname === to || props.active ? "active" : ""}
        to={to}
        title={children?.toString()}
      >
        {NavIcon}
        <span>{!expanded ? children : trimText(children?.toString(), 20)}</span>
      </MainNavNavItem>
    </MainNavNavListItem>
  );
};

interface MainNavDropNavItemPropsI {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  title: string;
}

MainNav.DropNavItem = function MainNavDropNavItemFunction(
  props: MainNavDropNavItemPropsI
) {
  const { title, children, icon: NavIcon } = props;
  const [active, setActive] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);
  const { expanded } = useContext(LayoutContext);
  const [highlighted, setHighlighted] = useState(false);
  const ref = useRef<any>(null);
  const location = useLocation();

  useEffect(() => {
    if (!mobileActive && highlighted) {
      setActive(true);
    }
  }, [mobileActive, highlighted]);

  const handleClick = () => {
    setActive((active) => !active);
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      const parentNode = ref.current;
      const el: any = e.target;
      if (el !== parentNode && !parentNode?.contains(e.target)) {
        setMobileActive(false);
      }
    });
  }, []);

  useEffect(() => {
    setHighlighted(false);
    React.Children.forEach(children, (element): any => {
      if (!React.isValidElement(element)) return;
      if (element.props.active || element.props.to === location.pathname)
        setHighlighted(true);
    });
  }, [children, location]);

  return (
    <DropdownContext.Provider
      value={{ active, setActive, highlighted, setHighlighted }}
    >
      <MainNavNavListItem
        onClick={() => {
          if (expanded === false) {
            setMobileActive((show: boolean) => !show);
          }
        }}
        ref={ref}
      >
        <MainNavDropNavItem
          onClick={handleClick}
          className={highlighted ? "active" : ""}
          title={title}
        >
          {NavIcon}
          <span>{trimText(title, 18)}</span>
          <VscChevronDown className="icon-drop" size="1.5rem" />
        </MainNavDropNavItem>

        <MainNavSubNav
          active={active}
          className={mobileActive ? "mobile-active" : ""}
        >
          {children}
        </MainNavSubNav>
      </MainNavNavListItem>
    </DropdownContext.Provider>
  );
};

interface MainNavDividerPropsI {
  children?: React.ReactNode;
}

MainNav.Divider = function MainNavDividerFunction(props: MainNavDividerPropsI) {
  const { children } = props;
  return <MainNavDivider>{children}</MainNavDivider>;
};
