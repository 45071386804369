import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { getFixedRateEquipments } from "../fixed-rate-equipment/fixedRateEquipmentApi";
import { RateEquipmentFormTypeI } from "./rateEquipmentSlice";

export const getRateEquipments = createAsyncThunk(
  "getRateEquipments",
  async (data: { budgetID: string; id: string }, { dispatch, rejectWithValue }) => {
    const { budgetID, id } = data;

    try {
      const res = await imsClient.get(`/yojana/${budgetID}/planning-rate-analysis/${id}/equipment-rate-analysis/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addRateEquipment = createAsyncThunk(
  "addRateEquipment",
  async (data: { budgetID: string; id: string; data: RateEquipmentFormTypeI }, { dispatch, rejectWithValue }) => {
    const { budgetID, id } = data;
    try {
      const res = await imsClient.post(
        `/yojana/${budgetID}/planning-rate-analysis/${id}/equipment-rate-analysis/`,
        data.data
      );
      toast.success("Equipment Rate added successfully!");
      dispatch(getFixedRateEquipments({ rateID: data.id }))

      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateRateEquipment = createAsyncThunk(
  "updateRateEquipment",
  async (
    data: { budgetID: string; id: string; updateID: string; data: RateEquipmentFormTypeI },
    { dispatch, rejectWithValue }
  ) => {
    const { budgetID, id, updateID } = data;
    try {
      const res = await imsClient.patch(
        `/yojana/${budgetID}/planning-rate-analysis/${id}/equipment-rate-analysis/${updateID}/`,
        data.data
      );
      toast.success("Equipment Rate updated successfully!");
      dispatch(getFixedRateEquipments({ rateID: data.id }))
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteRateEquipment = createAsyncThunk(
  "deleteRateEquipment",
  async (data: { budgetID: string; id: string; deleteID: string }, { dispatch, rejectWithValue }) => {
    const { budgetID, id, deleteID } = data;
    try {
      await imsClient.delete(`/yojana/${budgetID}/planning-rate-analysis/${id}/equipment-rate-analysis/${deleteID}/`);
      toast.success("Equipment Rate deleted successfully!");
      return data.deleteID;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
