import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Form, Table } from "@ims/common";

import ReuseForm from "../../../core-ui/ReuseForm";
import { getUsers } from "../../../rtk/feature/users/usersApi";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { Button, Grid, MainLayout } from "@ims/common";
import { getValue } from "@testing-library/user-event/dist/utils";
import {
  getPlanVerifications,
  submitPlanVerification,
  updatePlanVerification,
} from "../../../rtk/feature/plan-verification/planVerificationAPI";
import { FiEdit } from "react-icons/fi";
import { MdClose, MdDone, MdReplay } from "react-icons/md";
import usePagination from "../../../utils/usePagination";
import { BiCloset } from "react-icons/bi";
import { IplanVerificationData } from "../../../rtk/feature/plan-verification/planVerificationSlice";
import UpdateVerificationRow from "./UpdateVerificationRow";
import { roles } from "../../../constants/constants";
import useTranslated, { TranslateKeyTypes } from "../../../hooks/useTranslated";
export interface IsendVerification {
  user: {
    label: string;
    value: string;
  };
  description: string;
}

interface IupdateVerification {
  description: string;
}

interface IProps {
  budgetId: string;
  planVerificationData: IplanVerificationData | null;
}
const SendForVerification = (props: IProps) => {
  const planVerificationData = useAppSelector((state) => state.sendPlanVerification.getData.data);

  const { keyword } = useTranslated();

  const columns: TranslateKeyTypes[] = [
    "S.N.",
    "Yojana",
    "Description",
    "Send From",
    "Send To",
    "Status",
    "Created On",
    "Modified On",
    "Comment",
    "Action",
  ];

  const dispatch = useAppDispatch();
  const allUsers = useAppSelector((state) => state.users.data)?.results;
  const {
    control,
    handleSubmit,
    getValues,
    register: sendVerificationRegister,
    setValue,
    formState: { errors },
  } = useForm<IsendVerification>();

  const { register, getValues: updateFormValues } = useForm<IupdateVerification>();

  useEffect(() => {
    const controller = new AbortController();

    dispatch(getUsers({ params: { per_page: 100 }, signal: controller.signal }));
    dispatch(getPlanVerifications({ id: props.budgetId }));
    return () => controller.abort();
  }, []);

  const eligibleUsers = allUsers
    ?.filter(
      (user) =>
        user.role === roles.Prasasakiya_Adhikrit ||
        user.role === roles.Engineer ||
        (user.role === roles.SUB_ENGINNER && user.is_active === true)
    )
    .map((user, i) => {
      return {
        label: user.username,
        value: user.id,
      };
    });

  const onSubmit: SubmitHandler<IsendVerification> = (data) => {};
  const handleClick = async () => {
    const values = getValues();
    if (props.budgetId && values.user.value && values.description) {
      await dispatch(
        submitPlanVerification({
          id: props.budgetId,
          data: {
            user: values.user.value,
            description: values.description,
          },
        })
      );
      await dispatch(getPlanVerifications({ id: props.budgetId }));
    }
    dispatch(getPlanVerifications({ id: props.budgetId }));
  };
  const { Pagination, page, perPage, getSN } = usePagination({
    total: 10,
  });

  return (
    <Grid>
      <Grid.Row gap={1.5}>
        <Grid.Col spanMd={5} spanXs={12}>
          <Form.ReactSelect
            name="user"
            register={sendVerificationRegister("user.value")}
            options={eligibleUsers}
            placeholder={keyword["Select User"]}
            onChange={({ label, value }: { label: string; value: string }) => setValue("user.value", value)}
          />
        </Grid.Col>
        <Grid.Col spanXs={12} spanMd={6}>
          <Form.Input
            placeholder={keyword["Description"]}
            register={sendVerificationRegister("description")}
            name="description"
          />
        </Grid.Col>
        <Button type="submit" onClick={handleClick} variant="primary">
          {keyword["Send"]}
        </Button>
      </Grid.Row>
      <Table.Container style={{ marginTop: "2rem" }} id="budget-table">
        <Table width="100%">
          <Table.Row width="100%">
            {columns.map((col, i) => {
              return <Table.HeadCol key={col}> {keyword[col]} </Table.HeadCol>;
            })}
          </Table.Row>
          <Table.Body>
            {planVerificationData && (
              <UpdateVerificationRow data={planVerificationData} budgetId={props.budgetId} index={0} />
            )}
          </Table.Body>
        </Table>
      </Table.Container>
    </Grid>
  );
};

export default SendForVerification;
