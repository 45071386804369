import { useEffect } from "react";
import { IOptions } from "../interfaces/options.interface";
import { getUnits } from "../rtk/feature/unit/unitApi";
import { useAppDispatch, useAppSelector } from "../rtk/hook";

export const useUnitOptions = () => {
    const dispatch = useAppDispatch();
    const { data: units } = useAppSelector((state) => state.units);

    const unitOptions = units?.results?.map((unit) => {
        return {
            label: unit.name,
            value: unit.id
        }
    })
    useEffect(() => {
        dispatch(getUnits({ per_page: 100000000000 }))
    }, []);
    return { unitOptions }

}