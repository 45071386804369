import { useEffect, useState } from "react";
import { getAllVerifiedVerifications } from "../../../rtk/feature/plan-verification/planVerificationAPI";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import {
  IVerification,
  IplanVerificationData,
  planStatus,
  Yojana,
} from "../../../rtk/feature/plan-verification/planVerificationSlice";

import { Button, MainLayout, Table } from "@ims/common";
import useTranslated from "../../../hooks/useTranslated";
import { userFriendlyDate } from "../../../utils/date";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FiEye, FiUpload } from "react-icons/fi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PopupModal from "../../../components/PopupModal/PopupModal";
import AssingnConstructor from "./AssignConstructor";
import { showLoading } from "../../../rtk/feature/auth/authSlice";
import YojanaBookTemplateChoice from "../yojana-books/components/YojanaBookTemplateChoice";
import UploadDocument from "../ReviewPlanDetails/UploadDocument";
import { getBudgets } from "../../../rtk/feature/budget/budgetApi";
import { MdAssignmentAdd } from "react-icons/md";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";

export default function StartedVerifiedPlan() {
  const [planData, setPlanData] = useState<IplanVerificationData[] | null>(null);
  const [selectedYojanaId, setSelectedYojanaId] = useState<string>("");
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedYojana, setSelectedYojana] = useState<Yojana | null>(null);
  const [isSelectTemplate, setSelectTemplate] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { keyword } = useTranslated();

  const verifiedData = useAppSelector((state) => state.sendPlanVerification.getAllData);
  const allBudgets = useAppSelector((state) => state.budgets.data);

  const columns: ["Name", "Received Date", "Yojana Started", "File", "Action", "View"] = [
    "Name",
    "Received Date",
    "Yojana Started",
    "File",
    "Action",
    "View",
  ];

  useEffect(() => {
    dispatch(getAllVerifiedVerifications());
    dispatch(getBudgets());
  }, []);
  useEffect(() => {
    // if (myRole === roles.Prasasakiya_Adhikrit) return;
    dispatch(showLoading(verifiedData.loading));
  }, [dispatch, verifiedData.loading]);

  useEffect(() => {
    setPlanData(verifiedData.data);
  }, [verifiedData]);

  const handleShow = (id: string, verification: IplanVerificationData | IVerification) => {
    // if (verification.is_started && isYojanaSakha) {
    //   toast.error("you don't have permissions");
    //   return;
    // }
    if (
      (verification.is_started || verification.is_verified) &&
      /* isPrasasakiya || */ planStatus.CORRECTION === verification.status
    ) {
      toast.error("you don't have permissions");
      return;
    }
    navigate(`/plan-management/review-plan/${id}`);
  };

  const openAssignModalHandler = (id: string) => {
    setSelectedYojanaId(id);
    setIsOpenModal(true);
  };

  const openYojanaModalHandler = (y: any) => {
    setSelectedYojana(y);
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
    setSelectedYojanaId("");
    setSelectedYojana(null);
    setSelectTemplate(false);
  };

  const getPdf = (id: string) => {
    const matchedBudget = allBudgets && allBudgets.find((budget) => budget.id === id);
    if (matchedBudget) return matchedBudget.pdf_file;

    return "";
  };

  return (
    <>
      {" "}
      <MainLayout.Main>
        <Table.Container>
          <Table>
            <Table.Row>
              {columns.map((col, i) => {
                return <Table.HeadCol key={col}> {keyword[col]} </Table.HeadCol>;
              })}
            </Table.Row>
            <Table.Body>
              {planData &&
                planData.length > 0 &&
                planData.map((verification, index) => {
                  return (
                    <Table.Row key={verification.id}>
                      <Table.Col>{verification.yojana.name}</Table.Col>

                      <Table.Col>{userFriendlyDate(verification.created_on)}</Table.Col>
                      <Table.Col> {verification.is_started ? "Started" : "Not Started"} </Table.Col>
                      <Table.Col>
                        {" "}
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                          <FiUpload
                            cursor="pointer"
                            size={17}
                            color="rgb(31, 39, 57)"
                            title={keyword["Upload"]}
                            onClick={() => {
                              openYojanaModalHandler(verification.yojana);
                            }}
                          />
                          {!!getPdf(verification.yojana.id) ? (
                            <a href={getPdf(verification.yojana.id)} target="_black">
                              <BsFillFileEarmarkPdfFill
                                cursor="pointer"
                                size={17}
                                color="rgb(31, 39, 57)"
                                title={keyword["View"]}
                              />
                            </a>
                          ) : (
                            <BsFillFileEarmarkPdfFill cursor="not-allowed" size={17} color="#aaa" title="No File" />
                          )}
                        </div>
                      </Table.Col>
                      <Table.Col>
                        <span
                          style={{ cursor: "pointer", marginLeft: "30%" }}
                          onClick={() => {
                            openAssignModalHandler(verification.yojana.id);
                          }}
                        >
                          <MdAssignmentAdd color="rgb(96 30 30)" size={18} title={keyword["Assign"]} />
                          {/* {keyword["Assign"]} */}
                        </span>
                      </Table.Col>
                      <Table.Col align="center">
                        <Table.ActionButton color="green" onClick={() => handleShow(verification.id, verification)}>
                          <FiEye />
                        </Table.ActionButton>
                      </Table.Col>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Table.Container>
        <PopupModal isOpen={isOpenModal} onClose={closeModalHandler}>
          {selectedYojanaId && (
            <>
              <AssingnConstructor budgetID={selectedYojanaId} isWard={true} />
              <Button onClick={closeModalHandler}>{keyword["Cancel"]}</Button>
            </>
          )}
          {selectedYojana && (
            <>
              {isSelectTemplate ? (
                <YojanaBookTemplateChoice budgetId={selectedYojana.id} onClose={closeModalHandler} />
              ) : (
                <UploadDocument
                  setSelectTemplate={setSelectTemplate}
                  onClose={closeModalHandler}
                  yojana={selectedYojana}
                />
              )}
            </>
          )}
        </PopupModal>
      </MainLayout.Main>
    </>
  );
}
