import { url } from "inspector";
import styled from "styled-components/macro";

export const StyledAuth = styled.div<{ src?: string }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => (props.src ? `url(${props.src})` : `url(/auth-cover.png)`)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
  z-index: 99;
  color: #fff;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 86, 147, 0.65);
    opacity: 0.7;
    z-index: -1;
  }
`;
export const StyledAuthFormContainer = styled.div`
  width: 564px;

  border-radius: 10px;
  color: #000000de;
  background: #fff;
  padding: 12rem 3rem;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  & h2 {
    color: ${(props) => props.theme.palette.primary.main};

    font-weight: 700;
    size: 24px;
    line-height: 33px;
    margin-top: 1rem;
    margin-bottom: 5rem;
  }

  & > div {
    width: 100%;
    max-width: 420px;
  }

  @media screen and (max-width: 1440px) {
    padding: 5rem 4rem;
    width: 400px;

    & > img {
      width: 60px;
    }

    & h2 {
      line-height: 22px;
      margin-top: 0.5rem;
      margin-bottom: 3rem;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 100%;
    & > div {
      max-width: 100%;
    }
  }
`;

export const StyledAuthHeader = styled.div`
  width: auto;
  height: 105px;
  display: flex;
  align-items: center;
  gap: 3rem;
  position: relative;
  top: 25px;
  left: 50px;

  & > img {
    width: 125px;
  }

  @media screen and (max-width: 1440px) {
    top: 5px;
    left: 25px;
    & > img {
      width: 82px;
    }
  }
`;
export const StyledAuthBody = styled.div`
  max-width: 1440px;
  margin: auto;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  gap: 12%;
  margin-top: 4rem;
  margin-bottom: 6rem;

  & > div > h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
  }

  & > div > h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
  }
  & > div > h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }
  & > div > p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (max-width: 1440px) {
    padding: 4rem;
    margin-top: 0;
    margin-bottom: 0;

    & > div > img {
      width: 80px;
    }

    & > div > h1 {
      font-size: 28px;
      line-height: 36px;
    }

    & > div > h2 {
      font-size: 18px;
      line-height: 28px;
    }

    & > div > h3 {
      font-size: 16px;
      line-height: 28px;
    }
    & > div > p {
      font-size: 16px;
      line-height: 28px;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;
export const StyledAuthFooter = styled.div`
  // position: absolute;
  // left: 0;
  // bottom: 1rem;
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 18px;
  }

  @media screen and (max-width: 1440px) {
    & > p {
      font-size: 16px;
    }
  }
`;

export const StyledAuthAuthBanner = styled.div`
  padding: 4rem 2rem 2rem 2rem;

  flex: 7;

  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  & > div {
    text-align: center;
    font-size: 2.5rem;
  }
`;

export const StyledAuthAuthHeaderTitle = styled.div`
  width: 392px;
  font-weight: 500;

  line-height: 38px;
  font-size: 22px;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
