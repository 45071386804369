import { createSlice } from "@reduxjs/toolkit";
import RelativeDocs from "../../../pages/plan/RelativeDocs/RelativeDocs";
import {
  addRelativeDocs,
  getRelativeDoc,
  getRelativeDocs,
  removeRelativeDoc,
  updateRelativeDoc,
} from "./relativeDocsApi";

export interface RelativeDocsFormTypeI {
  id: string;
  yojana: string;
  name: string;
  file: string;
  image: string;
  file_url: string;
}

export interface RelativeDocTypeI {
  id: string;
  yojana: string;
  name: string;
  file: string;
  image: string;
  file_url: string;
}

export interface RelativeDocsSliceStateI {
  loading: boolean;
  data: RelativeDocTypeI[] | [];

  hasSubmitted: boolean;
  error: any;
}

const initialState: RelativeDocsSliceStateI = {
  loading: false,
  data: [],
  hasSubmitted: false,
  error: null,
};

const relativeDocsSlice = createSlice({
  name: "relativedocs",
  initialState,
  reducers: {
    RelativeDocsCleanUp: (state) => {
      state.data = [];
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRelativeDocs.pending, (state) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(getRelativeDocs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getRelativeDocs.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
    builder.addCase(addRelativeDocs.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addRelativeDocs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addRelativeDocs.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });

    builder.addCase(getRelativeDoc.pending, (state) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(getRelativeDoc.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getRelativeDoc.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
    builder.addCase(updateRelativeDoc.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateRelativeDoc.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateRelativeDoc.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(removeRelativeDoc.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(removeRelativeDoc.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(removeRelativeDoc.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
  },
});

export const { RelativeDocsCleanUp } = relativeDocsSlice.actions;
export default relativeDocsSlice.reducer;
