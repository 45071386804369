import { Button, Flex, Table } from "@ims/common";
import Dialog from "../../estimate/Dialog/Dialog.js";
import Page from "../../../../core-ui/Page";
import useTranslated, { keywordTypes } from "../../../../hooks/useTranslated";
import { RelativeDocTypeI } from "../../../../rtk/feature/relative-docs/relativeDocsSlice.js";
import { FaEdit, FaTrash } from "react-icons/fa";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { styleSN } from "../../../../utils/Prettify";
import { englishNepaliNumberTranslation } from "../../../../utils/translateNumber";

interface PreviewRelativeDocsI {
  data: RelativeDocTypeI[];
  handleEdit: (docId: string) => void;
  handleCreateNewDoc: () => void;
  handleRemoveDoc: (dataId: string) => void;
  isVerified: boolean;
}

export const StyledPrevewFile = styled.img`
  width: 60px;
  height: 24px;
  object-fit: cover;
`;

export default function PreviewRelativeDocs({
  data,
  handleEdit,
  handleCreateNewDoc,
  handleRemoveDoc,
  isVerified,
}: PreviewRelativeDocsI) {
  type columnTypes = keyof keywordTypes;

  const columns: columnTypes[] = ["S.N.", "Name", "File", "Actions"];
  const { keyword } = useTranslated();
  return (
    <>
      <Page.Container>
        <Flex align="center" justify="space-between">
          <Page.Title>{keyword["Preview Docs"]}</Page.Title>
          {isVerified && (
            <Page.ButtonGrp>
              <Button
                variant="primary"
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  handleCreateNewDoc();
                }}
              >
                {keyword["Add docs"]}
              </Button>
            </Page.ButtonGrp>
          )}
        </Flex>
      </Page.Container>
      <Table.Container>
        <Table id="preview-relative-docs">
          <Table.Head>
            <Table.Row>
              {columns.map((column, i) => (
                <Table.HeadCol key={i}>{keyword[column]} </Table.HeadCol>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data?.map((doc, idx) => {
              return (
                <Table.Row key={doc.id}>
                  <Table.Col> {englishNepaliNumberTranslation(styleSN(idx + 1))} </Table.Col>
                  <Table.Col> {doc.name} </Table.Col>
                  <Table.Col>
                    <Link to={doc.file_url} target="_blank">
                      <StyledPrevewFile alt="Relative Docs" src={doc.file_url} />
                    </Link>
                  </Table.Col>
                  <Table.Col align="center">
                    {isVerified ? (
                      <Flex style={{ flexWrap: "nowrap", width: "11rem", justifyContent: "space-between" }}>
                        <Table.ActionButton
                          onClick={() => {
                            handleEdit(doc.id);
                          }}
                        >
                          <FaEdit />{" "}
                        </Table.ActionButton>
                        <Table.ActionButton
                          color="red"
                          onClick={() => {
                            handleRemoveDoc(doc.id);
                          }}
                        >
                          {" "}
                          <FaTrash />
                        </Table.ActionButton>
                      </Flex>
                    ) : (
                      <p style={{ textAlign: "left" }}>N/A</p>
                    )}
                  </Table.Col>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Table.Container>
    </>
  );
}
