import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { WardFormTypeI } from "./wardSlice";
import { WardSuccess } from "../../../utils/res-mes/response-message-generator";

export const getWards = createAsyncThunk(
  "getWards",
  async (
    userParams: { per_page: number; page: number; signal?: AbortSignal } | undefined,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get("/ward/", {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getWard = createAsyncThunk("getWard", async (id: string, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.get(`/ward/${id}/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const addWard = createAsyncThunk("addWard", async (data: WardFormTypeI, { dispatch, rejectWithValue }) => {
  try {
    const res = await imsClient.post("/ward/", data);
    if (res.data) toast.success(WardSuccess("added"));
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const deleteWard = createAsyncThunk("deleteWard", async (deleteId: string, { dispatch, rejectWithValue }) => {
  try {
    await imsClient.delete(`/ward/${deleteId}/`);
    dispatch(getWards());
    toast.success(WardSuccess("deleted"));
    return deleteId;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(appError.getError());
  }
});

export const updateWard = createAsyncThunk(
  "updateWard",
  async (data: { data: WardFormTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/ward/${data.id}/`, data.data);
      if (res.data) toast.success(WardSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
