import React, { useEffect, useState } from "react";
import Table from "@ims/common/src/core-ui/table/Table";
import { Form, Grid } from "@ims/common";
import { useTranslation } from "react-i18next";
import { BiChevronLeft, BiChevronRight, BiChevronsRight } from "react-icons/bi";
import { BsChevronDoubleRight } from "react-icons/bs";
import { FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { GiDivergence } from "react-icons/gi";
import styled from "styled-components";
import { englishNepaliNumberTranslation } from "./translateNumber";

export interface PaginationPropsI {
  total: number;
}

function usePagination(props: PaginationPropsI) {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(1);

  useEffect(() => {
    setTotalItems(props.total);
  }, [props]);

  let flag = 0;

  const handleDoublePrev = () => {
    setPage((page) => {
      if (page > 5) return page - 5;
      return page;
    });
  };

  const handlePrev = () => {
    setPage((page) => {
      if (page > 1) return page - 1;
      return page;
    });
  };

  const handleNext = () => {
    setPage((page) => {
      if (page < totalItems / perPage) return page + 1;
      return page;
    });
  };

  const handleDoubleNext = () => {
    setPage((page) => {
      if (page + 5 < totalItems / perPage) return page + 5;
      return page;
    });
  };

  const totalPages = Math.ceil(totalItems / perPage);

  return {
    page,
    perPage,
    setPage,
    setPerPage,
    getSN: (index: number) => getSN({ index, page, perPage }),
    Pagination: () => (
      <Table.Pagination>
        <StyledPaginationWrapper>
          <div>
            <Form.Label>{t("Show per page")}: </Form.Label>
            <Form.Select
              name="page-items"
              value={perPage}
              onChange={(e: any) => {
                if (e.target.value === totalItems.toString()) {
                  setPage(1);
                }

                setPerPage(parseInt(e.target.value));
              }}
            >
              <Form.Option value="5">{englishNepaliNumberTranslation(5)}</Form.Option>
              <Form.Option value="10">{englishNepaliNumberTranslation(10)}</Form.Option>
              <Form.Option value="15">{englishNepaliNumberTranslation(15)}</Form.Option>
              <Form.Option value="20">{englishNepaliNumberTranslation(20)}</Form.Option>
              <Form.Option value={totalItems.toString()}>{t("All")}</Form.Option>
            </Form.Select>
          </div>

          <div>
            <Table.PaginationContainer>
              <span>
                {englishNepaliNumberTranslation(perPage * (page - 1) + 1)}-
                {englishNepaliNumberTranslation(perPage * page > totalItems ? totalItems : perPage * page)} {t("of")}{" "}
                {englishNepaliNumberTranslation(totalItems)} {t("items")}
              </span>

              <Table.PaginationItem disabled={page <= 5} onClick={handleDoublePrev}>
                <FiChevronsLeft />
              </Table.PaginationItem>

              <Table.PaginationItem disabled={page <= 1} onClick={handlePrev}>
                <BiChevronLeft />
              </Table.PaginationItem>

              {[...Array(totalPages)].map((item, index) => {
                const num = index + 1;

                if (num <= 2 || num >= totalPages - 1 || (num >= page - 1 && num <= page + 1)) {
                  return (
                    <Table.PaginationItem
                      key={index}
                      className={num === page ? "active" : ""}
                      onClick={() => {
                        setPage(num);
                      }}
                    >
                      {englishNepaliNumberTranslation(num)}
                    </Table.PaginationItem>
                  );
                } else if (num === page - 2 || num === totalPages - 2) {
                  return <Table.PaginationItem>...</Table.PaginationItem>;
                }
              })}

              <Table.PaginationItem disabled={page >= totalPages} onClick={handleNext}>
                <BiChevronRight />
              </Table.PaginationItem>

              <Table.PaginationItem disabled={page + 5 > totalPages} onClick={handleDoubleNext}>
                <FiChevronsRight />
              </Table.PaginationItem>
            </Table.PaginationContainer>
          </div>
        </StyledPaginationWrapper>
      </Table.Pagination>
    ),
  };
}

export default usePagination;

export interface SNTypeI {
  index: number;
  page: number;
  perPage: number;
}

export const getSN = (data: SNTypeI) => {
  const { index, page, perPage } = data;

  let sn = "";
  let key = (page - 1) * perPage + index + 1;

  if (key < 10) sn = "0" + key;
  else sn = key.toString();

  return sn;
};

export const StyledPaginationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;
