import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { getBudget } from "../budget/budgetApi";
import { PlanEstimateFormTypeI } from "./planEstimateSlice";
import { PlanEstimateSuccess } from "../../../utils/res-mes/response-message-generator";

export const getPlanEstimates = createAsyncThunk(
  "getPlanEstimates",
  async (planbriefParams: { budgetID: string } | undefined, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/yojana/${planbriefParams?.budgetID}/estimation-details/`);
      return res.data.estimation_details;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getPlanEstimate = createAsyncThunk("getPlanEstimate", async (budgetID: string) => {
  try {
    const res = await imsClient.get(`/yojana/${budgetID}/estimation-details/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
  }
});

export const addPlanEstimate = createAsyncThunk(
  "addPlanEstimate",
  async (data: { data: PlanEstimateFormTypeI; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/yojana/${data.budgetID}/estimation-details/`, data.data);
      if (res.data) toast.success(PlanEstimateSuccess("added"));

      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updatePlanEstimate = createAsyncThunk(
  "updatePlanEstimate",
  async (data: { data: PlanEstimateFormTypeI | any; id: string; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/yojana/${data.budgetID}/estimation-details/${data.id}/`, data.data);

      if (res.data) toast.success(PlanEstimateSuccess("updated"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deletePlanEstimate = createAsyncThunk(
  "deletePlanEstimate",
  async (data: { id: string; budgetID: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.delete(`/yojana/${data.budgetID}/estimation-details/${data.id}/`);
      if (res.data) toast.success(PlanEstimateSuccess("deleted"));
      return data.id;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
