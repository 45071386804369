import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { DataSuccess } from "../../../utils/res-mes/response-message-generator";
interface IGetSanitoryWorks {
  id: string;
}
export const getSanitoryWorks = createAsyncThunk(
  "getSanitoryWorks",
  async ({ id }: IGetSanitoryWorks, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/yojana/${id}/sanitary-works/`);
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(appError.getError());
    }
  }
);

interface IGetSanitoryWork {
  id: string;
  workId: string;
}

export const getSanitoryWork = createAsyncThunk(
  "getSanitoryWork",
  async ({ id, workId }: IGetSanitoryWork, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/yojana/${id}/sanitary-works/${workId}/`);
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(appError.getError());
    }
  }
);
interface IPostSanitoryWork {
  id: string;
  data: any;
}

export const postSanitoryWork = createAsyncThunk(
  "postSanitoryWork",
  async ({ id, data }: IPostSanitoryWork, { rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/yojana/${id}/sanitary-works/`, data);
      if (res.data) toast.success(DataSuccess("added"));
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(appError.getError());
    }
  }
);

interface IUpdateSanitoryWork {
  id: string;
  workId: string;
  data: any;
}

export const updateSanitoryWorks = createAsyncThunk(
  "updateSanitoryWork",
  async ({ id, workId, data }: IUpdateSanitoryWork, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/yojana/${id}/sanitary-works/${workId}/`, data);
      dispatch(getSanitoryWorks({ id }));
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(appError.getError());
    }
  }
);

interface IDeleteElectrificationWork {
  id: string;
  workId: string;
}

export const deleteSanitoryWorks = createAsyncThunk(
  "deleteSanitoryWork",
  async ({ id, workId }: IDeleteElectrificationWork, { rejectWithValue }) => {
    try {
      const res = await imsClient.delete(`/yojana/${id}/sanitary-works/${workId}/`);
      return workId;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(appError.getError());
    }
  }
);
