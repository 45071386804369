import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { addYojanaType, getYojanaType, updateYojanaType } from "../../rtk/feature/yojana-type/yojanaTypeApi";
import { useNavigate, useParams } from "react-router-dom";
import { YojanaTypeFormTypeI, yojanaTypesCleanUp } from "../../rtk/feature/yojana-type/yojanaTypeSlice";
import ReuseForm from "../../core-ui/ReuseForm";
import useTranslated from "../../hooks/useTranslated";

export interface YojanaTypeFormTypeII extends YojanaTypeFormTypeI {}

function YojanaTypeFormPage() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<YojanaTypeFormTypeII>({
    defaultValues: {
      name: "",
    },
  });

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const yojanaTypesSelector = useAppSelector((state) => state.yojanaTypes);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(yojanaTypesCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getYojanaType(params.id));
      setEditMode(true);
    } else {
      dispatch(yojanaTypesCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { item } = yojanaTypesSelector;

  useEffect(() => {
    if (item !== null) {
      setValue("name", item.name);
    } else {
      reset();
    }
  }, [item, setValue, reset]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = yojanaTypesSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/budget/yojana-type/list");
    }
  }, [yojanaTypesSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<YojanaTypeFormTypeII> = (data) => {
    if (editMode && params.id) {
      const newData = { ...data };
      dispatch(updateYojanaType({ data: newData, id: params.id }));
    } else {
      const newData = { ...data };
      dispatch(addYojanaType(newData));
    }
  };

  const handleCancel = () => {
    navigate("/budget/yojana-type/list");
  };
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <h4>{editMode ? "Edit" : "Create"} YojanaType</h4>
      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label="{keyword.Yojana}Type Name"
                name="name"
                placeholder="Enter {keyword.Yojana}Type"
                rules={{ required: keyword["This is required field."] }}
                error={errors["name"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? "Update {keyword.Yojana}Type" : "Create {keyword.Yojana}Type"}
              </Button>
              <Button onClick={handleCancel}>{keyword.Cancel}</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
}

export default YojanaTypeFormPage;
