import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import Form from "@ims/common/src/core-ui/forms/Form";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Button from "@ims/common/src/core-ui/button/Button";
import Grid from "@ims/common/src/core-ui/grid/Grid";
import { useAppDispatch, useAppSelector } from "../../../rtk/hook";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../core-ui/Page";
import { equipmentCleanUp, EquipmentFormTypeI } from "../../../rtk/feature/equipment/equipmentSlice";
import { addEquipment, getEquipment, updateEquipment } from "../../../rtk/feature/equipment/equipmentApi";
import { getTransportationTypes } from "../../../rtk/feature/transportation-type/transportationTypeApi";
import { getUnits } from "../../../rtk/feature/unit/unitApi";
import ReuseForm from "../../../core-ui/ReuseForm";
import { EquipmentTypeChoice, getOptionFromValue, TypeChoice } from "../../../utils/constants";
import {
  analyzeRatesCleanUp,
  FixedAnalyzeRateFormTypeI,
} from "../../../rtk/feature/fixed-analyze-rate/fixedAnalyzeRateSlice";
import {
  addFixedAnalyzeRate,
  getFixedAnalyzeRate,
  updateFixedAnalyzeRate,
} from "../../../rtk/feature/fixed-analyze-rate/fixedAnalyzeRateApi";
import { IOptions } from "../../../interfaces/options.interface";
import { Flex } from "@ims/common";
import useTranslated from "../../../hooks/useTranslated";

interface IForm extends Omit<FixedAnalyzeRateFormTypeI, "working_type" | "unit"> {
  working_type: IOptions;
  unit: IOptions;
}

function FixedRateAnalysisFormPage() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<IForm>();

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fixedAnalyzeRateSelector = useAppSelector((state) => state.fixedAnalyzeRates);
  const transportationTypesSelector = useAppSelector((state) => state.transportationTypes);
  const unitsSelector = useAppSelector((state) => state.units);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    dispatch(getTransportationTypes());
    dispatch(getUnits());

    return () => {
      dispatch(analyzeRatesCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      dispatch(getFixedAnalyzeRate(params.id));
      setEditMode(true);
    } else {
      dispatch(analyzeRatesCleanUp());
      setEditMode(false);
    }
  }, [params, dispatch]);

  const { item } = fixedAnalyzeRateSelector;
  const { data: transportationTypeList } = transportationTypesSelector;
  const { data: unitList } = unitsSelector;

  useEffect(() => {
    if (item !== null) {
      setValue("nepali_name", item.nepali_name);
      setValue("english_name", item.english_name);
      setValue("nepali_description", item.nepali_description);
      setValue("english_description", item.english_description);
      setValue("vat_applied", item.vat_applied);
      item?.unit && setValue("unit", { label: item?.unit?.name, value: item?.id });
      setValue("code", item.code);
      setValue("working_type", { label: item.working_type, value: item.working_type });
    } else {
      reset();
    }
  }, [item, setValue, reset, transportationTypeList, unitList]);

  useEffect(() => {
    const { error, loading, hasSubmitted } = fixedAnalyzeRateSelector;
    if (!error && !loading && hasSubmitted) {
      navigate("/fixed-rate-analysis/list");
    }
  }, [fixedAnalyzeRateSelector, dispatch, navigate]);

  const onSubmit: SubmitHandler<any> = (data) => {
    if (editMode && params.id) {
      dispatch(
        updateFixedAnalyzeRate({
          data: { ...data, working_type: data.working_type.value, unit: data.unit.value },
          id: params.id,
        })
      );
    } else {
      dispatch(addFixedAnalyzeRate({ ...data, working_type: data.working_type.value, unit: data.unit.value }));
    }
  };

  const handleCancel = () => {
    navigate("/fixed-rate-analysis/list");
  };
  const workingTypeOptions = EquipmentTypeChoice.map((choice) => {
    return {
      label: choice,
      value: choice,
    };
  });
  useEffect(() => {
    dispatch(getUnits({ per_page: 10000 }));
  }, []);
  const units = useAppSelector((state) => state.units.data);
  const unitOptions = units?.results?.map((unit) => {
    return {
      label: unit.name,
      value: unit.id,
    };
  });
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title>
          {editMode ? keyword.Update : keyword.Add} {keyword["Rate Analysis"]}{" "}
        </Page.Title>
      </Page.Container>

      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Rate Analysis"] + " " + keyword["Nepali"]}
                name="nepali_name"
                placeholder={keyword["Enter equipment name"]}
                rules={{ required: "Equipment name in nepali is required." }}
                error={errors["nepali_name"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Rate Analysis"] + " " + keyword["English"]}
                name="english_name"
                placeholder={keyword["Enter equipment name in english"]}
                rules={{ required: "Equipment name in english is required." }}
                error={errors["english_name"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.TextArea
                label={keyword["Rate Analysis Description"] + "(" + keyword["Nepali"] + ")"}
                name="nepali_description"
                placeholder={keyword["Enter your equipment description in Nepali"]}
                rules={{ required: "Equipment description in Nepali is required." }}
                error={errors["nepali_description"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.TextArea
                label={keyword["Rate Analysis Description"] + "(" + keyword["English"] + ")"}
                name="english_description"
                placeholder={keyword["Enter your equipment description in English"]}
                rules={{ required: "Equipment description in English is required." }}
                error={errors["english_description"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <label> {keyword["VAT applied"]} </label>
              <Flex>
                <Controller
                  name="vat_applied"
                  control={control}
                  rules={{ required: "Please select an option" }}
                  render={({ field: { name, onChange, value } }) => (
                    <Flex gap="1rem">
                      <input type="radio" value={"true"} checked={value === "true"} name={name} onChange={onChange} />
                      {keyword["Yes"]}
                      <input type="radio" value={"false"} checked={value === "false"} name={name} onChange={onChange} />
                      {keyword["No"]}
                    </Flex>
                  )}
                />
              </Flex>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.ReactSelect
                options={unitOptions}
                label={keyword["Unit"]}
                name="unit"
                placeholder={keyword["Enter unit"]}
                rules={{ required: "Unit name is required." }}
                error={errors["unit"]}
              />
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup>
                <ReuseForm.ReactSelect
                  options={workingTypeOptions}
                  label={keyword["Working type"]}
                  name="working_type"
                  placeholder={keyword["Enter unit name"]}
                  rules={{ required: "Unit name is required." }}
                  error={errors["working_type"]}
                />
              </Form.FormGroup>
            </Grid.Col>

            <Grid.Col spanMd={6} spanXs={12}>
              <ReuseForm.Input
                label={keyword["Code"]}
                name="code"
                placeholder={keyword["Enter code name"]}
                rules={{ required: "Code name is required." }}
                error={errors["code"]}
              />
            </Grid.Col>

            <Grid.Col spanMd={12} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword.Update : keyword.Add} {keyword["Rate Analysis"]}
              </Button>
              <Button onClick={handleCancel}>{keyword.Cancel}</Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
}

export default FixedRateAnalysisFormPage;
