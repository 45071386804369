import React, { createContext, HtmlHTMLAttributes, useContext, useEffect, useRef, useState } from "react";
import { StyledButton, StyledCard, StyledContainer, StyledMenuContainer, StyledProfile } from "./Profile.style";

export const ProfileContext = createContext<{
  profileShow: boolean;
  setProfileShow: Function;
}>({
  profileShow: false,
  setProfileShow: () => {},
});

export interface ProfilePropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

export default function Profile(props: ProfilePropsI) {
  const [profileShow, setProfileShow] = useState(false);

  return (
    <ProfileContext.Provider value={{ profileShow, setProfileShow }}>
      <StyledProfile {...props} />
    </ProfileContext.Provider>
  );
}

export interface ProfileContainerPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

Profile.Container = function ProfileContainerFunction(props: ProfileContainerPropsI) {
  return <StyledContainer {...props} />;
};

export interface ProfileMenuContainerPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

Profile.MenuContainer = function ProfileMenuContainerFunction(props: ProfileMenuContainerPropsI) {
  const { profileShow } = useContext(ProfileContext);

  if (profileShow) {
    return <StyledMenuContainer {...props} />;
  }

  return <React.Fragment />;
};

export interface ProfileCardPropsI extends HtmlHTMLAttributes<HTMLButtonElement> {}

Profile.Card = function ProfileCardFunction(props: ProfileCardPropsI) {
  const { setProfileShow } = useContext(ProfileContext);
  const ref = useRef<any>(null);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      const parentNode = ref.current;
      const el: any = e.target;
      if (el !== parentNode && !parentNode?.contains(e.target)) {
        setProfileShow(false);
      }
    });
  }, [setProfileShow]);

  return (
    <StyledCard
      {...props}
      ref={ref}
      onClick={() => {
        setProfileShow((show: boolean) => !show);
      }}
    />
  );
};

export interface ProfileButtonPropsI extends HtmlHTMLAttributes<HTMLButtonElement> {}

Profile.Button = function ProfileButtonFunction(props: ProfileButtonPropsI) {
  const { setProfileShow } = useContext(ProfileContext);
  return <StyledButton onClick={() => setProfileShow(false)} {...props} />;
};
