import React, { useEffect, useState } from "react";
import UserProfile from "../components/user-profile/UserProfile";
import { useAppDispatch, useAppSelector } from "../rtk/hook";
import { logout } from "../rtk/feature/auth/authApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getProfile, getUser } from "../rtk/feature/users/usersApi";
import { showLoading } from "../rtk/feature/auth/authSlice";
import BasicInformationPane from "../components/profile-page/BasicInformationPane";
import CredentialInformationPane from "../components/profile-page/CredentialnformationPane";
import { useTranslation } from "react-i18next";
import ActivityLogPane from "../components/profile-page/ActivityLogPane";
import { trimText } from "@ims/common/src/utils/trimText";
import useTranslated from "../hooks/useTranslated";

function ProfilePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();

  const { item, loading } = useAppSelector((state) => state.users);
  const [curUser, setCurUser] = useState<any>(undefined);

  useEffect(() => {
    if (item) setCurUser(item);
  }, [item]);

  useEffect(() => {
    dispatch(showLoading(loading));
  }, [loading, dispatch]);

  useEffect(() => {
    if (params.id) dispatch(getUser(params.id));
    else dispatch(getProfile());
  }, [dispatch, params.id]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <React.Fragment>
      <UserProfile.Wrapper>
        <UserProfile>
          <UserProfile.Card>
            <UserProfile.Avatar src="/logo.png" />
            <UserProfile.Title>{trimText(curUser?.full_name_english, 50)}</UserProfile.Title>
            <UserProfile.PostTitle>{curUser?.role}</UserProfile.PostTitle>

            <UserProfile.MenuContainer>
              <UserProfile.Button
                onClick={() => {
                  navigate(`/user/edit/${curUser?.id}`);
                }}
              >
                {t("Edit Profile")}
              </UserProfile.Button>
              <UserProfile.Button onClick={handleLogout}>{t("Logout")}</UserProfile.Button>
            </UserProfile.MenuContainer>
          </UserProfile.Card>
        </UserProfile>

        <UserProfile.Tab>
          <UserProfile.TabHeader>
            <UserProfile.TabHeadButton id="basic">{t("Basic Information")}</UserProfile.TabHeadButton>

            {true && (
              <UserProfile.TabHeadButton id="credentials" disabled={false}>
                {t("Credentials Information")}
              </UserProfile.TabHeadButton>
            )}

            {/* <UserProfile.TabHeadButton id="activity">{t("Activity Log")}</UserProfile.TabHeadButton> */}
          </UserProfile.TabHeader>

          <UserProfile.TabBody>
            <UserProfile.TabPane targetId="basic">
              <BasicInformationPane curUser={curUser} />
            </UserProfile.TabPane>

            <UserProfile.TabPane targetId="credentials">
              <CredentialInformationPane curUser={curUser} />
            </UserProfile.TabPane>
            <UserProfile.TabPane targetId="activity">
              <ActivityLogPane curUser={curUser} />
            </UserProfile.TabPane>
          </UserProfile.TabBody>
        </UserProfile.Tab>
      </UserProfile.Wrapper>
    </React.Fragment>
  );
}

export default ProfilePage;
