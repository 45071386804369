import React, { useTransition } from "react";
import { MainLayout } from "@ims/common";
import useTranslated from "../hooks/useTranslated";

function DashboardPage() {
  const { keyword } = useTranslated();

  return (
    <MainLayout.Main>
      <p>{keyword["Welcome to Dashboard!"]}</p>
    </MainLayout.Main>
  );
}

export default DashboardPage;
