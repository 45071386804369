import { createSlice } from "@reduxjs/toolkit";
import { INetworkRResponse, IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { IYojana } from "../plan-verification/planVerificationSlice";
import { getBillVerifications, postBillVerification, updatetBillVerification } from "./billVerificationAPI";



export interface IResult {
    id: string
    planning_bill_id: IPlanningBillId
    user_id: string
    status: string
    description: string
    comment: string
    is_verified: boolean
}

export interface IPlanningBillId {
    id: string
    yojana: IYojana
    bill_name: string
    bill_file: string
    bill_review: boolean
    is_verified: boolean
}





interface IBillVErificationSlice extends INetworkRResponse {
    data: IPaginatedResponse<IResult> | null,
    formSubmitted: boolean,
    byId: IBillVerificationById,

}


export interface IBillVerificationById extends INetworkRResponse {
    data: IPlanningBillId | null
}

const initialState: IBillVErificationSlice = {
    data: null,
    error: null,
    loading: false,
    formSubmitted: false,
    byId: {
        data: null,
        error: null,
        loading: false
    }
}
const billVerificationSlice = createSlice({
    name: "billVerificationSlice",
    initialState,
    reducers: {
        cleanupVerificationSlice: (state) => {
            return initialState
        }
    },
    extraReducers(builder) {
        builder.addCase(getBillVerifications.pending, (state) => {
            state.data = null;
            state.loading = true;
            state.error = null


        });
        builder.addCase(getBillVerifications.rejected, (state) => {
            state.data = null;
            state.loading = false;
            state.error = null
        });
        builder.addCase(getBillVerifications.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.loading = false;
            state.error = null
        })
        builder.addCase(postBillVerification.pending, (state) => {
            state.formSubmitted = false
        });
        builder.addCase(postBillVerification.fulfilled, (state, { payload }) => {
            state.formSubmitted = true

        })

        builder.addCase(updatetBillVerification.pending, (state, { payload }) => {
            state.formSubmitted = false

        })
        builder.addCase(updatetBillVerification.fulfilled, (state, { payload }) => {
            state.formSubmitted = true

        })



    },
});
export const { cleanupVerificationSlice } = billVerificationSlice.actions
export default billVerificationSlice.reducer