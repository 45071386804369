import { createSlice } from "@reduxjs/toolkit";
import { addYojanaBook, getYojanaBook, getYojanaBooks, removeYojanaBook, updateYojanaBook } from "./yojanaBooksApi";

export interface YojanaBooksFormTypeI {
  id: string;
  title: string;
  content: string;
  order: number;
  pdf_file?: string;
}

export interface CreateYojanaBookTypeI {
  id?: string;
  title?: string;
  content?: string;
  order?: number;
  pdf_file?: string;
  pdf_file_url?: string;
}

export interface YojanaBookTypeI {
  id: string;
  title: string;
  content: string;
  order: number;
  pdf_file?: string;
  pdf_file_url?: string;
}

export interface YojanaBooksSliceStateI {
  loading: boolean;
  data: YojanaBookTypeI[] | [];

  hasSubmitted: boolean;
  error: any;
}

const initialState: YojanaBooksSliceStateI = {
  loading: false,
  data: [],
  hasSubmitted: false,
  error: null,
};

const YojanaBooksSlice = createSlice({
  name: "YojanaBooks",
  initialState,
  reducers: {
    YojanaBooksCleanUp: (state) => {
      state.data = [];
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getYojanaBooks.pending, (state) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(getYojanaBooks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getYojanaBooks.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
    builder.addCase(addYojanaBook.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addYojanaBook.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addYojanaBook.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });

    builder.addCase(getYojanaBook.pending, (state) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(getYojanaBook.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getYojanaBook.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
    builder.addCase(updateYojanaBook.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(updateYojanaBook.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(updateYojanaBook.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
    builder.addCase(removeYojanaBook.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.hasSubmitted = false;
    });
    builder.addCase(removeYojanaBook.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = null;
    });
    builder.addCase(removeYojanaBook.rejected, (state, { payload }: any) => {
      state.error = payload;
      state.loading = false;
      state.hasSubmitted = false;
    });
  },
});

export const { YojanaBooksCleanUp } = YojanaBooksSlice.actions;
export default YojanaBooksSlice.reducer;
