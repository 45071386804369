import { Button, Flex, Form, Table } from "@ims/common";
import { getValue } from "@testing-library/user-event/dist/utils";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Input from "react-select/dist/declarations/src/components/Input";
import Page from "../../../../../core-ui/Page";
import useDiscloure from "../../../../../hooks/useDiscloure";
import useTranslated, { keywordTypes } from "../../../../../hooks/useTranslated";
import { IGeneralWorkData } from "../../../../../rtk/feature/general-work/generalWorkSlice";
import { useAppDispatch } from "../../../../../rtk/hook";
import Dialog from "../../Dialog/Dialog";
import { StringMap } from "i18next";
import { prettifyNumberValue } from "../../../../../utils/Prettify";

interface IProps {
  editUrl: string;
  data: IGeneralWorkData[] | null;
  deleteSuccess: boolean;
  onEditClick: (id: string) => void;
  onAddClick: () => void;
  addUrl: string;
  tableName: string;
  onDelete?: (id: string) => void;
  onRateAdd?: (value: number, workId: string) => void;
  onFinalQuantityAdd?: (value: number, workId: string) => void;
  rateAddSuccess: boolean;
  hideAddBtn?: boolean;
}

interface AddFeatureI {
  rate: boolean;
  finalQuantity: boolean;
}

const WorkTable = (props: IProps) => {
  const [addActiveID, setAddActiveID] = useState<string>("");
  const [addFeature, setAddFeature] = useState<AddFeatureI>({
    rate: false,
    finalQuantity: false,
  });

  const {
    data,
    onRateAdd,

    deleteSuccess,
    rateAddSuccess,
    onDelete,
    addUrl,
    editUrl,
    onEditClick,
    onAddClick,
  } = props;

  const { close, open, isOpen } = useDiscloure();
  const { close: closePopup, open: openPopup, isOpen: isPopupOpen } = useDiscloure();
  const [workData, setWorkData] = useState<IGeneralWorkData[] | []>([]);

  const dispatch = useAppDispatch();
  const [activeDeleteID, setActiveDeleteID] = useState("");
  const deleteItem = () => {
    onDelete?.(activeDeleteID);
    // dispatch(deleteGeneralWork({ id: props.budgetId, workId: activeDeleteItem }))
  };
  const handleDeleteClick = (id: string) => {
    open();
    setActiveDeleteID(id);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (deleteSuccess) {
      close();
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (rateAddSuccess) {
      closePopup();
    }
  }, [rateAddSuccess]);

  useEffect(() => {
    if (data) setWorkData(data);
    else setWorkData([]);
  }, [data]);

  const onAdd = () => {
    navigate(addUrl);
  };

  useEffect(() => {
    return () => setActiveDeleteID("");
  }, []);

  interface IForm {
    rate_value: number;
    final_quantity: number;
  }
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IForm>({});

  type columnTypes = keyof keywordTypes;
  const columns: columnTypes[] = [
    "Name",
    "Number",
    "Length",
    "Breadth",
    "Height",
    "Unit",
    "Remarks",
    "Quantity",
    "Final Quantity",
    "Rate",
    "Total",
  ];

  const handleAddRate = (workId: string) => {
    const rate = getValues("rate_value");
    onRateAdd?.(rate, workId);
    const updatedWorkData = workData?.map((work) => {
      if (work.id === workId) return { ...work, rate: rate.toString() };
      else return work;
    });
    if (!!updatedWorkData) setWorkData(updatedWorkData);
  };

  const handleOpenPopup = (id: string, feature: string) => {
    setAddActiveID(id);
    openPopup();
    setAddFeature({
      rate: feature === "rate",
      finalQuantity: feature === "finalQuantity",
    });
  };

  useEffect(() => {
    const activeId = workData?.find((el) => el.id === addActiveID);

    if (activeId) {
      setValue("rate_value", parseInt(activeId.rate));
      setValue("final_quantity", parseInt(activeId.final_quantity || "0"));
    }
  }, [workData, addActiveID]);

  const actionButtonStyles = {};
  const { keyword } = useTranslated();
  return (
    <>
      <Dialog active={isOpen} onClose={close}>
        <Dialog.Container className="container-dialog">
          <div className="container-body">
            <h2 className="sub-title">Are you sure, you want to delete?</h2>
            <div className="container-button">
              <Button onClick={close}>{keyword.Cancel}</Button>
              <Button variant="error" onClick={deleteItem}>
                Confirm
              </Button>
            </div>
          </div>
        </Dialog.Container>
      </Dialog>
      <Dialog active={isPopupOpen && addFeature.rate} onClose={closePopup}>
        <Dialog.Container className="container-dialog">
          <div className="container-body">
            <h2 className="sub-title">{keyword["Do you want to add rate?"]}</h2>

            <Flex justify="space-between" height="auto">
              <h4 className="title">{keyword["Add rate!"]}</h4>
              <Form.FormGroup error={!!errors.rate_value}>
                <Form.Input type="number" register={register("rate_value", { required: "rate value is required" })} />
                <Form.HelperText> {errors.rate_value?.message} </Form.HelperText>
              </Form.FormGroup>
              <Button variant="primary" style={{ marginLeft: ".5rem" }} onClick={() => handleAddRate(addActiveID)}>
                {keyword.Add}
              </Button>
              <Button type="button" onClick={closePopup}>
                {keyword.Cancel}
              </Button>
            </Flex>
          </div>
        </Dialog.Container>
      </Dialog>{" "}
      <Page.Container>
        <Flex align="center" justify="space-between">
          <Page.Title> {props.tableName} </Page.Title>
          {!props.hideAddBtn && (
            <Page.ButtonGrp>
              <Button variant="primary" style={{ display: "flex", alignItems: "center" }} onClick={onAdd}>
                {" "}
                {keyword.Add} <MdAdd />
              </Button>
            </Page.ButtonGrp>
          )}
        </Flex>
      </Page.Container>
      <Table.Container>
        <Table id="export-table">
          <Table.Head>
            <Table.Row>
              {columns.map((column, idx) => {
                return <Table.HeadCol key={idx}>{keyword[column]} </Table.HeadCol>;
              })}
              {!props.hideAddBtn && <Table.HeadCol width="20rem">{keyword.Actions} </Table.HeadCol>}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {workData?.map((work, idx) => {
              return (
                <Table.Row key={idx}>
                  <Table.Col> {work.name} </Table.Col>
                  <Table.Col> {work.number} </Table.Col>
                  <Table.Col> {work.length} </Table.Col>
                  <Table.Col> {work.breadth} </Table.Col>
                  <Table.Col> {work.height} </Table.Col>
                  <Table.Col> {work.unit?.name} </Table.Col>
                  <Table.Col> {work.remarks} </Table.Col>
                  <Table.Col> {work.quantity} </Table.Col>
                  <Table.Col>
                    {/* <span style={{ cursor: "pointer" }} onClick={() => handleOpenPopup(work.id, "finalQuantity")}>
                      {work.final_quantity || (
                        <Table.ActionButton color="green">
                          <FaPlus />
                        </Table.ActionButton>
                      )}
                    </span> */}
                    {prettifyNumberValue(work.final_quantity)}
                  </Table.Col>
                  <Table.Col> {work.rate} </Table.Col>
                  <Table.Col> {work.total} </Table.Col>

                  {!props.hideAddBtn && (
                    <Table.Col align="center">
                      <Flex style={{ flexWrap: "nowrap", width: "11rem", justifyContent: "space-between" }}>
                        <Table.ActionButton onClick={() => onEditClick(work.id)}>
                          <FaEdit />{" "}
                        </Table.ActionButton>
                        <Table.ActionButton color="red" onClick={() => handleDeleteClick(work.id)}>
                          {" "}
                          <FaTrash />
                        </Table.ActionButton>
                        <Table.ActionButton color="green" onClick={() => handleOpenPopup(work.id, "rate")}>
                          {" "}
                          <FaPlus />
                        </Table.ActionButton>

                        {/* <Table.ActionButton color="green" onClick={() => handleOpenPopup(work.id, "finalQuantity")}>
                          <FaPlus />
                        </Table.ActionButton> */}
                      </Flex>
                    </Table.Col>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Table.Container>
    </>
  );
};

export default WorkTable;
