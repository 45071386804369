import { createSlice } from "@reduxjs/toolkit";
import { FaSleigh } from "react-icons/fa";
import { INetworkRResponse, IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { getplanningContractorById, getPlanningContractors, postPlanningContractor, updatePlanningContractor } from "./planningContractorAPI";



export interface IPlanningContractor {
    id: string
    yojana_id: YojanaId
    user_id: UserId
    name: string
    description: string
    work_update: string
    is_completed: boolean
}

export interface YojanaId {
    id: string
    serial_no: number
    hint: string
    name: string
    expenditure_heading: string
    source: string
    attention: string
    unit: string
    allocation: string
    expenditure: string
    expenditure_rate: string
    balance: string
}

export interface UserId {
    id: string
    profile_id: any
    username: string
    email: string
    mobile_number: string
    role: string
    ward: Ward
    first_name: string
    last_name: string
    is_staff: boolean
    is_active: boolean
    is_superuser: boolean
    last_login: string
}

export interface Ward {
    id: string
    ward_name: string
    ward_number: number
}


interface IplanningContractorById extends INetworkRResponse {
    data: IPlanningContractor | null,


}


interface IInitialState extends INetworkRResponse {
    data: null | IPaginatedResponse<IPlanningContractor>,
    formSubmitted: boolean,
    byId: IplanningContractorById

}

const initialState: IInitialState = {
    data: null,
    error: null,
    loading: false,
    formSubmitted: false,
    byId: {
        data: null,
        error: null,
        loading: false

    }
}

const planningContractorSlice = createSlice({
    name: "planningContractor",
    initialState,
    reducers: {
        cleanupPlanningContractor: (state) => {
            return initialState
        }
    }
    , extraReducers(builder) {

        builder.addCase(getPlanningContractors.pending, (state) => {
            state.data = null;
            state.loading = true;
            state.error = null

        })

        builder.addCase(getPlanningContractors.rejected, (state) => {
            state.data = null;
            state.loading = false;
            state.error = null

        });
        builder.addCase(getPlanningContractors.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.loading = false;
            state.error = null

        });

        builder.addCase(getplanningContractorById.pending, (state) => {
            state.byId.data = null;
            state.byId.loading = true;
            state.byId.error = null

        })

        builder.addCase(getplanningContractorById.rejected, (state) => {
            state.byId.data = null;
            state.byId.loading = false;
            state.byId.error = null

        });
        builder.addCase(getplanningContractorById.fulfilled, (state, { payload }) => {
            state.byId.data = payload;
            state.byId.loading = false;
            state.byId.error = null

        });






        builder.addCase(postPlanningContractor.pending, (state, { payload }) => {
            state.formSubmitted = false

        });
        builder.addCase(postPlanningContractor.fulfilled, (state, { payload }) => {
            state.formSubmitted = true

        });


        builder.addCase(updatePlanningContractor.pending, (state, { payload }) => {
            state.formSubmitted = false

        });
        builder.addCase(updatePlanningContractor.fulfilled, (state, { payload }) => {
            state.formSubmitted = true

        });





    },
});
export const { cleanupPlanningContractor } = planningContractorSlice.actions;
export default planningContractorSlice.reducer