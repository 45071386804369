import styled, { css } from "styled-components/macro";

export const StyledTab = styled.div`
  border-radius: 4px;
  width: 100%;
`;

export const StyledTabHeader = styled.div`
  background-color: white;
  border-bottom: 3px solid #ccc;
`;

export const StyledTabHeadButton = styled.button<{ disabled?: boolean }>`
  padding: 1rem 2rem;
  position: relative;
  background: transparent;
  text-transform: capitalize;
  margin: 0px;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0px;
  font: inherit;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0px;
  }

  &:hover,
  &.active {
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:hover::after,
  &.active::after {
    background: ${(props) => props.theme.palette.primary.main};
  }

  ${(props) =>
    props.disabled &&
    css`
      &,
      &:hover,
      &.active {
        color: #999;
        cursor: not-allowed;
      }
      &:hover::after,
      &.active::after {
        background: transparent;
      }
    `}
`;

export const StyledTabBody = styled.div`
  padding: 2rem;
  background: white;

  box-shadow: rgba(41, 48, 66, 0.05) 0px 0px 0.875rem 0px;
`;

export const StyledTabPane = styled.div`
  /* table {
    width: 100%;
    font-size: 1.5rem;
    overflow: auto;

    tr td:first-child {
      font-weight: 500;
      width: 180px;
    }

    td {
      padding: 0.5rem 0rem;
      font-weight: 300;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  } */
`;
