import React from "react";
import styled from "styled-components";

const GalleryContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

interface GalleryProps {
  children: React.ReactNode;
}

const GalleryCard: React.FC<GalleryProps> = ({ children }) => {
  return <GalleryContainer>{children}</GalleryContainer>;
};

export default GalleryCard;
