import { createAsyncThunk } from "@reduxjs/toolkit";
import { imsClient } from "../../../utils/imsClient";
import { AppError } from "../../../utils/AppError";
import { toast } from "react-toastify";
import { MinuteSuccess } from "../../../utils/res-mes/response-message-generator";

interface UpabhoktaSamitiMinuteI {
  upabhokta_samiti: string;
  comments: string;
  title: string;
  file: string;
}
export interface UpabhoktaSamitiMinuteUpdateI {
  upabhokta_samiti?: string;
  comments?: string;
  title?: string;
  file?: string;
}

export const getUpahboktaSamitiMinute = createAsyncThunk(
  "getUpahboktaSamitiMinutes",
  async (args: { id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/yojana/${args.id}/upabhokta-details/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addUpahboktaSamitiMinute = createAsyncThunk(
  "addUpahboktaSamitiMinutes",
  async (args: { id: string; data: UpabhoktaSamitiMinuteI }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/yojana/${args.id}/upabhokta-details/`, args.data);
      if (res.data) toast.success(MinuteSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
export const updateUpahboktaSamitiMinute = createAsyncThunk(
  "updateUpahboktaSamitiMinute",
  async (
    args: { yojanaId: string; minuteId: string; data: UpabhoktaSamitiMinuteUpdateI },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await imsClient.patch(`/yojana/${args.yojanaId}/upabhokta-details/${args.minuteId}/`, args.data);
      if (res.data) toast.success(MinuteSuccess("updated"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
