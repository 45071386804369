import { createSlice } from "@reduxjs/toolkit";
import { IYojana } from "../plan-verification/planVerificationSlice";
import { WardTypeI } from "../ward/wardSlice";
import { assignWard, getAssignedWard } from "./assignWardApi";
import { keywordTypes } from "../../../hooks/useTranslated";

export interface WardDetailI {
  ward_name: keyof keywordTypes;
  ward_name_en: string;
  ward_number: number;
  ward_address: string;
  ward_address_en: string;
  ward_phone_no: string;
  ward_email: string;
  ward_fax_no: string;
  ward_chief: string;
  ward_chief_no: string;
}

export interface AssignWardTypeI {
  id: string;
  yojana: IYojana;
  ward: WardDetailI;
}

export interface AssignWardSliceStateI {
  loading: boolean;
  data: AssignWardTypeI[] | null;
  hasSubmitted: boolean;
  error: any;
}

const initialState: AssignWardSliceStateI = {
  loading: false,
  data: null,
  hasSubmitted: false,
  error: null,
};

const assignWardSlice = createSlice({
  name: "assignWard",
  initialState,
  reducers: {
    assignWardCleanUp: (state) => {
      state.data = null;
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(assignWard.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(assignWard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(assignWard.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getAssignedWard.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(getAssignedWard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getAssignedWard.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
  },
});

export const { assignWardCleanUp } = assignWardSlice.actions;
export default assignWardSlice.reducer;
