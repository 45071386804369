import { Button, Form, Grid, MainLayout } from "@ims/common";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Page from "../../core-ui/Page";
import ReuseForm from "../../core-ui/ReuseForm";
import useTranslated from "../../hooks/useTranslated";
import { IOptions } from "../../interfaces/options.interface";
import {
  getplanningContractorById,
  postPlanningContractor,
  updatePlanningContractor,
} from "../../rtk/feature/planning-contractor/planningContractorAPI";
import { cleanupPlanningContractor } from "../../rtk/feature/planning-contractor/planningContractorSlice";
import { getUsers } from "../../rtk/feature/users/usersApi";
import { getYojana, getYojanas } from "../../rtk/feature/yojana/yojanaApi";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";

export interface IPlanningContractorForm {
  yojana: IOptions;
  user: IOptions;
  name: string;
  description: string;
  work_update: string;
}
const PlanningContractorForm = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const editMode = location.pathname.includes("/edit");

  const { id } = useParams();

  const {
    formSubmitted,
    byId: { data: dataById },
  } = useAppSelector((state) => state.planningContractor);
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    setValue,
  } = useForm<IPlanningContractorForm>();

  useEffect(() => {
    dispatch(getYojanas());
    dispatch(getUsers({ params: {} }));
  }, []);

  useEffect(() => {
    if (dataById) {
      setValue("description", dataById.description);
      setValue("name", dataById.name);
      setValue("work_update", dataById.work_update);
      setValue("yojana", { label: dataById.yojana_id.name, value: dataById.yojana_id.id });
      setValue("user", { label: dataById.user_id.first_name, value: dataById.user_id.id });
    }
  }, [dataById]);

  useEffect(() => {
    if (!id) return;

    const abortController = new AbortController();
    dispatch(getplanningContractorById({ id: id, signal: abortController.signal }));
    return () => abortController.abort();
  }, [id]);

  const onSubmit = (data: IPlanningContractorForm) => {
    const finalData = { ...data, yojana_id: data.yojana.value, user_id: data.user.value };

    if (editMode && id) {
      dispatch(updatePlanningContractor({ data: finalData, id: id }));
    } else {
      dispatch(postPlanningContractor(finalData));
    }
  };

  const allUsers = useAppSelector((state) => state.users.data?.results);
  const yojanas = useAppSelector((state) => state.yojanas.data?.results);
  const userOptions = allUsers?.map((user) => {
    return {
      label: user.first_name,
      value: user.id,
    };
  });
  const yojanaOptions = yojanas?.map((yojana) => {
    return {
      label: yojana.name,
      value: yojana.id,
    };
  });
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/planning-contractor");
  };
  useEffect(() => {
    if (formSubmitted) {
      navigate("/planning-contractor");
    }
    return () => {
      dispatch(cleanupPlanningContractor());
    };
  }, [formSubmitted]);

  const { keyword } = useTranslated();
  return (
    <MainLayout.Main>
      {" "}
      <Page.Title> {keyword["Planning  Contractor"]} </Page.Title>
      <ReuseForm control={control} onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid.Row gap={1.5}>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["user"])}>
                <ReuseForm.ReactSelect
                  label={keyword.User}
                  name="user"
                  options={userOptions}
                  placeholder={keyword["Select Type"]}
                  rules={{ required: "Type is required." }}
                  error={errors["user"]}
                />
                {/* <Form.HelperText  > {errors.remarks?.message} </Form.HelperText> */}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["yojana"])}>
                <ReuseForm.ReactSelect
                  label={keyword.Yojana}
                  name="yojana"
                  options={yojanaOptions}
                  placeholder={keyword["Select Type"]}
                  rules={{ required: "Type is required." }}
                  error={errors["yojana"]}
                />
                {/* <Form.HelperText  > {errors.remarks?.message} </Form.HelperText> */}
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["name"])}>
                <Form.Label> {keyword.Name} </Form.Label>

                <Form.Input
                  placeholder={keyword["Name"]}
                  register={register("name", { required: "name is necessary" })}
                  name="name"
                />
                <Form.HelperText> {errors.name?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["name"])}>
                <Form.Label> {keyword.Description} </Form.Label>

                <Form.Input
                  placeholder={keyword["Description"]}
                  register={register("description", { required: "description is necessary" })}
                  name="description"
                />
                <Form.HelperText> {errors.description?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>{" "}
            <Grid.Col spanMd={6} spanXs={12}>
              <Form.FormGroup error={Boolean(errors && errors["work_update"])}>
                <Form.Label> {keyword["Work Update"]} </Form.Label>

                <Form.Input
                  placeholder={keyword["Work Update"]}
                  register={register("work_update")}
                  name="work_update"
                />
                <Form.HelperText> {errors.work_update?.message} </Form.HelperText>
              </Form.FormGroup>
            </Grid.Col>
            <Grid.Col spanMd={6} spanXs={12}>
              <Button type="submit" variant="primary">
                {editMode ? keyword.Update : keyword.Add}
              </Button>
              <Button type="button" onClick={handleCancel}>
                {keyword.Cancel}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>
    </MainLayout.Main>
  );
};

export default PlanningContractorForm;
