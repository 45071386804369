import { ActionI, LangI, generateSuccessMessage, getActiveLang, messageTypes } from "./response-message";

const feature: messageTypes = {
  en: {
    Unit: "Unit",
    Minute: "Minute",
    AnalyzeRate: "AnalyzeRate",
    Constructor: "Constructor",
    ConstructorMember: "Constructor Member",
    Ward: "Ward",
    Data: "Data",
    Budget: "Budget",
    CommunicationTitle: "Communication title",
    Document: "Document",
    Rate: "Rate",
    Yojana: "Yojana",
    Equipment: "Equipment",
    FiscalYear: "Fiscal Year",
    FixedAnalyzeRate: "Fixed Analyze Rate",
    FixedEquipmentRate: "Fixed Equipment Rate",
    GeneralDocument: "General Document",
    Work: "Work",
    Information: "Information",
    PlanBrief: "Plan Brief",
    PlanEstimate: "Plan Estimate",
    PlanEstimateItem: "Plan Estimate Item",
    EquipmentRate: "Equipment Rate",
    RelativeDocs: "Relative Docs",
    TransportationType: "Transportation Type",
    User: "User",
    YojanaTemplate: "Yojana Template",
    YojanaBook: "Yojana Book",
    Password: "Password",
    Bill: "Bill",
  },

  np: {
    Unit: "एकाइ",
    Minute: "माईन्युट",
    AnalyzeRate: "विश्लेषण दर",
    Constructor: "निर्माणकर्ता",
    ConstructorMember: "निर्माणकर्ता सदस्य",
    Ward: "वडा",
    Data: "डाटा",
    Budget: "बजेट",
    CommunicationTitle: "संचार शीर्षक",
    Document: "कागजात",
    Rate: "दर",
    Yojana: "योजना",
    Equipment: "उपकरण",
    FiscalYear: "वित्तीय वर्ष",
    FixedAnalyzeRate: "स्थिर विश्लेषण दर",
    FixedEquipmentRate: "निश्चित उपकरण दर",
    GeneralDocument: "सामान्य कागजात",
    Work: "कार्य",
    Information: "जानकारी",
    PlanBrief: "योजना संक्षिप्त",
    PlanEstimate: "योजना अनुमान",
    EquipmentRate: "उपकरण दर",
    RelativeDocs: "सापेक्ष कागजात",
    TransportationType: "यातायात प्रकार",
    PlanEstimateItem: "योजना अनुमानित वस्तु",
    User: "प्रयोगकर्ता",
    YojanaTemplate: "योजना टेम्प्लेट",
    YojanaBook: "योजना पत्र",
    Password: "पासवर्ड",
    Bill: "बिल",
  },
};

export const AuthSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  return generateSuccessMessage(action, "en");
};
export const VerifySuccess = AuthSuccess;

export const UnitSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Unit;
  return generateSuccessMessage(action, lang, name);
};

export const MinuteSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Minute;
  return generateSuccessMessage(action, lang, name);
};

export const AnalyzeRateSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].AnalyzeRate;
  return generateSuccessMessage(action, lang, name);
};
export const ConstructorSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Constructor;
  return generateSuccessMessage(action, lang, name);
};
export const ConstructorMemberSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].ConstructorMember;
  return generateSuccessMessage(action, lang, name);
};
export const WardSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Ward;
  return generateSuccessMessage(action, lang, name);
};
export const DataSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Data;
  return generateSuccessMessage(action, lang, name);
};
export const BudgetSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Budget;
  return generateSuccessMessage(action, lang, name);
};
export const CommunicationTitleSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].CommunicationTitle;
  return generateSuccessMessage(action, lang, name);
};
export const DocumentSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Document;
  return generateSuccessMessage(action, lang, name);
};
export const RateSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Rate;
  return generateSuccessMessage(action, lang, name);
};
export const YojanaSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Yojana;
  return generateSuccessMessage(action, lang, name);
};
export const EquipmentSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Equipment;
  return generateSuccessMessage(action, lang, name);
};
export const FiscalYearSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].FiscalYear;
  return generateSuccessMessage(action, lang, name);
};
export const FixedAnalyzeRateSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].FixedAnalyzeRate;
  return generateSuccessMessage(action, lang, name);
};
export const FixedEquipmentRateSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].FixedEquipmentRate;
  return generateSuccessMessage(action, lang, name);
};
export const GeneralDocumentSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].GeneralDocument;
  return generateSuccessMessage(action, lang, name);
};
export const WorkSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Work;
  return generateSuccessMessage(action, lang, name);
};
export const InformationSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Information;
  return generateSuccessMessage(action, lang, name);
};
export const PlanBriefSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].PlanBrief;
  return generateSuccessMessage(action, lang, name);
};
export const PlanEstimateSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].PlanEstimate;
  return generateSuccessMessage(action, lang, name);
};
export const EquipmentRateSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].EquipmentRate;
  return generateSuccessMessage(action, lang, name);
};
export const RelativeDocsSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].RelativeDocs;
  return generateSuccessMessage(action, lang, name);
};
export const TransportationTypeSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].TransportationType;
  return generateSuccessMessage(action, lang, name);
};
export const UserSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].User;
  return generateSuccessMessage(action, lang, name);
};
export const YojanaTemplateSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].YojanaTemplate;
  return generateSuccessMessage(action, lang, name);
};
export const YojanaBookSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].YojanaBook;
  return generateSuccessMessage(action, lang, name);
};
export const PlanEstimateItemSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].PlanEstimateItem;
  return generateSuccessMessage(action, lang, name);
};
export const PasswordSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Password;
  return generateSuccessMessage(action, lang, name);
};
export const BillSuccess = (action: ActionI): string => {
  const lang: LangI = getActiveLang();
  const name = feature[lang].Bill;
  return generateSuccessMessage(action, lang, name);
};
