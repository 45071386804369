import { Control, Controller, FieldErrors, UseFormHandleSubmit, useForm } from "react-hook-form";
import {
  RelativeDocTypeI,
  RelativeDocsCleanUp,
  RelativeDocsFormTypeI,
} from "../../../../rtk/feature/relative-docs/relativeDocsSlice";
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react";
import useTranslated from "../../../../hooks/useTranslated";
import { useAppDispatch, useAppSelector } from "../../../../rtk/hook";
import { useNavigate } from "react-router-dom";
import {
  addRelativeDocs,
  getRelativeDoc,
  updateRelativeDoc,
} from "../../../../rtk/feature/relative-docs/relativeDocsApi";
import ReuseForm from "../../../../core-ui/ReuseForm";
import { Button, Flex, Form, Grid } from "@ims/common";
import { toast } from "react-toastify";

interface RelativeDocsPropsI {
  budgetID: string;
  doc: RelativeDocsFormTypeI | undefined;
  handleCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  setCreateNewData: Dispatch<SetStateAction<boolean>>;
  selectedDataId: string | null;
}

export default function EditRelativeDocs({
  budgetID,
  doc,
  handleCancel,
  setCreateNewData,
  selectedDataId,
}: RelativeDocsPropsI) {
  const [editMode, setEditMode] = useState(false);
  const [imageFile, setImageFile] = useState<string | ArrayBuffer | null>(null);
  const dispatch = useAppDispatch();
  const { keyword } = useTranslated();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    register,
    control,
  } = useForm<RelativeDocsFormTypeI>({
    defaultValues: {},
  });

  useEffect(() => {
    if (doc) {
      setValue("name", doc?.name);
    }
  }, [doc]);

  useEffect(() => {
    if (doc) {
      setEditMode(true);
    } else {
      dispatch(RelativeDocsCleanUp());
      setEditMode(false);
    }
  }, [dispatch, doc]);

  const onSubmit = (data: any) => {
    if (!budgetID) {
      toast.warning(keyword["Please select a plan."]);
      return;
    }

    let payload: any = {};

    Object.keys(data).forEach((key) => {
      payload[key] = data[key];
    });

    if (imageFile) {
      payload["file"] = imageFile.toString();
    }

    if (selectedDataId && doc) {
      dispatch(updateRelativeDoc({ id: selectedDataId, budgetID: budgetID, data: payload }));
    } else {
      dispatch(addRelativeDocs({ data: payload, budgetID: budgetID }));
    }
    setCreateNewData(false);
    navigate(-1);
  };

  const convertFileToBase64 = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        setImageFile(reader.result);
        // return reader.result;
      };
    }
  };
  return (
    <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
      <Grid>
        <Grid.Row gap={1.5}>
          <Grid.Col spanMd={12} spanXs={12}>
            <ReuseForm.Input
              label={keyword["Name"]}
              name="name"
              placeholder={keyword["Enter docs name"]}
              rules={{ required: keyword["This is required field."] }}
              error={errors["name"]}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row gap={1.5}>
          <Grid.Col spanMd={12} spanXs={12}>
            <Flex.Col>
              <Form.Label> {keyword["Relative Docs"]} </Form.Label>
              <Controller
                name="file"
                rules={{
                  required: false,
                }}
                control={control}
                render={({ field: { onChange, value }, fieldState, formState }) => {
                  return (
                    <Form.File
                      id="file"
                      fileType="image"
                      onChange={(e) => {
                        onChange(convertFileToBase64(e));
                      }}
                      previewImage={doc?.file || doc?.file_url}
                    />
                  );
                }}
              ></Controller>
              {errors && errors["file"] && errors["file"].message && (
                <Form.HelperText>{errors["file"].message}</Form.HelperText>
              )}
            </Flex.Col>
          </Grid.Col>

          <Grid.Col spanMd={12} spanXs={12}>
            <Button type="submit" variant="primary">
              {editMode ? keyword["Update Relative Docs"] : "Create Relative Docs"}
            </Button>
            <Button onClick={handleCancel}>{keyword.Cancel}</Button>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </ReuseForm>
  );
}
