import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
display:inline-block;
  width: 180px; 
  height: 140px; 
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  margin-top:1rem;
  margin-bottom:1rem
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
`;

const Title = styled.h2`
  margin-top: 16px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface CardProps {
  imageUrl: string;
  title: string;
}

const Card: React.FC<CardProps> = ({ imageUrl, title }) => {
  const openImageInNewTab = () => {
    window.open(imageUrl, "_blank");
  };

  return (
    <CardContainer>
      <a href={imageUrl} target="_blank" onClick={openImageInNewTab}>
        <Image src={imageUrl} alt={title} />
      </a>
      <Title>{title}</Title>
    </CardContainer>
  );
};

export default Card;
