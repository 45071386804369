import { createAsyncThunk } from "@reduxjs/toolkit";
import { appendErrors } from "react-hook-form";
import { toast } from "react-toastify";
import { IWorkForm } from "../../../pages/plan/estimate/components/WorkForm/WorkForm";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { WorkSuccess } from "../../../utils/res-mes/response-message-generator";
interface IGetGenralWork {
  id: string;
}
export const getGeneralWorks = createAsyncThunk("getGeneralWork", async (data: IGetGenralWork, { rejectWithValue }) => {
  try {
    const res = await imsClient.get(`yojana/${data.id}/general-works/`);
    return res.data;
  } catch (err) {
    const appError = new AppError(err);
    appError.show();
    return rejectWithValue(err);
  }
});

interface IGetById {
  id: string;
  workId: string;
}

export const getGeneralWorkById = createAsyncThunk(
  "getGeneralWorkById",
  async (data: IGetById, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`yojana/${data.id}/general-works/${data.workId}/`);
      return res.data;
    } catch (error) {
      const apppError = new AppError(error);
      apppError.show();
      rejectWithValue(apppError.getError());
    }
  }
);

interface IPostData extends Omit<IWorkForm, "unit"> {
  unit: string;
}

export const postGeneralWork = createAsyncThunk(
  "postGeneralWork",
  async ({ data, id }: { data: IPostData; id: string }, { rejectWithValue }) => {
    try {
      const res = await imsClient.post(`yojana/${id}/general-works/`, data);
      if (res.data) toast.success(WorkSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(err);
    }
  }
);

interface IUpdateGeneralWork {
  data: any;
  id: string;
  workId: string;
}

export const updateGeneralWork = createAsyncThunk(
  "updateGeneralWork",
  async ({ data, id, workId }: IUpdateGeneralWork, { rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`yojana/${id}/general-works/${workId}/`, data);
      if (res.data) toast.success(WorkSuccess("updated"));

      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(err);
    }
  }
);

interface IDeleteGeneralWork {
  id: string;
  workId: string;
}

export const deleteGeneralWork = createAsyncThunk(
  "deleteeGeneralWork",
  async ({ id, workId }: IDeleteGeneralWork, { rejectWithValue }) => {
    try {
      const res = await imsClient.delete(`yojana/${id}/general-works/${workId}/`);
      if (res.data) toast.success(WorkSuccess("deleted"));
      return { deltedWorkId: workId };
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(err);
    }
  }
);
