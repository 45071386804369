import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";

interface IGetChequeVerifyByPrasasakiya {
  params: {
    per_page?: number;
    page?: number;
    search?: any;
    ordering?: any;
  };
}

export const getChequeVerifyByPrasasakiya = createAsyncThunk(
  "chequeVerifyByPrasasakiya",
  async ({ params }: IGetChequeVerifyByPrasasakiya, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/cheque-verify-by-prasasakiya/`, { params });
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(error);
    }
  }
);

export const getChequeVerifyByPrasasakiyaById = createAsyncThunk(
  `chequeVerifyByPrasasakiyaById`,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/cheque-verify-by-prasasakiya/${id}/`);
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(error);
    }
  }
);

interface VerifyForm {
  data: FormData;
  id: string;
}

export const postChequeVerifyByPrasasakiya = createAsyncThunk(
  `postchequeVerifyByPrasasakiyaBy`,
  async ({ id, data }: VerifyForm, { rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/cheque-verify-by-prasasakiya/${id}/`, data, {
        headers: { "Content-Type": "form/multipart" },
      });
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      rejectWithValue(error);
    }
  }
);
