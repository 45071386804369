import { createSlice } from "@reduxjs/toolkit";
import { INetworkRResponse } from "../../../interfaces/networkResponse.interface";
import { IGeneralWorkData } from "../general-work/generalWorkSlice";
import { deleteSanitoryWorks, getSanitoryWork, getSanitoryWorks, postSanitoryWork, updateSanitoryWorks } from "./sanitoryWorkAPI";


interface ISanitoryWorkById extends INetworkRResponse {
    data: IGeneralWorkData | null,

}

interface ISanitoryWork extends INetworkRResponse {
    data: null | IGeneralWorkData[],
    byId: ISanitoryWorkById,
    deleteSuccess: boolean,
    formSubmitted: boolean,
}
const initialState: ISanitoryWork = {
    data: null,
    error: null,
    loading: false,
    deleteSuccess: false,
    formSubmitted: false,
    byId: {
        data: null,
        error: null,
        loading: false
    }
}
const sanitoryWorkSlice = createSlice({
    name: "sanitoryWork",
    initialState,
    reducers: {
        cleanupSanitoryWork: (state) => {
            return initialState
        },

    },
    extraReducers(builder) {

        builder.addCase(getSanitoryWorks.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null
        })
        builder.addCase(getSanitoryWorks.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = null
        })
        builder.addCase(getSanitoryWorks.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
            state.error = null
        });
        builder.addCase(getSanitoryWork.pending, (state, { payload }) => {
            state.loading = true;
            state.byId.data = null;
            state.error = null
        });
        builder.addCase(getSanitoryWork.rejected, (state, { payload }) => {
            state.loading = false;
            state.byId.data = null;
            state.error = null
        });
        builder.addCase(getSanitoryWork.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.byId.data = payload;
            state.error = null
        })
        // delete work
        builder.addCase(deleteSanitoryWorks.pending, (state) => {
            state.deleteSuccess = false
        })
        builder.addCase(deleteSanitoryWorks.fulfilled, (state, { payload }) => {
            state.deleteSuccess = true;
            const newData = state.data?.filter((el) => el.id !== payload);
            state.data = newData || []
        });


        //update
        builder.addCase(updateSanitoryWorks.pending, (state) => {

            state.formSubmitted = false
        })
        builder.addCase(updateSanitoryWorks.fulfilled, (state) => {

            state.formSubmitted = true
        })


        //post
        builder.addCase(postSanitoryWork.pending, (state) => {

            state.formSubmitted = false
        })
        builder.addCase(postSanitoryWork.fulfilled, (state) => {

            state.formSubmitted = true
        })


    }
});
export const { cleanupSanitoryWork } = sanitoryWorkSlice.actions;
export default sanitoryWorkSlice.reducer