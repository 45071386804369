import styled from "styled-components/macro";

interface StyledDocumentPropsI {
  selected?: boolean;
}

export const StyledDocument = styled.div<StyledDocumentPropsI>`
  display: flex;
  align-items: center;
  margin: 4px;
  gap: 1rem;
  justify-content: space-between;
  padding: 5px 10px;
  max-width: 350px;
  border: solid;
  border-width: ${(props) => (props.selected ? "1px" : "0.5px")};
  border-radius: 4px;
  background: ${(props) => (props.selected ? "rgba(0, 114, 198, 0.2)" : null)};
  cursor: pointer;
  border-color: ${(props) => (props.selected ? "rgb(0, 114, 198)" : "#121212")};

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const StyledDocumentName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const StyledGrid = styled.div`
  padding: 20px;
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  align-items: stretch;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
`;
export const StyledButton = styled.button`
  border: none;
  font-size: 1.4rem;
  padding: 12px 30px;
  text-align: left;
  background: #1450a3;
  color: white;
  cursor: pointer;
  font-family: inherit;
  border-radius: 4px;
  &:hover {
    color: white;
    background: rgba(0, 114, 198, 1);
  }
`;
