import React, { useEffect, useState } from "react";
import MainLayout from "@ims/common/src/core-ui/layout/MainLayout";
import { AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import Table, { SortsTypeI } from "@ims/common/src/core-ui/table/Table";
import { useAppDispatch, useAppSelector } from "../../rtk/hook";
import { getUsers } from "../../rtk/feature/users/usersApi";
import { UserTypeI } from "../../rtk/feature/users/usersSlice";
import { useNavigate } from "react-router-dom";

import { FiEdit, FiFilter, FiSearch } from "react-icons/fi";
import { showLoading } from "../../rtk/feature/auth/authSlice";
import usePagination from "../../utils/usePagination";
import Page from "../../core-ui/Page";
import { Button, Grid } from "@ims/common";
import ReuseForm from "../../core-ui/ReuseForm";
import { useForm } from "react-hook-form";
import getFormattedSort from "../../utils/getFormattedSort";
import { BsPrinter } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import TableUtility, { htmlToPrint } from "./TableUtility";
import { useTranslation } from "react-i18next";
import useTranslated from "../../hooks/useTranslated";
import { FaUserCheck, FaUserPlus, FaUserTimes } from "react-icons/fa";
import { englishNepaliNumberTranslation } from "../../utils/translateNumber";

function UserManagePage() {
  const [users, setUsers] = useState<UserTypeI[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const usersState = useAppSelector((state) => state.users);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  /* Table pagination states */
  const [totalItems, setTotalItems] = useState(1);
  const [sorts, setSorts] = useState<SortsTypeI>({});

  const { Pagination, page, perPage, getSN } = usePagination({
    total: totalItems,
  });

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(
      getUsers({
        params: {
          per_page: perPage,
          page: page,
          ordering: getFormattedSort(sorts),
          search: getValues().search,
        },
        signal: abortController.signal,
      })
    );

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, perPage, page, sorts]);

  useEffect(() => {
    if (usersState.data) {
      setUsers(usersState.data.results);
      setTotalItems(usersState.data.total);
    }

    dispatch(showLoading(usersState.loading));
  }, [usersState, dispatch]);

  const handleEdit = (id: string) => {
    navigate(`/user/edit/${id}`);
  };

  const onSubmit = (data: any) => {
    dispatch(
      getUsers({
        params: {
          per_page: perPage,
          search: data.search,
          ordering: getFormattedSort(sorts),
        },
      })
    );
  };

  const handleReset = () => {
    let tempSorts = sorts;
    for (let key in sorts) {
      tempSorts[key].active = false;
    }
    setSorts({ ...tempSorts });
    dispatch(
      getUsers({
        params: {
          per_page: perPage,
          page: page,
          ordering: getFormattedSort(sorts),
        },
      })
    );
    reset();
  };

  const onSort = (data: any) => {
    const { field, active, asc } = data;

    let sorts1 = sorts;
    delete sorts1[field];

    sorts1 = { [field]: { active, asc }, ...sorts1 };
    setSorts(sorts1);
  };

  const handleShow = (id: string) => {
    navigate(`/profile/${id}`);
  };

  const tableUtility = new TableUtility("user-table", htmlToPrint);
  const { t } = useTranslation();
  const { keyword } = useTranslated();
  return (
    <MainLayout.Main>
      <Page.Container>
        <Page.Title> {t("User Manage")}</Page.Title>
        <Page.ButtonGrp>
          <Button startIcon={<CiExport />} onClick={tableUtility.export}>
            {t("Export")}
          </Button>

          <Button startIcon={<BsPrinter />} onClick={tableUtility.print}>
            {t("Print")}
          </Button>

          <Button startIcon={<FaUserPlus />} variant="primary" onClick={() => navigate(`/user/add`)}>
            {t("Add User")}
          </Button>
        </Page.ButtonGrp>
      </Page.Container>

      <ReuseForm onSubmit={handleSubmit(onSubmit)} control={control}>
        <Grid style={{ marginBottom: "1rem" }}>
          <Grid.Row gap={0.75}>
            <Grid.Col spanMd={4}>
              <ReuseForm.Input
                label={keyword["Search User"]}
                name="search"
                placeholder={keyword["Search User"]}
                rules={{ required: false }}
                error={errors["search"]}
                h="34px"
              />
            </Grid.Col>

            <Grid.Col spanMd={4} style={{ marginTop: "24px" }}>
              <Button variant="primary">
                <FiSearch />{" "}
              </Button>
              <Button type="reset" onClick={handleReset}>
                {t("Reset")}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </ReuseForm>

      <Table.Container>
        <Table id="user-table">
          <Table.HeadSort sorts={sorts}>
            <Table.Row>
              <Table.HeadCol>{keyword["S.N."]} </Table.HeadCol>
              <Table.HeadSortCol field="first_name" onSort={onSort}>
                {t("User Name")}
              </Table.HeadSortCol>
              <Table.HeadSortCol field="first_name" onSort={onSort}>
                {t("First Name")}
              </Table.HeadSortCol>
              <Table.HeadSortCol field="last_name" onSort={onSort}>
                {t("Last Name")}
              </Table.HeadSortCol>
              <Table.HeadSortCol field="email" onSort={onSort}>
                {t("Email")}
              </Table.HeadSortCol>
              <Table.HeadCol>{t("Role")}</Table.HeadCol>
              <Table.HeadCol>{t("Active")}</Table.HeadCol>
              <Table.HeadCol align="center">{t("Action")}</Table.HeadCol>
            </Table.Row>
          </Table.HeadSort>
          <Table.Body>
            {users &&
              users.length > 0 &&
              users.map((user, index) => {
                return (
                  <Table.Row key={user.id}>
                    <Table.Col>{englishNepaliNumberTranslation(getSN(index))}</Table.Col>
                    <Table.Col>{user.username}</Table.Col>

                    <Table.Col>{user.first_name}</Table.Col>

                    <Table.Col>{user.last_name}</Table.Col>
                    <Table.Col>{user.email}</Table.Col>
                    <Table.Col>{user.role}</Table.Col>
                    <Table.Col>
                      <span style={{ marginLeft: "30%" }}>
                        {user.is_active ? (
                          <FaUserCheck color="green" size={20} title={keyword["Active"]} />
                        ) : (
                          <FaUserTimes color="#555" size={20} title={keyword["Not Active"]} />
                        )}
                      </span>
                    </Table.Col>
                    <Table.Col align="center">
                      <Table.ActionButton onClick={() => handleEdit(user.id)}>
                        <FiEdit />
                      </Table.ActionButton>

                      <Table.ActionButton onClick={() => handleShow(user.id)}>
                        <AiOutlineEye color="#8d1818" />
                      </Table.ActionButton>
                    </Table.Col>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Pagination />
      </Table.Container>
    </MainLayout.Main>
  );
}

export default UserManagePage;
