import { HTMLAttributes, HtmlHTMLAttributes } from "react";
import {
  StyledGrid,
  StyledGridCol,
  StyledGridContainer,
  StyledGridRow,
} from "./Grid.style";

interface GridPropsI extends HtmlHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export default function Grid(props: GridPropsI) {
  return <StyledGrid {...props} />;
}

interface GridRowPropsI {
  children?: React.ReactNode;
  gap?: number;
}

interface GridContainerPropsI extends HtmlHTMLAttributes<HTMLDivElement> {}

Grid.Container = function GridContainerFunction(props: GridContainerPropsI) {
  return <StyledGridContainer {...props} />;
};

Grid.Row = function GridRowFunction(props: GridRowPropsI) {
  return <StyledGridRow {...props} />;
};

export interface GridColPropsI extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  spanXs?: number;
  spanSm?: number;
  spanMd?: number;
  spanLg?: number;
  spanXl?: number;
}

Grid.Col = function GridColFunction(props: GridColPropsI) {
  return <StyledGridCol {...props} />;
};
