import React, {
  createContext,
  HtmlHTMLAttributes,
  useContext,
  useEffect,
  useState,
} from "react";
import { useWindowSize } from "../../utils/useWindowSize";
import {
  MainLayoutAside,
  MainLayoutHeader,
  MainLayoutMain,
  MainLayoutRoot,
  MainLayoutSection,
} from "./MainLayout.style";

export const LayoutContext = createContext<{
  expanded: boolean;
  setExpanded: Function;
  mobileNavOpen: boolean;
  setMobileNavOpen: Function;
}>({
  expanded: true,
  setExpanded: () => {},
  mobileNavOpen: false,
  setMobileNavOpen: () => {},
});

interface MainLayoutPropsI {
  children: React.ReactNode;
}

/* Navbar state i.e expanded or collapsed should be maintained even after refreshing the page 

  1. store navbar state when toggled in localstorage
  2. retrive navbar state
  3. set navbar state 
  */
export class NavbarState {
  hasExpanded: boolean;

  constructor() {
    this.hasExpanded = true;
  }

  saveState(hasExpanded: boolean) {
    localStorage.setItem("hasNavExpanded", JSON.stringify(hasExpanded));
  }

  getState() {
    const tempItem = localStorage.getItem("hasNavExpanded");
    if (tempItem) this.hasExpanded = Boolean(JSON.parse(tempItem));
    return this.hasExpanded;
  }
}

const navbarState = new NavbarState();

export default function MainLayout(props: MainLayoutPropsI) {
  const [expanded, setExpanded] = useState(navbarState.getState());
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const size = useWindowSize();
  const { children } = props;

  useEffect(() => {
    if (size && size.width && size.width <= 920) {
      setExpanded(true);
      setMobileNavOpen(false);
      navbarState.saveState(true);
    }
  }, [size]);

  return (
    <LayoutContext.Provider
      value={{ expanded, setExpanded, mobileNavOpen, setMobileNavOpen }}
    >
      <MainLayoutRoot>{children}</MainLayoutRoot>
    </LayoutContext.Provider>
  );
}

interface MainLayoutMainPropsI extends HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

MainLayout.Main = function MainLayoutMainFunction(props: MainLayoutMainPropsI) {
  return <MainLayoutMain {...props} />;
};

interface MainLayoutHeaderPropsI {
  children?: React.ReactNode;
}

MainLayout.Header = function MainLayoutHeaderFunction(
  props: MainLayoutHeaderPropsI
) {
  const { children } = props;
  return <MainLayoutHeader>{children}</MainLayoutHeader>;
};

interface MainLayoutSectionPropsI {
  children?: React.ReactNode;
}

MainLayout.Section = function MainLayoutSectionFunction(
  props: MainLayoutSectionPropsI
) {
  const { children } = props;
  return <MainLayoutSection>{children}</MainLayoutSection>;
};

interface MainLayoutAsidePropsI {
  children?: React.ReactNode;
}

MainLayout.Aside = function MainLayoutAsideFunction(
  props: MainLayoutAsidePropsI
) {
  const { children } = props;
  const { expanded, mobileNavOpen } = useContext(LayoutContext);

  return (
    <MainLayoutAside
      className={expanded ? "" : "expanded"}
      overlay={mobileNavOpen}
    >
      {children}
    </MainLayoutAside>
  );
};
