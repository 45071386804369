import { createAsyncThunk } from "@reduxjs/toolkit";
import async from "react-select/dist/declarations/src/Async";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { VerifySuccess } from "../../../utils/res-mes/response-message-generator";

export const getBillVerifications = createAsyncThunk(
  "getBillVerifications",
  async (
    userParams: { per_page: number; page?: number; search?: any; ordering?: any; signal?: AbortSignal } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const res = await imsClient.get(`/get-bill-verification/`, {
        params: userParams,
        signal: userParams?.signal,
      });
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getBillVerificationById = createAsyncThunk(
  "getBillVerificationsById",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/bill-verifications/${id}`);
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

interface IPostBillVerification {
  data: any;
  id: string;
}
export const postBillVerification = createAsyncThunk(
  "getBillVerificationsById",
  async ({ id, data }: IPostBillVerification, { rejectWithValue }) => {
    try {
      const res = await imsClient.post(`planning-bill/${id}/bill-verification/`, data);
      if (res.data) toast.success(VerifySuccess("verification"));
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

interface IUpdateBillVerification {
  id: string;
  data: any;
}

export const updatetBillVerification = createAsyncThunk(
  "updateBillVerificationsById",
  async ({ id, data }: IUpdateBillVerification, { rejectWithValue, dispatch }) => {
    try {
      const res = await imsClient.patch(`get-bill-verification/${id}/`, data);
      if (res.data) toast.success(VerifySuccess("verified"));
      dispatch(getBillVerifications());
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

interface ISendBillToLekha {
  user_id: string;
  plan_bill_verification: string;
}

export const sendBillToLekha = createAsyncThunk(
  "sendBillToLekha",
  async (data: ISendBillToLekha, { rejectWithValue, dispatch }) => {
    try {
      const res = await imsClient.post(`cheque-verify-by-lekha/`, data);
      if (res.data) toast.success(VerifySuccess("verification"));
      dispatch(getBillVerifications());
      return res.data;
    } catch (error) {
      const appError = new AppError(error);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
