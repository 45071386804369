import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import styled from "styled-components/macro";
import { FileUploadInputI } from "./Form";

export const StyledForm = styled.form``;

export const StyledFormFormGroup = styled.div<{ hasCheck?: boolean }>`
  display: flex;
  font-size: 1.4rem;
  flex-direction: ${({ hasCheck }) => (hasCheck ? "row" : "column")};
  gap: ${({ hasCheck }) => (hasCheck ? "1rem" : "0px")};
  align-items: ${({ hasCheck }) => (hasCheck ? "center" : "stretch")};
  padding: ${({ hasCheck }) => (hasCheck ? "0rem 0px" : "0px")};

  ${(props) => props.hasCheck && `label {margin-bottom: 0px;}`}

  &.form-error {
    label,
    small {
      color: ${(props) => props.theme.palette.error.main};
    }

    input,
    select,
    textarea {
      border: 1px solid ${(props) => props.theme.palette.error.main};
    }
  }
`;

export const StyledFormLabel = styled.label`
  text-align: left;
  margin-bottom: 0.25rem;
  display: inline-block;
  font-size: 1.4rem;
  text-transform: capitalize;
`;

export const StyledFormFloatingLabel = styled.label<{
  active: boolean;
  filled: boolean;
}>`
  position: absolute;
  top: ${(props) => (props.active ? "-12px" : props.filled ? "-12px" : "8px")};
  left: 0;
  transition: top 0.3s, font-size 0.3s;
  font-size: ${(props) =>
    props.active ? "12px" : props.filled ? "12px" : "16px"};
  color: #333;
  pointer-events: none;
  user-select: none;
  background: white;
  padding: 0 5px;
`;

export const StyledFormInput = styled.input`
  font: inherit;
  padding: 0.5rem 1.1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "40px")};
  // &:hover,
  // &:focus-visible,
  // &:focus {
  //   transition: 0.3s all ease;
  //   border: 1px solid rgba(0, 0, 255, 0.5);
  // }

  padding-left: ${(props) => (props.type === "file" ? "0px" : "1.1rem")};

  &[type="file"] {
    border: 0px solid white;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const StyledFormFloatingInput = styled.input`
  font: inherit;
  padding: 0.7rem 1.1rem;
  outline: none;
  border: none;
  border-bottom: 1px solid #000000de;
  background: none;
  transition: border-bottom 0.3s;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "40px")};
  padding-left: ${(props) => (props.type === "file" ? "0px" : "1.1rem")};

  &:focus {
    border-bottom: 1px solid #007bff;
  }
`;

export const StyledNepaliDatePicker = styled(NepaliDatePicker)`
  input {
    font: inherit;
    padding: 0.7rem 1.1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;

    &:hover,
    &:focus-visible,
    &:focus {
      transition: 0.3s all ease;
      border: 1px solid rgba(0, 0, 255, 0.5);
    }

    padding-left: 1.1rem;
    width: 100%;
  }
`;

export const StyledFormTextArea = styled.textarea`
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.7rem 1.1rem;
`;

export const StyledFormSelect = styled.select`
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.7rem 1.1rem;
  text-transform: capitalize;
`;

export const StyledFormOption = styled.option`
  text-transform: capitalize;
`;

export const StyledSmall = styled.small`
  line-height: 1.6;
  text-align: left;
`;

export const StyledFormLink = styled(Link)`
  margin-top: 8px;
  display: inline-block;
  color: ${(props) => props.theme.palette.text.primary} !important;
  font-size: 1.2rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledReactSelect = styled(Select)`
  & input:focus-visible {
    box-shadow: none !important;
    outline: none;
  }

  /* & > div {
    transition: 0.3s all ease;

    &:hover,
    &:active,
    &:focus-visible,
    &:focus {
      border: 1px solid rgba(0, 0, 255, 0.5) !important;
      box-shadow: rgb(92 147 237 / 50%) 0px 0px 0px 2px;
      outline: none;
    }
  } */
`;

export const StyledAsyncReactSelect = styled(AsyncSelect)`
  & input:focus-visible {
    box-shadow: none !important;
    outline: none;
  }
`;

export const StyledFileUpload = styled.input`
  visibility: hidden;
`;
export const StyledUploadButton = styled.label<FileUploadInputI>`
  background-color: #e7e7e7;
  border: none;
  color: black;
  padding: 0.9rem 2rem;
  font-size: 1.4rem;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
  margin-right: 1rem;
  cursor: pointer;
  transition: 0.3s all ease;

  ${(props) =>
    props.variant === "primary" &&
    `background: ${props.theme.palette.primary.main}; 
     color: ${props.theme.palette.primary.contrastText};`};

  ${(props) =>
    props.variant === "error" &&
    `background: ${props.theme.palette.error.main}; 
     color: ${props.theme.palette.error.contrastText};`};

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 1;
  }

  &:focus-visible {
    box-shadow: rgb(92 147 237 / 50%) 0px 0px 0px 3.2px;
    outline: none;
  }
`;

export const PreviewImageStyles = styled.img`
  width: 20rem;
  height: 20rem;
  object-fit: contain;
`;
