import { createSlice } from "@reduxjs/toolkit";
import { addConstructorDetails, getConstructorDetails, getFilteredConstructorDetails } from "./assignConstructorApi";
import AssingnConstructor from "../../../pages/plan/assign-constructor/AssignConstructor";

export interface AssignConstructorSliceStateI {
  loading: boolean;
  data: any;

  hasSubmitted: boolean;
  error: any;
}

const initialState: AssignConstructorSliceStateI = {
  loading: false,
  data: [],
  hasSubmitted: false,
  error: null,
};

const assignConstructorSlice = createSlice({
  name: "assignConstructorSlice",
  initialState,
  reducers: {
    assignConstructorCleanUp: (state) => {
      state.data = [];
      state.hasSubmitted = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConstructorDetails.pending, (state) => {
      state.loading = true;
      state.data = [];
      state.error = null;
    });
    builder.addCase(getConstructorDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = null;
    });
    builder.addCase(getConstructorDetails.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = [];
      state.error = true;
    });
    builder.addCase(addConstructorDetails.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(addConstructorDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hasSubmitted = true;
      state.error = false;
    });
    builder.addCase(addConstructorDetails.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
    builder.addCase(getFilteredConstructorDetails.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.hasSubmitted = false;
    });
    builder.addCase(getFilteredConstructorDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = false;
    });
    builder.addCase(getFilteredConstructorDetails.rejected, (state) => {
      state.loading = false;
      state.hasSubmitted = false;
      state.error = true;
    });
  },
});

export const { assignConstructorCleanUp } = assignConstructorSlice.actions;
export default assignConstructorSlice.reducer;
