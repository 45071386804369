import { createAsyncThunk } from "@reduxjs/toolkit";
import { imsClient } from "../../../utils/imsClient";
import { AppError } from "../../../utils/AppError";
import { toast } from "react-toastify";
import { DocumentSuccess } from "../../../utils/res-mes/response-message-generator";

export const getContractorDocs = createAsyncThunk(
  "getContractorDocs",
  async (constractorId: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/yojana-contractor-details/${constractorId}/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addContractorDoc = createAsyncThunk(
  "addContractorDoc",
  async (data: { contractor_details: string; title: string; file: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/contractor-docs/`, data);
      if (res.data) toast.success(DocumentSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
export const updateContractorDoc = createAsyncThunk(
  "updateContractorDoc",
  async (args: { contractorId: string; data: { title?: string; file?: string } }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/contractor-docs/${args.contractorId}/`, args.data);
      if (res.data) toast.success(DocumentSuccess("updated"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
