import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppError } from "../../../utils/AppError";
import { imsClient } from "../../../utils/imsClient";
import { FixedAnalyzeRateFormTypeI } from "./fixedAnalyzeRateSlice";
import { FixedAnalyzeRateSuccess } from "../../../utils/res-mes/response-message-generator";

export const getFixedAnalyzeRates = createAsyncThunk(
  "getFixedAnalyzeRates",
  async (
    data: { per_page: number; page?: number; search?: any; ordering?: any; signal?: any },
    { dispatch, rejectWithValue }
  ) => {
    const { per_page, page, search, ordering, signal } = data;
    try {
      const res = await imsClient.get(`/rate-analysis/`, {
        params: { per_page, page, search, ordering },
        signal: signal,
      });
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const getFixedAnalyzeRate = createAsyncThunk(
  "getFixedAnalyzeRate",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.get(`/rate-analysis/${id}/`);
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const addFixedAnalyzeRate = createAsyncThunk(
  "addFixedAnalyzeRate",
  async (data: FixedAnalyzeRateFormTypeI, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.post(`/rate-analysis/`, data);
      if (res.data) toast.success(FixedAnalyzeRateSuccess("added"));
      return res.data;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const deleteFixedAnalyzeRate = createAsyncThunk(
  "deleteFixedAnalyzeRate",
  async (deleteId: string, { dispatch, rejectWithValue }) => {
    try {
      await imsClient.delete(`/rate-analysis/${deleteId}/`);
      toast.success(FixedAnalyzeRateSuccess("deleted"));
      return deleteId;
    } catch (err) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);

export const updateFixedAnalyzeRate = createAsyncThunk(
  "updateFixedAnalyzeRate",
  async (data: { data: FixedAnalyzeRateFormTypeI; id: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await imsClient.patch(`/rate-analysis/${data.id}/`, data.data);
      if (res.data) toast.success(FixedAnalyzeRateSuccess("deleted"));
      return res.data;
    } catch (err: any) {
      const appError = new AppError(err);
      appError.show();
      return rejectWithValue(appError.getError());
    }
  }
);
