import { createSlice } from "@reduxjs/toolkit";
import { INetworkRResponse, IPaginatedResponse } from "../../../interfaces/networkResponse.interface";
import { IYojana } from "../plan-verification/planVerificationSlice";
import { getChequeVerifyByPrasasakiya, getChequeVerifyByPrasasakiyaById, postChequeVerifyByPrasasakiya } from "./chequeVerifyByPrasasakiyaAPI";

export interface FromToUser {
    id: string
    username: string
    email: string
    mobile_number: string
    role: string
    ward: string
    first_name: string
    last_name: string
}

interface PlanBillVerification {
    id: string
    planning_bill_id: PlanningBillId
    status: string
    description: string
    comment: string
    is_verified: boolean
    from_to_user: FromToUser
}

interface PlanningBillId {
    id: string
    yojana: IYojana
    bill_name: string
    bill_file: string
    bill_review: boolean
    is_verified: boolean
}




interface PlanCheque {
    id: string
    plan_bill_verification: PlanBillVerification
    cheque_verify_by_lekha: boolean
    cheque_photo: any
    send_to_user: FromToUser
}





export interface IChequeVerificationByPrasasakiya {
    id: string
    plan_cheque: PlanCheque
    user_id: string
    cheque_verify_by_prasasakiya: boolean
    updated_cheque_photo: string
}


interface IChequeVerificationByPrasasakiyaById extends
    INetworkRResponse {
    data: null | IChequeVerificationByPrasasakiya
}

interface IInitialState extends INetworkRResponse {
    data: null | IPaginatedResponse<IChequeVerificationByPrasasakiya>,
    byId: IChequeVerificationByPrasasakiyaById,
    formSubmitted: boolean
};
const initialState: IInitialState = {
    data: null,
    error: null,
    loading: false,
    byId: {
        data: null,
        error: null,
        loading: false
    },
    formSubmitted: false
}

const chequeVerifyByPrasasakiya = createSlice({
    name: "chequeVerifyByPrasasakiya",
    initialState,
    reducers: {
        cleanupChequeVerifyByPrasasakiya: (state) => {
            return initialState
        }
    }
    ,
    extraReducers(builder) {

        builder.addCase(getChequeVerifyByPrasasakiya.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null
        });
        builder.addCase(getChequeVerifyByPrasasakiya.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = null
        });
        builder.addCase(getChequeVerifyByPrasasakiya.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
            state.error = null
        });

        builder.addCase(getChequeVerifyByPrasasakiyaById.pending, (state) => {
            state.byId.loading = true;
            state.byId.data = null;
            state.byId.error = null
        });
        builder.addCase(getChequeVerifyByPrasasakiyaById.rejected, (state) => {
            state.byId.loading = false;
            state.byId.data = null;
            state.byId.error = null
        });

        builder.addCase(getChequeVerifyByPrasasakiyaById.fulfilled, (state, { payload }) => {
            state.byId.loading = false;
            state.byId.data = payload;
            state.byId.error = null
        });


        //

        builder.addCase(postChequeVerifyByPrasasakiya.pending, (state) => {
            state.formSubmitted = false
        });


        builder.addCase(postChequeVerifyByPrasasakiya.fulfilled, (state, { payload }) => {
            state.formSubmitted = true

        });


    },

});
export const { cleanupChequeVerifyByPrasasakiya } = chequeVerifyByPrasasakiya.actions;
export default chequeVerifyByPrasasakiya.reducer