import React, { ReactNode } from "react";
import { roleTypes } from "../../rtk/feature/auth/authSlice";
import { useAppSelector } from "../../rtk/hook";
interface IProps {
  children: JSX.Element | JSX.Element[] | ReactNode[];
  notAllowedRoles?: roleTypes[];
  allowedRoles?: roleTypes[];
}
const ProtectWithRole = (props: IProps): JSX.Element => {
  const myRole = useAppSelector((state) => state.auth.user?.role);

  if (
    myRole &&
    (props?.allowedRoles?.includes(myRole) ||
      (!!props?.notAllowedRoles?.length && !props.notAllowedRoles?.includes(myRole)))
  )
    return <>{props.children} </>;
  return <></>;
};

export default ProtectWithRole;
