export const KeywordBeautify = (status: string): string => {
  return status.split("_").join(" ");
};

export const styleSN = (num: number): string => {
  if (num < 10) return `0${num}`;
  else return String(num);
};

export const prettifyNumberValue = (val: number | string | null | undefined) => {
  return !!val && +val > -1 ? val : "__";
};
