import { NepaliDatePickerProps } from "nepali-datepicker-reactjs/dist/NepaliDatePicker/Types";
import React, {
  ChangeEvent,
  FormHTMLAttributes,
  HtmlHTMLAttributes,
  InputHTMLAttributes,
  Ref,
  RefAttributes,
  SelectHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { BsUpload } from "react-icons/bs";
import { LinkProps } from "react-router-dom";
import Select, { ActionMeta, StylesConfig } from "react-select";
import Button from "../button/Button";
import {
  StyledForm,
  StyledFormFormGroup,
  StyledFormLabel,
  StyledFormInput,
  StyledSmall,
  StyledFormSelect,
  StyledFormOption,
  StyledFormTextArea,
  StyledNepaliDatePicker,
  StyledReactSelect,
  StyledFormLink,
  StyledAsyncReactSelect,
  StyledFileUpload,
  StyledUploadButton,
  PreviewImageStyles,
  StyledFormFloatingInput,
  StyledFormFloatingLabel,
} from "./Form.style";
import useTranslated from "../../../../admin/src/hooks/useTranslated";

interface FormPropsI extends React.FormHTMLAttributes<HTMLFormElement> {}

export default function Form(props: FormPropsI) {
  return <StyledForm onSubmit={props.onSubmit}>{props.children}</StyledForm>;
}

interface FormFormGroupPropsI extends HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  hasCheck?: boolean;
  error?: boolean;
}

Form.FormGroup = function FormFormGroup({
  error,
  ...props
}: FormFormGroupPropsI) {
  return (
    <StyledFormFormGroup className={error ? "form-error" : ""} {...props} />
  );
};

interface FormLabelPropsI extends React.LabelHTMLAttributes<HTMLLabelElement> {
  for?: string;
}

Form.Label = function FormLabel(props: FormLabelPropsI) {
  return <StyledFormLabel {...props} />;
};

interface FormLinkPropsI extends LinkProps {}

Form.Link = function FormLink(props: FormLinkPropsI) {
  return <StyledFormLink {...props} />;
};

interface FormInputPropsI extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  name?: string;
  value?: string;
  id?: string;
  register?: any;
  ref?: any;
  width?: any;
  height?: any;
  isDisable?: any;
}
interface FormFloatingInputPropsI
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder?: string;
  name?: string;
  value?: string;
  id?: string;
  register?: any;
  ref?: any;
  width?: any;
  height?: any;
}

Form.Input = React.forwardRef(function FormInput(props: FormInputPropsI, ref) {
  const { register, isDisable = false, autoComplete, ...otherProps } = props;
  return (
    <StyledFormInput
      step="any"
      ref={ref}
      {...otherProps}
      disabled={isDisable}
      {...register}
    />
  );
});

Form.FloatingInput = React.forwardRef(function FormInput(
  props: FormFloatingInputPropsI,
  ref
) {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const { register, autoComplete, label, ...otherProps } = props;
  return (
    <div style={{ position: "relative" }}>
      <StyledFormFloatingLabel active={isActive} filled={inputValue.length > 0}>
        {label}
      </StyledFormFloatingLabel>
      <StyledFormFloatingInput
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        step="any"
        ref={ref}
        {...otherProps}
        {...register}
      />
    </div>
  );
});

interface FormTextAreaPropsI extends HtmlHTMLAttributes<HTMLTextAreaElement> {
  placeholder?: string;
  name?: string;
  value?: string;
  id?: string;
  ref?: any;
  register?: any;
  isDisable?: any;
}

const colourStyles: StylesConfig<{}> = {
  control: (styles) => ({ ...styles }),
};

Form.ReactSelect = function FormReactSelect(props: any) {
  return <StyledReactSelect styles={colourStyles} {...props} />;
};

Form.AsyncSelect = function FormAsyncSelect(props: any) {
  return <StyledAsyncReactSelect styles={colourStyles} {...props} />;
};

Form.TextArea = React.forwardRef(function FormTextArea(
  props: FormTextAreaPropsI,
  ref
) {
  const { register, isDisable = false, ...otherProps } = props;

  return (
    <StyledFormTextArea
      disabled={isDisable}
      ref={ref}
      {...otherProps}
      {...register}
    />
  );
});

Form.NepaliDatePicker = function FormNepaliDatePicker(
  props: NepaliDatePickerProps
) {
  return <StyledNepaliDatePicker {...props} />;
};

interface FormSelectPropsI extends SelectHTMLAttributes<HTMLSelectElement> {
  children: React.ReactNode;
}

Form.Select = function FormSelect(props: FormSelectPropsI) {
  return <StyledFormSelect {...props} />;
};

interface FormOptionPropsI extends SelectHTMLAttributes<HTMLOptionElement> {
  children: React.ReactNode;
  value?: string;
}

Form.Option = function FormOption(props: FormOptionPropsI) {
  return <StyledFormOption {...props} />;
};

interface FormHelperTextPropsI {
  children: React.ReactNode;
}

Form.HelperText = function FormHelperText(props: FormHelperTextPropsI) {
  return <StyledSmall {...props} />;
};
export interface FileUploadInputI
  extends InputHTMLAttributes<HTMLInputElement> {
  accept?: string;
  multiple?: boolean;
  fileType?: string;
  variant?: "primary" | "secondary" | "error";
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  previewImage?: string | null;
  previewName?: string;
  icon?: any;
  clearData?: boolean;
}

Form.File = function FormFile(props: FileUploadInputI) {
  const { keyword } = useTranslated();
  const [fileName, setFileName] = useState("");
  const [previewImage, setPreviewImage] = useState(props.previewImage);

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const previewImageUrl = URL.createObjectURL(e.target.files[0]);
      setPreviewImage(previewImageUrl);
      setFileName(e.target?.files[0]?.name);
    }
    props.onChange?.(e);
  };

  useEffect(() => {
    if (!props.clearData) {
      setPreviewImage(null);
      setFileName("");
    }
  }, [props.clearData]);

  return (
    <>
      <StyledUploadButton htmlFor={props.id}>
        <BsUpload /> {keyword["Upload"]}
      </StyledUploadButton>
      <StyledFileUpload
        hidden
        id={props.id}
        type="file"
        accept="xlsx"
        {...props}
        onChange={handleFile}
      />

      <h5>{fileName}</h5>

      {props.fileType === "image" && (previewImage || props?.previewImage) && (
        <PreviewImageStyles
          src={previewImage || `${props.previewImage}` || "/logo.png"}
        />
      )}
    </>
  );
};
