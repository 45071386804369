import { roleTypes } from "../rtk/feature/auth/authSlice";

const roleKeys = [
  "NORMAL_USER",
  "IT_OFFICER",
  "PALIKA_USER",
  "WARD_SACHIB",
  "Prasasakiya_Adhikrit",
  "Purwadhar_Sakha",
  "Yojana_Sakha",
  "Gaupalika_Stariya_Anugaman_Samiti",
  "Lekha_Sakha",
  "ADMIN",
  "Engineer",
  "SUB_ENGINNER",
  "UPABHOKTA_SAMITI",
] as const;

export type RoleKey = (typeof roleKeys)[number];

export const roles: Record<RoleKey, roleTypes> = {
  NORMAL_USER: "Normal User",
  IT_OFFICER: "IT Officer",
  PALIKA_USER: "Palika User",
  WARD_SACHIB: "Ward Sachib",
  Prasasakiya_Adhikrit: "Prasasakiya Adhikrit",
  Purwadhar_Sakha: "Purwadhar Sakha",
  Yojana_Sakha: "Yojana Sakha",
  Gaupalika_Stariya_Anugaman_Samiti: "Gaupalika Stariya Anugaman Samiti",
  Lekha_Sakha: "Lekha Sakha",
  ADMIN: "Admin",
  Engineer: "Engineer",
  SUB_ENGINNER: "Sub Engineer",
  UPABHOKTA_SAMITI: "Upabhokta Samiti",
};
